<template>
  <button
    :class="{ 'button-grey-disabled': this.disabled }"
    class="button-grey"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "ButtonGray",

  props: {
    disabled: Boolean,
  },
};
</script>

<style scoped>
.button-grey {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 48px;
  padding: 0 25px;

  border-radius: 12px;
  box-sizing: border-box;
  transition: 0.2s all ease-in-out;

  font-size: 16px !important;
  color: #1e2022;

  background-color: #f7f9fc;
}

.button-grey:hover {
  background-color: #fed47a;
}

.button-grey:active {
  color: #a1a2a2;
  background-color: #f0f3f8;
}

.button-grey-disabled:hover {
  background-color: #f7f9fc !important;
}
</style>