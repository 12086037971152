import axios from "axios"

export default {
  state: () => ({
    owner: {},

    lastOwnerData: {},

    confirmPopupVisible: false,

    confirming: false,

    editProfileData: false,

    loadingProfile: true,
  }),
  mutations: {
    setOwnerInfo(state, info) {
      state.owner = {
        ...info
      };
      state.lastOwnerData = {
        ...info
      };
    },
    setEditProfileData(state, value) {
      state.editProfileData = value
    },
    setLoaderProfile(state, value) {
      state.loadingProfile = value
    }
  },
  getters: {
    getOwnerInfo(state) {
      return state.owner
    }
  },
  actions: {
    async getOwnerInfo(context) {
      context.commit('setLoaderProfile', true)
      return await axios({
        method: 'get',
        url: `${window.apiPath}/owner`,
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
        },
      }).then((request) => {
        context.commit('setLoaderProfile', false)
        context.commit('setOwnerInfo', request.data.data.company_owner)

        context.commit('telegram/setTelegramPhone', request.data.data.company_owner.phone, {root: true})
      }).catch((err) => {
        context.commit('setLoaderProfile', false)
        context.commit("addDashboardNotification", {
          text: `Ошибка ${err.response.status} при запросе данных`,
          type: "err",
        });
      })
    },
    async postOwnerInfo(context) {
      const isChangePhone = context.state.owner.phone !== context.state.lastOwnerData.phone
      if (!isChangePhone || context.state.confirming) {
        console.log('сохранено');

        context.commit('setLoaderProfile', true)
        return await axios({
          method: 'put',
          url: `${window.apiPath}/owner`,
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          },
          params: context.getters.getOwnerInfo
        }).then((request) => {
          if (isChangePhone) {
            context.dispatch('logout')
          } else {
            context.commit('setOwnerInfo', request.data.data.company_owner)
            context.commit('setLoaderProfile', false)
            context.commit('setEditProfileData', false)

            context.commit("addDashboardNotification", {
              text: `Успешно`,
              type: "ok",
            });
          }
        }).catch((err) => {
          context.commit('setLoaderProfile', false)
          context.commit("addDashboardNotification", {
            text: `Ошибка ${err.response.status} при сохранении данных`,
            type: "err",
          });
        })
      } else {
        context.state.confirmPopupVisible = true
      }

    }
  },
}