import axios from "axios";

export default {
  state: () => ({
    loadingSmsStatus: false,
    loadingSmsSettings: false,
    loadingSmsProviders: false,
    loadingSmsToggle: false,
    smsStatus: {},
    smsSettings: {},
    smsProviders: {},
    curentSmsProvider: null,
    smsHelp: {}
  }),
  getters: {

  },
  mutations: {
    setSmsStatus(state, status) {
      state.smsStatus = status
    },
    setSmsSettings(state, settings) {

      state.smsSettings = settings

      const objProps = state.smsSettings.form_fields.properties;

      if (settings.settings === undefined) {
        state.smsSettings.settings = {}

        for (let key in objProps) {
          state.smsSettings.settings[key] = ''
        }
      }

      if (typeof settings.settings === 'string') {
        state.smsSettings.settings = JSON.parse(settings.settings)
      }

      for (let key in objProps) {

        switch (objProps[key].title) {
          case 'Sender':
            objProps[key].label = 'Имя отправителя';
            break;
          case 'Username':
            objProps[key].label = 'Имя пользователя';
            break;
          case 'Password':
            objProps[key].label = 'Пароль';
            break;
          case 'Timeout':
            objProps[key].label = 'Таймаут';
            break;
          case 'Токен':
            objProps[key].label = 'Токен'
            break;
          default:
            objProps[key].label = objProps[key].title
        }
      }
    },
    setCurentSmsProvider(state, provider) {
      state.curentSmsProvider = provider;
    },
    setSmsProviders(state, providers) {
      state.smsProviders = providers
    },
    setLoadingSmsStatus(state, value) {
      state.loadingSmsStatus = value;
    },
    setLoadingSmsSettings(state, value) {
      state.loadingSmsSettings = value;
    },
    setLoadingSmsProviders(state, value) {
      state.loadingSmsProviders = value
    },
    setLoadingSmsToggle(state, value) {
      state.loadingSmsToggle = value
    },
    setSmsHelp(state, value) {
      state.smsHelp = value
    }
  },
  actions: {
    async getSmsSatatus(context) {
      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/sms/status`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        }
      }).then(request => {
        context.commit('setSmsStatus', request.data.data)
        context.commit('setLoadingSmsStatus', false)
      }).catch(() => {
        context.commit('setLoadingSmsStatus', false)
      })
    },
    async getSmsProviders(context) {
      context.commit('setLoadingSmsProviders', true)
      return await axios({
        type: 'get',
        url: `${window.apiPath}/company/sms/provider/list`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        }
      }).then(request => {
        context.commit('setSmsProviders', request.data.data)

        if (request.data.data.selected) {
          context.commit('setCurentSmsProvider', request.data.data.selected)
          context.dispatch('getSmsSettings').then(() => {
            context.commit('setLoadingSmsProviders', false)
          })
        } else {
          context.commit('setLoadingSmsProviders', false)
        }

      }).catch(() => {
        context.commit('setLoadingSmsProviders', false)
      })
    },
    async getSmsSettings(context) {
      context.commit('setLoadingSmsSettings', true)
      const currentSmsProvider = context.state.curentSmsProvider
      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/sms/settings/${currentSmsProvider}`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        }
      }).then((request) => {
        context.commit('setSmsSettings', request.data.data)
        context.commit('setLoadingSmsSettings', false)
      }).catch(() => {
        context.commit('setLoadingSmsSettings', false)
      })
    },
    async putSmsSettings(context) {
      const fromObj = {
        provider: context.state.curentSmsProvider,
        ...context.state.smsSettings.settings
      }

      context.commit('setLoadingSmsSettings', true)
      return await axios({
        method: 'put',
        url: `${window.apiPath}/company/sms/settings`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
        data: {
          ...fromObj
        }
      }).then(() => {
        context.dispatch('getSmsSatatus').then(() => {
          context.dispatch('getSmsSettings').then(() => {
            context.commit('setLoadingSmsSettings', false)
          })
        })
      }).catch(() => {
        context.commit('setLoadingSmsSettings', false)
      })
    },
    async toggleSmsActive(context) {
      context.commit('setLoadingSmsToggle', true)

      return await axios({
        method: 'POST',
        url: `${window.apiPath}/company/sms/toggle`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },

      }).then(() => {
        context.dispatch('getSmsSatatus')
        context.commit('setLoadingSmsToggle', false)
      }).catch(err => {
        context.commit('setLoadingSmsToggle', false)

        console.log(err);
      })
    },
    getSmsHelp(context) {
      if (!context.state.smsHelp.title) {
        context.dispatch('flatPages', {
          url: 'help_sms',
          mutation: 'setSmsHelp'
        }).then(() => {}).catch(() => {
          context.commit('addDashboardNotification', {
            text: 'При загрузке инструкции, произошла ошибка',
            type: 'err'
          })
        })
      }

    },
  },
}