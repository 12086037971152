<template>
  <cm-card :ratio="88.311688" :specialClass="specialClass">
    <div class="serviceCardWrapper position-relative h-100">
      <div class="card__wrapper">
        <div class="card__header">
          <div class="image-wrapper" v-if="img" :class="{'blur': loading}">
            <div
              class="servicesImg"
              :style="{ 'background-image': 'url(' + img + ')' }"
            ></div>
          </div>

          <div v-else class="cardTitle d-flex align-items-center" :class="{'blur': loading}">
            <h3>{{ title }}</h3>
          </div>

          <status-icon class="serviceCardWrapper-icon" :type="statusIcon" :loader="loading"></status-icon>
        </div>

        <div
          v-if="!statuses"
          class="cardContent d-flex align-items-center fs-16"
          :class="{'blur': loading}"
        >
          <div>
            <p class="card-text" v-html="text"></p>
          </div>
        </div>

        <div v-else class="statusesWrap" :class="{'blur': loading}">
          <div
            v-for="(status, index) in statuses"
            :key="index"
            class="statuses__main d-flex justify-content-between"
          >
            <p class="card-text w-50" v-html="status.name"></p>
            <p class="card-text w-50" v-html="status.value"></p>
          </div>
        </div>
      </div>

      <div>
        <slot></slot>
      </div>

      <div class="button-wrapper" v-if="btnText">
        <button-transparent class="card-button" @click="$emit('btn-action')">
          {{ btnText }}
          <img src="../../../assets/arrow-right-black.svg" alt="" />
        </button-transparent>
      </div>
    </div>
  </cm-card>
</template>


<script>
import CmCard from "@/components/UI/CmCard";
import buttonTransparent from "@/components/UI/buttons/ButtonTransparent";
import statusIcon from "../../UI/status-icon.vue";

export default {
  name: "ServicesCard",
  components: { buttonTransparent, CmCard, statusIcon },
  props: {
    width: {
      type: String,
      default: "100%",
    },
    img: {
      type: String,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    btnText: {
      type: String,
    },
    action: {
      type: Function,
    },
    statuses: {
      type: Array,
    },
    btnToNext: {
      type: Boolean,
      default: false,
    },
    btnToBack: {
      type: Boolean,
      default: false,
    },
    statusIcon: {
      type: Number, 
      default: 4
    },
    loading: Boolean,
    specialClass: String
  },
};
</script>

<style scoped>
.blur {
  filter: blur(10px)
}
hr {
  color: #d4d4d4;
}
.cardContent,
.statusesWrap {
  flex-grow: 1;
}

.serviceCardWrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.cardTitle {
  height: 3.25rem;
}
.serviceCardButton {
  position: absolute;
  bottom: 0.7rem;
}

.image-wrapper {
  padding-bottom: 20px;
  border-bottom: 1px solid #eff0f0;
}

.servicesImg {
  height: 42px;
  width: 100%;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}

.card-button {
  height: 40px;
}

.card-button > img {
  margin-left: 10px;
}

.card-text {
  font-size: 16px;
  line-height: 30px;
}

.card__header {
  margin-bottom: 20px;
  position: relative;
}

.serviceCardWrapper {
  justify-content: space-between;
}

@media (max-width: 1600px) and (min-width: 1024px) {
  .card__header {
    margin-bottom: 20px;
  }

  .card-button {
    padding: 0 15px;
    font-size: 12px !important;
  }

  .card-text {
    font-size: 12px;
    line-height: 20px;
  }

  .servicesImg {
    height: 30px;
  }
}

@media (max-width: 1280px) and (min-width: 1024px) {
  .card-button {
    height: 30px;
    padding: 0 15px;
    border-radius: 10px;

    font-size: 10px !important;
  }

  .card-text {
    font-size: 10px;
    line-height: 18px;
  }

  .servicesImg {
    height: 25px;
  }

  .card-button > img {
    height: 10px;
    margin-left: 5px;
  }
}

@media (max-width: 1024px) {
  .card__header {
    margin-bottom: 25px;
  }

  .servicesImg {
    height: 35px;
  }
}

@media (max-width: 768px) and (min-width: 551px) {
  .card__header {
    margin-bottom: 20px;
  }

  .image-wrapper {
    padding-bottom: 15px;
  }

  .card-text {
    font-size: 13px;
    line-height: 21px;
  }

  .card-button {
    height: 40px;
    padding: 0 20px;

    font-size: 14px !important;
  }

  .card-button > img {
    margin-left: 5px;
  }
}

@media (max-width: 550px) {
  .servicesImg {
    width: 112px;
    height: 32px;
  }

  .cardContent {
    margin-bottom: 20px;

    font-size: 14px;
    line-height: 24px;
  }

  .card-text {
    font-size: 14px;
    line-height: 24px;
  }
}
</style>

<style>
.serviceCardWrapper-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  top: -5px;
  right: 0;
}
.serviceCardWrapper-icon svg {
  width: 40px;
  height: 40px;
}

@media (min-width: 1500px) {
  .serviceCardWrapper-icon,
  .serviceCardWrapper-icon svg {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 1500px) and (min-width: 1024px) {
  .serviceCardWrapper-icon,
  .serviceCardWrapper-icon svg {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 1023px) {
  .serviceCardWrapper-icon,
  .serviceCardWrapper-icon svg {
    width: 40px;
    height: 40px;
  }
}
</style>