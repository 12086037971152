<template>
  <div class="container" :class="{ 'cursor-loader': step3.loading }">
    <v-popup :visible="popupVisible" @close="popupVisible = false">
      <h2>Пользователем с такими данными уже зарегистрирован</h2>
      <p>
        Воспользуетесь входом в ваш личный кабинет или
        <a href="https://t.me/qoob_store_support_bot" target="_blank"
          >обратитесь в поддержку</a
        >
      </p>
      <cm-button cls="main" @click="$router.push('/login')"> Войти </cm-button>
    </v-popup>

    <v-popup :visible="err500" @close="err500 = false">
      <h2>Ошибка 500</h2>
      <p class="mb-5 login-text">
        Упс... Произошла ошибка на сервере, повторите попытку позже
      </p>

      <a href="https://t.me/qoob_store_support_bot" class="link" target="_blank"
        >Поддержка qoob.store</a
      >
    </v-popup>

    <info-modal
      :infoIndex="agreementIndex"
      :closeType="2"
      @closeInfo="agreementIndex = 0"
    >
    </info-modal>

    <div class="forms">
      <salonReg-form @err500="err500 = true" @openModal="agreementIndex = 1" v-if="$store.getters.getSalonId"></salonReg-form>
      <reg-form @openModal="agreementIndex = 1" v-else></reg-form>

    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CmInput from "@/components/UI/inputs/CmInput";
import customCheckbox from "@/components/Dashboard/Billing/custom-checkbox";
import CmButton from "@/components/UI/buttons/CmButton";
import telInput from "@/components/UI/inputs/TelInput";
import Timer from "../components/auth/Timer";
import useValidate from "@vuelidate/core";
import VPopup from "@/components/UI/v-popup.vue";
import infoModal from "@/components/Dashboard/Billing/info-modal.vue";
import buttonOrange from "@/components/UI/buttons/ButtonOrange";
import buttonGrey from "@/components/UI/buttons/ButtonGrey";
import {useStore} from "vuex";
import {computed} from "vue";
import regForm from '@/components/auth/regForm.vue';

import salonRegForm from '@/components/auth/salonRegForm.vue';


// import axios from "axios";
export default {
  name: "Registration",
  components: {
    CmButton,
    CmInput,
    Timer,
    telInput,
    VPopup,
    infoModal,
    customCheckbox,
    buttonOrange,
    buttonGrey,
    regForm,
    salonRegForm
  },
  setup() {
    const store = useStore();

    const popupState = computed(() => {
      console.log(store.state.startPagePopup)
      return store.state.startPagePopup;
    })

    return { popupState }
  },
  data() {
    return {
      v$: useValidate(),
      agreementIndex: 0,
      step3: {
        inputReferalPhone: "",
        inputPromoCode: "",
        loading: false,
      },
      err500: false,

      popupVisible: false,
      panelVisible: false,

      enterCode: 0,
      step: 1,

      timer: 300,
    };
  },

  mounted() {
    this.$router.push('/registration')
  },
};
</script>

<style scoped>

.check-wrapper {
  display: flex;
  margin-bottom: 70px;
}

.check__agreement {
  margin-left: 20px;

  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #328FE4;
}

.reg-next {
  width: 100%;
  height: 48px;
}

.reg-next img {
  margin-left: 10px;
}

.go-back {
  width: 50%;
  height: 48px;
}

.button-submit {
  width: 50%;
  height: 48px;
}

.v-popup h2 {
  font-size: 27px;
  line-height: 1.2;

  margin-bottom: 10px;
}
.v-popup p {
  font-size: 18px;
}

.regForm {
  width: 385px;

  margin: 150px auto;
  box-sizing: content-box;
}
.regSteps {
  height: 2.375rem;
  margin-top: 1.9rem;
  margin-bottom: 1rem;
}
.regBtn_half {
  width: 183px;
  margin-top: 2px;
}
.regBtn_half_step_3 {
  margin-top: 32px;
}
.step {
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #dedede;
}
.checkBoxWrap {
  margin-top: 10px;
}
.stepNumber {
  font-family: "Graphik LCG";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #a1a2a2;
}
.stepDescription {
  font-family: "Graphik LCG";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #000000;
  text-align: left;
  margin-bottom: 32px;
}
.stepNumberLighted {
  font-weight: 500;
  line-height: 22px;
  color: #000000;
}
.stepBordered {
  width: 100%;
  border-bottom: 2px solid #ffcb00;
  text-align: center;
}
.timerWrap {
  margin-top: 8px;
  margin-bottom: 42px;
}

.wrap {
  margin-bottom: 16px;
}
.wrapper {
  margin-bottom: 20px;
}
.wrapInput {
  margin: 0 0 25px;
}

.reg-contract {
  font-size: 16px;
  list-style-type: none;
}
.reg-contract li {
  margin-bottom: 20px;
  user-select: text;
}

.reg__step {
  width: 100%;
  max-width: 385px;
}

.reg__title {
  font-size: 32px;
}

.regSteps {
  height: auto;
}

.stepBordered {
  padding-bottom: 12px;
}

.stepNumberLighted {
  font-size: 16px;
  font-weight: 500;
}

.stepDescription {
  margin-top: 20px;
}

.check-wrapper {
  margin-bottom: 50px;
}

.tel-input {
  padding: 0;
  padding-bottom: 50px;
  box-sizing: content-box;
}
</style>

