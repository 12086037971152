<template>
  <router-view v-if="this.$store.state.visibleApp" />
  <!-- <v-error :error="$store.state.error" :visible="true"></v-error> -->

  <v-popup
    :visible="this.$store.state.differentVersions"
    @close="this.$store.commit('setDifferentVersions', false)"
  >
    <h2 class="mb-4">Ваша версия сайта устарела</h2>
    <p class="mb-5 login-text">
      <span class="mb-3 d-block">
        Чтобы использовать самую последнюю версию сайта, вам нужно обновить
        страницу.
      </span>
      Если это окно появилось опять, то стоит очистить кеш бразера
    </p>

    <!-- <button-orange @click="reload()">Обновить страницу</button-orange> -->
  </v-popup>

  <v-popup
    :overflow="'auto'"
    class="welcome-popup"
    :maxWidth="'80%'"
    :visible="
      !this.$store.state.isFrameVersion && this.$store.state.welcomePopupVisible
    "
    :closeBtn="false"
  >
    <h2 class="tit-h2">Разрешите, я расскажу вам:</h2>
    <div class="mb-5 login-text">
      <ul>
        <li>- Что такое qoob.store?</li>
        <li>- Как сервис уведомлений поможет вашему бизнесу?</li>
      </ul>

      <p>
        Представьте, что ваш месенджер возвращает спящих клиентов автоматически,
        подтверждает клиентов на завтра, а также, отправляет все нужные
        уведомления!
      </p>

      <p>Это и многое другое умеет делать сервис qoob.store 🧙🏻‍♂️</p>

      <hr />

      <p>
        Стоимость рассчитывается автоматически после подключения к crm
      </p>

      <p>
        Можно использовать смайлы, отступы и ссылки, для перехода на любые сайты
      </p>

      <hr />

      <p>Кто уже работает с сервисом?</p>

      <p class="weight">ПЕРСОНА / MA&MI / TOPGUN / CHOP CHOP / CHERNIKA</p>

      <p>💆‍♀️ тысячи брендов доверяют сервису qoob.store</p>

      <hr />

      <p>Продолжаю...</p>

      <p>🤓 Что именно делает сервис уведомлений?</p>

      <p>🔥 Сообщает о том, что клиент успешно записан 👇</p>

      <div class="mess">
        <p class="mess__title">Елена, вы успешно записаны ⚡️ на услуги:</p>

        <ul class="mess__list">
          <li>✅ Стрижка</li>
          <li>✅ Окрашивание в один тон</li>
        </ul>

        <div class="mess__bottom">
          <p>К мастеру - Маргарита 👱‍♀️</p>
          <p>на 21.08.2022 в 10:30</p>
        </div>
      </div>

      <p>
        🔥 Подтверждает клиентов на завтра, вот такими сообщениями в WhatsApp /
        Telegram 👇
      </p>

      <div class="mess">
        <p class="mess__title">Елена, здравствуйте, этo ваш любимый Салон ❣️</p>

        <div class="mess__subtitle">⏰ Напоминаем:</div>
        <div class="mess__subtitle">- Вы записаны на завтра - 10:30 -</div>

        <ul class="mess__list">
          <li>✅ Стрижка</li>
          <li>✅ Окрашивание в один тон</li>
        </ul>

        <div class="mess__subtitle">Вы подойдёте?</div>
      </div>

      <p>🔥 А так же умеет возвращать клиентов автоматически 24/7 👇</p>

      <div class="mess">
        <p class="mess__title">Мария, здравствуйте, это ваш любимый салон 🤗</p>

        <div class="mess__subtitle">
          Нам кажется, что мы не виделись уже целую вечность.
        </div>
        <div class="mess__subtitle">
          Подобрaть вaм yдoбный день и врeмя для запиcи?
        </div>
      </div>

      <hr />

      <p>
        😎 qoob.store возвращает клиентов каждый день, отправляя сообщения тем
        кто нe был (20 / 30 / 60)* днeй.
      </p>

      <p>* настройки можно поменять!</p>

      <p>Ещё qoob.store умеет:</p>

      <hr />

      <ul>
        <li>
          🔥 Отправлять отзывы по рейтингу ⭐️⭐️⭐️/⭐️⭐️ - на карты, если
          хороший или владельцу, если плохой
        </li>
        <li>
          🔥 Дeлaть массовые раccылки по клиентской бaзе - - Мы переехали 📍 / -
          Мастер в отпуске 🏝
        </li>
        <li>
          🔥 Сообщать о начислении бонусных баллов и их скором сгорании -
          Запишитесь до 10 числа иначе ваши 560 баллов сгорят 👩‍🚒
        </li>
      </ul>

      <p>и много чего еще 🧙🏻‍♂️</p>

      <hr />

      <p>- Можно подключить и протестировать?</p>

      <p class="weight">⚡️ Да! сейчас мы дарим именно вам 7 дней бесплатно</p>

      <p>Наши эксперты помогут с настройками под ключ 💆‍♀️</p>

      <p>
        ✅ Для этого вам нужно начать с нами общение прямо в
        <a
          href="https://t.me/qoob_store_support_bot"
          target="_blank"
          class="link"
          >этом чате!</a
        >
      </p>

      <p class="weight">
        Задавайте ваши вопросы / пишите нам с 8:00 до 20:00
      </p>

    </div>

    <a
      class="link"
      v-if="this.$store.getters.getSalonId"
      :href="`https://yclients.com/appstore/${this.$store.getters.getSalonId}/applications/8/settings
`"
      >Перейти</a
    >
  </v-popup>
</template>

<script>
// import VError from '@/components/UI/v-error'
import { useStore } from "vuex";
import { onBeforeMount, onUnmounted } from "vue";
import VPopup from "./components/UI/v-popup.vue";
import axios from 'axios'

export default {
  components: { VPopup },

  setup() {
    const store = useStore();

    function setWindowWidth() {
      store.commit("updateWindowWidth", document.documentElement.clientWidth);
    }

    window.addEventListener("resize", setWindowWidth);

    onBeforeMount(() => {
      setWindowWidth();

      axios.interceptors.request.use(function (config) {
          // Проверяем, содержит ли URL /api/v1
          if (config.url.includes('/api/v1')) {
            // Добавляем заголовок в объект конфигурации запроса
            if (!config.headers) config.headers = {};
            config.headers['X-Request-Source'] = 'lk';
          }
          return config;
        });
    });

    onUnmounted(() => {
      window.removeEventListener("resize", setWindowWidth);

      localStorage.clear();
    });
  },

  

  mounted() {

    var isFramed = false;
    try {
      isFramed =
        window != window.top ||
        document != top.document ||
        self.location != top.location;
    } catch (e) {
      isFramed = true;
    }
    if ((isFramed || this.$store.getters.isLocalHost) && this.$route.path !== "/app") {
      this.$store.commit("setIsFrameVersion", true);
      if (this.$store.getters.isAuth) {
        this.$router.push("/dashboard/iframe");
      }
    } else {
      if (this.$route.path === "/app") {
        console.log('apppppp');
      }
      this.$store.commit("setIsFrameVersion", false);
      // if (this.$store.getters.isAuth) {
      //   this.$router.push('/dashboard/iframe')
      // }
    }

    let params = window.location.search
      .replace("?", "")
      .split("&")
      .reduce(function (p, e) {
        let a = e.split("=");
        p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
        return p;
      }, {});

    if (params["user_data"]) {
      this.$store.dispatch("registerOneClick");
    } else {
      const salon_id = params["salon_id"];

      if (salon_id) {
        localStorage.setItem("salon_id", salon_id);

        if (localStorage.getItem("isAuth") === "auth") {
          this.$store.dispatch("logout");
        }
      }
    }
  },
};
</script>
<style src="./app.css" >
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss" scoped>
.welcome-popup {
  .iframe-welcome {
    width: 100%;
    max-width: 800px;
    height: 400px;

    @media (max-width: 600px) {
      height: 250px;
    }
  }

  p {
    margin-bottom: 10px;
    font-size: 16px;
  }

  ul {
    li {
      font-weight: 500;
      font-size: 16px;
    }
    margin-bottom: 15px;
  }

  .weight {
    font-weight: 600;
  }

  .mb-4 {
  }

  hr {
    opacity: 0.2;
    width: 30%;
  }

  .mess {
    margin-bottom: 20px;
    display: inline-block;

    // border: solid 1px;
    background: #e0fcd6;
    width: 100%;
    max-width: 500px;

    box-shadow: 0 0 2px #333;

    border-radius: 10px 10px 10px 0;
    padding: 10px;

    &__title {
      font-weight: 600;
      color: #377cb0;
    }

    &__list {
      li {
        font-size: 14px;
      }
    }

    &__bottom {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      font-weight: 500;
    }

    &__subtitle {
      font-size: 15px;
      margin-bottom: 5px;
      font-weight: 500;
    }
  }
}
</style>