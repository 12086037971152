<template>
  <div class="settingsContainer col mt-4">
    <h3 class="settingsHeader">Настройки подключаемых сервисов qoob.store</h3>
    <p class="settingsSubHeader">Являясь всего лишь частью общей картины, предприниматели в сети интернет лишь добавляют<br> фракционных разногласий и указаны как претенденты на роль ключевых факторов.</p>

    <settings-item
        v-for="(service, index) in content.services"
        :key="index"
        :index="index"
        :service="service"
        @btn-click="openSettings(service.name)"
    ></settings-item>

    <side-popup ref="sidepopup">
      <component :is="currentComponent"></component>
    </side-popup>
  </div>

  <div class="blank"></div>

</template>

<script>
import SidePopup from "../../components/UI/SidePopup";
import {mapState} from "vuex";
import SettingsItem from "@/components/Dashboard/Settings/SettingsItem";
import CrmSettings from "@/components/Dashboard/Services/CrmSettings";
import WhatsAppSettings from "@/components/Dashboard/Services/WhatsAppSettings";
import SmsSettings from "@/components/Dashboard/Services/SmsSettings";
export default {
  name: "Settings",
  components: {
    SettingsItem,
    SidePopup,
    CrmSettings,
    WhatsAppSettings,
    SmsSettings,
  },
  data() {
    return {
      currentComponent: null,
    }
  },
  computed: {
    ...mapState({
      content: state => state.texts.dashboard.settings,
    }),
  },
  methods: {
    openSettings(serviceName){
      if (serviceName === 'crm'){
        this.currentComponent = CrmSettings;
      } else if (serviceName === 'whatsapp'){
        this.currentComponent = WhatsAppSettings;
      } else if (serviceName === 'sms'){
        this.currentComponent = SmsSettings;
      } else {
        this.currentComponent = null;
      }
      this.$refs.sidepopup.open();
    }
  }
}
</script>

<style scoped>
.sectionWrap{
  margin: 3.75rem 3.75rem 10rem 3.125rem;
}
.settingsContainer {
  max-width: 1210px;
  padding: 0 60px;
  padding-bottom: 60px;
}
.settingsHeader {
  font-size: 26px;
  line-height: 42px;
  text-align: left;
}
.settingsSubHeader {
  margin: 30px 0;
  font-size: 16px;
  line-height: 30px;
}
.blank{
  position: fixed;
  top: 0;
  right: 0;

  height: 100%;
  width: 26.5%;
  padding-right: 60px;

  background-color: #F7F9FC;
}
.col-4-custom {
  width: 31%;
}

@media (max-width: 1440px) {
  .blank {
    display: none;
  }
}

@media (max-width: 1280px) {
  .settingsContainer {
    padding: 0 30px;
    padding-bottom: 60px;
  }
}

@media (max-width: 1024px) {
  .settingsContainer {
    padding: 0;
    padding-bottom: 60px;
  }
}
</style>

<style scoped>
@media (max-width: 550px) {

  .settingsContainer {
    padding: 0 15px;
    padding-bottom: 60px;
  }

  .settingsHeader {
    font-size: 20px;
    line-height: 26px;
    text-align: left;
  }

  .settingsSubHeader {
    margin-top: 15px;
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
