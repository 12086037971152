<template>
  <div
    class="telegram-settings pb-4 "
    :class="[
      {
        _loader: loadingTelegram,
      },
    ]"
  >
    <confirm-popup
      :popupVisible="confirmVisible"
      @confirming="this.$store.dispatch('telegram/deleteTelegramBot')"
      @popupClose="confirmVisible = false"
      @cancelConfirm="confirmVisible = false"
      confirmString="отключить"
    >
    </confirm-popup>

    <div
      class="telegram-settings__wrapper"
      v-if="license.isActive && crmConnect"
    >
      <form class="telegram-setting__token if-main-width" @submit.prevent>
        <div class="tit-h6 if-col-25 align-items-center">
          {{
            telegramStatus ? "Ссылка на ваш Telegram" : "Введите токен Telegram"
          }}
        </div>
        <div class="if-main if-gap-3 mb-4">
          <div class="telegram__input _mobile w-100">
            <default-input
              :inputClass="'_small'"
              v-model="$store.state.telegram.token"
              :placeholder="'Токен'"
              v-if="!telegramStatus"
            ></default-input>

            <default-input
              v-model="telegramLink"
              v-else
              :placeholder="'Ссылка на бота'"
              :inputClass="'link weight-600 _small'"
              :disabled="true"
            ></default-input>
            <div
              class="d-flex gap-2"
              :class="{ 'mw-124px': !telegramStatus }"
              v-if="windowWidth > 768"
            >
              <button
                class="mw-124px btn-orange _small"
                :disabled="
                  $store.state.telegram.token.length === 0 || phoneEdit
                "
                v-if="!telegramStatus"
                @click="this.$store.dispatch('telegram/postTelegramToken')"
              >
                Подключить
              </button>
              <button
                class="mw-124px btn-orange _small"
                v-else
                @click="copyText(telegramLink)"
                :disabled="textCopied"
              >
                {{ textCopied ? "Скопировано" : "Поделиться" }}
              </button>
              <button
                class="mw-124px telegram-settings__off btn-gray _small"
                @click="confirmVisible = true"
                v-if="telegramStatus && windowWidth > 768"
              >
                Отключить
              </button>
            </div>
          </div>
        </div>

        <div class="telegram-setting__btn mb-3" v-if="windowWidth <= 768">
          <button
            class="btn-orange _small"
            :disabled="$store.state.telegram.token.length === 0 || phoneEdit"
            v-if="!telegramStatus"
            @click="this.$store.dispatch('telegram/postTelegramToken')"
          >
            Подключить
          </button>
          <button
            class="btn-orange _small"
            v-else
            @click="copyText(telegramLink)"
            :disabled="textCopied"
          >
            {{ textCopied ? "Скопировано" : "Поделиться" }}
          </button>

          <button
            class="btn-gray _small"
            @click="confirmVisible = true"
            v-if="telegramStatus && windowWidth <= 768"
          >
            отключить
          </button>
        </div>

        <div class="if-main if-gap-3 mb-4" v-if="!telegramStatus">
          <div class="if-main-width w-100">
            <div class="tit-h6">Владелец бота</div>
            <div class="telegram__input">
              <input
                type="text"
                class="default-input _small"
                v-model="this.$store.state.telegram.telegramPhone"
                :disabled="!phoneEdit"
              />
              <button
                :class="!phoneEdit ? 'btn-orange' : 'btn-white'"
                class="_small mw-124px"
                @click="editPhoneToggle()"
              >
                {{ phoneEdit ? "Сохранить" : "Изменить" }}
              </button>
            </div>
          </div>
        </div>
      </form>

      <hr class="if-main-width m-0 mb-2" v-if="telegramStatus" />

      <div class="if-main if-gap-3" v-if="!telegramStatus">
        <div
          class="telegram-setting__video"
          :class="windowWidth < 1024 ? 'if-col-60' : 'if-col-80'"
        >
          <p class="telegram-setting__title mb-3">Видео - инструкция</p>
          <div
            class="video"
            v-html="$store.state.telegram.telegramVideo.content"
          ></div>
        </div>

        <div
          class=""
          :class="{
            'if-col-18': this.$store.state.windowWidth < 1024,
          }"
        >

          <div>
            <p class="telegram-setting__title mb-3">Тестовый бот</p>
            <img
              class="telegram-setting__img"
              src="../../../assets/telegram-banner.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="telegram-setting__setting if-main-width" v-else>
        <div class="mb-4">
          <default-input
            title="кол-во клиентов запустивших бот"
            :label="'Количество пользователей телеграм бота'"
            :disabled="true"
            :inputClass="'weight-500 _small'"
            v-model="telegram_bot_users_count"
          ></default-input>
        </div>

        <div class="mb-4">
          <default-input
            title="Владелец бота"
            :label="'Владелец бота'"
            :disabled="true"
            :inputClass="'weight-500 _small'"
            v-model="telegramInfo.telegram_bot_phone"
          ></default-input>
        </div>

        <div class="telegram-admins telegram-stats mb-4">
          <h3 class="tit-h6 mb-1">Аккаунты в телеграмм которые могут управлять ботом</h3>

          <div
            class="telegram__input mb-4"
            v-for="(admin, index) in $store.state.telegram.admins"
            :key="index"
          >
            <input
              type="text"
              class="default-input _small weight-500"
              disabled
              v-model="$store.state.telegram.admins[index].tel"
            />
            <button
              class="btn-white _small mw-124px"
              :class="{ '_loader-btn': admin.loading }"
              @click="this.$store.dispatch('telegram/deleteAdminTelegram', admin.tel)"
            >
              Удалить
            </button>
          </div>

          <transition name="fade" mode="out-in">
            <button
              class="btn-orange _small"
              @click="addAdmin"
              v-if="!addAdminInput"
            >
              Добавить
            </button>

            <div v-else>
              <input
                ref="adminInput"
                :label="'Добавить админа'"
                :id="'addAdminInput'"
                v-model.trim="this.$store.state.telegram.newAdminTelephone"
                class="mb-4 add-admin__input default-input _small"
                :placeholder="'Номер телефона (без +)'"
                type="number"
              />

              <div class="d-flex gap-4">
                <button class="btn-white _small" @click="addAdminInput = false">
                  Отменить
                </button>
                <button
                  class="btn-orange _small"
                  @click="
                    this.$store.dispatch('telegram/createAdminTelegram'),
                      (this.addAdminInput = false)
                  "
                  :disabled="
                    this.$store.state.telegram.newAdminTelephone.length === 0
                  "
                >
                  Сохранить
                </button>
              </div>
            </div>
          </transition>
        </div>

        <div class="telegram-setting__video">
          <div
            class="video "
            v-html="$store.state.telegram.telegramVideo.content"
          ></div>
        </div>
      </div>
    </div>

    <div class="telegram-settings__wrapper" v-else>
      <div v-if="!crmConnect">
        <crmCyncError></crmCyncError>
      </div>
      <div class="wa-warn" v-else-if="!license.isActive">
        <h3>Внимание!</h3>

        <!-- crm не подключенна или лицензии никогда не существовало -->
        <div class="wa-warn__text">
          <p>
            Мы не нашли у Вас ни одной активной лицензии на использование
            сервиса доставки уведомлений через телеграм.
          </p>
          <p class="mb-4">
            Для начала работы, неоходимо приобрести лицензию сервиса qoob.store.
          </p>
        </div>
        <!-- лицензия закончилась -->

        <button-orange
          class="sms-button"
          cls="main"
          to-next
          @click="$router.push('/dashboard/iframe/billing')"
          >Выбрать лицензию</button-orange
        >
      </div>
    </div>
  </div>
</template>

<script>
import buttonOrange from "@/components/UI/buttons/ButtonOrange";
import confirmPopup from "../../confirmPopup.vue";
import { useStore } from "vuex";
import { computed, ref } from "@vue/runtime-core";
import ButtonOrange from "../../UI/buttons/ButtonOrange.vue";
import crmCyncError from "../../UI/crmCyncError.vue";

export default {
  components: {
    buttonOrange,
    crmCyncError,
    confirmPopup,
    ButtonOrange,
  },
  setup() {
    const $store = useStore();

    const crmConnect = computed(() => $store.state.crm.crmConnect);
    const qoobLicenseActive = computed(
      () => $store.state.tarif.licenseInfo.isActive
    );
    const license = computed(() => $store.state.tarif.license);
    const licenseExisted = computed(() => $store.state.tarif.licenseExisted);
    const loadingTelegram = computed(
      () => $store.state.telegram.loadingTelegram
    );
    const telegramStatus = computed(() => $store.state.telegram.status);

    const telegramInfo = computed(() => $store.state.telegram.telegramStatus);

    const telegramLink = computed(
      () =>
        "http://t.me/" + $store.state.telegram.telegramStatus.telegram_bot_name
    );

    const windowWidth = computed(() => $store.state.windowWidth);

    const platformLinks = computed(() => $store.state.telegram.platformLinks);

    const textCopied = ref(false);

    const phoneEdit = ref(false);

    const editPhone = ref(null);

    const confirmVisible = ref(false);

    const addLinkFormVisible = ref(false);

    function copyText(text) {
      const str = text;
      const el = document.createElement("textarea");
      el.value = str;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      textCopied.value = true;

      setTimeout(() => {
        textCopied.value = false;
      }, 1000);
    }

    function editPhoneToggle() {
      phoneEdit.value = !phoneEdit.value;

      if (phoneEdit.value) {
        setTimeout(() => {
          editPhone.value.focus();
        }, 100);
      }
    }

    const telegram_bot_users_count = computed(() =>
      telegramInfo.value.telegram_bot_users_count
        ? telegramInfo.value.telegram_bot_users_count
        : 0
    ); // 79605783784

    let addAdminInput = ref(false);
    let adminInput = ref(null);

    function addAdmin() {
      addAdminInput.value = true;

      setTimeout(() => {
        adminInput.value.focus();
      }, 400);
    }

    return {
      crmConnect,
      qoobLicenseActive,
      editPhoneToggle,
      license,
      licenseExisted,
      loadingTelegram,
      confirmVisible,
      telegramStatus,
      telegramLink,
      windowWidth,
      telegramInfo,
      copyText,
      textCopied,
      platformLinks,
      addLinkFormVisible,
      telegram_bot_users_count,
      phoneEdit,
      editPhone,
      addAdminInput,
      adminInput,
      addAdmin,
    };
  },
};
</script>

<style scoped lang="scss">
.mobile-reviews-wrap {
  margin-bottom: 55px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
}
.telegram-setting__setting .tit-h6 {
  margin-bottom: 14px;
}
.rating-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.rating-wrapper span {
  display: none;
}

.telegram-settings__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
}

.telegram-setting__input {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.telegram-setting__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
.telegram-setting__input input {
  width: 100%;
  border: 2px solid #f1f3f7;
  font-size: 14px;
  padding: 12px;
  border-radius: 10px;
}
.telegram-setting__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  max-width: 1200px;
}

.telegram-setting__token {
  display: flex;
  flex-direction: column;
  position: relative;
}
.telegram-setting__banners {
  display: flex;
  gap: 39px;
}
.telegram-setting__img {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 11px;
  width: 222px;
  height: 400px;
}

.telegram-setting__setting {
  max-width: 1200px;
}

.telegram-setting__btn {
  align-items: center;
}
@media (max-width: 768px) {
  .telegram-setting__img {
    height: 280px;
  }
  .telegram-setting__video video {
    min-height: 280px;
  }
  .telegram-setting__btn {
    display: flex;
    gap: 15px;
  }
  .telegram-setting__row {
    flex-direction: column;
    gap: 5px;
  }
  .telegram-setting__text {
    width: 100%;
  }
  .telegram-setting__input {
    width: 100%;
  }
}

@media (max-width: 645px) {
  .telegram-setting__banners {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 550px) {
}
</style>

<style>
.telegram-setting__video iframe {
  width: 100%;
  min-height: 450px;
  height: auto;
  background: #f7f9fc;
  border-radius: 10px;
}
@media (max-width: 1024px) {
  .telegram-setting__video iframe {
    min-height: 400px;
  }
}
@media (max-width: 768px) {
  .telegram-setting__video iframe {
    min-height: 350px;
  }
}
@media (max-width: 568px) {
  .telegram-setting__video iframe {
    min-height: 250px;
  }
}
</style>