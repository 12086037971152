import Moment from "moment";

function declOfNum(n, text_forms) {
  n = Math.abs(n) % 100;
  let n1 = n % 10;
  if (n > 10 && n < 20) {
    return text_forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return text_forms[1];
  }
  if (n1 == 1) {
    return text_forms[0];
  }
  return text_forms[2];
}

function getDuration(config, resultType = 'string') {
  if (resultType === 'string') {
    if (config.when === "instantly") {
      return "В момент события";
    } else {
      let result = "";
      const interval = Moment.duration(config.interval)._data;

      if (config.when === "before") {
        result += "За ";
      } else if (config.when === "after") {
        result += "Через ";
      }

      if (interval.months) {
        result += `${interval.months} ${declOfNum(interval.months, [
        "месяц",
        "мысяца",
        "месяцев",
      ])} `;
      }
      if (interval.days) {
        result += `${interval.days} ${declOfNum(interval.days, [
        "день",
        "дня",
        "дней",
      ])} `;
      }
      if (interval.hours) {
        result += `${interval.hours} ${declOfNum(interval.hours, [
        "час",
        "часа",
        "часов",
      ])} `;
      }
      if (interval.minutes) {
        result += `${interval.minutes} ${declOfNum(interval.minutes, [
        "минута",
        "минуты",
        "минут",
      ])} `;
      }
      if (interval.seconds) {
        result += `${interval.seconds} ${declOfNum(interval.seconds, [
        "секунда",
        "секунды",
        "секунд",
      ])} `;
      }

      if (config.when === "before") {
        result += "до события";
      } else if (config.when === "after") {
        result += "после события";
      }

      return result;
    }
  } else if (resultType === 'object'){
    return Moment.duration(config.interval)._data;
  }

}

export default getDuration