import axios from "axios";

export default {
  state: () => ({
    intervalId: null,
    qrCode: null,
    status: null,
    err: null,
    isAuth: false,
    isFirstGetQr: true,
    isGettingQr: false,
  }),
  getters: {},
  mutations: {},
  actions: {
    getQrCode(context) {
      try {
        context.dispatch("isAuthed", true).then(() => {
          clearInterval(context.state.intervalId);

          context.state.isFirstGetQr = false;
          context.state.isGettingQr = true;
          context.dispatch("updateQRCode");

          context.state.intervalId = setInterval(() => {
            context.dispatch("updateQRCode");
          }, 5000);
        });
      } catch (reason) {
        console.error(reason);
        context.state.err = reason + ". See logs for details";
      }
    },
    async updateQRCode(context) {
      const whatsAppStatusSettings =
        context.rootState.whatsapp.whatsappStatus.provider_settings;

      return await axios(
        `https://api.green-api.com/waInstance${whatsAppStatusSettings.id_instance}/qr/${whatsAppStatusSettings.api_token_instance}`
      )
        .then((data) => {
          console.log(data);
          if (data.data.type === "qrCode") {
            context.state.qrCode = data.data.message;
          } else {
            context.state.qrCode = null;
            clearInterval(context.state.intervalId);
            context.dispatch("isAuthed");
            context.state.status = data.data.message;
          }
        })
        .catch((reason) => {
          console.error(reason);
          context.state.err = reason + ". See logs for details";
        });
    },
    async isAuthed(context, startGeting = false) {
      const whatsAppStatusSettings =
        context.rootState.whatsapp.whatsappStatus.provider_settings;

      return await axios(
        `https://api.green-api.com/waInstance${whatsAppStatusSettings.id_instance}/getStateInstance/${whatsAppStatusSettings.api_token_instance}`
      )
        .then((data) => {
          context.state.status = data.data.stateInstance;

          console.log("isAuthed", data);
          /* eslint-disable */
          if (data.data.stateInstance === "authorized") {
            context.state.isAuth = true;
            context.state.isGettingQr = false;
            context.state.isFirstGetQr = false;
          } else {
            context.state.isAuth = false;

            if (!context.state.isGettingQr && !context.state.isFirstGetQr) {
              context.dispatch("getQrCode");
            } else {
              context.state.isAuth = false;
            }
          }
        })
        .catch((err) => {
          if (err.response.data === "Instance is deleted" && startGeting) {
            console.log("Instance is deleted");
            context.dispatch("createInstance");
          } else {
            context.state.isAuth = false;
          }
        });
    },
    async reboot(context) {
      const whatsAppStatusSettings =
        context.rootState.whatsapp.whatsappStatus.provider_settings;

      await axios(
        `https://api.green-api.com/waInstance${whatsAppStatusSettings.id_instance}/reboot/${whatsAppStatusSettings.api_token_instance}`
      ).then((data) => {
        console.log(data);
      });
    },
    stopGettingQrStatus(context) {
      clearInterval(context.state.intervalId);
    },
    async createInstance(context) {
      return await axios({
        method: "post",
        url: `${window.apiPath}/company/whatsapp/instance`,
        headers: {
          Authorization: `${localStorage.getItem(
            "authTokeType"
          )} ${localStorage.getItem("authToken")}`,
          accept: "application/json",
        },
      })
        .then((data) => {
          console.log("createInstance data", data);
          context.dispatch('getWhatsappStatus', true, {root: true})
        })
        .catch((err) => {
          console.log("createInstance err", err);
        });
    },
  },
  namespaced: true,
};
