<template>
  <div class="graph-circle__main">
    <div class="wrapper">
      <p class="center__text">Все клиенты по сегментам</p>

      <diagram-component />
    </div>

    <ul class="graph__stat">
      <li class="stat__item">
        <span class="stat__color yellow"></span>
        <p class="stat__percent">{{ chartData[0] }}%</p>
        <p class="stat__text">Новички</p>
      </li>
      <li class="stat__item">
        <span class="stat__color green"></span>
        <p class="stat__percent">{{ chartData[1] }}%</p>
        <p class="stat__text">Активные</p>
      </li>
      <li class="stat__item">
        <span class="stat__color grey"></span>
        <p class="stat__percent">{{ chartData[2] }}%</p>
        <p class="stat__text">Отток</p>
      </li>
      <li class="stat__item">
        <span class="stat__color grey--dark"></span>
        <p class="stat__percent">{{ chartData[3] }}%</p>
        <p class="stat__text">Потерянные</p>
      </li>
      
    </ul>
  </div>
</template>

<script>
import diagramComponent from "@/components/Dashboard/Services/diagram-component";
import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "graph-circle",
  components: {
    diagramComponent,
  },
  setup() {
    const store = useStore();
    const wrapper = ref(null);

    const wrapperParams = computed(() => {
      if (wrapper.value) {
        const width = wrapper.value.clientWidth;
        const height = wrapper.value.clientHeight;

        return { width, height };
      }
      return 0;
    });

    const chartData = computed(() => {
      return store.state.dashboard.chartsCircle.data;
    });

    return { wrapper, wrapperParams, chartData };
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  width: 260px;
  height: 260px;
}

.graph-circle__main {
  display: flex;
}

.center__text {
  position: absolute;
  top: 115px;
  left: 21px;

  max-width: 220px;

  font-size: 18px;
  line-height: 26px;
  text-align: center;
}

.graph__stat {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 70px;
}

.stat__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 180px;
  margin: 15px 0;

  font-size: 16px;
}

.stat__text {
  width: 85px;
}

.stat__color {
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 25px;

  border-radius: 7px;
}

.stat__percent {
  margin-right: 20px;
  font-weight: 500;
}

.yellow {
  background-color: #ffc549;
}

.green {
  background-color: #4addbc;
}

.grey {
  background-color: #cdcdcd;
}

.blue {
  background-color: #328FE4;
}

.grey--dark {
  background-color: #575c62;
}
@media (min-width: 1600px) {
  .stat__color {
    min-width: 24px;
    min-height: 24px;
  }
}
@media (max-width: 1600px) {
  .wrapper {
    width: 240px;
    height: 240px;
  }

  .center__text {
    top: 105px;
    left: 20px;

    max-width: 200px;
  }

  .graph__stat {
    margin-left: 60px;
  }

  .stat__item {
    font-size: 14px;
  }
}

@media (max-width: 1440px) and (min-width: 1025px) {
  .wrapper {
    width: 200px;
    height: 200px;
  }

  .center__text {
    max-width: 180px;
    top: 90px;
    left: 10px;

    font-size: 14px;
    line-height: 20px;
  }

  .stat__color {
    width: 20px;
    height: 20px;
  }

  .stat__item {
    width: 160px;
  }

  .graph__stat {
    margin-left: 20px;
  }

  .stat__color {
    margin: 0;
  }

  .stat__percent {
    margin: 0;
  }
}

@media (max-width: 1280px) and (min-width: 1025px) {
  .stat__item {
    width: 120px;
  }

  .stat__percent {
    display: none;
  }
}

@media (max-width: 768px) {
  .stat__item {
    width: 160px;
  }

  .graph__stat {
    margin-left: 40px;
  }

  .stat__color {
    margin-right: 15px;
  }

  .stat__percent {
    margin-right: 10px;
  }
}

@media (max-width: 550px) {
  .graph-circle__main {
    flex-direction: column;
    align-items: center;
  }

  .graph__stat {
    width: 100%;
    margin-left: 10px;
    margin-top: 30px;
  }

  .stat__item {
    margin: 10px 0;
  }

  .wrapper {
    width: 260px;
    height: 260px;
  }

  .center__text {
    top: 115px;
    left: 30px;
  }
}
</style>