<template>
  <div class="select-plan w-100">
    <div class="plan-info w-100" v-if="planStep === 0">
      <h3 class="title" v-if="currentTariff.isTestDrive">
        Подключение пробного тарифа
      </h3>
      <h3 class="title" v-else>Подлючение тарифного плана</h3>

      <div>
        <div class="one-filial">
          {{ companyList.currentFilial.name }}
        </div>
      </div>

      <div class="pay-block">
        <ul class="plan-stat">
          <li class="plan-stat__item plan-stat__item1">
            <p class="stat__text">Тарифный план</p>
            <p class="stat__text--bold">{{ currentTariff.name }}</p>
          </li>
          <li class="plan-stat__item">
            <p class="stat__text">Срок лицензии</p>
            <p class="stat__text--bold">{{ currentTariff.period }} дней</p>
          </li>
          <li class="plan-stat__item">
            <p class="stat__text">Суммарный срок лицензии</p>

            <p class="stat__text--bold">
              {{
                license.isActive
                  ? currentTariff.period + license.days
                  : currentTariff.period
              }}
            </p>
          </li>
        </ul>

        <div class="sum-block">
          <p class="sum__text">К оплате:</p>
          <p class="sum__number">{{ currentTariff.price }} ₽</p>
        </div>
      </div>

      <ul class="terms">
        <li class="terms__item" v-for="(item, index) in termsList" :key="item">
          <custom-checkbox
            :item="item"
            :index="index"
            @toggleCheckbox="toggleCheckbox($event)"
          />
          <button class="terms__text" @click="selectInfo(index === 0 ? 1 : 3)">
            {{ item.text }}
          </button>
        </li>
      </ul>

      <button
        v-if="currentTariff.isTestDrive"
        class="plan__submit"
        :class="{ '_loader-btn': loadingActiveTestDrive }"
        @click="this.$store.dispatch('activeTestDrive')"
        :disabled="!termsList[0].state || !termsList[1].state"
      >
        Подключить пробный тариф
      </button>
      <div v-else class="d-flex gap-3">
        <button
          class="plan__submit"
          :disabled="!termsList[0].state || !termsList[1].state"
          @click="createInvoice('yookassa')"
        >
          Картой онлайн
        </button>

        <button
          class="plan__submit"
          @click="planStep++"
          :disabled="!termsList[0].state || !termsList[1].state"
        >
          Выставить счет на оплату
        </button>
      </div>
    </div>

    <!-- поиск по ИНН -->
    <div v-else-if="planStep === 1" class="w-100">
      <payment-data
        class="w-100"
        :currentPrice="currentTariff.price"
        ref="paymentInn"
      ></payment-data>

      <button
        class="plan__submit"
        @click="paymentInn.valid() ? createInvoice('bank') : ''"
      >
        Выставить счет
      </button>
    </div>

    <div
      class="w-100"
      v-else-if="planStep === 2"
      :class="{ _loader: this.$store.state.invoice.invoiceLoading }"
    >
      <div class="w-100">
        <h2 class="tit-h2">Оплата yookassa</h2>

        <p class="text mb-5">
          Если вас не перенаправило на страницу оплаты, нажмите кнопку "Перейти
          к оплате"
        </p>

        <div class="plan__btns">
          <a
            :href="this.$store.state.invoice.payUrl"
            target="_blank"
            class="plan__submit mb-5"
            @click="confirmVisible = true"
          >
            Перейти к оплате
          </a>

          <div>
            <h3 class="tit-h3 mb-3">Статус оплаты</h3>

            <p class="text mb-3">
              Как только вы оплатите, данные автоматически обновяться. Если
              этого не произошло, перезагрузите страницу
            </p>

            <div
              class="invoice-status"
              v-if="Object.keys(currentInvoiceStatus.data).length !== 0"
            >
              <p class="text invoice-status__text">
                {{ statusInvoiceText }}
              </p>

              <status-icon
                class="invoice-status__icon"
                :type="
                  currentInvoiceStatus.data.invoice.paid &&
                  currentInvoiceStatus.data.invoice.status !== 'canceled'
                    ? 2
                    : 0
                "
                :loader="
                  (currentInvoiceStatus.data.invoice.paid &&
                    currentInvoiceStatus.data.invoice === 'not found') ||
                  currentInvoiceStatus.data.invoice.status === 'pending'
                "
                :kit="0"
              ></status-icon>
            </div>
            <div class="invoice-status" v-else>
              <p class="text invoice-status__text">
                {{ statusInvoiceText }}
              </p>

              <status-icon
                class="invoice-status__icon"
                :type="0"
                :loader="true"
                :kit="0"
              ></status-icon>
            </div>
          </div>

          <!-- <button
          target="_blank"
          class="plan__submit"
          v-if="confirmVisible"
          @click="this.$emit('finish', { type: 'yookassa' })"
        >
          Я всё оплатил
        </button> -->
        </div>
      </div>
    </div>

    <div
      v-else-if="planStep === 3"
      :class="{ _loader: this.$store.state.invoice.invoiceLoading }"
    >
      <h2 class="mb-3">Ваш счет готов</h2>

      <div>
        <button
          target="_blank"
          class="plan__submit mb-4"
          @click="
            $store.dispatch(
              'downloadInvoice',
              this.$store.state.invoice.bankInfo.bitrix_invoice_id
            )
          "
        >
          Скачать счет
        </button>

        <button
          target="_blank"
          class="plan__submit"
          @click="this.$emit('finish', { type: 'bank' })"
        >
          Вернуться
        </button>
      </div>
    </div>

    <div v-else-if="planStep === 4">
      <h2 v-if="error.method === 'bank'">
        Ошибка {{ error.status }} при создании счета
      </h2>
      <h2 v-if="error.method === 'yookassa'">
        Ошибка {{ error.status }} при создании ссылки ykassa
      </h2>

      <p class="text">техническая информация для поддержки:</p>
      <p class="text text-14-op w-50 mb-5">{{ error.text }}</p>

      <p class="text mb-4">
        Упс, произошла ошибка, обратитесь в
        <a
          href="https://t.me/qoob_store_support_bot"
          class="link"
          target="_blank"
          >поддержку qoob.store, </a
        >и мы решим все проблемы
      </p>
    </div>
  </div>

  <transition name="modalAm">
    <info-modal
      v-show="infoIndex != 0"
      :infoIndex="infoIndex"
      :closeType="2"
      @closeInfo="selectInfo(0)"
    />
  </transition>
</template>

<script>
import customCheckbox from "@/components/Dashboard/Billing/custom-checkbox";
import infoModal from "@/components/Dashboard/Billing/info-modal";
import { ref, reactive, computed } from "vue";
import { useStore } from "vuex";
import paymentData from "./payment-data.vue";
import statusIcon from "../../UI/status-icon.vue";

export default {
  name: "select-plan",
  components: {
    customCheckbox,
    infoModal,
    paymentData,
    statusIcon,
  },
  props: {
    currentTariff: {
      type: Object,
      required: true,
    },
    // companyList: {
    //   type: Array,
    //   required: true,
    // },
  },
  setup(props) {
    const $store = useStore();
    const companyList = computed(() => $store.state.crm.filialsList);
    const license = computed(() => $store.state.tarif.license);

    const currentInvoiceStatus = computed(
      () => $store.state.invoice.currentInvoiceStatus
    );

    const error = computed(() => $store.state.invoice.error);

    const confirmVisible = ref(false);

    let planStep = ref(0);

    let loadingActiveTestDrive = computed(
      () => $store.state.tarif.loadingActiveTestDrive
    );

    const statusInvoiceText = computed(() => {
      let text = "Ожидание транзикции";
      console.log(JSON.stringify(currentInvoiceStatus.value.data));
      if (Object.keys(currentInvoiceStatus.value.data).length === 0) {
        text = "Ожидание транзикции";
      } else {
        switch (currentInvoiceStatus.value.data.invoice.status) {
          case "succeeded":
            text = "Оплата прошла успешно";

            break;

          case "canceled":
            text = "Операция отменена";
            break;

          case "pending":
            text = "Ожидание";
            break;

          default:
            text = "Ожидание транзакции";
            break;
        }
      }

      return text;
    });

    const termsList = reactive([
      {
        text: "Я принимаю условия соглашения сервиса qoob.store",

        state: false,
      },
      {
        text: "Я даю согласия на обработку персональных данных",

        state: false,
      },
    ]);

    function toggleCheckbox(data) {
      termsList[data.index].state = data.state;
    }

    const infoIndex = ref(0);

    function selectInfo(index) {
      infoIndex.value = index;
    }

    let interval;

    function createInvoice(invoiceType) {
      $store.state.invoice.invoiceInfo.invoice_type = invoiceType;
      $store.state.invoice.invoiceInfo.tariff_code = props.currentTariff.code;
      if (props.currentTariff.personal) {
        $store.state.invoice.invoiceInfo.personal_offer_id =
          props.currentTariff.id;
      }
      if (invoiceType === "yookassa") {
        planStep.value = 2;
      } else if (invoiceType === "bank") {
        planStep.value = 3;
      }
      $store.dispatch("createInvoice", props.currentTariff.price).then(() => {
        if (error.value.status) {
          planStep.value = 4;
        } else {
          interval = setInterval(() => {
            $store.dispatch("getStatusInvoice");

            if (Object.keys(currentInvoiceStatus.value.data).length !== 0) {
              console.log(currentInvoiceStatus.value.data.invoice.status);
              if (
                currentInvoiceStatus.value.data.invoice.status === "succeeded"
              ) {
                clearInterval(interval);

                $store.dispatch("pageReload");
              }
            }
          }, 2500);
        }
      });
    }

    const paymentInn = ref(null);

    return {
      companyList,
      termsList,
      toggleCheckbox,
      loadingActiveTestDrive,
      currentInvoiceStatus,
      statusInvoiceText,
      confirmVisible,
      createInvoice,
      paymentInn,
      infoIndex,
      selectInfo,
      error,
      license,
      planStep,
    };
  },
  mounted() {
    this.$store.dispatch(
      "timeline",
      `клиент выбрал лицензию "${this.currentTariff.price}₽  - ${this.currentTariff.name}"`
    );
  },
};
</script>

<style scoped>
.partner-image {
  height: 100%;
  width: auto;
}

.plan__btns {
  width: 70%;
}

.one-filial {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 20px;
  border-radius: 12px;
  box-sizing: border-box;
  background-color: #f6f9fc;
  transition: 0.2s;
  overflow: visible;
  font-size: 16px;
}

.instruction__text {
  margin-top: 15px;

  font-size: 16px;
  line-height: 24px;
  color: #6d6d6d;
}

.arrow-down {
  margin-left: auto;
}

.instruction-title__text {
  margin-left: 15px;
}

.instruction__title {
  display: flex;
  align-items: center;

  font-size: 16px;
}

.instruction {
  position: absolute;
  right: 0;
  top: calc(100% - 150px);

  width: 100%;
  min-height: 150px;
  padding: 32px 40px;

  box-shadow: 0px 17px 40px rgba(19, 49, 97, 0.07),
    0px 6.20528px 21.901px rgba(19, 49, 97, 0.0482987),
    0px 3.01255px 10.6325px rgba(19, 49, 97, 0.0389404),
    0px 1.47681px 5.21226px rgba(19, 49, 97, 0.0310596),
    0px 0.583932px 2.06094px rgba(19, 49, 97, 0.0217013);
  border-radius: 30px;
  box-sizing: border-box;

  background-color: #fff;
}

.card__text {
  margin-left: 20px;

  font-size: 14px;
  color: #6d6d6d;
}

.plan-block__item {
  display: flex;
  align-items: center;

  margin-bottom: 15px;
}

.plan-block__list {
  padding-top: 25px;
  padding-left: 40px;
  padding-bottom: 60px;
}

.card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #eff0f0;
}

.card__title {
  padding-left: 40px;

  font-size: 20px;
  font-weight: 600;
  text-align: left;
  color: #1e2022;
}

.card__days {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 95px;
  height: 95px;

  border-radius: 0px 30px 0px 0px;

  background: #ffc549;
}

.card__days p {
  font-size: 20px;
  font-weight: 500;
}

.card__days span {
  font-size: 14px;
}

.plan-block {
  width: 100%;

  box-shadow: 0px 17px 40px rgba(19, 49, 97, 0.07),
    0px 6.20528px 21.901px rgba(19, 49, 97, 0.0482987),
    0px 3.01255px 10.6325px rgba(19, 49, 97, 0.0389404),
    0px 1.47681px 5.21226px rgba(19, 49, 97, 0.0310596),
    0px 0.583932px 2.06094px rgba(19, 49, 97, 0.0217013);
  border-radius: 30px;

  background-color: #fff;
}

.plan__right {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 54%;
  max-width: 488px;
  margin-left: 60px;
}

.plan__submit {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 48px;

  border-radius: 12px;

  font-size: 16px;

  background-color: #ffc549;
}

.plan__submit:hover {
  background-color: #fed47a;
}

.plan__submit:active {
  background-color: #f0f3f8;
}

.plan__submit:disabled {
  color: #000;
  background-color: #f7f9fc;
}

.arrow {
  margin-left: 10px;
}

.terms__text {
  margin-left: 20px;

  font-size: 14px;
  text-align: left;
  color: #328fe4;
}

.terms__item {
  display: flex;

  margin-bottom: 20px;
}

.terms {
  margin-bottom: 35px;
}

.sum__number {
  font-size: 32px;
  letter-spacing: -2.5px;
  color: #1e2022;
}

.sum__text {
  font-size: 20px;
  font-weight: 600;
}

.sum-block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 60px;
  padding: 0 20px;
  margin-bottom: 35px;

  border-radius: 12px;

  background-color: #f6f9fc;
}

.stat__text--bold {
  font-size: 15px;
  font-weight: 500;
}

.plan-stat__item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 16px;
}

.plan-stat__item:not(:last-child) {
  margin-bottom: 20px;
}

.plan-stat {
  margin: 40px 0;
}

.drop__item-wrapper:hover {
  background-color: #f6f9fc;
}

.drop__item-wrapper:active {
  background-color: #f0f3f8;
}

.drop__item {
  display: flex;
  align-items: center;

  height: 45px;
  padding-left: 20px;

  font-size: 15px;
}

.drop__label {
  margin-bottom: 10px;

  font-size: 14px;
  color: #6d6d6d;
}

.subtitle {
  margin-bottom: 20px;

  font-size: 16px;
}

.title {
  margin-bottom: 25px;

  font-size: 20px;
  font-weight: 600;
  color: #1e2022;
}

.select-plan {
  display: flex;
  justify-content: space-between;
}

.modalAm-enter-active,
.modalAm-leave-active {
  transition: 0.5s all ease;
}

.modalAm-enter-from,
.modalAm-leave-to {
  right: -300px;
  opacity: 0;
}
</style>

<style scoped>
@media (max-width: 1600px) {
  .card__title {
    font-size: 18px;
  }

  .plan-block__list {
    padding-bottom: 25px;
  }
}

@media (max-width: 1440px) {
  .card__title {
    padding-left: 25px;
    font-size: 16px;
  }

  .plan-block__list {
    padding-left: 25px;
  }

  .instruction__text {
    font-size: 12px;
  }
}

@media (max-width: 1280px) {
  .plan__right {
    margin-left: 30px;
  }

  .subtitle {
    font-size: 14px;
  }

  .plan-stat {
    margin: 25px 0;
  }

  .plan-stat__item {
    font-size: 14px;
  }

  .stat__text--bold {
    font-size: 13px;
  }

  .sum-block {
    height: 50px;
    margin-bottom: 20px;
  }

  .sum__text {
    font-size: 16px;
  }

  .sum__number {
    font-size: 26px;
  }

  .terms {
    margin-bottom: 20px;
  }

  .card__days {
    width: 70px;
    height: 70px;
  }

  .card__days p {
    font-size: 18px;
  }

  .card__days span {
    font-size: 13px;
  }

  .card__text {
    font-size: 12px;
  }

  .instruction__title {
    font-size: 14px;
    line-height: 23px;
  }

  .instruction__text {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .one-filial {
    font-size: 14px;
  }

  .select-plan {
    display: block;
  }

  .plan__right {
    display: none;
  }

  .title {
    display: flex;
    align-items: center;

    height: 60px;
    border-bottom: 1px solid #eff0f0;

    font-size: 16px;
  }

  .plan-info {
    max-width: none;
  }

  .pay-block {
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 35px;
    border-radius: 10px;

    box-shadow: 0px 17px 40px rgba(19, 49, 97, 0.07),
      0px 6.20528px 21.901px rgba(19, 49, 97, 0.0482987),
      0px 3.01255px 10.6325px rgba(19, 49, 97, 0.0389404),
      0px 1.47681px 5.21226px rgba(19, 49, 97, 0.0310596),
      0px 0.583932px 2.06094px rgba(19, 49, 97, 0.0217013);
  }

  .plan-stat {
    margin: 0;
  }

  .plan-stat__item {
    font-size: 16px;
  }

  .plan-stat__item:not(:last-child) {
    margin-bottom: 10px;
  }

  .plan-stat__item1 {
    margin-bottom: 60px !important;
  }

  .stat__text--bold {
    font-size: 15px;
  }

  .sum-block {
    margin-top: 25px;
    margin-bottom: 0;
  }
}
</style>
<style>
.invoice-status {
  display: flex;
  justify-content: space-between;
  background: #f1f3f7;

  padding: 10px;

  border-radius: 10px;
}
.invoice-status__text {
  align-self: center;
}
.invoice-status__icon svg {
  width: 50px;
  height: 50px;
}
</style>