<template>
  <div class="d-flex justify-content-center regForm">
        <transition name="fade">
          <div v-if="step === 1" class="reg__step">
            <div class="text-center">
              <h2 class="reg__title">Регистрация</h2>
            </div>
            <div class="regSteps d-flex justify-content-between">
              <div class="stepBordered">
                <span class="stepNumberLighted">1</span>
              </div>
              <div class="step">
                <span class="stepNumber">2</span>
              </div>
              <div class="step">
                <span class="stepNumber">3</span>
              </div>
            </div>
            <div>
              <p class="stepDescription">
                Укажите личный контакт ответственного лица на которого будет зарегистрирован аккаунт.
              </p>
            </div>
            <tel-input
              ref="regInputStep1"
              :id="'telInput1'"
              @countryChange="
                (country) => (this.step1.inputPhoneCountry = country)
              "
              @tel="setTelephone"
              :country="this.step1.inputPhoneCountry"
              v-model="step1.inputPhoneNum"
              :class="{
                invalid:
                  this.v$.step1.inputPhone.$invalid &&
                  this.v$.step1.inputPhone.$dirty,
              }"
              :label="'Телефон'"
            >
            </tel-input>

            <cm-input
              :class="{
                invalid:
                  this.v$.step1.inputEmail.$invalid &&
                  this.v$.step1.inputEmail.$dirty,
              }"
              label="E-mail"
              :placeholder="'Ваш E-mail'"
              v-model="step1.inputEmail"
            ></cm-input>

            <div class="check-wrapper">
              <custom-checkbox item="" index="" @click="step1.inputCheck = !step1.inputCheck" />
              <button class="check__agreement" @click="this.$emit('openModal')">Я принимаю условия соглашения qoob.store</button>
            </div>

            <button-orange
                class="reg-next"
                :class="{ '_loader-btn': this.step1.loading }"
                :disabled="
                this.v$.step1.inputCheck.$invalid ||
                this.v$.step1.inputEmail.$invalid ||
                this.v$.step1.inputPhone.$invalid
              "
                @click="doStep()"
            >
              Далее
              <img src="../../assets/arrow-right-black.svg" alt="">
            </button-orange>

          </div>
        </transition>
        <transition name="fade">
          <div v-if="step === 2" class="reg__step">
            <div class="text-center">
              <h2 class="reg__title">Регистрация</h2>
            </div>
            <div class="regSteps d-flex justify-content-between mb-5">
              <div class="stepBordered"><span class="stepNumber">1</span></div>
              <div class="stepBordered">
                <span class="stepNumberLighted">2</span>
              </div>
              <div class="step"><span class="stepNumber">3</span></div>
            </div>
            <div class="">
              <p class="text-16-fw500 mb-4">
                Код отправлен на ватсап {{ step1.inputPhone }}
              </p>
            </div>
            <cm-input
              class="mb-4 caret-blue"
              placeholder="_ _ _ _"
              v-maska="'####'"
              v-model.trim="step2.inputCode"
              :id="'regStep2Code'"
              ref="registerCode"
              :label="
                this.step2.invalidCode
                  ? 'Код поддтверждения введён не верно'
                  : 'Код подтверждения'
              "
              :class="{ invalid: this.step2.invalidCode }"
            ></cm-input>
            <Timer
              class="mb-4"
              v-show="!step2.confirm"
              :date="refreshTimer()"
              @sendCode="sendCode()"
            ></Timer>
            <div class="d-flex justify-content-between mt-4 gap-4">

              <button-grey
                  class="go-back"
                  :class="{ '_loader-btn': this.step2.loading }"
                  @click="doStepBack"
              >
                <img src="../../assets/arrow-left-black.svg" alt="">
                Назад
              </button-grey>

              <button-orange
                  class="button-submit"
                  :class="{ '_loader-btn': this.step2.loading }"
                  :disabled="String(step2.inputCode).length !== 4"
                  @click="doStep"
              >
                Подтвердить
              </button-orange>

            </div>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="step === 3" class="reg__step">
            <div class="text-center">
              <h2 class="reg__title">Регистрация</h2>
            </div>
            <div class="regSteps d-flex justify-content-between">
              <div class="stepBordered"><span class="stepNumber">1</span></div>
              <div class="stepBordered"><span class="stepNumber">2</span></div>
              <div class="stepBordered">
                <span class="stepNumberLighted">3</span>
              </div>
            </div>
            <div>
              <p class="stepDescription">
                Если вам нечего добавить, то можете просто пропустить этот шаг,
                нажав кнопку “Готово”.
              </p>
            </div>
            <tel-input
                class="tel-input"
              v-model="step3.inputReferalPhone"
              :label="'Телефон того, кто порекомендовал'"
              :id="'refTelephone'"
              :fill="true"
            >
            </tel-input>
            <!-- <cm-input
              v-maska="'### - ###'"
              :placeholder="'... - ...'"
              v-model="step3.inputPromoCode"
              label="У меня есть промокод"
            ></cm-input> -->
            <div
              class="d-flex justify-content-between regBtn_half_step_3 gap-4"
            >

              <button-grey
                  class="go-back"
                  @click="doStepBack"
                  :class="{ '_loader-btn': this.step3.loading }"
              >
                <img src="../../assets/arrow-left-black.svg" alt="">
                Назад
              </button-grey>

              <button-orange
                  class="button-submit"
                  :class="{ '_loader-btn': this.step3.loading }"
                  @click="finish()"
              >
                Готово
              </button-orange>
              <!--  @click="$router.push({ name: 'services' })"-->
            </div>
          </div>
        </transition>
  </div>
</template>

<script>
/* eslint-disable */
import CmInput from "@/components/UI/inputs/CmInput";
import customCheckbox from "@/components/Dashboard/Billing/custom-checkbox";
import CmButton from "@/components/UI/buttons/CmButton";
import telInput from "@/components/UI/inputs/TelInput";
import Timer from "@/components/auth/Timer";
import useValidate from "@vuelidate/core";
import VPopup from "@/components/UI/v-popup.vue";
import infoModal from "@/components/Dashboard/Billing/info-modal.vue";
import buttonOrange from "@/components/UI/buttons/ButtonOrange";
import buttonGrey from "@/components/UI/buttons/ButtonGrey";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
import {useStore} from "vuex";
import {computed} from "vue";
// import axios from "axios";
export default {
  name: "Registration",
  components: {
    CmButton,
    CmInput,
    Timer,
    telInput,
    VPopup,
    infoModal,
    customCheckbox,
    buttonOrange,
    buttonGrey
  },
  setup() {
    const store = useStore();

    const popupState = computed(() => {
      console.log(store.state.startPagePopup)
      return store.state.startPagePopup;
    })

    return { popupState }
  },
  data() {
    return {
      v$: useValidate(),
      agreementIndex: 0,
      step1: {
        formatPhone: "",
        inputPhoneCode: "+7",
        inputPhone: "",
        inputPhoneCountry: "ru",
        inputPhoneNum: "",
        inputEmail: "",
        inputCheck: false,
        loading: false,
      },
      step2: {
        inputCode: "",
        code: 1234,
        invalidCode: false,
        confirm: false,
        loading: false,
      },
      step3: {
        inputReferalPhone: "",
        inputPromoCode: "",
        loading: false,
      },

      popupVisible: false,
      panelVisible: false,

      enterCode: 0,
      step: 1,

      timer: 300,
    };
  },
  validations() {
    return {
      step1: {
        inputPhone: {
          required,
          minLength: minLength(11),
        },
        inputEmail: { required, email },
        inputCheck: { sameAs: sameAs(true) },
      },
    };
  },
  methods: {
    doStep() {
      if (this.step === 1) {
        this.v$.step1.$touch();
        if (!this.v$.step1.$invalid) {
          this.sendCode();
        }
      } else if (this.step === 2) {
        this.step++;
        this.step2.confirm = true;
      }
    },
    doStepBack() {
      this.step--;
    },
    async sendCode() {
      this.step1.loading = true;
      this.enterCode = 1;
      this.step1.formatPhone = this.step1.inputPhone
        .replace(/\s/g, "")
        .slice(1);

      this.$store
        .dispatch("sendRegisterCode", this.step1.formatPhone)
        .then((request) => {
          console.log(request);
          let timerSeconds;
          if (request.data.data.code_ttl) {
            timerSeconds = request.data.data.code_ttl;
          } else if (request.data.error.code_ttl) {
            timerSeconds = request.data.error.code_ttl;
          }
          this.timer = timerSeconds;
          this.step1.loading = false;
          if (
            !!request.data.error.phone &&
            !!(request.data.error.phone.indexOf("User with phone") + 1) &&
            request.data.error.phone.indexOf("already exists")
          ) {
            console.log('4334');
            this.$store.dispatch("sendToLogin", [
              this.step1.inputPhoneCountry,
              this.step1.inputPhoneNum,
            ]);
          } else {
            this.step = 2;
            setTimeout(() => {
              this.$refs.registerCode.focus();
            }, 200);
          }
        });
    },
    async finish() {
      const regObj = {
        phone: this.step1.formatPhone,
        email: this.step1.inputEmail,
        register_code: this.step2.inputCode,
        partner: this.step3.inputReferalPhone
          ? this.step3.inputReferalPhone
          : "",
        promo_code: this.step3.inputPromoCode ? this.step3.inputPromoCode : "",
      };

      this.step3.loading = true;

      this.$store
        .dispatch("finishRegister", regObj)
        .then(() => {})
        .catch((err) => {
          this.step3.loading = false;
          console.log("err");
          console.log(err.response);
          console.log(err.response.status);
          if (err.response.data.detail === "Incorrect register code") {
            this.step = 2;
            this.step2.invalidCode = true;
          }
        });
    },
    refreshTimer() {
      this.date = new Date(Date.now() + this.timer * 1000);
      return this.date;
    },
    setTelephone(telArr) {
      this.step1.inputPhone = telArr[0];
      this.step1.inputPhoneCode = telArr[1];
      this.step1.inputPhoneNum = telArr[2];
    },
  },
  mounted() {
    this.$refs.regInputStep1.focus();

    this.$router.push('/registration')
  },
};
</script>

<style scoped>

.check-wrapper {
  display: flex;
  margin-bottom: 70px;
}

.check__agreement {
  margin-left: 20px;

  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #328FE4;
}

.reg-next {
  width: 100%;
  height: 48px;
}

.reg-next img {
  margin-left: 10px;
}

.go-back {
  width: 50%;
  height: 48px;
}

.button-submit {
  width: 50%;
  height: 48px;
}

.v-popup h2 {
  font-size: 27px;
  line-height: 1.2;

  margin-bottom: 10px;
}
.v-popup p {
  font-size: 18px;
}

.regForm {
  width: 385px;

  margin: 150px auto;
  box-sizing: content-box;
}
.regSteps {
  height: 2.375rem;
  margin-top: 1.9rem;
  margin-bottom: 1rem;
}
.regBtn_half {
  width: 183px;
  margin-top: 2px;
}
.regBtn_half_step_3 {
  margin-top: 32px;
}
.step {
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #dedede;
}
.checkBoxWrap {
  margin-top: 10px;
}
.stepNumber {
  font-family: "Graphik LCG";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #a1a2a2;
}
.stepDescription {
  font-family: "Graphik LCG";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #000000;
  text-align: left;
  margin-bottom: 32px;
}
.stepNumberLighted {
  font-weight: 500;
  line-height: 22px;
  color: #000000;
}
.stepBordered {
  width: 100%;
  border-bottom: 2px solid #ffcb00;
  text-align: center;
}
.timerWrap {
  margin-top: 8px;
  margin-bottom: 42px;
}

.wrap {
  margin-bottom: 16px;
}
.wrapper {
  margin-bottom: 20px;
}
.wrapInput {
  margin: 0 0 25px;
}

.reg-contract {
  font-size: 16px;
  list-style-type: none;
}
.reg-contract li {
  margin-bottom: 20px;
  user-select: text;
}

.reg__step {
  width: 100%;
  max-width: 385px;
}

.reg__title {
  font-size: 32px;
}

.regSteps {
  height: auto;
}

.stepBordered {
  padding-bottom: 12px;
}

.stepNumberLighted {
  font-size: 16px;
  font-weight: 500;
}

.stepDescription {
  margin-top: 20px;
}

.check-wrapper {
  margin-bottom: 50px;
}

.tel-input {
  padding: 0;
  padding-bottom: 50px;
  box-sizing: content-box;
}
</style>

