<template>
  <div
    class=" sms-wrapper"
    :class="{
      _loader: loadingSmsSettings || loadingSmsProviders || loadingSmsToggle,
    }"
  >
    <h4 class="tit-h4 mb-5">
      {{ !smsActive ? "Подключение канала SMS" : "SMS канал подключен" }}
    </h4>
    <div class="col-6 p-3 pe-4 sms-top">
      <div v-if="!crmConnect">
        <crmCyncError></crmCyncError>
      </div>
      <div class="wa-warn" v-else-if="!crmConnect || !qoobLicenseActive">
        <h3>Внимание!</h3>

        <!-- crm не подключенна или лицензии никогда не существовало -->
        <div class="wa-warn__text" v-if="!licenseExisted">
          <p>
            Мы не нашли у Вас ни одной активной лицензии на использование
            сервиса доставки уведомлений через SMS.
          </p>
          <p class="mb-4">
            Для начала работы, неоходимо приобрести лицензию сервиса qoob.store.
          </p>
        </div>
        <!-- лицензия закончилась -->
        <div class="wa-warn__text" v-else>
          <p>Ваш аккаунт был остановлен {{ license.date }} из-за неуплаты.</p>
          <p>
            Прямо сейчас вы можете включить этот инстанс, просто продлив
            подписку.
          </p>
        </div>

        <button-orange
          class="sms-button"
          cls="main"
          to-next
          @click="$router.push('/dashboard/iframe/billing')"
          >Выбрать лицензию</button-orange
        >
      </div>
      <!-- crm подключенна и лицензия активна -->
      <div v-if="crmConnect && qoobLicenseActive && !loadingSmsProviders">
        <!-- когда sms активен -->
        <div class="sms-drop">
          <drop-menu :itemSelected="itemSelected" :newClass="true" :bound="true">
            <template v-slot:name>SMS агрегатор</template>

            <template v-slot:text>
              <span v-if="selectedSmsAggregatorId === -1"
                >Выберите SMS агрегатор</span
              >
              <span v-else>{{ this.selectedSmsAggregatorName }}</span>
            </template>

            <template v-slot:item>
              <li
                class="drop__item-wrapper"
                v-for="(item, index) in smsProviders.providers"
                :key="index"
                @click="selectSms(index)"
              >
                <button class="drop__item">
                  {{ item }}
                </button>
              </li>
            </template>
          </drop-menu>
        </div>

        <div v-if="selectedSmsAggregatorId >= 0">
          <!-- <cm-input class="mt-3" label="Отправитель"></cm-input> -->
          <cm-input
            v-for="(field, index) in smsSettings.form_fields.properties"
            :key="index"
            :label="field.label"
            class="mt-3 sms-input"
            :password="field.title === 'Password'"
            v-model="smsSettings.settings[index]"
          ></cm-input>
          <button-orange
            cls="main"
            class="sms-button sms-button1 mt-3"
            @click="putSmsSettings"
          >
            {{ smsActive ? "Сохранить" : "Подключить" }}
          </button-orange>

          <!-- <cm-switch></cm-switch> -->
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
/* eslint-disable */
import CmInput from "@/components/UI/inputs/CmInput";
import CmSwitch from "@/components/UI/inputs/СmSwitch";
import dropMenu from "@/components/Dashboard/Billing/drop-menu";
import HelpCard from "@/components/Dashboard/HelpCard";
import StatusIcon from "../../../components/UI/status-icon.vue";
import { mapActions, mapState } from "vuex";
import CmCardBase from "@/components/UI/CmCardBase";
import buttonOrange from "@/components/UI/buttons/ButtonOrange";
import crmCyncError from '../../UI/crmCyncError.vue';



export default {
  name: "SmsSettings",
  components: {
    CmCardBase,
    HelpCard,
    dropMenu,
    CmInput,
    StatusIcon,
    CmSwitch,
    buttonOrange,
    crmCyncError
  },
  data: function () {
    return {
      isActive: false,
      selectedSmsAggregatorName: "",
      selectedSmsAggregatorId: -1,
      itemSelected: false,

      SmsAgregatorOn: "",
      SmsAgregatorStatus: "",
    };
  },

  computed: {
    ...mapState({
      content: (state) => state.texts.components.smsSettings,

      crmConnect: (state) => state.crm.crmConnect,
      qoobLicenseActive: (state) => state.tarif.licenseInfo.isActive,
      license: (state) => state.tarif.license,
      licenseExisted: (state) => state.tarif.licenseExisted,
      smsActive: (state) => state.sms.smsStatus.isAvailable,
      smsStatus: (state) => state.sms.smsStatus,
      loadingSmsStatus: (state) => state.sms.loadingSmsStatus,
      loadingSmsSettings: (state) => state.sms.loadingSmsSettings,
      loadingSmsProviders: (state) => state.sms.loadingSmsProviders,

      smsProviders: (state) => state.sms.smsProviders,
      smsSettings: (state) => state.sms.smsSettings,
      curentSmsProvider: (state) => state.sms.curentSmsProvider,

      smsActiveSwitch: (state) => state.sms.smsStatus.sms_enabled,

      loadingSmsToggle: (state) => state.sms.loadingSmsToggle,

      smsHelp: (state) => state.sms.smsHelp,
    }),
    currentFields() {
      let currentCrm = this.crmList.find(
        (el) => el.id === this.selectedSmsAggregatorId
      );
      return currentCrm ? currentCrm.fields : [];
    },
  },
  methods: {
    selectSms(index) {
      this.$store.commit(
        "setCurentSmsProvider",
        this.smsProviders.providers[index]
      );
      this.$store.dispatch("getSmsSettings").then(() => {
        this.selectedSmsAggregatorId = index;
        this.selectedSmsAggregatorName = this.smsProviders.providers[index];
      });
      this.itemSelected = true;
      setTimeout(() => {
        this.itemSelected = false;
      }, 500);
    },
    getTypeIcon() {
      if (!this.crmConnect || !this.qoobLicenseActive) {
        return 0;
      } else {
        if (this.qoobLicenseActive && this.smsActive && this.smsActiveSwitch) {
          return 2;
        } else {
          return 1;
        }
      }
    },
    ...mapActions({
      putSmsSettings: "putSmsSettings",
    }),
  },
  mounted() {
    this.$store.dispatch("getSmsHelp");

    if (this.smsActive) {
      this.SmsAgregatorOn = `${this.smsStatus.balance}₽`;
      this.SmsAgregatorStatus = this.smsStatus.sms_provider;
    } else {
      this.SmsAgregatorOn = "Не подключен";
      this.SmsAgregatorStatus = "Не активен";
    }

    if (!this.smsProviders.providers) {
      this.$store.dispatch("getSmsProviders");
    }
    const currentAggregator = this.curentSmsProvider;
    if (currentAggregator) {
      this.$store.dispatch("getSmsSettings").then(() => {
        this.selectedSmsAggregatorId = this.selectedSmsAggregatorId =
          this.smsProviders.providers.indexOf(currentAggregator);
        this.selectedSmsAggregatorName = currentAggregator;
      });
    }
  },
  updated() {
    if (this.smsActive) {
      this.SmsAgregatorOn = `${this.smsStatus.balance}₽`;
      this.SmsAgregatorStatus = this.smsStatus.sms_provider;
    } else {
      this.SmsAgregatorOn = "Не подключено";
      this.SmsAgregatorStatus = "Не активен";
    }
  },
  watch: {
    smsProviders() {
      if (this.smsProviders.selected) {
        this.selectedSmsAggregatorId = this.smsProviders.providers.indexOf(
          this.smsProviders.selected
        );
        this.selectedSmsAggregatorName = this.smsProviders.selected;
      }
    },
    smsSettings() {
      this.$forceUpdate;
    },
  },
};
</script>

<style scoped>
.w-70 {
  width: 70%;
}

.card-bottom {
  padding-top: 20px;
  margin-top: 20px !important;
  border-top: 1px solid #eff0f0;
}

.sms-settings__title {
  padding-bottom: 20px;
  margin-bottom: 25px !important;

  border-bottom: 1px solid #eff0f0;

  font-size: 20px;
}
hr {
  color: #d4d4d4;
}

.smsLogo {
  width: 130px;
  height: 33px;
}

.drop__item-wrapper:hover {
  background-color: #f6f9fc;
}

.drop__item-wrapper:active {
  background-color: #f0f3f8;
}

.drop__item {
  display: flex;
  align-items: center;

  height: 45px;
  padding-left: 20px;

  font-size: 15px;
}

.sms-button {
  margin-top: 30px !important;
}

.settings__button {
  width: 100%;
  max-width: 330px;
  height: 48px;
}

.bottom__text {
  font-size: 16px;
  line-height: 30px;
}

.sms-top {
  padding: 0 !important;
}

@media (max-width: 768px) {
  .sms-wrapper {
    flex-direction: column;
    margin: 0 !important;
  }

  .sms-top {
    width: 100%;
    padding: 0 !important;
  }

  .sms-settings__title {
    display: flex;
    align-items: center;

    height: 60px;
    padding: 0;
    padding-left: 15px;
    margin: 0;
  }

  .wa-warn {
    padding: 0 15px;
    background-color: #fff;
  }

  .wa-warn__text {
    margin-bottom: 0;
  }

  .sms-button {
    width: 100%;
    margin-top: 0 !important;
  }

  .sms-left {
    width: 100%;
  }

  .sms-card {
    padding: 25px 30px;
    margin-top: 50px;
  }

  .smsLogo {
    width: 120px;
    height: 28px;
  }

  .card-header1 {
    align-items: center;
  }

  .text-wrapper {
    width: 40%;
  }

  .card-text1 {
    font-size: 14px;
  }

  .sms-input {
    margin: 0 15px;
  }

  .sms-drop {
    margin: 0 15px;
  }

  .sms-button1 {
    width: calc(100% - 30px);
    margin: 0 15px !important;
    margin-top: 25px !important;
  }

  .icon-status {
    width: 40px;
    height: 40px;
  }

  .settings__drop {
    max-width: 600px;
    margin-left: 15px;
  }

  .input-wrapper {
    max-width: 600px;
    margin: 0 15px;
  }

  .settings__button {
    width: 100%;
    max-width: 330px;
    height: 48px;
    margin-top: 30px;
  }

  .settings__bottom {
    width: 100%;
    padding: 0 !important;
    margin-top: 90px;
  }

  .settings__card {
    max-width: 350px;
    padding: 30px;
  }

  .bottom__text {
    font-size: 14px;
  }

  .help-card {
    max-width: 350px;
  }
}
</style>

