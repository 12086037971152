<template>
  <div iv class="container">
    <div class="align-items-center justify-content-center loginForm">
      <div>
        <h2 class="logout__title">Выход</h2>
        <div class="logoutMessege">
          Вы уверенны, что хотите выйти?
        </div>

        <button-orange class="btnLogin logout__button" @click="this.$store.dispatch('logout')">Выйти</button-orange>
        <button-grey class="btnLogin logout__button" @click="this.$router.push('/dashboard/services')">
          <img class="arrow" src="../assets/arrow-left-black.svg" alt="">
          Вернуться в личный кабинет
        </button-grey>
      </div>
    </div>
  </div>
</template>

<script>
import buttonOrange from "@/components/UI/buttons/ButtonOrange";
import buttonGrey from "@/components/UI/buttons/ButtonGrey";

export default {
  name: "Login",
  components: { buttonOrange, buttonGrey },
  mounted() {
    if (!(localStorage.getItem('isAuth') === 'auth')) {
      this.$router.push('/')
    }
  }
};
</script>

<style scoped>
.logoutMessege {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-bottom: 41px;
}

.loginForm {
  margin: 295px auto;
  width: 385px;
  text-align: center;
}

h2 {
  font-family: Graphik LCG;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  color: #1e2022;
  padding-bottom: 20px;
  margin-bottom: 17px;

  border-bottom: solid 2px #ffc549;
}
.btnLogin {
  width: 100%;
  margin-bottom: 38px;
}

.logout__button {
  width: 100%;
  height: 48px;
}

.logout__button1 {
  margin-top: 40px;
  height: 48px;
}

.arrow {
  margin-right: 10px;
}
</style>

<style scoped>
@media (max-width: 550px) {

  .loginForm {
    width: 100%;
    max-width: 330px;
    padding: 100px 15px;
    margin: 0 auto;
  }

  .logout__title {
    font-size: 20px;
  }

  .logoutMessege {
    font-size: 14px;
  }

  .logout__button {
    width: 100%;
    height: 48px;

    margin-bottom: 20px;
  }
}
</style>

