<template>
  <transition name="modalAm">
    <div class="info-modal" v-show="infoIndex !== 0">
      <mobile-modal-header @closeModal="closeModal">
        Согласие
      </mobile-modal-header>

      <div class="info__wrapper">
        <template v-if="infoIndex === 1" >
          <h2 v-html="licenseAgreement.title"></h2>
          <div v-html="licenseAgreement.content">

          </div>
        </template>

        <template v-else-if="infoIndex === 2">
          <h2 v-html="privacyPolicyCrm.title"></h2>
          <div v-html="privacyPolicyCrm.content">

          </div>
        </template>

        <template v-else-if="infoIndex === 3">
          <h2 v-html="personalDataAgreement.title"></h2>
          <div v-html="personalDataAgreement.content">

          </div>
        </template>
      </div>

      <close-button
        class="info__close"
        :closeType="closeType"
        @click="closeModal"
        v-show="windowWidth > 550"
      />
    </div>
  </transition>

  <transition name="backgroundAm">
    <div
      class="info__background"
      @click="closeModal"
      v-show="infoIndex !== 0"
    ></div>
  </transition>
</template>

<script>
import closeButton from "@/components/Dashboard/Billing/close-button";
import mobileModalHeader from "@/components/Dashboard/mobileModalHeader";
import { computed } from "vue";
import {mapState} from 'vuex';

export default {
  name: "info-modal",
  components: {
    closeButton,
    mobileModalHeader,
  },
  props: {
    infoIndex: {
      type: Number,
      default: 0,
    },
    closeType: {
      type: Number,
    },
  },
  emits: ["closeInfo"],
  setup(props, { emit }) {
    function closeModal() {
      emit("closeInfo");
    }

    const windowWidth = computed(() => {
      return document.documentElement.clientWidth;
    });

    return { closeModal, windowWidth };
  },
  computed: {
    ...mapState({
      licenseAgreement: state => state.doc.licenseAgreement,
      privacyPolicyCrm: state => state.doc.privacyPolicyCrm,
      personalDataAgreement: state => state.doc.personalDataAgreement,
    })
  },
  mounted() {
    this.$forceUpdate()
  }
};
</script>

<style>
.info__close {
  top: 71px;
  left: -48px;
  z-index: 40;

  box-shadow: -15px 0px 13px -16px rgba(114, 114, 114, 0.2) inset;
}

.info-modal {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 40;

  width: 63%;
  min-width: 720px;
  min-height: 100vh;
  max-height: 100vh;

  box-shadow: 0 24px 120px -30px rgba(0, 0, 0, 0.15);

  background-color: #fff;
}

.info-modal .info__wrapper {
  position: relative;
  z-index: 20;

  padding: 80px 55px 40px 55px;
  max-height: 100vh;
  height: 100vh;

  box-sizing: border-box;
  overflow-y: scroll;
  -ms-overflow-style: none;
}

.info-modal .info__wrapper::-webkit-scrollbar {
  width: 0;
}
.info-modal .info__wrapper::-webkit-scrollbar {
  width: 0;
}

.info-modal h3 {
  margin-bottom: 60px;

  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  line-height: 30px;
}

.info-modal p {
  margin-bottom: 30px;

  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

.info-modal .info__list {
  margin-bottom: 30px;
}

.info-modal .info__item {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

.info__background {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;

  width: 120vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
}

.modalAm-enter-active,
.modalAm-leave-active {
  transition: 0.3s all ease;
}

.modalAm-enter-from,
.modalAm-leave-to {
  right: -700px;
  opacity: 0;
}

.backgroundAm-enter-active,
.backgroundAm-leave-active {
  transition: 0.3s all ease;
}

.backgroundAm-enter-from,
.backgroundAm-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .info-modal {
    width: 100%;
    max-height: none;
    min-width: auto;
  }

  .info-modal .info__wrapper {
    padding: 25px;
    padding-bottom: 100px;
  }

  .info-modal h3 {
    margin-bottom: 40px;
  }

  .info-modal p {
    font-size: 16px;
  }

  .info-modal .info__item {
    font-size: 16px;
  }
}
</style>