<template>
  <div :class="{'_loader-corner': loadingLicense || loadingCrmStatus}">
    <cm-card
      height="100%"
      class="itemSecond smsCard"
      @click="this.qoobApp ? $router.push('/dashboard/app') : false"
      :specialClass="'status-card'"

    >
      <div class="d-flex justify-content-between">
        <div
          class="servicesSmsLogo"
          :style="{
            'background-image':
              'url(' + require('@/assets/qoob-logo.svg') + ')',
          }"
        ></div>

        <status-icon
        class="card-icon-status"
        :type="typeIcon"
      ></status-icon>
        <!--      <img class="servicesSmsLogo" src="@/assets/sms.png" alt="">-->
      </div>
      <div class="servicesSmsContent fs-14">
        <div class="w-100">
          <div class="d-flex w-100">
            <p class="w-50">Приложение</p>
            <p class="">
              {{ this.qoobApp ? 'Активно'  : 'Не активно' }}
            </p>
          </div>
          <div class="d-flex w-100">
            <p class="w-50">Скачиваний:</p>
            <p class="" v-if="qoobApp">{{ this.qoobApp.user_count }}</p>
          </div>
        </div>
      </div>
    </cm-card>
  </div>
</template>

<script>
import CmCard from "@/components/UI/CmCard";
import { mapState } from "vuex";
import statusIcon from '../../../components/UI/status-icon.vue';

export default {
  name: "servicesQoobApp",
  components: { CmCard, statusIcon },
  computed: {
    ...mapState({
      loadingLicense: (state) => state.tarif.loadingLicense,
      loadingCrmStatus: (state) => state.crm.loadingCrmStatus,
      content: (state) => state.texts.dashboard.services.qoobApp,
      crmConnect: (state) => state.crm.crmConnect,
      isActive: (state) => state.tarif.licenseInfo.isActive,
      license: (state) => state.tarif.licenseInfo,
      licenseExisted: (state) => state.tarif.licenseExisted,
      qoobApp: (state) => state.tarif.licenseInfo.qoob_app
    }),

    typeIcon() {
      if (!this.crmConnect || !this.isActive) {
        return 0;
      } else {
        if (this.qoobApp) {
          return 2;
        } else {
          return 1;
        }
      }
    },
  },
};
</script>

<style scoped>
.smsCard {
  cursor: pointer;
}
.text-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.text-column {
  font-size: 14px;
  line-height: 24px;
}

.servicesSmsContent {
  display: flex;
  align-items: center;
  margin-top: 25px;

  font-size: 14px;
  line-height: 24px;
}
.servicesSmsLogo {
  height: 33px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}
.itemSecond {
  height: 100% !important;
  padding: 25px;
}

@media (max-width: 1600px) and (min-width: 1024px) {
  .itemSecond {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .servicesSmsContent {
    margin-top: 15px;

    font-size: 12px;
    line-height: 20px;
  }

  .text-column {
    font-size: 12px;
    line-height: 20px;
  }

  .servicesSmsLogo {
    height: 25px;
  }
}

@media (max-width: 1280px) and (min-width: 1024px) {
  .itemSecond {
    padding: 15px 20px;
  }

  .servicesSmsContent {
    font-size: 10px;
    line-height: 18px;
  }

  .servicesSmsContent {
    margin-top: 10px;
  }

  .servicesSmsLogo {
    height: 20px;
  }
}

@media (max-width: 768px) and (min-width: 551px) {
  .itemSecond {
    padding: 15px 25px;
  }

  .servicesSmsLogo {
    height: 25px;
  }

  .servicesSmsContent {
    margin-top: 15px;
  }

  .servicesSmsContent {
    font-size: 12px;
    line-height: 24px;
  }
}

@media (max-width: 550px) {
  .servicesSmsContent {
    margin-top: 15px;
  }

  .servicesSmsContent {
    font-size: 12px;
    line-height: 20px;
  }

  .servicesSmsLogo {
    height: 24px;
  }
}
</style>