<template>

  <div class="d-flex justify-content-center regForm">
    <!-- 1 -->
    <transition name="fade" mode="out-in">
      <div v-if="step === 1" class="reg__step">
        <div class="text-center">
          <h2 class="reg__title">Интеграция с CRM</h2>
          <p class="reg__text">Филиал #{{ this.$store.getters.getSalonId }}</p>
        </div>
        <div class="regSteps d-flex justify-content-between ">
          <div class="stepBordered">
            <span class="stepNumberLighted">1</span>
          </div>
          <div class="step">
            <span class="stepNumber">2</span>
          </div>
          <div class="step">
            <span class="stepNumber">3</span>
          </div>

          <div class="reg-version">{{this.$store.state.version}}</div>
        </div>
        <div>
          <p class="stepDescription align-center">
            Укажите личный контакт ответственного лица.
          </p>
        </div>
        <tel-input
          ref="regInputStep1"
          :id="'telInput1'"
          @countryChange="(country) => (this.step1.inputPhoneCountry = country)"
          @tel="setTelephone"
          :country="this.step1.inputPhoneCountry"
          v-model="step1.inputPhoneNum"
          :class="{
            invalid:
              this.v$.step1.inputPhone.$invalid &&
              this.v$.step1.inputPhone.$dirty,
          }"
          :label="'Телефон'"
        >
        </tel-input>

        <cm-input
          :class="{
            invalid:
              this.v$.step1.inputEmail.$invalid &&
              this.v$.step1.inputEmail.$dirty,
          }"
          label="E-mail"
          :placeholder="'Ваш E-mail'"
          v-model="step1.inputEmail"
        ></cm-input>

        <div class="check-wrapper">
          <custom-checkbox
            item=""
            index=""
            @click="step1.inputCheck = !step1.inputCheck"
          />
          <button class="check__agreement" @click="this.$emit('openModal')">
            Я принимаю условия соглашения qoob.store
          </button>
        </div>

        <button-orange
          class="reg-next"
          :class="{ '_loader-btn': this.step1.loading }"
          :disabled="
            this.v$.step1.inputCheck.$invalid ||
                this.v$.step1.inputEmail.$invalid ||
                this.v$.step1.inputPhone.$invalid
          "
          @click="regCheck()"
        >
          Далее
          <img src="../../assets/arrow-right-black.svg" alt="" />
        </button-orange>
      </div>
      <div v-else-if="step === 3" class="reg__step">
        <div class="text-center">
          <h2 class="reg__title">Интеграция с CRM</h2>
          <p class="reg__text">Филиал #{{ this.$store.getters.getSalonId }}</p>
        </div>
        <div class="regSteps d-flex justify-content-between">
          <div class="stepBordered"><span class="stepNumber">1</span></div>
          <div class="stepBordered">
            <span class="stepNumber">2</span>
          </div>
          <div class="stepBordered">
            <span class="stepNumber stepNumberLighted">3</span>
          </div>
          <div class="reg-version">{{this.$store.state.version}}</div>
        </div>
        <div>
          <p class="stepDescription">
            Если вам нечего добавить, то можете просто пропустить этот шаг,
                нажав кнопку “Готово”.
          </p>
        </div>
        
        <tel-input
                class="tel-input"
              v-model="step3.inputReferalPhone"
              :label="'Телефон того, кто порекомендовал'"
              :id="'refTelephone'"
              :fill="true"
            ></tel-input>
        <div class="d-flex justify-content-between regBtn_half_step_3 gap-4">
          <!-- <button-grey
            class="go-back"
            @click="doStepBack"
            :class="{ '_loader-btn': this.step3.loading }"
          >
            <img src="../../assets/arrow-left-black.svg" alt="" />
            Назад
          </button-grey> -->

          <button-orange
            class="button-submit w-100"
            :disabled="false"
            :class="{ '_loader-btn': this.step3.loading }"
            @click="finishReg()"
          >
            Готово
          </button-orange>
        </div>
      </div>
      <div v-else-if="step === 2" class="reg__step">
        <div class="text-center">
          <h2 class="reg__title">Интеграция с CRM</h2>
          <p class="reg__text">Филиал #{{ this.$store.getters.getSalonId }}</p>
        </div>
        <div class="regSteps d-flex justify-content-between mb-5">
          <div class="stepBordered"><span class="stepNumber">1</span></div>
          <div class="stepBordered">
            <span class="stepNumberLighted">2</span>
          </div>
          <div class="step"><span class="stepNumber">3</span></div>
        </div>
        <div class="">
          <p class="text-16-fw500 mb-4">
            Код отправлен на ватсап {{ step1.inputPhone }}
          </p>
        </div>
        <cm-input
          class="mb-4 caret-blue"
          placeholder="_ _ _ _"
          v-maska="'####'"
          v-model.trim="step2.inputCode"
          :id="'regStep2Code'"
          ref="registerCode"
          :label="
            this.step2.invalidCode
              ? 'Код поддтверждения введён не верно'
              : 'Код подтверждения'
          "
          :class="{ invalid: this.step2.invalidCode }"
        ></cm-input>
        <Timer
          class="mb-4"
          v-show="!step2.confirm"
          :date="refreshTimer()"
          @sendCode="sendCode()"
        ></Timer>
        <div class="d-flex justify-content-between mt-4 gap-4">
          <!-- <button-grey
            class="go-back"
            :class="{ '_loader-btn': this.step2.loading }"
            @click="doStepBack"
          >
            <img src="../../assets/arrow-left-black.svg" alt="" />
            Назад
          </button-grey> -->

          <button-orange
            class="button-submit w-100"
            :class="{ '_loader-btn': this.step2.loading }"
            :disabled="String(step2.inputCode).length !== 4"
            @click="finish()"
          >
            Подтвердить
          </button-orange>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable */
import CmInput from "@/components/UI/inputs/CmInput";
import customCheckbox from "@/components/Dashboard/Billing/custom-checkbox";
import CmButton from "@/components/UI/buttons/CmButton";
import telInput from "@/components/UI/inputs/TelInput";
import Timer from "@/components/auth/Timer";
import useValidate from "@vuelidate/core";
import VPopup from "@/components/UI/v-popup.vue";
import infoModal from "@/components/Dashboard/Billing/info-modal.vue";
import buttonOrange from "@/components/UI/buttons/ButtonOrange";
import buttonGrey from "@/components/UI/buttons/ButtonGrey";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
import { useStore } from "vuex";
import { computed } from "vue";
// import axios from "axios";
export default {
  name: "Registration",
  components: {
    CmButton,
    CmInput,
    Timer,
    telInput,
    VPopup,
    infoModal,
    customCheckbox,
    buttonOrange,
    buttonGrey,
  },
  setup() {
    const store = useStore();

    const popupState = computed(() => {
      console.log(store.state.startPagePopup);
      return store.state.startPagePopup;
    });

    return { popupState };
  },
  data() {
    return {
      v$: useValidate(),
      agreementIndex: 0,
      step1: {
        formatPhone: "",
        inputPhoneCode: "+7",
        inputPhone: "",
        inputPhoneCountry: "ru",
        inputPhoneNum: "",
        inputEmail: "",
        inputCheck: false,
        loading: false,
      },
      step2: {
        inputCode: "",
        code: 1234,
        invalidCode: false,
        confirm: false,
        loading: false,
        inputEmail: "",
      },
      step3: {
        inputReferalPhone: "",
        inputPromoCode: "",
        loading: false,
      },

      popupVisible: false,
      panelVisible: false,

      enterCode: 0,
      step: 1,

      timer: 300,

      authUser: null,
    };
  },
  validations() {
    return {
      step1: {
        inputPhone: {
          required,
          minLength: minLength(11),
        },
        inputCheck: { sameAs: sameAs(true) },
        inputEmail: { required, email },
      },
    };
  },
  methods: {
    doStep() {
      if (this.step === 1) {
        this.v$.step1.$touch();
        if (!this.v$.step1.$invalid) {
          this.regCheck();
        }
      } else if (this.step === 2) {
        this.step++;
        this.step2.confirm = true;
      }
    },
    doStepBack() {
      this.step--;
    },
    async regCheck() {
      this.step1.loading = true;
      this.enterCode = 1;
      this.step1.formatPhone = this.step1.inputPhone
        .replace(/\s/g, "")
        .slice(1);
      this.$store.dispatch('regCheck', this.step1.formatPhone).then((request) => {
        if (request.data.error.user === 'already registered') {
          this.authUser = "login";
          this.sendCode()
        } else {
          this.authUser = "registration";
          this.sendCode()
        }
      })
      
    },
    sendCode() {
      if (this.authUser === 'login') {
        this.$store
        .dispatch("sendLoginCode", this.step1.formatPhone)
        .then((request) => {
          let timerSeconds;
          if (request.data.data.code_ttl) {
            timerSeconds = request.data.data.code_ttl;
          } else if (request.data.error.code_ttl) {
            timerSeconds = request.data.error.code_ttl;
          }
          this.timer = timerSeconds;
          this.step1.loading = false;

          this.step = 2;
        })
        
      } else if (this.authUser === "registration") {
        this.$store
        .dispatch("sendRegisterCode", this.step1.formatPhone)
        .then((request) => {
          console.log(request);
          let timerSeconds;
          if (request.data.data.code_ttl) {
            timerSeconds = request.data.data.code_ttl;
          } else if (request.data.error.code_ttl) {
            timerSeconds = request.data.error.code_ttl;
          }
          this.timer = timerSeconds;
          this.step1.loading = false;
          this.step++
        });
      }
    },
    async finish() {
      if (this.authUser === "registration") {
        this.step++
      } else if (this.authUser === "login") {
        this.$store
          .dispatch("finishLogin", {
            telephone: this.step1.formatPhone,
            code: this.step2.inputCode,
            salon_id: localStorage.getItem('salon_id')
          })
          .then(() => {
            this.loadingPushApp = false;
          })
          .catch((err) => {
            if (err.response && err.response.data && err.response.data.detail) {
              if (
                err.response.data.detail === "Incorrect authentication code"
              ) {
                console.log(err.response.data.detail);
                this.invalidCode = true;
                this.loadingPushApp = false;
              }
            } 
            if (err.response.status === 500) {
              console.log('err500');
              this.$emit('err500')
            }
          });
      }
    },
    finishReg() {
      const regObj = {
          phone: this.step1.formatPhone,
          email: this.step1.inputEmail,
          register_code: this.step2.inputCode,
          partner: this.step3.inputReferalPhone
            ? this.step3.inputReferalPhone
            : "",
          promo_code: this.step3.inputPromoCode
            ? this.step3.inputPromoCode
            : "",
            salon_id: this.$store.getters.getSalonId
        };
        this.step3.loading = true;

        this.$store
          .dispatch("finishRegister", regObj)
          .then(() => {})
          .catch((err) => {
            if (err.response && err.response.data && err.response.data.detail) {
              if (
                err.response.data.detail === "Incorrect authentication code"
              ) {
                console.log(err.response.data.detail);
                this.invalidCode = true;
                this.loadingPushApp = false;
              }
            } 
            if (err.response.status === 500) {
              console.log('err500');
              this.$emit('err500')
            }
          });
    },
    refreshTimer() {
      this.date = new Date(Date.now() + this.timer * 1000);
      return this.date;
    },
    setTelephone(telArr) {
      this.step1.inputPhone = telArr[0];
      this.step1.inputPhoneCode = telArr[1];
      this.step1.inputPhoneNum = telArr[2];
    },
  },
  mounted() {
    this.$refs.regInputStep1.focus();

    this.$router.push("/registration");
  },
};
</script>

<style scoped>
.reg-version {
  font-size: 12px;
  position: absolute;
  right: 0;
  bottom: -20px;
  opacity: .7;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.check-wrapper {
  display: flex;
  margin-bottom: 70px;
}

.check__agreement {
  margin-left: 20px;

  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #328fe4;
}

.reg-next {
  width: 100%;
  height: 48px;
}

.reg-next img {
  margin-left: 10px;
}

.go-back {
  width: 50%;
  height: 48px;
}

.button-submit {
  width: 50%;
  height: 48px;
}

.v-popup h2 {
  font-size: 27px;
  line-height: 1.2;

  margin-bottom: 10px;
}
.v-popup p {
  font-size: 18px;
}

.regForm {
  width: 385px;

  margin: 150px auto;
  box-sizing: content-box;
}
.regSteps {
  height: 2.375rem;
  margin-top: 1.9rem;
  margin-bottom: 1rem;

  position: relative;
}
.regBtn_half {
  width: 183px;
  margin-top: 2px;
}
.regBtn_half_step_3 {
  margin-top: 32px;
}
.step {
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #dedede;
}
.checkBoxWrap {
  margin-top: 10px;
}
.stepNumber {
  font-family: "Graphik LCG";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #a1a2a2;
}
.stepDescription {
  font-family: "Graphik LCG";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #000000;
  text-align: left;
  margin-bottom: 32px;
}
.stepNumberLighted {
  font-weight: 500;
  line-height: 22px;
  color: #000000;
}
.stepBordered {
  width: 100%;
  border-bottom: 2px solid #ffcb00;
  text-align: center;
}
.timerWrap {
  margin-top: 8px;
  margin-bottom: 42px;
}

.wrap {
  margin-bottom: 16px;
}
.wrapper {
  margin-bottom: 20px;
}
.wrapInput {
  margin: 0 0 25px;
}

.reg-contract {
  font-size: 16px;
  list-style-type: none;
}
.reg-contract li {
  margin-bottom: 20px;
  user-select: text;
}

.reg__step {
  width: 100%;
  max-width: 385px;
}

.reg__title {
  font-size: 32px;
}

.regSteps {
  height: auto;
}

.stepBordered {
  padding-bottom: 12px;
}

.stepNumberLighted {
  font-size: 16px;
  font-weight: 500;
}

.stepDescription {
  margin-top: 20px;
}

.check-wrapper {
  margin-bottom: 50px;
}

.tel-input {
  padding: 0;
  padding-bottom: 50px;
  box-sizing: content-box;
}

.reg__text {
  font-size: 17px;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .reg__text {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .reg__text {
    font-size: 12px;
  }
}
</style>

