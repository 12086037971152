import {
  createRouter,
  createWebHistory
} from 'vue-router'
import StartPage from "../views/StartPage";
import Services from "@/views/Dashboard/Services";
import Dashboard from "@/views/Dashboard";
import Guide from "@/views/Dashboard/Guide";
import MobileApp from "@/views/Dashboard/MobileApp";
import Settings from "@/views/Dashboard/Settings";
import PersonalArea from "@/views/Dashboard/PersonalArea";
import NotificationSegments from "@/views/Dashboard/Notifications/NotificationSegments";
import NewNotificationTypes from "@/views/Dashboard/Notifications/NewNotificationTypes";
import NotificationHistory from "@/views/Dashboard/Notifications/NotificationHistory";
import BillingPayments from "@/views/Dashboard/BillingPayments";
import BillingLicenseView from "@/views/Dashboard/BillingLicenseView";
import TestModals from "@/views/TestModals";
import Logout from "@/views/Logout";
import supportLogin from "@/views/supportLogin";
import Registration from "@/views/Registration";
import iframePage from '@/iframe/iframeMain.vue'

import historyPage from '@/iframe/historyPage.vue'

import telegramSettings from '@/components/Dashboard/Services/telegramSettings.vue'
import WhatsAppSettings from '@/components/Dashboard/Services/WhatsAppSettings.vue'
import SmsSettings from '@/components/Dashboard/Services/SmsSettings.vue'

import mobileReviews from '@/iframe/iframePages/mobileReviews.vue'

import Review from '@/views/Review.vue'

import stepsSettings from '@/iframe/telegram/stepsSettings.vue'

import webappIframe from '@/views/webappIframe.vue'

const routes = [{
    name: 'review',
    path: '/review',
    component: Review,
  },
  {
    name: 'webapp',
    path: '/app',
    component: webappIframe
  },
  {
    path: '/',
    children: [{
        path: '',
        name: 'startPage',
        component: StartPage
      },
      {
        path: 'supportLogin',
        name: 'supportLogin',
        component: supportLogin
      },
      {
        path: 'registration',
        name: 'registration',
        component: Registration
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      auth: true,
      name: 'Главная'
    },
    component: Dashboard,
    children: [{
        path: '',
        redirect: {
          name: 'services'
        }
      },
      {
        path: 'services',
        name: 'services',
        meta: {
          name: 'Статусы сервисов'
        },
        component: Services
      },
      {
        path: 'guide',
        name: 'guide',
        component: Guide,
        meta: {
          name: 'Гид по настройке'
        },
      },
      {
        path: 'app',
        name: 'mobileApp',
        component: MobileApp,
        meta: {
          name: 'Приложение'
        },
      },
      {
        path: 'telegram',
        name: 'telegram',
        component: telegramSettings,
        meta: {
          name: 'Телеграм'
        },
      },
      {
        path: 'whatsapp',
        name: 'whatsapp',
        component: WhatsAppSettings,
        meta: {
          name: 'WhatsApp'
        },
      },

      {
        path: 'sms',
        name: 'sms',
        component: SmsSettings,
        meta: {
          name: 'sms'
        }

      },
      {
        path: 'settings',
        name: 'settings',
        component: Settings,
        meta: {
          name: 'Настройки'
        },
      },
      {
        path: 'personal_area',
        name: 'personalArea',
        component: PersonalArea,
        meta: {
          name: 'Личный кабинет'
        },
      },
      {
        path: 'notifications/segments',
        name: 'notificationSegments',
        component: NotificationSegments,
        meta: {
          name: 'Сегменты',
          parent: 'Уведомления'
        },
      },
      {
        path: 'notifications/types',
        name: 'notificationTypes',
        component: NewNotificationTypes,
        meta: {
          name: 'Типы',
          parent: 'Уведомления'
        },
      },
      {
        path: 'notifications/history',
        name: 'notificationHistory',
        component: NotificationHistory,
        meta: {
          name: 'Шаблоны',
          parent: 'Уведомления'
        },
      },
      {
        path: 'billing/license',
        name: 'billingLicense',
        component: BillingLicenseView,
        meta: {
          name: 'Лицензия',
          parent: 'Биллинг'
        },
      },
      {
        path: 'billing/payments',
        name: 'billingPayments',
        component: BillingPayments,
        meta: {
          name: 'Оплаты',
          parent: 'Биллинг'
        },
      },
      {
        path: 'iframe',
        name: 'iframe',
        component: iframePage,
        redirect: '/dashboard/iframe/billing'
      },
      {
        path: 'iframe/billing',
        name: 'Биллинг',
        component: BillingLicenseView
      },

      {
        path: 'iframe/telegram',
        name: 'Telegram',
        component: telegramSettings
      },

      {
        path: 'iframe/qoobapp',
        name: 'Приложение qoob',
        component: MobileApp
      },

      {
        path: 'iframe/whatsapp',
        name: 'WhatsApp',
        component: WhatsAppSettings
      },

      {
        path: 'iframe/sms',
        name: 'Агрегатор sms',
        component: SmsSettings
      },

      {
        path: 'iframe/profile',
        name: 'Владелец аккаунта',
        component: PersonalArea
      },

      {
        path: 'iframe/payments',
        name: 'Счета',
        component: BillingPayments,
        meta: {
          name: 'Счета',
          parent: 'Биллинг'
        },
      },
      {
        path: 'iframe/reviews',
        name: 'Отзывы',
        component: mobileReviews,
        meta: {
          name: 'Отзывы',
          parent: 'iframe'
        },
      },
      {
        path: 'iframe/app',
        name: 'Настройка шагов',
        component: stepsSettings,
        meta: {
          name: 'Настройка шагов',
          parent: 'iframe'
        },
      },
      {
        path: 'iframe/historyPage',
        name: 'История сообщений',
        component: historyPage,
      }

    ]
  },
  {
    path: '/test_modals',
    name: 'test_modals',
    component: TestModals
  },
  {
    path: '/dashboard/logout',
    name: 'logout',
    component: Logout
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const isAuth = localStorage.getItem('isAuth') === 'auth';
  const requireAuth = to.matched.some(record => record.meta.auth);
  if (requireAuth && !isAuth) {
    next('/login')
  } else {
    next();
  }
});


export default router