<template>
  <div class="main-screen">
    <div class="step-order mb-5">
      <h2 class="tit-h4 step-order__header m-0 p-0 pb-3">
        Выберите разделы, которые будут отображаться на гл. экране приложения
      </h2>
      <draggable
        v-model="sections"
        group="people"
        @start="drag = true"
        @end="drag = false"
        item-key="id"
      >
        <template #item="{ element }">
          <div class="step-order__item">
            <div class="step-order__row">
              <div>{{ element.name }}</div>
              <CmSwitch
                v-model="element.show"
                class="staff-card__switch"
              ></CmSwitch>
            </div>
          </div>
        </template>
      </draggable>
    </div>

    <div class=" mb-5">
      <p class="tit-h6">Текст кнопки записи на персональные услуги</p>
      <input type="text" class="default-input _small mb-4" v-model="$store.state.telegram.steps.allStepsSettings.widgetSettings.buttons.toPersonalOrdening">
      <p class="tit-h6">Текст кнопки записи на групповые события</p>
      <input type="text" class="default-input _small" v-model="$store.state.telegram.steps.allStepsSettings.widgetSettings.buttons.toGroupOrdening">
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import draggable from "vuedraggable";
import CmSwitch from "@/components/UI/inputs/СmSwitch.vue";
import { useStore } from "vuex";

let store = useStore();

let sections = computed({
  get() {
    return (
      store.state.telegram.steps.allStepsSettings.widgetSettings
        .mainScreenSections || []
    );
  },
  set(newValue) {
    store.state.telegram.steps.allStepsSettings.widgetSettings.mainScreenSections =
      newValue;
  },
});
</script>

