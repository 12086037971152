<template>
  <div class="modal-main">
    <div class="section-wrapper">
      <button
        v-for="(rule_template, index) in availableRuleTemplates"
        :key="rule_template.id"
        :class="[
          'main__section',
          'main__section2',
          { 'main__section--active': activeSection === index },
        ]"
        @click="selectSection(index)"
      >
        {{ rule_template.channel }}
      </button>
    </div>

    <div class="main__middle">
      <div
        v-for="(rule_template, index) in notification.rule_templates"
        :key="getCurrentRule(rule_template).id"
        class="main__textarea"
        contenteditable="true"
        @input="inputTextarea($event.target)"
        :id="`textarea${index}`"
        :data-uid="getCurrentRule(rule_template).uid"
        v-show="activeSection === index"
        v-html="getCurrentRule(rule_template).value"
        @blur="writePositionCursor"
      ></div>

      <transition name="overlayAm">
        <spec-overlay
          v-show="overlayState === 1"
          @addVariable="addVariable($event)"
        />
      </transition>

      <transition name="overlayAm">
        <emoji-overlay v-show="emojiOverlayState" @addEmoji="addEmoji" />
      </transition>
    </div>

    <div class="main__bottom">
      <button class="bottom__button bottom__button1" @click="toggleOverlay(1)">
        Переменные
      </button>

      <button class="bottom__button bottom__button2" @click="toggleEmojes(1)">
        <img src="~@/assets/emojis.svg" alt="" />
      </button>

      <button class="bottom__button bottom__button4" @click="clearTextarea">
        Очистить
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref, computed, onMounted } from "vue";
import specOverlay from "./spec-overlay";
import emojiOverlay from "./emoji-overlay";
import { useStore } from "vuex";

export default {
  name: "modal-main",
  components: {
    specOverlay,
    emojiOverlay,
  },
  props: {
    texts: {
      type: Object,
    },
    notification: Object,
  },
  setup(props, { emit }) {
    const store = useStore();
    const activeSection = ref(0);

    let positionCursor = ref(0);

    let availableRuleTemplates = computed(() => {
      return props.notification.rule_templates.filter(i => i.visible)
    })

    function getCurrentRule(rule_template) {
      return props.notification.templates.find(i => i.channel === rule_template.channel)
    }

    function writePositionCursor(event) {
      positionCursor.value = getCursorPosition(event.target);
    }

    function setCursorLastPosition() {
      document.querySelector(`#textarea${activeSection.value}`).innerHTML +=
        "  ";
      setCursorPosition(
        document.querySelector(`#textarea${activeSection.value}`),
        positionCursor.value + 1
      );
    }

    function getCursorPosition(parent) {
      let selection = document.getSelection();
      let range = new Range();
      range.setStart(parent, 0);
      range.setEnd(selection.anchorNode, selection.anchorOffset);
      return range.toString().length;
    }

    function setCursorPositionAfterElement(el, pId) {
      const p = el.querySelector(`#${pId}`);

      if (p) {
        const range = document.createRange();
        const nextNode = p.nextSibling;
        if (
          nextNode &&
          nextNode.nodeType === Node.TEXT_NODE &&
          nextNode.textContent.trim().length > 0
        ) {
          range.setStart(nextNode, 0);
        } else {
          const textNode = document.createTextNode("\u00A0");
          p.parentNode.insertBefore(textNode, nextNode);
          range.setStartAfter(textNode);
        }
        range.collapse(true);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }

    function setCursorPosition(el, index) {
      console.log(el, index);
      const range = document.createRange();
      const sel = window.getSelection();
      let currentNode = el.firstChild;

      while (currentNode) {
        if (currentNode.nodeType === Node.TEXT_NODE) {
          const len = currentNode.length;

          if (index <= len) {
            range.setStart(currentNode, index);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
            return;
          }

          index -= len;
        }

        currentNode = currentNode.nextSibling;
      }
    }

    onMounted(() => {
      parseVars(props.notification.templates);

      addRemoveVariablesEvents();
    });

    function selectSection(number) {
      activeSection.value = number;
    }

    const overlayState = ref(0);
    let emojiOverlayState = ref(false);

    function toggleEmojes() {
      emojiOverlayState.value = !emojiOverlayState.value;
    }

    function addEmoji(emoji) {
      emojiOverlayState.value = false;

      const textarea = document.querySelector(
        `#textarea${activeSection.value}`
      );

      let currentValue = textarea.innerHTML.replaceAll("&nbsp;", " ");
      currentValue = currentValue.replaceAll("<div>", "<br>");
      currentValue = currentValue.replaceAll("</div>", "");

      let emojiPos = positionCursor.value;

      let beforeTags = getHtmlTagsBeforePosition(
        currentValue,
        positionCursor.value
      );

      beforeTags.forEach((i) => {
        emojiPos += i.length;
      });
      let array = currentValue.split("");
      console.log(beforeTags);
      array.splice(emojiPos++, 0, emoji);

      textarea.innerHTML = array.join("");

      setCursorLastPosition();
      inputTextarea(textarea);
    }

    function toggleOverlay(index) {
      if (overlayState.value === index) {
        overlayState.value = 0;
      } else {
        overlayState.value = index;
      }
    }

    function clearTextarea() {
      const textarea = document.querySelector(
        `#textarea${activeSection.value}`
      );
      textarea.innerHTML = "";

      inputTextarea(textarea);
    }

    const overlayList = computed(() => store.state.notification.types.vars);

    function getHtmlTagsBeforePosition(elementHtml, position) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = elementHtml;
      const tagsArray = [];
      const childNodes = tempDiv.childNodes;
      let charCount = 0;
      for (let i = 0; i < childNodes.length; i++) {
        const node = childNodes[i];
        if (node.nodeType === Node.TEXT_NODE) {
          charCount += node.length;
          // Если charCount превысило заданное значение, значит мы нашли конец позиции, и можем прервать цикл
          if (charCount >= position) {
            break;
          }
        } else {
          // Если узел — это не текстовый узел (т.е. это тег), добавляем его в tagsArray
          tagsArray.push(node.outerHTML);
        }
      }
      // Возвращаем массив с тегами html, которые стоят до заданной позиции
      return tagsArray;
    }
    function addVariable(variable) {
      setCursorLastPosition();
      const textarea = document.querySelector(
        `#textarea${activeSection.value}`
      );
      overlayState.value = 0;

      let currentValue = textarea.innerHTML.replaceAll("&nbsp;", " ");
      currentValue = currentValue.replaceAll("<div>", "<br>");
      currentValue = currentValue.replaceAll("</div>", "");

      let varPos = positionCursor.value;

      let beforeTags = getHtmlTagsBeforePosition(
        currentValue,
        positionCursor.value
      );

      beforeTags.forEach((i) => {
        varPos += i.length;
      });
      let array = currentValue.split("");

      let variableParams = templateVariable(variable);

      array.splice(varPos, 0, variableParams.template);

      textarea.innerHTML = array.join("");

      addRemoveVariablesEvents();
      inputTextarea(textarea);

      setCursorPositionAfterElement(textarea, variableParams.id);
    }
    function addRemoveVariablesEvents() {
      const currentTextarea = document.querySelector(
        `#textarea${activeSection.value}`
      );
      const delBtns = currentTextarea
        ? Array.from(currentTextarea.querySelectorAll("p[data-var] > button"))
        : [];
      if (delBtns.length > 0) {
        delBtns.forEach((btn) => {
          btn.addEventListener("click", (e) => delVariable(e.target));
        });
      }
    }
    function delVariable(delBtn) {
      const parentElement = delBtn.parentElement; // находим родительский элемент
      parentElement.remove(); // удаляем родительский элемент из DOM
    }
    function inputTextarea(textarea) {
      console.log(textarea);
      parseTextareaValue(textarea);
    }
    function templateVariable(variable) {
      let id = "var_" + (Math.random() * 100000).toFixed(0);
      let template =
        variable && variable.name
          ? `<p data-var="${variable.name}" id="${id}" data-text="${variable.name}" contenteditable="false"><button class="close-icon"></button></p>`
          : "";
      return {
        id: id.toString(),
        template: template,
      };
    }
    function parseTextareaValue(textarea) {
      let result = `${textarea.innerHTML}`;
      const varsElements = Array.from(textarea.querySelectorAll("p[data-var]"));

      result = result.replaceAll("<br>", "\n");
      result = result.replaceAll("<div>", "");
      result = result.replaceAll("</div>", "");
      result = result.replaceAll("&nbsp;", " ");

      varsElements.forEach((element) => {
        let variable = overlayList.value.find(
          (i) => i.name === element.getAttribute("data-var")
        );
        // const template = templateVariable(variable);
        let regExp = new RegExp("<s*p[^>]*>(.*?)<s*/s*p>");
        // console.log( result.match(regExp));
        result = result.replace(regExp, `{{${variable.name}}}`);
      });

      return result;
    }
    function parseVars(templates) {
      templates.forEach((template) => {
        let variables = template.value.match(/(?:\{{2}).+?(?=\}{2})/g);
        template.value = template.value.replaceAll(`\n`, "<br>");

        if (variables && variables.length > 0) {
          variables.forEach((variable) => {
            let varb = variable.slice(2);
            console.log(varb);
            template.value = template.value.replace(
              `{{${varb}}}`,
              templateVariable(overlayList.value.find((i) => i.name === varb)).template
            );
          });
        }
      });
    }
    function getTemplates() {
      let result = { ...props.notification };
      result.rule_templates.forEach((rule_template) => {
        const textarea = document.querySelector(`div[data-uid="${getCurrentRule(rule_template).uid}"]`);

        getCurrentRule(rule_template).value = parseTextareaValue(textarea);
      });

      return result.templates;
    }

    return {
      activeSection,
      selectSection,
      overlayState,
      toggleOverlay,
      clearTextarea,
      addVariable,
      inputTextarea,
      getTemplates,
      emojiOverlayState,
      toggleEmojes,
      writePositionCursor,
      addEmoji,
      positionCursor,
      getCurrentRule,
      availableRuleTemplates
    };
  },
};
</script>

<style scoped lang="scss">
.main__textarea {
  font-size: 14px;
  line-height: 25px;
  min-height: 220px;
  span {
    background-color: none !important;
  }
}

.modal-main {
  position: relative;
}

.main__middle {
  position: relative;
  min-height: 200px;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;

  border-radius: 0 12px 0 0;

  background: #f6f9fc;
}

.section-wrapper {
  display: flex;
  height: 49px;
}

.main__section {
  position: relative;
  top: 7px;

  height: 42px;
  margin-right: 5px;

  padding: 12px 24px;
  border-radius: 12px 12px 0 0;
  box-sizing: border-box;
  transition: 0.2s all ease;

  font-size: 14px;

  background: #f0f3f8;
}

.main__section--active {
  top: 0;
  height: 49px;
  background-color: #f6f9fc;
}

.main__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 75px;
  border-top: 1px solid #e1e2e2;
  border-radius: 0 0 12px 12px;

  background: #f0f3f8;
}

.bottom__button {
  height: 44px;
  padding: 10px 24px;

  box-shadow: 0 1px 2px rgba(21, 21, 21, 0.15);
  border-radius: 12px;
  box-sizing: border-box;

  font-size: 14px;
  color: #6d6d6d;

  background: #ffffff;
}

.bottom__button2 {
  padding: 10px 16px;
}

.bottom__button1 {
  margin-left: 15px;
  margin-right: 14px;
}

.bottom__button2 {
  margin-right: 15px;
}

.bottom__button4 {
  height: 44px;
  margin-right: 15px;
  box-sizing: border-box;
  margin-left: auto;
}

.text--color {
  padding: 5px 14px;
  border-radius: 49px;

  font-size: 14px;
  color: #fff;
}

.text--blue {
  background: #5cc3c6;
}

.text--red {
  background-color: #dd6068;
}

.text--green {
  background-color: #8ecd88;
}

.text--dark-blue {
  background-color: #328fe4;
}

.text--purple {
  background-color: #9d58a3;
}

.overlayAm-enter-active,
.overlayAm-leave-active {
  transition: 0.3s all ease;
}

.overlayAm-enter-from,
.overlayAm-leave-to {
  bottom: -30px;
  opacity: 0;
}
@media (max-width: 768px) {
  .modal-main {
    margin-top: 40px;
  }

  .main__middle {
    height: 240px !important;
  }

  .main__section {
    top: 7px !important;
    padding: 12px 24px !important;
    font-size: 14px !important;
  }

  .main__section--active {
    top: 0 !important;
  }
}
</style>

<style scoped>
@media (max-height: 900px) {
  .modal-main {
    margin-top: 30px;
  }
}

@media (max-height: 800px) {
  .modal-main {
    margin-top: 15px;
  }

  .main__bottom {
    height: 60px;
  }

  .bottom__button {
    height: 38px;
  }

  .main__section {
    top: 16px;
    padding: 0 20px;
    font-size: 12px;
  }

  .main__section--active {
    top: 0;
  }
}
</style>

<style lang="scss">
p[data-var] {
  position: relative;
  font-size: 13px;
  height: 20px;
  background: #5cc3c6;
  border-radius: 49px;
  color: #000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 34px 0 8px;
  user-select: contain;
  caret-color: #000;
  &:after {
    content: attr(data-text);
    color: #fff;
    line-height: 1.4;
  }

  button {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-52%);
    z-index: 1;
    right: 14px;
    width: 10px;
    height: 10px;
    background: url("../../../../assets/close.svg") no-repeat center / contain;
    filter: invert(100%);
  }
}
</style>