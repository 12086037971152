<template>
  <div class="editor">
    <close-button
      class="editor__close"
      :closeType="3"
      @click="closeEditor"
      v-if="windowWidth <= 768"
    />

    <h3 class="editor__title">Редактор шаблонов</h3>

    <div class="editor__item">
      <p class="label">Тип уведомления</p>
      <div class="editor__area">
        <p class="area-text" v-if="!isNewTemplate">{{ notification.name }}</p>
        <input class="area-text" v-else v-model="resultRedacting.name" />
      </div>
    </div>

    <!-- <div class="editor__item">
      <p class="label">Сегмент</p>
      <div class="editor__area">
        <p class="area-text">{{ segment.segment }}</p>
      </div>
    </div> -->

    <div class="editor__wrapper" v-if="false">
      <div class="editor__item1">
        <drop-menu
          class="item__drop"
          :itemSelected="'deee'"
          :itemClass="'mini'"
          v-if="resultRedacting.config"
        >
          <template v-slot:name>
            <p class="drop__label">Время отправки</p>
          </template>

          <template v-slot:text>{{
            whenList.find((i) => i.value === resultRedacting.config.when).name
          }}</template>
          <template v-slot:item>
            <div
              class="drop__item-wrapper"
              v-for="(item, index) in whenList"
              :key="index"
            >
              <button
                class="drop__item"
                @click="resultRedacting.config.when = item.value"
              >
                {{ item.name }}
              </button>
            </div>
          </template>
        </drop-menu>
      </div>

      <div class="editor__time">
        <div class="time__wrapper">
          <p class="time__label">Дней</p>
          <div class="time__area">
            <input
              class="area-text area-text1"
              v-model.number="times.days"
              type="number"
              min="0"
              @blur="validateTime('days', false)"
            />
          </div>
        </div>
        <div class="time__wrapper">
          <p class="time__label">Часов</p>
          <div class="time__area">
            <input
              class="area-text area-text1"
              v-model.number="times.hours"
              type="number"
              min="0"
              max="24"
              @blur="validateTime('hours', 24)"
            />
          </div>
        </div>
        <div class="time__wrapper">
          <p class="time__label">Минут</p>
          <div class="time__area">
            <input
              class="area-text area-text1"
              v-model.number="times.minutes"
              type="number"
              min="0"
              max="60"
              @blur="validateTime('minutes', 60)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="editor__wrapper" v-if="editorType === 'Личный'">
      <div class="editor__item">
        <drop-menu
          class="item__drop"
          blockClass="modal-drop"
          :itemSelected="itemSelected"
        >
          <template v-slot:name>
            <p class="drop__label">Фильтр</p>
          </template>

          <template v-slot:text>{{ filterList[selectedFilter] }}</template>
          <template v-slot:item>
            <div
              class="drop__item-wrapper"
              v-for="(item, index) in filterList"
              :key="index"
            >
              <button class="drop__item" @click="selectDrop(index, 1)">
                {{ item }}
              </button>
            </div>
          </template>
        </drop-menu>
      </div>
      <div class="editor__item editor__service">
        <drop-menu
          class="item__drop"
          blockClass="modal-drop"
          :itemSelected="itemSelected"
        >
          <template v-slot:name>
            <p class="drop__label">{{ serviceList[selectedFilter].title }}</p>
          </template>

          <template v-slot:text>{{
            serviceList[selectedFilter].list[selectedService]
          }}</template>
          <template v-slot:item>
            <div
              class="drop__item-wrapper"
              v-for="(item, index) in serviceList[selectedFilter].list"
              :key="index"
            >
              <button class="drop__item" @click="selectDrop(index, 2)">
                {{ item }}
              </button>
            </div>
          </template>
        </drop-menu>
      </div>
    </div>

    <p class="label label--bottom">Каналы</p>

    <modal-main :notification="notification" ref="modalMain" />

    <div class="editor--bottom">
      <button-grey class="button-close" @click="closeEditor"
        >Закрыть без изменений</button-grey
      >
      <button-orange class="button-save" @click="saveNotification"
        >Сохранить изменения</button-orange
      >
    </div>
  </div>
</template>

<script>
import modalMain from "@/components/Dashboard/Segments/popup-components/modal-main";
import buttonGrey from "@/components/UI/buttons/ButtonGrey";
import buttonOrange from "@/components/UI/buttons/ButtonOrange";
import dropMenu from "@/components/Dashboard/Billing/drop-menu";
import closeButton from "@/components/Dashboard/Billing/close-button";
import getDuration from "@/functions/getDuration.js";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import Moment from "moment";

export default {
  name: "TemplatesEditor",
  components: {
    modalMain,
    buttonGrey,
    buttonOrange,
    dropMenu,
    closeButton,
  },
  props: {
    notification: {
      type: String,
    },
    editorType: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const windowWidth = computed(() => {
      return store.state.windowWidth;
    });

    const isNewTemplate = computed(() => props.notification.uid === "new");

    const modalMain = ref(null);

    const filterList = ["По услугам", "По сотрудникам"];

    const selectedFilter = ref(0);
    const selectedService = ref(0);

    const filterDropState = ref(false);
    const serviceDropState = ref(false);

    function toggleDrop(id) {
      if (id === 0) {
        timeDropState.value = !timeDropState.value;
      } else if (id === 1) {
        filterDropState.value = !filterDropState.value;
      } else {
        serviceDropState.value = !serviceDropState.value;
      }
    }

    const types = computed(() => store.state.notification.types);

    const serviceList = [
      {
        title: "Услуга",
        list: ["Стрижка", "Покраска"],
        selected: 0,
      },
      {
        title: "Сотрудник",
        list: ["Сотрудник1", "Сотрудник2", "Сотрудник3"],
        selected: 0,
      },
    ];

    const selectedTime = ref(0);
    const timeDropState = ref(false);

    function closeEditor() {
      emit("toggleEditor");
    }

    const itemSelected = ref(false);

    function selectDrop(index, id) {
      if (id === 0) {
        selectedTime.value = index;
      } else if (id === 1) {
        selectedFilter.value = index;
      } else {
        selectedService.value = index;
      }

      itemSelected.value = true;
      setTimeout(() => {
        itemSelected.value = false;
      }, 300);
    }

    function saveNotification() {
      resultRedacting.value.config.interval = Moment.duration(
        times.value
      ).toISOString();
      resultRedacting.value.config.precision = "time";
      resultRedacting.value.config.overdue = "pass";
      resultRedacting.value.config.period = {
        from: "09:00",
        till: "20:00",
      };

      resultRedacting.value.templates = modalMain.value.getTemplates();

      if (resultRedacting.value.uid === "new") {
        store.dispatch("postRule", resultRedacting.value);
      } else {
        store.dispatch("udpadeRule", resultRedacting.value);
      }

      closeEditor();
    }

    const times = ref({});
    const resultRedacting = ref({});

    onMounted(() => {
      times.value = getDuration(props.notification.config, "object");
      resultRedacting.value = { ...props.notification };

      document.querySelector("html").scrollTop = 0;
    });

    function validateTime(type, limit) {
      if (times.value[type] < 0) {
        times.value[type] = 0;
      }
      if (limit && times.value[type] > limit) {
        times.value[type] = limit;
      }
    }

    let whenList = ref([
      { name: "Во время события", value: "instantly" },
      { name: "До события, за", value: "before" },
      { name: "После события, через", value: "after" },
    ]);

    let whenListSelected = ref("instantly");

    return {
      closeEditor,
      filterList,
      selectedFilter,
      filterDropState,
      serviceList,
      serviceDropState,
      toggleDrop,
      selectedService,
      selectDrop,
      itemSelected,
      selectedTime,
      timeDropState,
      modalMain,
      windowWidth,
      getDuration,
      times,
      types,
      resultRedacting,
      whenList,
      whenListSelected,
      validateTime,
      saveNotification,
      isNewTemplate,
    };
  },
};
</script>

<style scoped>
.drop__item-wrapper:hover {
  background-color: #f6f9fc;
}

.drop__item-wrapper:active {
  background-color: #f0f3f8;
}

.drop__item {
  display: flex;
  align-items: center;

  height: 38px;
  padding-left: 20px;

  font-size: 15px;
}

.drop__label {
  font-size: 14px;
  line-height: 24px;
  color: #6d6d6d;
}

.editor-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9500;

  background: rgba(0, 0, 0, 0.3);
}

.editor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 30;

  background-color: #ffffff;
}

.editor__title {
  margin-bottom: 25px;

  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

.editor__item {
  width: 100%;
  margin-bottom: 25px;
}

.editor__item1 {
  width: 100%;
}

.label {
  margin-bottom: 5px;

  font-size: 14px;
  line-height: 24px;
  color: #6d6d6d;
}

.editor__area {
  display: flex;
  align-items: center;

  width: 100%;
  height: 48px;

  padding-left: 25px;
  border-radius: 12px;

  background-color: #f6f9fc;
}

.area-text {
  width: 100%;
  font-size: 16px;
}

.area-text1 {
  text-align: center;
}

.editor__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-bottom: 25px;
}

.editor__time {
  display: flex;
  justify-content: space-between;

  min-width: 240px;
  margin-left: 25px;
}

.time__label {
  font-size: 11px;
}

.time__area {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70px;
  height: 48px;

  border-radius: 6px;
  background: #f0f3f8;
}

.label--bottom {
  margin-bottom: 15px;
  border-bottom: 1px solid #eff0f0;
}

.editor--bottom {
  display: flex;
  justify-content: flex-end;

  margin-top: 40px;
}

.button-close {
  margin-right: 20px;
}

.editor__service {
  width: 300px;
  min-width: 300px;
  margin-left: 30px;
}

@media (max-width: 768px) {
  .editor__close {
    position: absolute;
    top: 0;
    right: 0;

    width: 60px;
    height: 60px;

    background-color: #000;
  }

  .editor__title {
    display: flex;
    align-items: center;

    height: 60px;
    padding-left: 15px;
    border-bottom: 1px solid #eff0f0;
  }

  .editor__item {
    margin: 0 15px;
  }

  .editor__wrapper {
    margin: 0 15px;
  }

  .label--bottom {
    margin: 0 15px;
  }

  .editor__area {
    height: 48px !important;
  }

  .time__area {
    height: 48px !important;
  }

  .editor--bottom {
    padding-bottom: 30px;
  }

  .editor__area {
    height: auto !important;
    min-height: 48px;
    width: calc(100% - 30px);
  }

  .button-save {
    height: 48px !important;
  }

  .button-close {
    height: 48px !important;
  }
}

@media (max-width: 550px) {
  .editor__wrapper {
    flex-direction: column;
  }

  .editor__time {
    margin-top: 15px;
    margin-left: 0;
  }

  .editor--bottom {
    padding: 10px;
    margin: 0 10px;
    box-shadow: 0px 17px 40px rgba(19, 49, 97, 0.07),
      0px 6.20528px 21.901px rgba(19, 49, 97, 0.0482987),
      0px 3.01255px 10.6325px rgba(19, 49, 97, 0.0389404),
      0px 1.47681px 5.21226px rgba(19, 49, 97, 0.0310596),
      0px 0.583932px 2.06094px rgba(19, 49, 97, 0.0217013);
    border-radius: 16px 16px 0 0;
  }

  .button-save {
    width: 100%;
  }

  .button-close {
    width: 100%;
  }
}
</style>

<style scoped>
@media (max-height: 900px) {
  .editor__item {
    margin-bottom: 15px;
  }
}

@media (max-height: 800px) {
  .editor__wrapper {
    margin-bottom: 10px;
  }

  .editor--bottom {
    margin-top: 20px;
  }

  .editor__area {
    height: 38px;
  }

  .editor__title {
    font-size: 16px;
    line-height: 18px;
  }

  .area-text {
    font-size: 14px;
  }

  .label {
    font-size: 12px;
  }

  .drop__label {
    font-size: 12px;
  }

  .button-close {
    height: 38px;

    font-size: 14px !important;
  }

  .button-save {
    height: 38px;

    font-size: 14px !important;
  }

  .time__area {
    height: 38px;
  }
}
</style>