export default {
  state: () => ({
    licenseAgreement: null,
    privacyPolicyCrm: null,
    personalDataAgreement: null
  }),
  mutations: {
    setLicenseAgreement(state, value) {
      state.licenseAgreement = value;
    },
    setPrivacyPolicyCrm(state, value) {
      state.privacyPolicyCrm = value;
    },
    setPersonalDataAgreement(state, value) {
      state.personalDataAgreement = value;
    }
  },
  actions: {
    getLicenseAgreement(context) {
      context.dispatch('flatPages', {
        url: 'license-agreement',
        mutation: 'setLicenseAgreement'
      })
    },
    getPrivacyPolicyCrm(context) {
      context.dispatch('flatPages', {
        url: 'privacy-policy-crm',
        mutation: 'setPrivacyPolicyCrm'
      })
    },
    getPersonalDataAgreement(context) {
      context.dispatch('flatPages', {
        url: 'personal-data-agreement',
        mutation: 'setPersonalDataAgreement'
      })
    },
    getAllDocs(context) {
      context.dispatch('getLicenseAgreement');
      context.dispatch('getPrivacyPolicyCrm');
      context.dispatch('getPersonalDataAgreement');
    }
  },
}