<template>
  <svg
    width="40"
    height="37"
    viewBox="0 0 40 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M20 0L25.4076 12.557L39.0211 13.8197L28.7497 22.843L31.7557 36.1803L20 29.2L8.2443 36.1803L11.2503 22.843L0.97887 13.8197L14.5924 12.557L20 0Z"
        :fill="color"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      default: '#F0F3F8'
    }
  }
};
</script>

<style>
</style>