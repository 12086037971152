/* eslint-disable */
import axios from "axios";

export default {
  state: () => ({
    loadingRfmInfo: true,
    loadingRfmRecommendation: false,
    loadingRfmSegmentClients: true,
    rfmStatus: null,
    rfmInfo: {
      positions: [],
      segments: null,
      info: null,
      recommendations: []
    },
    recommendation: null,

    defaultRfmResponse: {
      "lock": false,
      "max_recency": 160,
      "max_frequency": null,
      "total_clients": 'XXX',
      "average_check": 'XX',
      "segments": {
        "without_orders": {
          "index": 0,
          "total_clients": 'XXX',
          "total_clients_percent": "XX %",
          "average_check": 0,
          "min_recency": 0,
          "max_recency": null,
          "min_frequency": 0,
          "max_frequency": null,
        },
        "outflow": {
          "index": 10,
          "total_clients": 'XXX',
          "total_clients_percent": "XX %",
          "average_check": null,
          "min_recency": 160,
          "max_recency": null,
          "min_frequency": 0,
          "max_frequency": null,
        },
        "at risk": {
          "index": 9,
          "total_clients": 'XXX',
          "total_clients_percent": "XX %",
          "average_check": null,
          "min_recency": 101,
          "max_recency": 160,
          "min_frequency": 4,
          "max_frequency": null,
        },
        "on_the_brink": {
          "index": 8,
          "total_clients": 'XXX',
          "total_clients_percent": "XX %",
          "average_check": null,
          "min_recency": 91,
          "max_recency": 160,
          "min_frequency": 2,
          "max_frequency": 3,
        },
        "sleeping": {
          "index": 7,
          "total_clients": 'XXX',
          "total_clients_percent": "XX %",
          "average_check": null,
          "min_recency": 121,
          "max_recency": 160,
          "min_frequency": 1,
          "max_frequency": 1,
        },
        "need_attention": {
          "index": 6,
          "total_clients": 'XXX',
          "total_clients_percent": "XX %",
          "average_check": null,
          "min_recency": 91,
          "max_recency": 120,
          "min_frequency": 1,
          "max_frequency": 1,
        },
        "loyal": {
          "index": 5,
          "total_clients": 'XXX',
          "total_clients_percent": "XX %",
          "average_check": null,
          "min_recency": 41,
          "max_recency": 100,
          "min_frequency": 4,
          "max_frequency": null,
        },
        "doubting": {
          "index": 4,
          "total_clients": 'XXX',
          "total_clients_percent": "XX %",
          "average_check": null,
          "min_recency": 41,
          "max_recency": 90,
          "min_frequency": 1,
          "max_frequency": 3,
        },
        "champions": {
          "index": 3,
          "total_clients": 'XXX',
          "total_clients_percent": "XX %",
          "average_check": null,
          "min_recency": 0,
          "max_recency": 40,
          "min_frequency": 4,
          "max_frequency": null,
        },
        "growing": {
          "index": 2,
          "total_clients": 'XXX',
          "total_clients_percent": "XX %",
          "average_check": null,
          "min_recency": 0,
          "max_recency": 40,
          "min_frequency": 2,
          "max_frequency": 3,
        },
        "newcomer": {
          "index": 1,
          "total_clients": 'XXX',
          "total_clients_percent": "XX %",
          "average_check": null,
          "min_recency": 0,
          "max_recency": 40,
          "min_frequency": 1,
          "max_frequency": 1,
        }
      }
    },

    rfmInfoHasAlready: false,
  }),
  getters: {
    getRfmSegments(state) {
      return state.rfmInfo.segments
    }
  },
  mutations: {
    setRfmInfo(state, rfmObj) {
      state.rfmStatus = {
        ...rfmObj
      }

      const resultObj = {
        positions: [],
        segments: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
        info: {},

      }
      const segments = rfmObj.segments

      for (let key in segments) {
        if (key === 'newcomer') {
          segments[key].name = "Новички"
          segments[key].flatUrl = "beginners"
          segments[key].segment = key
          segments[key].smileIndex = 0
          resultObj.segments[0] = segments[key];
        } else if (key === 'growing') {
          resultObj.segments[1] = segments[key];
          segments[key].flatUrl = "growing"
          segments[key].segment = key
          segments[key].smileIndex = 1
          segments[key].name = "Растущие"
        } else if (key === 'champions') {
          resultObj.segments[2] = segments[key];
          segments[key].flatUrl = "champions"
          segments[key].segment = key
          segments[key].smileIndex = 2
          segments[key].name = "Чемпионы"
        } else if (key === 'doubting') {
          resultObj.segments[3] = segments[key];
          segments[key].flatUrl = "doubters"
          segments[key].segment = key
          segments[key].smileIndex = 3
          segments[key].name = "Сомневающиеся"
        } else if (key === 'loyal') {
          resultObj.segments[4] = segments[key];
          segments[key].flatUrl = "loyal"
          segments[key].segment = key
          segments[key].smileIndex = 4
          segments[key].name = "Лояльные"
        } else if (key === 'on_the_brink') {
          resultObj.segments[7] = segments[key];
          segments[key].flatUrl = "averages"
          segments[key].segment = key
          segments[key].smileIndex = 7
          segments[key].name = "Средние на грани"
        } else if (key === 'sleeping') {
          resultObj.segments[6] = segments[key];
          segments[key].flatUrl = "sleeping"
          segments[key].segment = key
          segments[key].smileIndex = 6
          segments[key].name = "Спящие"
        } else if (key === 'need_attention') {
          resultObj.segments[5] = segments[key];
          segments[key].flatUrl = "attention"
          segments[key].segment = key
          segments[key].smileIndex = 5
          segments[key].name = "Требуют внимания"
        } else if (key === 'at risk') {
          resultObj.segments[8] = segments[key];
          segments[key].flatUrl = "atrisk"
          segments[key].segment = key
          segments[key].smileIndex = 8
          segments[key].name = "В зоне риска"
        }

      }

      const maxClientPage = 25;

      resultObj.segments.forEach(item => {
        resultObj.positions.push({
          minFrequency: item.min_frequency,
          maxFrequency: item.max_frequency ? item.max_frequency : 'max',

          minRecency: item.min_recency,
          maxRecency: item.max_recency ? item.max_recency : 'max',
        })
        item.clients = []
      });

      resultObj.info = {
        averageCheck: rfmObj.average_check.toLocaleString(),
        lock: rfmObj.lock,
        totalClients: rfmObj.total_clients.toLocaleString(),

        withoutOrders: {
          totalClients: rfmObj.segments['without_orders'].total_clients,
          totalClientsPercent: rfmObj.segments['without_orders'].total_clients_percent
        },
      }

      resultObj.segments[9] = {
        name: 'Потерянные за всё время',
        segment: 'outflow',
        total_clients: rfmObj.segments.outflow.total_clients,
        total_clients_percent: rfmObj.segments.outflow.total_clients_percent,
        index: 10,
        average_check: rfmObj.segments.outflow.average_check,
        min_frequency: 1,
        max_frequency: null,
        min_recency: 161,
        max_recency: null,
        // maxRecency:1,
      }

      resultObj.segments.forEach(item => {
        item.clientsPages = []
        const maxClientPages = Math.ceil(item.total_clients / maxClientPage)

        if (maxClientPages > 1) {
          for (let i = 0; i < maxClientPages; i++) {
            item.clientsPages.push([])
          }
        } else {
          item.clientsPages.push([])
        }
      })
      

      state.rfmInfo = resultObj

    },
    setLoadingRfmInfo(state, value) {
      state.loadingRfmInfo = value;
    },
    addRfmRecommendation(state, page) {
      state.recommendation = page
    },
    setLoadingRfmRecommendation(state, value) {
      state.loadingRfmRecommendation = value
    },
    setLoadingRfmSegmentClients(state, value) {
      state.loadingRfmSegmentClients = value
    },
    setRfmSegmentClients(state, obj) {
      const segment = obj.segment;
      const clients = obj.clients;
      const page = obj.page

      const resultArr = []

      for (let i in clients) {
        resultArr.push({
          number: clients[i].phone ? '+' + clients[i].phone.replace(/\D/g, "")
            .replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5") : '',
          chanel: clients[i].channel === 'WhatsApp' ? 'WA' : clients[i].channel ? clients[i].channel : '',
          status: 'Прошел',
          visits: clients[i].frequency,
          late: clients[i].recency,
          state: false
        })
      }

      if (resultArr.length === 0) {
        resultArr.push(false)
      }

      segment.clients = resultArr
      segment.clientsPages[obj.page-1] = resultArr
    }
  },
  actions: {
    async getRfmInfo(context) {
      if (!context.state.rfmInfoHasAlready) {
        // context.commit('setRfmInfo', context.state.defaultRfmResponse)
        context.commit('setLoadingRfmInfo', true)

        return await axios({
          method: 'get',
          url: `${window.apiPath}/rfm/clients`,
          headers: {
            Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
            accept: 'application/json',
          },

        }).then((request) => {

          if (request.data.error && request.data.status_code === 404 && request.data.error.company_rf === 'not found') {
            context.commit('setRfmInfo', context.state.defaultRfmResponse)
            console.log('err' + 404);
            context.state.rfmInfoHasAlready = true
          } else {
            context.commit('setRfmInfo', request.data.data);
            console.log('ok' + 200);
            context.state.rfmInfoHasAlready = true
          }

          context.commit('setLoadingRfmInfo', false)
        }).catch(() => {
          context.commit('setRfmInfo', context.state.defaultRfmResponse)
          context.commit('setLoadingRfmInfo', false)
        })
      }

    },
    getRfmRecommendation(context, segmentId) {

      const segments = context.getters.getRfmSegments
      if (segments && segments.length > 0) {
        let currentSegment = segments.find(i => i.index === segmentId)

        if (!currentSegment.recommendation) {
          context.commit('setLoadingRfmRecommendation', true)
          context.dispatch('flatPages', {
            url: `segments_${currentSegment.flatUrl}`,
            mutation: 'addRfmRecommendation'
          }).then(() => {
            currentSegment.recommendation = context.state.recommendation;
            context.state.recommendation = null
            context.commit('setLoadingRfmRecommendation', false)
          }).catch((err) => {
            console.dir(err)
            context.commit('setLoadingRfmRecommendation', false)
          })
        }

      }

    },
    async getRfmSegmentClients(context, obj) {
      const segments = context.getters.getRfmSegments

      context.commit('setLoadingRfmSegmentClients', true)
      let currentSegment = segments.find(i => i.segment === obj.segmentName)

      return await axios({
        method: 'get',
        url: `${window.apiPath}/rfm/segment/${currentSegment.segment}/clients`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
        params: {
          page: obj.page,
          size: 25,
        }
      }).then((request) => {
        context.commit('setRfmSegmentClients', {
          segment: currentSegment,
          clients: request.data.data,
          page: obj.page
        })
      }).catch(() => {
        context.commit('setLoadingRfmSegmentClients', false)
      })
    }
  },
}