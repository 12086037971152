<template>
  <div class="segments-wrapper m-0 p-0" :class="{_loader: $store.state.rfm.loadingRfmInfo}">
    <new-segment-layout :rfmInfo="rfmInfo" @toggleMainModal="toggleMainModal($event)" v-if="windowWidth > 1280 &&  $store.state.rfm.rfmInfo.positions.length > 0" />

    <segments-layout-mobile :rfmInfo="this.$store.state.rfm.rfmInfo" v-else-if="windowWidth < 1280 && $store.state.rfm.rfmInfo.positions.length > 0" @toggleMainModal="toggleMainModal($event)" />

    <segments-popup
        class="popup-wrapper"
        @toggleMainModal="toggleMainModal($event)"
        :segmentPopup="segmentPopup"
        :mainModalState="modalState"
        v-if="this.$store.state.rfm.rfmInfo.segments && this.$store.state.rfm.rfmInfo.segments.length > 0"
    />
  </div>
  
</template>

<script>
import { ref, computed, onMounted } from "vue";
import {useStore} from "vuex";
import segmentsPopup from "@/views/Dashboard/Notifications/segmentsPopup";
import segmentsLayoutMobile from "@/views/Dashboard/Notifications/SegmentsLayoutMobile";
import newSegmentLayout from "@/views/Dashboard/Notifications/NewSegmentLayout";

export default {
  components: {
    segmentsPopup,
    segmentsLayoutMobile,
    newSegmentLayout
  },
  setup() {

    const store = useStore();

    const modalState = ref(false);
    const segmentPopup = ref(2);

    function toggleMainModal(data) {
      if (data.state) {
        modalState.value = data.state;
        segmentPopup.value = data.index + 1;
      } else {
        modalState.value = data;
      }
    }

    const windowWidth = computed(() => {
      return store.state.windowWidth;
    })

    const rfmInfo = computed(() => {
      return store.state.rfm.rfmInfo;
    })

    onMounted(() => {
      store.dispatch('getRfmInfo')
    })



    return { modalState, toggleMainModal, segmentPopup, windowWidth, rfmInfo }

  },
};
</script>

<style scoped>

.segments-wrapper {
  padding-bottom: 60px!important;
  min-height: 100vh;
}

.popup-wrapper {
  position: fixed;
  right: 0;
  top: 0;
}

@media (max-width: 550px) {
  .popup-wrapper {
    overflow: hidden;
  }
}
</style>

<style src="../../../assets/css/main.css"></style>