<template>
  <cm-card class="settingsItemCard">
    <div class="settings-item__wrapper d-flex justify-content-between align-items-center">
      <div class="w-100 settingsDiv">
        <img class="settingsImage" :class="'settingsImage' + index" :src="require('../../../assets/settingsItem' + (index + 1) + '.svg')" alt="icon">
        <div class="settingsTextDiv d-flex align-items-center">
          <p class="setting-item__text w-75" >{{ service.text }}</p>
        </div>

      </div>

      <button-transparent class="settingsButton" @click="$emit('btn-click')">
        Настроить
        <img src="../../../assets/arrow-right-black.svg" alt="">
      </button-transparent>

    </div>
  </cm-card>
</template>

<script>
import CmCard from "@/components/UI/CmCard";
import buttonTransparent from "@/components/UI/buttons/ButtonTransparent";

export default {
  name: "SettingsItem",
  components: {CmCard, buttonTransparent},
  props: {
    service: {
      type: Object,
    },
    index: {
      type: Number
    }
  }
}
</script>

<style scoped>
.settingsItemCard {
  height: 195px!important;
  padding: 0 60px!important;
  margin-bottom: 10px;
}

.settings-item__wrapper {
  height: 100%;
}

.settingsTextDiv {
  margin-top: 10px;
}

.settingsButton > img {
  margin-left: 10px;
}

.settingsImage0 {
  height: 36px;
}

.settingsImage1 {
  height: 40px;
}

.settingsImage2 {
  height: 47px;
}

.settingsImage3 {
  height: 42px;
}

.settingsImage4 {
  height: 36px;
}

.setting-item__text {
  font-size: 16px;
  line-height: 30px;
}

@media (max-width: 1280px) {
  .settingsItemCard {
    padding: 0 30px!important;
  }
}

@media (max-width: 768px) {
  .settingsItemCard {
    height: auto!important;
    padding: 25px 30px!important;
  }

  .settings-item__wrapper {
    flex-direction: column;
    justify-content: center!important;
    align-items: flex-start!important;
  }

  .setting-item__text {
    width: auto!important;

    font-size: 14px;
    line-height: 24px;
  }

  .settingsTextDiv {
    padding: 20px 0;
    border-top: 1px solid #EFF0F0;
  }
}
</style>

<style scoped>
@media (max-width: 550px) {

  .settingsImage {
    max-height: 32px;
  }

  .settingsDiv {
    padding: 0;
  }

  .settingsButton {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 146px;
    height: 40px;

    padding: 0;
    margin: 0;
  }

  .settingsButton > img {
    margin-left: 10px;
  }
}
</style>