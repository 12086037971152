import axios from "axios"
import stepsSettings from "./stepsSettings"

export default {
  modules: {
    steps: stepsSettings
  },
  state: () => ({
    status: false,
    loadingTelegram: false,
    token: '',
    admins: [],
    helpTelegram: {},

    newAdminTelephone: '',

    telegramPhone: '',

    telegramStatus: {},
    lastTelegramStatus: {},
    
    telegramVideo: '',

    

    feedbackInApp: null,

    
  }),
  getters: {
    getLoadingAdminInput(state) {
      return state.admins.find(i => i.loading)
    }
  },
  mutations: {
    setLoadingTelegram(state, value) {
      state.loadingTelegram = value
    },
    setHelpTelegram(state, value) {
      state.helpTelegram = value
    },
    addAdminTelegram(state, phone) {
      state.admins.push({
        loading: true,
        tel: phone
      })
    },
    deleteTelegramAdmin(state, phone) {
      const delIndex = state.admins.findIndex(i => i.tel === phone)

      state.admins.splice(delIndex, 1)
    },
    setTelegramAdmins(state, admins) {
      const arr = []

      admins.forEach(item => {
        arr.push({
          loading: false,
          tel: item
        })
      });

      state.admins = arr
    },
    setTelegramPhone(state, phone) {
      state.telegramPhone = phone
    },
    setTelegramStatus(state, status) {
      state.lastTelegramStatus = {
        ...status
      }
      state.telegramStatus = status

      state.status = status.telegram_bot_connected
    },
    setTelegramVideo(state, obj) {
      state.telegramVideo = obj
    }
  },
  actions: {
    async getTelegramStatus(context) {
      context.commit('setLoadingTelegram', true)
      return await axios({
        method: 'get',
        url: `${window.apiPath}/telegram/status`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
          'Content-Type': "application/json",
        },
      }).then((request) => {

        context.commit('setTelegramStatus', request.data.data)

        context.commit('setLoadingTelegram', false);
        if (request.data.data.telegram_bot_connected) {
          context.dispatch('getGroupTelegramVideo')
          context.dispatch('getFeedbackInApp')
          context.dispatch('getTelegramAdmins').catch(() => {
            context.commit('setLoadingTelegram', false);
            context.commit('addDashboardNotification', {
              text: `при загрузке списка админов произошла ошибка`,
              type: 'err'
            }, {root: true})
          })
          
        } else {
          context.dispatch('getTelegramVideo')
        }
      }).catch(() => {
        context.commit('setLoadingTelegram', false);
      })
    },
    async postTelegramToken(context) {
      context.commit('setLoadingTelegram', true)
      return await axios({
        method: 'post',
        url: `${window.apiPath}/telegram`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
          'Content-Type': "application/json",
        },
        params: {
          token: context.state.token,
          phone: context.state.telegramPhone
        }
      }).then((request) => {
        context.commit('setLoadingTelegram', false)
        console.log(request);
        if (request.data.success) {
          context.state.status = true

          context.dispatch('getGroupTelegramVideo')

          context.commit('setLoadingTelegram', false)

        } else {
          const message = JSON.parse(request.data.error.message)

          context.commit('addDashboardNotification', {
            text: message.description,
            type: 'err'
          }, {root: true})
        }
        console.log('telegram loadfing false');
        context.commit('setLoadingTelegram', false)
      })
    },
    async deleteTelegramBot(context) {
      context.commit('setLoadingTelegram', true)
      return await axios({
        method: 'delete',
        url: `${window.apiPath}/telegram`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
          'Content-Type': "application/json",
        },
      }).then((request) => {
        console.log(request);
        if (request.data.success) {
          context.state.status = request.data.data.telegram_bot_connected

          context.dispatch('getTelegramVideo')

          const message = JSON.parse(request.data.data.result.message)
          console.log(message);
          context.commit('addDashboardNotification', {
            text: message.description,
            type: 'ok'
          })
          context.commit('setLoadingTelegram', false)

        } else {
          const message = JSON.parse(request.data.error.message)

          context.commit('addDashboardNotification', {
            text: message.description,
            type: 'err'
          })

          context.commit('setLoadingTelegram', false)
        }
        // context.dispatch('getTelegramStatus').then(() => {
        //   context.commit('setLoadingTelegram', false)
        // }).catch(() => {
        //   context.commit('setLoadingTelegram', false)
        // })

      }).catch(() => {
        // context.commit('addDashboardNotification', {
        //   text: `Ошибка`,
        //   type: 'err'
        // })
        context.commit('setLoadingTelegram', false)
      })
    },
    async createAdminTelegram(context) {
      const phone = context.state.newAdminTelephone
      context.state.newAdminTelephone = ''
      context.commit('addAdminTelegram', phone)
      return await axios({
        method: 'post',
        url: `${window.apiPath}/telegram/admins`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
          'Content-Type': "application/json",
        },
        params: {
          admin_phone: phone
        }
      }).then((request) => {
        console.log(request);
        console.log(request.data.success);
        if (request.data.success) {
          const result = JSON.parse(request.data.data.result)

          const admin = context.getters.getLoadingAdminInput;
          admin.loading = false;

          context.commit('addDashboardNotification', {
            text: result.description,
            type: 'ok'
          }, {root: true})
        } else {
          const message = JSON.parse(request.data.error.message)

          console.log(message);

          const admin = context.getters.getLoadingAdminInput;
          admin.loading = false;
          admin.tel = phone;
          context.commit('deleteTelegramAdmin', phone)

          context.commit('addDashboardNotification', {
            text: message.description,
            type: 'err'
          }, {root: true})
        }

      }).catch((err) => {
        console.log(err);
        context.commit('addDashboardNotification', {
          text: `Ошибка`,
          type: 'err'
        })
        context.commit('deleteTelegramAdmin', phone)

      })
    },
    async deleteAdminTelegram(context, telphone) {
      context.state.admins.find(i => i.tel === telphone).loading = true

      return await axios({
        method: 'delete',
        url: `${window.apiPath}/telegram/admins`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
          'Content-Type': "application/json",
        },
        params: {
          admin_phone: telphone
        }
      }).then((request) => {
        console.log(request);
        const result = JSON.parse(request.data.data.result)
        if (request.data.success) {
          console.log(200);
          context.commit('deleteTelegramAdmin', telphone)

          context.commit('addDashboardNotification', {
            text: result.description,
            type: 'ok'
          }, {root: true})
        } else {
          // context.commit('deleteTelegramAdmin', telphone)
          context.state.admins.find(i => i.tel === telphone).loading = false

          const result = JSON.parse(request.data.data.result)

          context.commit('addDashboardNotification', {
            text: result.description,
            type: 'err'
          }, {root: true})
        }
      }).catch((err) => {
        console.log(err);
        context.commit('addDashboardNotification', {
          text: `Ошибка`,
          type: 'err'
        }, {root: true})

        context.state.admins.find(i => i.tel === telphone).loading = false
      })
    },
    async getTelegramAdmins(context) {
      return await axios({
        method: 'get',
        url: `${window.apiPath}/telegram/admins`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
          'Content-Type': "application/json",
        },
      }).then((request) => {
        context.commit('setTelegramAdmins', request.data.data.telegram_admins_phones)
      })
    },
    getTelegramHelp(context) {
      context.dispatch('flatPages', {
        url: 'help_telegram',
        mutation: 'setHelpTelegram'
      }, {root: true}).then(() => {}).catch(() => {
        context.commit('addDashboardNotification', {
          text: 'При загрузке инструкции, произошла ошибка',
          type: 'err'
        }, {root: true})
      })
    },
    getTelegramVideo(context) {
      context.dispatch('flatPages', {
        mutation: 'telegram/setTelegramVideo',
        url: 'telegram',
      }, {root: true})
    },
    getGroupTelegramVideo(context) {
      context.dispatch('flatPages', {
        mutation: 'telegram/setTelegramVideo',
        url: 'telegram-group',
      }, {root: true})
    },
    async getFeedbackInApp(context) {
      context.state.feedbackInApp = 'loading'
      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/feedback_in_app`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
      }).then((data) => {
        context.state.feedbackInApp = data.data.data.feedbackInApp

      }).catch((err) => {
        context.state.telegram.feedbackInApp = 'err'
        console.log(err);
      })
    },
    async putFeedbackInApp(context, feedback_in_app) {
      if (context.rootState.review.platformLinks.length === 0 && context.state.feedbackInApp === true) {
        context.commit('addDashboardNotification', {
          text: 'Добавьте хотя бы одну ссылку на ресурс',
          type: 'warm'
        }, {root: true})
      } else {
        context.state.feedbackInApp = 'loading'
        return await axios({
          method: 'put',
          url: `${window.apiPath}/company/feedback_in_app`,
          headers: {
            Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
            accept: 'application/json',
          },
          params: {
            feedback_in_app: feedback_in_app
          }
        }).then((data) => {
          console.log(data);
          context.state.feedbackInApp = data.data.data.feedbackInApp
          context.commit('addDashboardNotification', {
            text: 'успех',
            type: 'ok'
          }, {root: true})
        }).catch((err) => {
          context.state.feedbackInApp = 'err'
          console.log(err);
          context.commit('addDashboardNotification', {
            text: 'ошибка',
            type: 'err'
          }, {root: true})
        })
      }

    },
  },
  namespaced: true
}