<template>
  <div>
    <qoob-loader :visible="$store.state.allLoader"></qoob-loader>
    <confirm-popup></confirm-popup>
    <dashboard-notifications></dashboard-notifications>
    <!--    :style="{ padding: calcPadding }"-->
    <iframeNav></iframeNav>
    <div
      class="dash_content"
      :style="containerWidth"
      :class="
        this.$store.state.isFrameVersion
          ? 'container-fluid-iframe'
          : 'container-fluid'
      "
    >
      <!--  -->
      <personal-area-header
        v-if="!$store.state.isFrameVersion"
      ></personal-area-header>
      <div
        :class="{
          paddingBottomDashboardContent: !this.$store.state.isFrameVersion,
        }"
      >
        <router-view />
      </div>
    </div>

    <global-modal></global-modal>

    <side-popup-v2
      :visible="this.$store.state.crm.crmOpenModal"
      @close="this.$store.dispatch('crmModalHide')"
    >
      <crm-settings></crm-settings>
    </side-popup-v2>

    <v-popup
      :visible="this.$store.state.supportPopupOpen"
      @close="this.$store.commit('hideSupportPopup')"
    >
      <h2 class="mb-3">
        Извините, но данный функционал сейчас находится в разработке
      </h2>
      <p class="mb-3">
        Подпишитесь на наш
        <a href="https://t.me/qoobstore" class="link" target="_blank"
          >телеграмм канал</a
        >, чтобы узнавать о всех обновлениях, или
        <a
          href="https://t.me/qoob_store_support_bot"
          class="link"
          target="_blank"
          >обратитесь в поддержку</a
        >
      </p>
    </v-popup>

    <v-popup
      :visible="this.$store.state.errPopup.open"
      @close="this.$store.commit('setErrPopup', { open: false, status: 0 })"
    >
      <h2>Ошибка {{ this.$store.state.errPopup.status }}</h2>
      <p class="mb-5 login-text">
        Упс... Произошла ошибка {{ this.$store.state.errPopup.status }},
        повторите попытку позже или обратитесь в поддержку
      </p>

      <a href="https://t.me/qoob_store_support_bot" class="link" target="_blank"
        >Поддержка qoob.store</a
      >
    </v-popup>

    <v-popup
      :visible="this.$store.state.errYclientsPopupOpen"
      @close="this.$store.commit('errYclientsPopupOpen', false)"
    >
      <h2>Yclients не отвечает</h2>
      <p class="mb-5 login-text">
        Упс... без Yclients наш сервис не может полноценно работать, но они уже
        работают над устранением неполадок. Попробуйте зайти на сайт чуть позже
      </p>

      <a href="https://t.me/qoob_store_support_bot" class="link" target="_blank"
        >Поддержка qoob.store</a
      >
    </v-popup>
  </div>
</template>

<script>
import PersonalAreaHeader from "@/components/Dashboard/DashboardHeader";
import GlobalModal from "@/components/UI/GlobalModal";
import sidePopupV2 from "@/components/UI/SidePopupV2";
import crmSettings from "@/components/Dashboard/Services/CrmSettings.vue";
import VPopup from "../components/UI/v-popup.vue";
import dashboardNotifications from "@/components/Dashboard/dashboardNotifications.vue";
import confirmPopup from "@/components/confirmPopup.vue";
import qoobLoader from "@/components/UI/qoob-loader.vue";
import iframeNav from "../iframe/iframeComponents/iframeNav.vue";

export default {
  name: "Dashboard",
  components: {
    GlobalModal,
    PersonalAreaHeader,
    sidePopupV2,
    crmSettings,
    VPopup,
    dashboardNotifications,
    confirmPopup,
    qoobLoader,
    iframeNav,
  },
  data: function () {
    return {
      show: true,

      mobileMenuVisible: false,
      modileMenuClosing: false,
      mobileMenuOpen: false,
    };
  },
  computed: {
    showBlank() {
      let sectionsWithBlank = ["personalArea", "settings", "mobileApp"];
      return sectionsWithBlank.includes(this.$route.name);
    },
    mobilePreview() {
      let pageWithMobilePreview = ["mobileApp"];
      return pageWithMobilePreview.includes(this.$route.name);
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    containerWidth() {
      const sectionsWithBlank = ["personalArea", "settings", "mobileApp"];

      if (
        sectionsWithBlank.includes(this.$route.name) &&
        this.windowWidth > 1440
      ) {
        return "width: 63%";
      }
      return 0;
    },
  },
  mounted() {
    this.$store.dispatch("checkAuth");

    // if (this.$store.getters.isAuth) {
    //   this.$store.dispatch("baseResponses");
    // }

    if (localStorage.getItem("crmSynced") === "success") {
      this.$store.dispatch("crmModalVisible", { success: true });
      localStorage.removeItem("crmSynced");
    }

    if (this.$store.state.isFrameVersion) {
      this.$router.push("/dashboard/iframe/billing");
    }
  },
  unmounted() {
    localStorage.removeItem("crmPassword");
    localStorage.removeItem("crmLogin");
    localStorage.removeItem("crm_name");
  },
  methods: {
    mobileLink() {
      if (this.mobileMenuVisible) {
        this.mobileMenuOpen = false;

        this.modileMenuClosing = true;
        setTimeout(() => {
          this.mobileMenuVisible = false;
          this.modileMenuClosing = false;
        }, 500);
      } else {
        this.mobileMenuOpen = true;
        this.mobileMenuVisible = true;
      }
    },
  },
};
</script>

<style scoped>
.container-fluid {
  width: auto;
  padding-left: 200px !important;
  padding-right: 0 !important;
  margin: 0 !important;
  box-sizing: content-box;
}

.container-fluid-iframe {
  width: auto;
  margin-top: 40px;
  padding-left: 0px !important;
  padding-right: 0 !important;
  box-sizing: content-box;
}

@media (max-width: 1440px) {
  .container-fluid-iframe {
    padding-left: 0 !important;
  }
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.paddingBottomDashboardContent > div {
  padding: 0 60px;
}

@media (min-width: 1440px) {
  .paddingBottomDashboardContent > div {
    padding: 0 30px;
  }
}

@media (max-width: 1440px) {
  .container-fluid {
    padding: 0 !important;
  }

  .paddingBottomDashboardContent > div {
    padding: 0 !important;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 550px) {
  .container-fluid {
    padding: 0 !important;
  }
}
</style>

<style scoped lang="scss">
.mobile-wrap {
  padding-top: 50px;
}
.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 200vh;
  z-index: 800;
  display: flex;
  flex-direction: column;

  &__list {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }

  &._open {
    height: 100vh;
    .mobile-menu__item {
      background: rgba(255, 255, 255, 1);
      border: solid 1px rgba(51, 51, 51, 0.268);
    }
    .mobile-menu__item.router-link-active {
      flex-grow: 0 !important;
    }
    .mobile-menu__item:last-child {
      flex-grow: 1 !important;
    }

    .mobile-menu__item.router-link-active:before {
      display: none;
    }
  }

  &._closing {
    .mobile-menu__item.router-link-active {
      height: auto;
      opacity: 1;
      flex-grow: 1;
    }

    .mobile-menu__item {
      opacity: 0.1s;
      height: 0;
      opacity: 0;
      padding: 0;
      margin: 0;
    }
  }

  &._close {
    height: auto !important;
    .mobile-menu__item.router-link-active {
      height: auto;
      opacity: 1;
      flex-grow: 1;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: solid 1px rgba(51, 51, 51, 0.268);
      background: #fff;
    }

    .mobile-menu__item {
      opacity: 0.1s;
      height: 0;
      opacity: 0;
      padding: 0;
      margin: 0;
    }
  }

  &__item {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    padding: 10px;
    transition: 0.5s;
    position: relative;
    &.router-link-active {
      flex-grow: 1;
    }

    &.router-link-active:before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      background: url("../assets/drop-down1.svg") no-repeat center / 60%;
      right: 40px;
    }

    &._title {
      background: rgba(255, 255, 255, 1);
    }
  }
}
</style>