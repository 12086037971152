<template>
  <div class="iframe-nav" ref="menu">
    <button class="iframe-nav__button _prev" @click="scrollMenuLeft">
      <svg
        width="6"
        height="12"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.71326 13.634C0.582716 13.634 0.452172 13.5827 0.352905 13.4793C0.153689 13.2732 0.153689 12.9398 0.352905 12.7337L5.78272 7.11666L0.356304 1.61496C0.155049 1.41099 0.151649 1.07689 0.348825 0.869399C0.546001 0.661203 0.868961 0.657687 1.07022 0.861662L6.86379 6.73614C6.96102 6.83461 7.01677 6.96896 7.01677 7.11033C7.01745 7.251 6.96374 7.38605 6.86787 7.48593L1.07362 13.4793C0.974348 13.5827 0.843804 13.634 0.71326 13.634Z"
          fill="#4e4e4e"
        />
      </svg>
    </button>
    <iframeNavItem
      v-for="link in navigationLinks"
      :link="link"
      :key="link.route"
    ></iframeNavItem>
    <button class="iframe-nav__button _next" @click="scrollMenuRight">
      <svg
        width="6"
        height="12"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.71326 13.634C0.582716 13.634 0.452172 13.5827 0.352905 13.4793C0.153689 13.2732 0.153689 12.9398 0.352905 12.7337L5.78272 7.11666L0.356304 1.61496C0.155049 1.41099 0.151649 1.07689 0.348825 0.869399C0.546001 0.661203 0.868961 0.657687 1.07022 0.861662L6.86379 6.73614C6.96102 6.83461 7.01677 6.96896 7.01677 7.11033C7.01745 7.251 6.96374 7.38605 6.86787 7.48593L1.07362 13.4793C0.974348 13.5827 0.843804 13.634 0.71326 13.634Z"
          fill="#4e4e4e"
        />
      </svg>
    </button>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import iframeNavItem from "./iframeNavItem.vue";

const store = useStore();

const license = computed(() => store.state.tarif.license);
const telegramStatus = computed(() => store.state.telegram.status);
const smsStatus = computed(() => store.state.sms.smsStatus);
const whatsappStatus = computed(() => store.state.whatsapp.whatsappStatus);
const greenApi = computed(() => store.state.whatsapp.greenApi);

const instanceStatusCode = computed(
      () => store.state.whatsapp.instanceStatusCode
    );

const statusWhatsapp = computed(() => {
  if (whatsappStatus.value.provider !== "greenapi") {
    return instanceStatusCode.value === 200 ? 1 : 0
  } else {
      return greenApi.value.isAuth ? 1 : 0;
  }
});

const visibleQoobApp = computed(() => {
  return (
    store.state.tarif.licenseInfo &&
    store.state.tarif.licenseInfo.qoob_app &&
    store.state.tarif.licenseInfo.qoob_app.user_count > 0 &&
    store.state.mobile.mobileSettings.info &&
    store.state.mobile.mobileSettings.info.show_app_settings
  );
});

const isTestFilial = computed(() => {
  return store.state.crm.crmLicense.acc_id === 27326;
});

const navigationLinks = computed(() => {
  let resultArr = [];
  let arr = [
    {
      name: "Биллинг",
      route: "/dashboard/iframe/billing",
      statusIcon: false,
      status: license.value.isActive ? 1 : 0,
      visible: true,
      typeLink: "router-link",
    },
    {
      name: "Telegram",
      route: "/dashboard/iframe/telegram",
      statusIcon: false,
      status: telegramStatus.value ? 1 : 0,
      visible: true,
      typeLink: "router-link",
    },
    {
      name: "Приложение",
      route: "/dashboard/iframe/app",
      statusIcon: false,
      status: telegramStatus.value ? 1 : 0,
      visible: true,
      typeLink: "router-link",
    },
    {
      name: "WhatsApp",
      route: "/dashboard/iframe/whatsapp",
      statusIcon: 0,
      status: statusWhatsapp.value,
      visible: true,
      typeLink: "router-link",
    },
    {
      name: "Моб. приложение",
      route: "/dashboard/iframe/qoobapp",
      statusIcon: false,
      status: license.value.isActive ? 1 : 0,
      visible: visibleQoobApp.value,
      typeLink: "router-link",
    },
    {
      name: "Отзывы",
      route: "/dashboard/iframe/reviews",
      statusIcon: false,
      status: license.value.isActive ? 1 : 0,
      visible: true,
      typeLink: "router-link",
    },
    {
      name: "SMS",
      route: "/dashboard/iframe/sms",
      statusIcon: false,
      status: smsStatus.value.isActive ? 1 : 2,
      visible: true,
      typeLink: "router-link",
    },
    {
      name: "История",
      route: "/dashboard/iframe/historyPage",
      statusIcon: false,
      status: 1,
      visible: true,
      typeLink: "router-link",
    },
    {
      name: "Типы",
      route: "/dashboard/notifications/types",
      statusIcon: false,
      status: 1,
      visible: isTestFilial.value,
      typeLink: "router-link",
    },
    {
      name: "Сегменты",
      route: "/dashboard/notifications/segments",
      statusIcon: false,
      status: 1,
      visible: isTestFilial.value,
      typeLink: "router-link",
    },
    {
      name: "Владелец",
      route: "/dashboard/iframe/profile",
      statusIcon: false,
      status: 1,
      visible: true,
      typeLink: "router-link",
    },
    {
      name: "Поддержка",
      route: "https://t.me/qoob_store_support_bot",
      statusIcon: false,
      status: 3,
      visible: true,
      typeLink: "a",
    },
  ];

  arr.forEach((link) => {
    if (link.visible) {
      resultArr.push(link);
    }
  });

  return resultArr;
});
/* eslint-disable */
const menu = ref(null);

function scrollMenuLeft() {
  menu.value.scrollLeft -= 205;
}
function scrollMenuRight() {
  menu.value.scrollLeft += 205;
}

function stopMenuScroll() {
  isScrollMenu.value = false;
}
</script>

<style lang="scss" scoped>
.iframe-nav {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #f1f3f8;

  &__button {
    display: none;
  }

  @media (max-width: 750px) {
    width: 100vw;
    overflow: auto;
    scroll-snap-type: y mandatory;
    transition: 0.2s;
    scroll-behavior: smooth;
    align-items: center;
    flex-wrap: nowrap;

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 36px;
      height: 36px;
      position: sticky;
      transition: 0.3s;

      background: #ffffff;
      border: 2px solid #e0e3eb;
      border-radius: 10px;

      &._prev {
        top: 0;
        left: 0;
        transform: rotate(180deg);
      }
      &._next {
        top: 0;
        right: 0;
      }
    }
  }
}
</style>