<template>
  <div>
    
  </div>
</template>

<script>
export default {
  mounted() {
    if (!this.$store.state.isFrameVersion) {
        this.$router.push('/dashboard/services')
      }
  }
}
</script>

<style>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-active {
  opacity: 0;
}
</style>