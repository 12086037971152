<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.8691 18.3096H8.86914C6.10914 18.3096 3.86914 16.0696 3.86914 13.3096C3.86914 10.5496 6.10914 8.30957 8.86914 8.30957H19.8691" stroke="#6D6D6D" stroke-width="1.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.5703 10.8104L20.1303 8.25043L17.5703 5.69043" stroke="#6D6D6D" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "redo-icon"
}
</script>

<style scoped>

</style>