<template>
  <div :class="['checkbox', {'checkbox--active': item.state || checkboxState}]" @click="toggleCheckbox">
    <tick-item-icon v-show="item.state || checkboxState" />
  </div>
</template>

<script>
import { ref, watch, onMounted, watchEffect } from "vue";
import tickItemIcon from "@/components/Dashboard/Billing/icons/tick-item-icon";

export default {
  name: "custom-checkbox",
  components: {
    tickItemIcon: tickItemIcon
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    defaultState: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toggleCheckbox'],
  setup(props, { emit }) {
    const checkboxState = ref(false);

    function toggleCheckbox() {
      checkboxState.value = !checkboxState.value;
    }

    watch(checkboxState, () => {
      emit('toggleCheckbox', {
        index: props.index,
        state: checkboxState.value
      });
    })
    watchEffect(() => {
      checkboxState.value = props.item.state
    })

    onMounted(() => {
      checkboxState.value = props.defaultState;
    })

    return { toggleCheckbox, checkboxState }
  }
}
</script>

<style scoped>
  .checkbox {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;

    border: 1.5px solid #A1A2A2;
    border-radius: 7px;
    box-sizing: border-box;
    transition: .2s all ease;
    cursor: pointer;
  }

  .checkbox--active {
    border: 1px solid #FFC549;
  }
</style>