<template>
  <div class="category-settings">
    <div class="category-list">
      <newAccordion
        v-for="category in categories"
        :key="category.id"
        :baseHeight="category.services.length * 63 + 42"
        :open="category.open"
        @toggleOpen="category.open = !category.open"
      >
        <template v-slot:title>
          <div class="category-item">
            <div class="category-item__left" @click="category.open = !category.open">
              <div class="category-item__name">{{ category.title }}</div>
              <div class="category-item__legth">
                Содержит услуг: {{ category.services.length }}
              </div>
            </div>
            <div class="category-item__right">
              <div class="category-drop">
                <newDropList
                  :list="['ОСНОВНАЯ', 'ДОПОЛНИТЕЛЬНАЯ']"
                  v-model="category.isAdditional"
                ></newDropList>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:content> 
          <div class="services-list" v-if="category.services.length > 0">
            <div class="services-list__header">
              <div class="services-list__section _section">Название</div>
              <div class="services-list__section _section">Онлайн - запись</div>
              <div class="services-list__section _section">Цена</div>
            </div>
            <div
              class="services-list__item"
              v-for="service in category.services"
              :key="service.id"
            >
              <div class="services-list__name _section">
                {{ service.title }}
              </div>
              <div class="services-list__switch _section">
                <CmSwitch v-model="service.active"></CmSwitch>
              </div>
              <div class="services-list__price _section">
                {{ getFullPrice(service) }}
              </div>
            </div>
          </div>
        </template>
      </newAccordion>

      <h2 class="tit-h5">Выберите сотрудников, которые будут отображаться только при записи на дополнительные услуги</h2>

      <div v-for="staff in staffs" :key="staff.id" class="staff-card">
        <img :src="staff.avatar" alt="" class="staff-card__avatar">

        <div class="staff-card__info">
          <div class="staff-card__name">{{staff.name}}</div>
          <div class="staff-card__spec">{{staff.specialization}}</div>
        </div>

        <CmSwitch v-model="staff.additional" class="staff-card__switch"></CmSwitch>
        
      </div>
    </div>
  </div>
</template>

<script setup>
import newAccordion from "@/iframe/UI/newAccordion.vue";
import CmSwitch from "@/components/UI/inputs/СmSwitch.vue";
import { computed, onBeforeMount } from "vue";
import { useStore } from "vuex";

import newDropList from "../UI/newDropList.vue";

const store = useStore();

const categories = computed(
  () => store.state.telegram.steps.categoriesSettigs.list
);

const staffs = computed(
  () => store.state.telegram.steps.categoriesSettigs.staffs.filter(item => !item.hidden)
);

onBeforeMount(() => {
  store.dispatch("telegram/steps/getCategories");
});

function getFullPrice(service) {
  if (service.price_max === service.price_min) {
    return service.price_max.toLocaleString();
  } else {
    return `От ${service.price_min.toLocaleString()} до ${service.price_max.toLocaleString()}`;
  }
}
</script>

<style lang="scss">
.category-settings {
}
.category-list {
  display: flex;
  flex-direction: column;
  gap: 11px;
}
.category-item {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;

  &__left {
  }

  &__name {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 7px;
  }

  &__legth {
    font-size: 12px;
    color: #c0c4c5;
    font-weight: 500;
  }

  &__right {
  }
}
%serviceColumn {
  ._section:first-child {
    flex-basis: 50%;
  }
  ._section:nth-child(2) {
    flex-basis: 20%;
    display: flex;
    justify-content: end;
  }
  ._section:nth-child(3) {
    flex-basis: 30%;
    display: flex;
    justify-content: end;
  }
}
.services-list {
  &__header {
    @extend %serviceColumn;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;

    @media (max-width: 768px) {
      padding: 1px 2px;
    }
  }
  &__section {
    font-size: 12px;
    line-height: 23px;
    color: #c0c4c5;
  }
  &__item {
    @extend %serviceColumn;

    display: flex;
    justify-content: space-between;
    padding: 19px 15px;
    border-bottom: 1px solid #bdbdbd6c;
  }
  &__item:last-child {
    border-bottom: none;
  }
  &__name {
    font-size: 12px;
    font-weight: 500;
  }

  &__price {
    font-size: 12px;
  }
}

.category-item__right {
  width: 100%;
  max-width: 242px;

  .drop-list {
    border: solid 1px #000;
    border-radius: 6px;

    &__value {
      padding: 8px 0 8px 20px !important;
    }
    &__list {
      border: solid 1px #000;
      border-top: none;
    }
  }
}
.category-item__left {
  flex-grow: 1;
  cursor: pointer;
}

.staff-card {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
width: 40%;
padding-bottom: 7px;
		&__avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
		}

		&__info {
      flex-grow: 1;
		}

		&__name {
      font-size: 14px;
      font-weight: 500;
		}

		&__spec {
      font-size: 11px;
		}

    &__switch {

    }
}

@media (max-width:800px ) {
  .staff-card {
    width: 50%;
  }
}
@media (max-width:567px ) {
  .staff-card {
    width: 80%;
  }
}

</style>