<template>
  <div class="cards_wrapper cards_wrapper2 w-100">
    <div
      class="cards-flex"
      :class="{ 'cards-wrapper--full': showWelcomeMessage }"
    >
      <transition name="fade">
        <div v-if="showWelcomeMessage" class="servicesCard1 p-2">
          <cm-card>
            <div class="welcome-wrapper">
              <div class="welcome-content">
                <p class="card-title" v-html="content.welcome.title">
                </p>

                <p class="card-text" v-html="content.welcome.text"></p>
              </div>

              <div class="button-wrapper">
                <button-transparent
                  class="services__welcome-button"
                  @click="
                    this.$store.dispatch('setAnnouncement', true),
                      (showWelcomeMessage = false),
                      this.$store.dispatch('timeline', 'клиент прочитал миссию компании')
                  "
                >
                  {{ content.welcome.btnText }}
                </button-transparent>
              </div>
            </div>
          </cm-card>
        </div>
      </transition>
      <!--      :class="{_loader: crmStatusLoading}"-->

      <services-crm
        class="itemWrapper itemWrapper1 p-2 status-card-hover"
        :class="{ 'pl-0': !showWelcomeMessage }"
      ></services-crm>
      <services-qoob-store
        class="itemWrapper itemWrapper2 p-2 "
      ></services-qoob-store>

      

      <div class="w-29 p-2 lastServicesContainer">
        <services-qoob-app class="itemSecond itemSecond1 status-card-hover"></services-qoob-app>
        <services-telegram class="itemSecond status-card-hover"></services-telegram>
      </div>

      <div class="w-29 p-2 lastServicesContainer">
        <services-whats-app class="itemSecond itemSecond1 status-card-hover"></services-whats-app>
        <services-sms class="itemSecond status-card-hover"></services-sms>
      </div>

    </div>
  </div>

  <div class="cards_wrapper1 w-100" style="overflow-x: unset">
    <div class="graph-section">
      <services-client-flow></services-client-flow>
      <services-segment-statistic></services-segment-statistic>
    </div>
  </div>
  <side-popup></side-popup>
</template>

<script>
import CmCard from "@/components/UI/CmCard";
import SidePopup from "@/components/UI/SidePopup";
import { mapState } from "vuex";
import ServicesCrm from "@/views/Dashboard/Services/ServicesCrm";
import ServicesQoobStore from "@/views/Dashboard/Services/ServicesQoobStore";
import ServicesQoobApp from "@/views/Dashboard/Services/ServicesQoobApp";
import ServicesWhatsApp from "@/views/Dashboard/Services/ServicesWhatsApp";
import ServicesTelegram from "@/views/Dashboard/Services/ServicesTelegram";
import ServicesSms from "@/views/Dashboard/Services/ServicesSms";
import ServicesClientFlow from "@/views/Dashboard/Services/ServicesClientFlow";
import ServicesSegmentStatistic from "@/views/Dashboard/Services/ServicesSegmentStatistic";
import buttonTransparent from "@/components/UI/buttons/ButtonTransparent";

export default {
  name: "Services",
  components: {
    ServicesSegmentStatistic,
    ServicesClientFlow,
    ServicesSms,
    ServicesWhatsApp,
    ServicesQoobApp,
    ServicesTelegram,
    ServicesQoobStore,
    ServicesCrm,
    SidePopup,
    CmCard,
    buttonTransparent,
  },
  data: function () {
    return {
      showWelcomeMessage: false,
      crmStatusLoading: false,
    };
  },
  computed: {
    ...mapState({
      content: (state) => state.texts.dashboard.services,
      crmConnect: (state) => state.crm.crmConnect,
      alreadyAttended: (state) => state.alreadyAttended,
    }),
  },
  mounted() {
    this.showWelcomeMessage = !(localStorage.getItem("announcement") === "yes");
  },
};
</script>

<style scoped>
.card-title {
  margin-bottom: 15px;

  font-size: 26px;
  font-weight: 600;
  line-height: 42px;
}

.card-text {
  font-size: 16px;
  line-height: 30px;
}

.cards_wrapper {
  height: auto;
  display: flex;

  padding: 0 60px;
  margin-top: 40px;
}

.cards_wrapper::-webkit-scrollbar {
  display: none;
}

.cards_wrapper1 {
  display: flex;
  margin-bottom: 50px;
  padding: 0 60px;
}

.welcomeContent {
  margin-top: 1rem;
}
.welcomeTitle {
  height: 2.5rem;
  font-size: 1.625rem;
  font-weight: 600;
}
.wlcmBtn {
  position: absolute;
  bottom: 0.7rem;
}
.p-2 {
  padding: 0.625rem !important;
}

.servicesCard1 {
  /* width: calc(50% + 10px);
  max-width: 810px;
  min-width: 650px; */

  padding: 0 !important;
}

.welcome-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
}

.px-2 {
  padding: 0 0.625rem !important;
}
.w-29 {
  width: 25%;
}
.graph-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;

  width: 100%;
  max-width: 1600px;

  box-sizing: border-box;
}

.pl-0 {
  padding-left: 0 !important;
}

.cards-flex {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;

  max-width: 1600px;
  width: 100%;
  height: 340px;
}

.cards-flex.cards-wrapper--full {
  height: auto !important;
}

.itemWrapper {
  width: 100%;
  height: 100%;
  padding: 0 !important;
}

.lastServicesContainer {
  display: flex;
  flex-direction: column;

  min-width: auto;
  width: 100%;

  padding: 0 !important;
}

.itemSecond {
  max-height: 160px;
  padding: 0 !important;
}

.itemSecond1 {
  margin-bottom: 20px;
}

.welcome-button {
  width: 160px;
  height: 48px;
  margin-top: 30px;
}

.grey-bg {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;

  width: 35%;
  height: 100vh;
  padding: 0;

  background-color: #f7f9fc;
}

.cards_wrapper2 {
  margin-top: 0;
  padding-top: 40px;
  padding-bottom: 80px;
  overflow-y: scroll;
}

.cards-wrapper--full {
  grid-template-columns: 2.06fr 1fr 1fr 1fr 1fr;
  min-width: 2400px;
}

.services__welcome-button {
  margin-left: auto;
}

@media (max-width: 1920px) {
  .cards-wrapper--full {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 20px;
    min-width: auto;
  }

  .cards_wrapper2  {
    overflow: visible;
  }

  .servicesCard1 {
    grid-column: 1/5;
  }
}


@media (max-width: 1600px) and (min-width: 1024px) {

  .cards-wrapper--full {
    grid-row-gap: 10px;
  }

  .cards-flex {
    grid-column-gap: 10px;
    height: 250px;
  }

  .cards_wrapper1 {
    margin-top: 60px;
  }

  .card-text {
    font-size: 14px;
    line-height: 22px;
  }

  .services__welcome-button {
    height: 40px;
    padding: 0 15px;
    font-size: 12px !important;
  }

  .itemSecond1 {
    margin-bottom: 10px;
  }

  .graph-section {
    grid-column-gap: 10px;
  }
}

@media (max-width: 1440px) and (min-width: 1024px) {
  .cards-flex {
    grid-column-gap: 10px;
    height: 230px;
  }

  .cards_wrapper1 {
    margin-top: 45px;
  }
}

@media (max-width: 1280px) and (min-width: 1024px) {
  .itemSecond {
    padding: 15px;
  }

  .cards_wrapper {
    padding: 0 30px;
  }

  .cards_wrapper1 {
    padding: 0 30px;
  }

  .cards_wrapper2 {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .cards-flex {
    height: 200px;
  }
}

@media (max-width: 1023px) {
  

  .cards_wrapper {
    padding: 0;
  }

  .cards_wrapper1 {
    padding: 0;
  }

  .cards_wrapper2 {
    padding-top: 40px;
    padding-bottom: 60px;
  }

  .cards-flex {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;

    height: auto;
  }

  .cards-wrapper--full {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr 1fr;
  }

  .servicesCard1 {
    grid-column: 1/3;
  }

  .itemWrapper1 {
    //grid-column: 1/2;
  }

  .itemWrapper2 {
    //grid-column: 2/4;
  }

  .itemWrapper3 {
    //grid-column: 1/2;
  }

  .itemSecond1 {
    margin-bottom: 10px;
  }

  .graph-section {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 80px;
  }
}

@media (max-width: 768px) and (min-width: 551px) {
  .cards-flex {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;

    height: auto;
  }

  .cards-wrapper--full {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
  }

  .itemWrapper1 {
    //grid-column: 1/2;
  }

  .itemWrapper2 {
    //grid-column: 2/4;
  }

  .itemWrapper3 {
    //grid-column: 1/2;
  }
  
  .itemSecond {
    padding: 15px 25px;
  }

  .card-text {
    font-size: 14px;
    line-height: 24px;
  }
}

@media (max-width: 550px) {

  .graph-section {
    grid-template-rows: auto auto;
    grid-row-gap: 20px;
  }

  .cards_wrapper {
    padding: 0 10px;
  }

  .cards_wrapper1 {
    height: auto;
    padding: 0 10px;
  }

  .cards_wrapper2 {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .cards-flex {
    grid-template-columns: 1fr;
  }

  .cards-wrapper--full {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr 1fr 1fr;
  }

  .itemWrapper {
    height: auto;
    min-height: 260px;
  }

  .servicesCard1 {
    grid-column: 1/2;
  }

  .lastServicesContainer {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 10px;
  }

}
</style>

