<template>
  <div class="payment-data">
    <h3 class="payment-title">Реквизиты организации</h3>

    <div class="sum-block">
      <p class="sum__text">К оплате:</p>
      <p class="sum__number">{{ currentPrice }} ₽</p>
    </div>

    <div class="inn">
      <labelInput
        :label="
          !error
            ? 'Инн юридического лица'
            : 'Ничего не найдено, проверьте правильность ИНН, или введите данные вручную'
        "
        :labelWidth="25"
        class="payment-data__input"
        :placeholder="'0000 0000 0000'"
        :mask="'#### #### #####'"
        :lazy="true"
        v-model.lazy="$store.state.invoice.invoiceInfo.inn"
        :invalid="
        this.v$.paymentData.inn.$invalid &&
          this.v$.paymentData.inn.$dirty"
      ></labelInput>
      <button
        class="inn__search"
        @click="getCompany"
        :class="{ '_loader-btn': loadingCompany }"
      >
        Найти по номеру
      </button>
    </div>

    <div
      class="input-wrapper input-wrapper1"
      :class="{
        invalid:
          this.v$.paymentData.company.$invalid &&
          this.v$.paymentData.company.$dirty,
      }"
    >
      <label for="inn__input2" class="mb-2">Название организации</label>
      <input
        class="default-input"
        id="inn__input2"
        type="text"
        placeholder="Укажите название вашей организации"
        v-model="$store.state.invoice.invoiceInfo.company"
      />
    </div>

    <div
      class="input-wrapper"
      :class="{
        invalid:
          this.v$.paymentData.company_adr.$invalid &&
          this.v$.paymentData.company_adr.$dirty,
      }"
    >
      <label for="inn__input3" class="mb-2">Юридический адрес</label>
      <input
        class="default-input"
        id="inn__input3"
        type="text"
        placeholder="Укажите юридический адрес"
        v-model="$store.state.invoice.invoiceInfo.company_adr"
      />
    </div>
  </div>
</template>

<script>
import labelInput from "../../UI/inputs/labelInput.vue";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";

let innValidator = (value) => {
  if (value.length == 12) {
    return true;
  }
  if (value.length == 10) {
    return true;
  }
  return false;
};

export default {
  name: "payment-data",
  props: {
    currentPrice: {
      type: String,
      required: true,
    },
  },
  components: {
    labelInput,
  },
  data() {
    return {
      v$: useVuelidate(),
      // paymentData: {
      //   inn: "",
      //   name: "",
      //   address: "",
      // },
      loadingCompany: false,
      error: false,
    };
  },
  validations: {
    paymentData: {
      inn: {
        required,
        innValidator,
      },
      company: {
        required,
      },
      company_adr: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      paymentData: (state) => state.invoice.invoiceInfo,
    }),
  },
  methods: {
    getCompany() {
      this.v$.paymentData.inn.$touch();

      console.log(this.v$.paymentData.inn.$invalid);

      if (!this.v$.paymentData.inn.$invalid) {
        this.loadingCompany = true;
        const inn = this.paymentData.inn.replace(/\s+/g, "");
        this.$store
          .dispatch("getCompanyByInn", inn)
          .then((request) => {
            console.log(request);
            this.loadingCompany = false;
            if (request.data.suggestions.length === 0) {
              this.error = true;
            } else {
              this.error = false;
            }
            this.valid();
          })
          .catch(() => {
            this.loadingCompany = false;
          });
      }
    },
    valid() {
      this.v$.paymentData.$touch();

      return !this.v$.paymentData.$invalid;
    },
  },
  watch: {
    paymentData: {
      handler() {
        const resultObj = { ...this.paymentData };
        resultObj.inn = resultObj.inn.replace(/\s+/g, "");
        this.$emit("dataUpdate", resultObj);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.input-wrapper1 {
  margin-bottom: 25px;
}

.payment-data__input {
  flex-basis: 100%;
}

#inn__input1 {
  width: 336px;
}

.inn__search {
  width: 180px;
  min-width: 180px;
  height: 48px;

  border: 1.7px solid #ffc549;
  box-sizing: border-box;
  border-radius: 12px;

  font-size: 16px;
}

.inn__search:hover {
  border: none;
  background-color: #fed47a;
}

.inn__search:active {
  border: none;
  background-color: #f0f3f8;
}

.main-input {
  width: 100%;
  height: 48px;
  padding-left: 20px;
  margin-top: 5px;

  border-radius: 12px;
  box-sizing: border-box;

  background: #f6f9fc;
}

label {
  font-size: 14px;
  color: #6d6d6d;
}

.inn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 40px;
  margin-bottom: 100px;

  gap: 20px;

  border-bottom: 1px solid #eff0f0;
}

.sum__number {
  font-size: 32px;
  letter-spacing: -2.5px;
  color: #1e2022;
}

.sum__text {
  font-size: 20px;
}

.sum-block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 60px;
  padding: 0 20px;
  margin-bottom: 35px;

  border-radius: 12px;

  background-color: #f6f9fc;
}

.payment-title {
  margin-bottom: 35px;

  font-size: 20px;
  color: #1e2022;
}

@media (max-width: 768px) {
  .inn {
    display: block;
    padding-bottom: 0;
    margin-bottom: 35px;
    border-bottom: none;
  }

  .payment-data__input {
    margin-bottom: 20px;
  }

  .input-wrapper {
    margin-bottom: 25px;
  }

  .main-input {
    font-size: 16px;
  }

  .inn__search {
    width: 100%;
  }
}
</style>