<template>
  <div class="dashboard-notifications" @click="this.$store.state.dashboard.dashboardNotificationsList = []">
    <transition-group name="fade">
      <div
        class="dashboard-notifications__item"
        v-for="notification in notificationList"
        :key="notification"
        :class="[
          { _error: notification.type === 'err' },
          { _warn: (notification.type === 'warn') },
          { _success: (notification.type === 'ok') },
        ]"
      >
        {{ notification.text ? notification.text : notification.type === 'err' ? 'Ошибка' : notification.type === 'ok' ? 'Сохранено' : ''}}
      </div>
    </transition-group>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
export default {
  setup() {
    const $store = useStore();
    const notificationList = computed(
      () => $store.state.dashboard.dashboardNotificationsList
    );

    return {
      notificationList,
    };
  },
};
</script>

<style scoped>
.dashboard-notifications {
  position: fixed;
  right: 0;
  top: 0;
  padding: 10px;
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 999999999;
  max-height: 400px;
  cursor: pointer;
  pointer-events: none;

  /* background: #000; */

  color: #fff;
}
.dashboard-notifications__item {
  text-align: center;
  width: 100%;
  padding: 15px 5px;

  background: #ffc549;
  color: #1e2022;
  border-radius: 15px 15px 0 15px;
  font-weight: 600;

  cursor: pointer;

  font-size: 12px;

  box-shadow: 0 0 10px rgba(0,0,0, .3);
}
.dashboard-notifications__item._error {
  background: #DD6068;
  color: #fff;
}
.dashboard-notifications__item._warn {
  background: #FFC549;
}
.dashboard-notifications__item._success {
  background: #65c45c;
  color: #fff;
}

@media (max-width: 540px) {
  .dashboard-notifications {
    max-width: 15vh;
    overflow: hidden;
    max-width: 150px;
  }
  .dashboard-notifications__item {
    border-radius: 10px 10px 0 10px;
    width: 100%;
    padding: 10px 5px;
  }
}
</style>