import axios from "axios"
/* eslint-disable */
// const NqApi = 'https://nq.dev.inqanateam.pro/api/v1';
// const NqEventsApi = 'https://events.dev.inqanateam.pro/api/v1'
export default {
  state: () => ({
    notificationLoading: false,

    loadingHistoryMesseges: false,
    historyMesseges: [],
    historyMessegesPages: {

    },
    historyPageSize: 15,
    currentHistoryPage: 1,
    historySize: 15,

    historySearch: {
      type: '',
      params: {},
      value: '',
      result: {
        type: '',
        value: ''
      }
    },
    historyMode: 'pagination', // 'pagination' || 'search'

    types: {
      rules: null,
      routes: null,
      categories: null,
      vars: null,
      defaultChannels: null,
      availableChannels: null
    }
  }),
  getters: {
    getEmptyType(state, getters, rootState) {
      let result = {
        "uid": "new",
        "name": "",
        "route": "82346b60-0f22-48f0-82aa-6f0d4930ca4a",
        "customer": rootState.crm.filialsList.currentFilial.uid,
        "config": {
            "when": "after",
            "period": {
                "from": "09:00",
                "till": "20:00"
            },
            "overdue": "pass",
            "interval": "P1D",
            "precision": "time"
        },
        "category": "",
        "is_active": true,
        "templates": [
        ],
        "rule_templates": [
        ]
    }

      result.rule_templates.forEach(ruleTemplate => {
        ruleTemplate.value = ''
      })
      result.templates.forEach(template => {
        template.value = ''
      })

      return result
    }
  },
  mutations: {
    setNotificationLoading(state, value) {
      state.notificationLoading = value
    },
    setTimeDrop(state, obj) {
      obj.notification.channels.action_time_choice_id = obj.dropId
    },
    setDefaultChannels(state, channels) {
      // telegram whatsapp sms push
      let resultArr = []
      resultArr.push(channels.find(i => i.label === 'telegram'))
      resultArr.push(channels.find(i => i.label === 'whatsapp'))
      resultArr.push(channels.find(i => i.label === 'sms'))
      resultArr.push(channels.find(i => i.label === 'push'))

      state.types.defaultChannels = resultArr
    },
    setAvailableChannels(state, channels) {
      state.types.availableChannels = channels
    }
  },
  actions: {
    async getHistoryMesseges(context) {
      const page = context.state.currentHistoryPage
      const pageSize = context.state.historyPageSize
      const historySearch = context.state.historySearch
      const historyMode = context.state.historyMode
      const historyMessegesPages = context.state.historyMessegesPages
      context.state.loadingHistoryMesseges = true;

      if (historyMode === 'pagination') {
        if (historySearch.type) {
          historyMessegesPages = {
            page1: {}
          }
        }
      } else if (historyMode === 'search') {
        if (!historySearch.type) {
          historyMessegesPages = {
            page1: {}
          }
        }
      }

      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/whatsapp/messages`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
        params: {
          page: page,
          size: pageSize,
          ...historySearch.params
        }
      }).then((request) => {
        console.log(request);
        request.data.data.messages.forEach(i => {
          i.open = false
        })
        historyMessegesPages.mode = historyMode
        historyMessegesPages[`page${page}`] = request.data.data.messages
        context.state.historySize = request.data.data.count

        context.state.loadingHistoryMesseges = false

        historySearch.result = {
          type: historySearch.type,
          value: historySearch.value
        }
      }).catch((err) => {
        console.dir(err);
        context.state.loadingHistoryMesseges = false

        // context.commit('addDashboardNotification', {
        //   text: `При загрузке инстории сообщений, произошла ошибка ${err.response.status}`,
        //   type: 'err'
        // })
      })
    },
    async searchHistory(context, value) {
      function fullValue(value) {
        return value > 9 ? value : '0' + value;
      }

      function formatDate(date) {
        const year = date.getFullYear();
        const month = fullValue(date.getMonth() + 1)
        const firstDay = fullValue(date.getDate())
        const hours = fullValue(date.getHours())
        const minutes = fullValue(date.getMinutes())
        const seconds = fullValue(date.getSeconds())

        return `${year}-${month}-${firstDay}T${hours}:${minutes}:${seconds}`
      }
      // type - phone || date || text
      if (value.length === 0) {
        context.state.historySearch = {
          type: '',
          params: {},
          value: '',
          result: {
            type: '',
            value: ''
          }
        }
        context.state.historyMode = 'pagination'
        context.dispatch('getHistoryMesseges')
      } else {
        context.state.historyMode = 'search'
        const noSpaceValue = value.replace(/ /g, '')
        const currentDate = new Date()

        let splitDate = value.split('.')

        if (splitDate.length === 1 && +splitDate[0] < 31) {
          splitDate[1] = currentDate.getMonth()
        }
        if (splitDate.length === 2 && splitDate[0] < 12) {
          splitDate[2] = currentDate.getFullYear()
        }
        if (splitDate.length === 3 && splitDate[2].length > 5) {
          const arr = [splitDate[2].split(' ')[1], splitDate[0], splitDate[1], splitDate[2].split(' ')[0], ]

          splitDate = arr
        }

        const date = new Date(value)
        const reverseDate = new Date(splitDate.reverse().join('/'))
        //(new Date(value).getDay() && (currentDate - new Date(value)) > 0) || ((splitDate[0].length < 3 && +splitDate[0] < 31 && !isNaN(+splitDate[0])) && value.length > 4) 
        const currentSearchDate = date.getDay() ? date : reverseDate
        if (currentSearchDate.getDay() && currentDate - currentSearchDate > 0) {

          const firstDate = formatDate(currentSearchDate)
          if (value.split('.').length === 1) {
            currentSearchDate.setFullYear(currentSearchDate.getFullYear() + 1)
          }
          if (value.split('.').length === 2) {
            currentSearchDate.setMonth(currentSearchDate.getMonth() + 1)
          }

          currentSearchDate.setDate(currentSearchDate.getDate() + 1)

          const lastDate = formatDate(currentSearchDate);


          context.state.historySearch = {
            type: 'date',
            params: {
              dt_start: firstDate,
              dt_end: lastDate,
            }
          }

        } else if (!isNaN(+noSpaceValue) && noSpaceValue.length < 16) {
          context.state.historySearch = {
            type: 'phone',
            params: {
              phone: +noSpaceValue,
            }
          }
        } else {
          context.state.historySearch = {
            type: 'text',
            params: {
              text: value,
            }
          }
        }

        context.state.historySearch.value = value

      }
    },

    // nq
    getAllTypes(context) {
      context.dispatch('getAllRules').then(() => {
        context.dispatch('getAllCategories')
        context.dispatch('getRoutesTypes')
        context.dispatch('getAllVars')
        context.dispatch('getDefaultChannels')
        context.dispatch('getAvailableChannels')
      })
    },
    async getAllRules(context) {

      return await axios({
        method: 'GET',
        url: `${window.apiPath}/rules`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        },
      }).then((res) => {
        console.log(res);
        context.state.types.rules = res.data.data
      }).catch((err) => {
        console.log(err);
      })
    },
    async getAllCategories(context) {
      return await axios({
        method: 'GET',
        url: `${window.apiPath}/categories`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        },
      }).then((res) => {
        console.log(res);
        context.state.types.categories = res.data.data
      }).catch((err) => {
        console.log(err);
      })
    }
    ,
    async getAvailableChannels(context) {
      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/list_channels`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        },
      }).then((res) => {
        context.commit('setAvailableChannels', res.data.data.channels)
      })
    },
    async getAllVars(context) {
      return await axios({
        method: 'GET',
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        },
        url: `${window.apiPath}/template-vars`
      }).then(res => {
        console.log(res);
        context.state.types.vars = res.data.data
      }).catch((err) => {
        console.log(err);
        context.commit('addDashboardNotification', {
          text: `Ошибка ${err.response.status} при загрузке переменных!`,
          type: 'err'
        })
      })
    },
    async getRoutesTypes(context) {
      return await axios({
        method: 'GET',
        url: `${window.apiPath}/routes`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        },
      }).then((res) => {
        console.log(res);

        context.state.types.routes = res.data.data
      }).catch((err) => {
        console.log(err);
      })
    },

    async setRout(context, obj) {
      const firstRoute = obj.rule.route
      obj.rule.route = obj.route
      return await axios({
        method: 'patch',
        url: `${window.apiPath}/rules/${obj.rule.uid}`,
        data: obj.rule,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        },
      }).then((data) => {
        console.log(data);

        context.commit('addDashboardNotification', {
          text: 'Успех!',
          type: 'ok'
        })

      }).catch(err => {
        obj.rule.route = firstRoute
        console.log(err);
        context.commit('addDashboardNotification', {
          text: 'Ошибка!',
          type: 'err'
        })
      })
    },

    async udpadeRuleField(context, obj = {
      field: '',
      rule: {},
      value: null
    }) {
      const firstRoute = obj.rule.route
      obj.rule[obj.field] = obj.value
      return await axios({
        method: 'put',
        url: `${window.apiPath}/rules/${obj.rule.uid}`,
        data: obj.rule,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        },
      }).then((data) => {
        console.log(data);

        context.commit('addDashboardNotification', {
          text: 'Успех!',
          type: 'ok'
        })

      }).catch(err => {
        obj.rule.route = firstRoute
        console.log(err);
        context.commit('addDashboardNotification', {
          text: 'Ошибка!',
          type: 'err'
        })
      })
    },
    async udpadeRule(context, rule) {
      console.log(rule);
      const firstRoute = rule

      let sendRule = structuredClone(rule)
      // delete sendRule.rule_templates

      // sendRule.templates = sendRule.templates.filter(obj => obj.value !== "" || !obj.uid.includes("new-template-"));

      // sendRule.templates.forEach(obj => {
      //   if (obj.uid.includes("new-template")) {
      //     delete obj.uid;
      //   }
      // });

      return await axios({
        method: 'put',
        url: `${window.apiPath}/rules/${rule.uid}`,
        data: sendRule,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        },
      }).then((res) => {
        console.log(res);

        context.state.types.rules.find(i => i.uid === rule.uid).templates = res.data.data.templates

        context.commit('addDashboardNotification', {
          text: 'Успех!',
          type: 'ok'
        })

      }).catch(err => {
        currentRule = {
          ...firstRoute
        }
        console.log(err);
        context.commit('addDashboardNotification', {
          text: 'Ошибка!',
          type: 'err'
        })
      })
    },
    async postRule(context, rule) {
      console.log(rule);
      const sendRule = structuredClone(rule.rule_templates)
      delete sendRule.rule_templates

      sendRule.templates = sendRule.templates.filter(obj => obj.value !== "" || !obj.uid.includes("new-template"));

      sendRule.templates.forEach(obj => {
          delete obj.uid;
      });

      delete sendRule.uid

      return await axios({
        method: 'post',
        url: `${window.apiPath}/rules`,
        data: sendRule,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        },
      }).then((data) => {
        console.log(data);

        rule.uid = data.data.result.uid

        rule.templates = data.data.result.templates

        context.state.types.rules.push(rule)

        context.commit('addDashboardNotification', {
          text: 'Успех!',
          type: 'ok'
        })

      }).catch(err => {

        console.log(err);
        context.commit('addDashboardNotification', {
          text: 'Ошибка!',
          type: 'err'
        })
      })
    },

    async getDefaultChannels(context) {
      return await axios({
        method: 'get',
        url: `${window.apiPath}/providers/channels`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        },
      }).then((res) => {
        context.commit('setDefaultChannels', res.data.data)
      })
    }
  },


  // "templates": [{
  //   "uid": "57224c68-419a-4b91-9155-b662d6ad2eaf",
  //   (этот шаблон обновляется)
  //   "channel": "whatsapp",
  //   "name": "test template",
  //   "value": "test template text",
  //   "customer": "8a31819d-29ef-4875-80e8-113bf36da5a3"
  // }, {
  //   "channel": "sms",
  //   (этот шаблон добавляется)
  //   "name": "test template 1",
  //   "value": "test template text 1",
  //   "customer": "8a31819d-29ef-4875-80e8-113bf36da5a3"
  // }]

  // textarea1.value.innerHTML = `Здравствуйте <img style="${style(4)}" src="${imageURL(4)}" />!<br>
  //      Напоминаем вам, что <img style="${style(0)}" src="${imageURL(0)}" />,<br>
  //      вы записаны к <img style="${style(5)}" src="${imageURL(5)}" />, на <img style="${style(7)}" src="${imageURL(7)}" />.<br>
  //      Ваша команда "Health & Beauty".`

  //     textarea2.value.innerHTML = `Здравствуйте <img style="${style(4)}" src="${imageURL(4)}" />!<br>
  //      Напоминаем вам, что <img style="${style(0)}" src="${imageURL(0)}" />,<br>
  //      вы записаны к <img style="${style(5)}" src="${imageURL(5)}" />, на <img style="${style(7)}" src="${imageURL(7)}" />.<br>
  //      Ваша команда "Health & Beauty".`

  //     textarea3.value.innerHTML = `Здравствуйте <img style="${style(4)}" src="${imageURL(4)}" />!<br>
  //      Напоминаем вам, что <img style="${style(0)}" src="${imageURL(0)}" />,<br>
  //      вы записаны к <img style="${style(5)}" src="${imageURL(5)}" />, на <img style="${style(7)}" src="${imageURL(7)}" />.<br>
  //      Ваша команда "Health & Beauty".`

}