<template>
  <confirm-popup
    :popupVisible="this.$store.state.profile.confirmPopupVisible"
    @confirming="
      () => {
        this.$store.state.profile.confirming = true;
        this.$store.dispatch('postOwnerInfo');
      }
    "
    @popupClose="this.$store.state.profile.confirmPopupVisible = false"
    @cancelConfirm="
      (owner.phone = lastOwnerData.phone),
        (this.$store.state.profile.confirmPopupVisible = false)
    "
    confirmString="сохранить"
  >
    Подтвердите, что вы хотите изменить номер телефона для входа <br />
    с <strong>{{ lastOwnerData.phone }}</strong> на
    <strong>{{ owner.phone }}</strong> ? <br />
    После сохранения будет выполнен выход из сервиса, и вам потребуется
    выполнить вход
  </confirm-popup>
  <!--<h1>Личный кабинет</h1>-->
  <div v-if="crmConnect" class="col sectionWrap" :class="{_loader: loadingProfile, 'base-padding': !this.$store.state.isFrameVersion}">
    <div class="">
      <h3 class="tit-h4 ">{{ text.profileTitle }}</h3>
      <p class="text-secondary fs-16">
        Аккаунт qoob.store #{{ license.acc_id }}
      </p>
    </div>
    <div class="d-flex justify-content-between contentWrap">
      <div class="mainWrapper me-5 w-50">
        <div>
          <h4 class="personal-title">Личные данные</h4>

          <!--        <change-picture-->
          <!--          class="change-picture mb-4"-->
          <!--          :title="text.personalData.title"-->
          <!--          :requirements-text="text.personalData.avatarDescriptionText"-->
          <!--          v-model="picture"-->
          <!--        ></change-picture>-->

          <CmInput
            class="mainInput"
            :label="'Имя'"
            v-model="owner.name"
            @input="this.$store.commit('setEditProfileData', true)"
          ></CmInput>

          <drop-menu class="drop" :itemSelected="itemSelected" v-if="false">
            <template v-slot:name>
              <p class="drop__label">Статус</p>
            </template>

            <template v-slot:text>{{
              profileStatuses[currentStatus].name
            }}</template>
            <template v-slot:item>
              <li
                class="drop__item-wrapper"
                v-for="(status, index) in profileStatuses"
                :key="status.id"
              >
                <button class="drop__item" @click="selectDrop('status', index)">
                  {{ status.name }}
                </button>
              </li>
            </template>
          </drop-menu>

          <CmInput
            type="number"
            class="mainInput"
            :label="'Номер телефона'"
            disabled
            v-model="owner.phone"
            @input="this.$store.commit('setEditProfileData', true)"
          ></CmInput>
          <CmInput
            class="mainInput"
            :label="'E-mail'"
            disabled
            v-model="owner.email"
            @input="this.$store.commit('setEditProfileData', true)"
          ></CmInput>
        </div>
        
      </div>
      <div class="rightColumn w-50" v-if="false">
        <div class="subtitle--second">
          <h4 class="mainTitle1 fw-600">{{ text.alertWhatsapp.title }}</h4>
          <p class="mainSubtitle fs-16" style="margin-top: 0.9rem">
            {{ text.alertWhatsapp.text }}
          </p>
        </div>
        <div>
          <PersonalAreaContact
            v-for="contact in contacts"
            :key="contact.id"
            :contact-prop="contact"
            @delete="contacts = contacts.filter((el) => el.id !== contact.id)"
          ></PersonalAreaContact>
          <PersonalAreaContact last></PersonalAreaContact>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div class="bottomBox" v-show="editProfileData">
        <div
          class="
            save-wrapper
            d-flex
            justify-content-end
            align-items-center
            h-100
          "
        >
          <div>
            <button-orange
              class="save-button"
              @click="this.$store.dispatch('postOwnerInfo')"
              >Сохранить</button-orange
            >
          </div>
        </div>
      </div>
    </transition>
  </div>

  <div v-else>
        <crmCyncError></crmCyncError>
  </div>

</template>

<script>
/* eslint-disable */
import CmInput from "../../components/UI/inputs/CmInput";
import PersonalAreaContact from "./PersonalArea/PersonalAreaContact";
import { mapState } from "vuex";
import dropMenu from "@/components/Dashboard/Billing/drop-menu";
import customCheckbox from "@/components/Dashboard/Billing/custom-checkbox";
import buttonTransparent from "@/components/UI/buttons/ButtonTransparent";
import buttonOrange from "@/components/UI/buttons/ButtonOrange";
import confirmPopup from "../../components/confirmPopup.vue";
import crmCyncError from '../../components/UI/crmCyncError.vue';



export default {
  name: "PersonaArea",
  components: {
    PersonalAreaContact,
    crmCyncError,
    CmInput,
    dropMenu,
    customCheckbox,
    buttonTransparent,
    buttonOrange,
    confirmPopup,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      text: (state) => state.texts.dashboard.personalArea,
      license: (state) => state.tarif.license,
      crmLicense: (state) => state.crm.crmLicense,
      owner: (state) => state.profile.owner,
      lastOwnerData: (state) => state.profile.lastOwnerData,
      editProfileData: (state) => state.profile.editProfileData,
      loadingProfile: (state) => state.profile.loadingProfile,
      crmConnect: state => state.crm.crmConnect
    }),
  },
  methods: {
    selectContactMethod(obj) {
      this.selectedContactMethodName = obj.name;
    },
    selectDrop(item, index) {
      if (item === "status") {
        this.currentStatus = index;
      } else {
        this.currentMethod = index;
      }
      this.itemSelected = true;

      setTimeout(() => {
        this.itemSelected = false;
      }, 300);
    },
  },
};
</script>

<style scoped>
.sectionWrap {
  position: relative;
  padding: 0;
}
.profileTitle {
  font-size: 26px;
  line-height: 27px;
  text-align: left;
}

.text-secondary {
  font-size: 16px;
  line-height: 24px;
}

.card-img {
  width: 146px;
  height: 146px;
  left: 0px;
  top: 50px;
}

.blank {
  position: fixed;
  top: 0;
  right: 0;

  height: 100%;
  width: calc(37% - 200px);
  padding-right: 60px;

  background-color: #f7f9fc;
}

.contentWrap {
  margin-top: 40px;
}

.drop__label {
  margin-bottom: 10px;

  font-size: 14px;
  color: #6d6d6d;
}

.drop__item-wrapper:hover {
  background-color: #f6f9fc;
}

.drop__item-wrapper:active {
  background-color: #f0f3f8;
}

.drop__item {
  display: flex;
  align-items: center;

  height: 45px;
  padding-left: 20px;

  font-size: 15px;
}

.checkbox-wrapper {
  display: flex;
  margin-bottom: 20px;
}
.checkbox__label {
  margin-left: 20px;

  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

.checkbox-area {
  margin-top: 35px;
}

.buttonTransparent {
  padding: 0.675rem 1.375rem;
}

.drop {
  margin-bottom: 25px;
}

.mainTitle {
  margin-top: 60px;

  font-size: 20px;
  line-height: 26px;
}

.mainTitle1 {
  font-size: 20px;
  line-height: 26px;
}

.mainSubtitle {
  margin-top: 20px !important;

  font-size: 16px;
  line-height: 30px;
}

.personal-title {
  display: none;
}

@media (max-width: 1440px) {
  .blank {
    display: none;
  }
}

@media (max-width: 1280px) {
  .profileTitle {
    font-size: 22px;
  }

  .text-secondary {
    font-size: 14px;
  }

  .mainWrapper {
    width: calc(50% - 20px) !important;

    margin-bottom: 60px;
  }

  .rightColumn {
    width: calc(50% - 20px) !important;
    margin: 0;
  }

  .mainTitle1 {
    font-size: 18px;
  }

  .mainTitle {
    font-size: 18px;
  }

  .mainSubtitle {
    font-size: 14px;
    line-height: 24px;
  }

  .checkbox__label {
    font-size: 13px;
  }

  .buttonTransparent {
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {
  .contentWrap {
    flex-direction: column;
  }

  .mainWrapper {
    width: 100% !important;
    margin-right: 0 !important;
  }

  .rightColumn {
    width: 100% !important;
  }

  .profileTitle {
    font-size: 24px;
  }

  .text-secondary {
    font-size: 16px;
  }

  .mainSubtitle {
    font-size: 16px;
  }

  .mainTitle {
    font-size: 20px;
  }

  .buttonTransparent {
    font-size: 16px !important;
  }

  .checkbox__label {
    font-size: 14px;
  }
}
</style>

<style scoped>
@media (max-width: 550px) {
  .contentWrap {
    display: block !important;
    margin-top: 35px;

    padding-bottom: 130px;
  }

  .mainWrapper {
    width: 100% !important;
    min-width: auto;
    margin-right: 0 !important;
    margin-bottom: 0;
  }

  .rightColumn {
    min-width: auto;
  }

  .personal-title {
    display: block;
    margin-bottom: 30px;

    font-size: 20px;
    line-height: 26px;
  }

  .change-picture {
    display: none;
  }

  .sectionWrap {
    position: relative;
    padding: 0;
  }

  .profileTitle {
    margin-bottom: 10px;

    font-size: 20px;
    line-height: normal;
  }

  .text-secondary {
    font-size: 16px;
  }

  .mainInput {
    margin-bottom: 30px;
  }

  .drop {
    margin-bottom: 30px;
  }

  .mainTitle {
    margin-top: 30px !important;
  }

  .mainTitle1 {
    margin: 0;
  }

  .mainSubtitle {
    font-size: 14px;
    line-height: 24px;
  }

  .buttonTransparent {
    width: 100%;
    height: 48px;
    padding: 0;
    margin-top: 15px !important;
    margin-bottom: 35px;
  }

  .rightColumn {
    width: 100% !important;
    margin: 0 !important;
  }

  .save-wrapper {
    display: block !important;
  }

  .save-button {
    width: 100%;
    height: 48px;
  }

  .subtitle--second {
    padding-bottom: 20px;
    border-bottom: 1px solid #eff0f0;
  }
}
</style>