import axios from 'axios'
import $router from '@/router'

// http://localhost:8080/registration?salon_id=27326&user_data=eyJpZCI6MTIwNTQxNTEsIm5hbWUiOiLQmtC40YDQuNC70LsiLCJwaG9uZSI6Ijc5MTU1OTY3MzQ0IiwiZW1haWwiOiJraXJpbGx6YXl0c2V2NjVAZ21haWwuY29tIn0%3D&user_data_sign=06f272b44ff3b23d8aa6b6b17fd2736c91fed48fb94fe8f2d5d18ad7983243ee

export default {
  state: () => ({
    regOneClickLoading: false,
    sendToLoginPhone: '',
    supportLogin: {
      login: '',
      password: ''
    },
    supportLoginLoading: false,

    isRegistered: false,

  }),
  getters: {
    // getSalonId() {
    //   return localStorage.getItem('salon_id')
    // },
    isAuth() {
      if (localStorage.getItem('authToken') && localStorage.getItem('authTokenType') && localStorage.getItem('auth') === 'auth') {
        return true
      } else {
        return false
      }
    }
  },
  mutations: {
    setsendToLoginPhone(state, phone) {
      state.sendToLoginPhone = phone
    },
    setSupportLoginLoading(state, value) {
      state.supportLoginLoading = value
    },
    setIsRegistered(state, value) {
      state.isRegistered = value
    }
  },
  actions: {
    async sendLoginCode(context, telephone) {
      return await axios
        .get(`${window.apiPath}/user/auth/code?phone=${telephone}`)
    },
    async finishLogin(context, loginObj) {
      let salon_id = localStorage.getItem('salon_id')
      let url = ''
      let data = ''

      if (loginObj.salon_id) {
        url = `${window.apiPath}/user/auth/access/token?salon_id=${salon_id}`
        data = `username=${loginObj.telephone}&password=${loginObj.code}&salon_id=${salon_id}`
      } else {
        url = `${window.apiPath}/user/auth/access/token`
        data = `username=${loginObj.telephone}&password=${loginObj.code}`
      }
      return await axios
        .post(
          url,
          data,
        )
        .then((data) => {
          console.log(data);
          if (data.data.access_token && data.data.token_type) {
            localStorage.setItem("isAuth", "auth");
            localStorage.setItem("authToken", data.data.access_token);
            localStorage.setItem("authTokeType", data.data.token_type);
            context.dispatch('timeline', 'клиент авторизовался')
            setTimeout(() => {
              location.reload();
            }, 200);
          }

        })

    },
    async sendRegisterCode(context, telephone) {
      return await axios
        .get(`${window.apiPath}/user/register/code/${telephone}`)
    },
    async finishRegister(context, regObj) {
      let salon_id = localStorage.getItem('salon_id')
      let url = ''
      if (salon_id) {
        url = `${window.apiPath}/user/register?salon_id=${salon_id}`
        localStorage.setItem('salon_id', salon_id)
      } else {
        url = `${window.apiPath}/user/register`
      }
      return await axios
        .post(url, regObj)
        .then((response) => {
          console.log(response);
          console.log(response.status);
          if (response.data.data.access_token && response.data.data.token_type) {
            localStorage.setItem("authToken", response.data.data.access_token);
            localStorage.setItem("authTokeType", response.data.data.token_type);
            localStorage.setItem("isAuth", "auth");
            context.dispatch('timeline', 'клиент зарегистрировался')
          }
          setTimeout(() => {
            location.reload();
          }, 200);
        })
    },
    async supportLogin(context) {
      context.commit('setSupportLoginLoading', true)
      return await axios({
          method: 'post',
          url: `${window.apiPath}/user/support/login`,
          data: `username=${context.state.supportLogin.login}&password=${context.state.supportLogin.password}`
        })
        .then((response) => {
          console.log(response);
          console.log(response.status);
          localStorage.setItem("isAuth", "auth");
          localStorage.setItem("authToken", response.data.access_token);
          localStorage.setItem("authTokeType", response.data.token_type);
          location.reload()
          console.log(response);
          context.commit('setSupportLoginLoading', false)
        }).catch((err) => {
          console.log(err);
          context.commit('setSupportLoginLoading', false)
        })
    },
    sendToLogin(context, phone) {
      console.log(phone);
      context.commit('setsendToLoginPhone', phone);

      $router.push('/login');
    },
    logout() {
      localStorage.setItem("isAuth", "notAuth");
      localStorage.removeItem("authToken");
      localStorage.removeItem("authTokenType");

      setTimeout(() => {
        location.reload();
      }, 200);

      console.log('logout');
    },
    async regCheck(context, phone) {
      return await axios({
        method: 'post',
        url: `${window.apiPath}/user/check/${phone}`,
      })
    },
    checkAuth(context) {
      axios({
        method: 'get',
        url: `${window.apiPath}/crm/status`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          'accept': 'application/json'
        }
      }).then((request) => {
        console.log('api/v2/crm/status текущая компания -', request.data.data);
      }).catch(err => {
        if (err.response.data.detail === "Not authenticated") {
          context.dispatch('logout')
        }
      })
    },
    /* eslint-disable */
    async registerOneClick(context) {
      context.commit('setLoaderText', {text: 'Выполняем вход', percent: 5, isLoading: true})
      context.state.regOneClickLoading = true;
      let params = window.location.search
        .replace("?", "")
        .split("&")
        .reduce(function (p, e) {
          let a = e.split("=");
          p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
          return p;
        }, {});

      console.log(params);

      return await axios({
        method: 'POST',
        url: `${window.apiPath}/user/register/from/crm`,
        data: {
          salon_id: params['salon_id'],
          user_data: params['user_data'],
          sign: params['user_data_sign'],
          "crm_name": "yclients"
        }
      }).then((request) => {

        if (request.data.data.access_token && request.data.data.token_type) {
          localStorage.setItem("isAuth", "auth");
          localStorage.setItem("authToken", request.data.data.access_token);
          localStorage.setItem("authTokeType", request.data.data.token_type);
          // context.dispatch('timeline', 'клиент )
          setTimeout(() => {
            // location.reload();
            context.dispatch('baseResponses')
            $router.push('/dashboard/iframe/billing')
          }, 200);

          // context.state.regOneClickLoading = false;
        } else if (request.data.error.message) {
          context.commit('addDashboardNotification', {type: 'err',text: request.data.error.message})
        }

      }).catch((err) => {

        context.commit('addDashboardNotification', {type: 'err',text: 'Ошибка'})

        if (err.response.status === 500) {
          this.$store.commit("setErr500popupOpen", true);
        } 
        // context.state.regOneClickLoading = false;
      })
    }
  },

}