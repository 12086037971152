<template>
  <div class="YMetrika-settings">
    <div class="tit-h5"></div>

    <div class="step-check-box">
      <div class="tit-h5">Yandex metrika</div>

      <custom-checkbox
        @toggleCheckbox="toggleMetrikaBlock"
        :defaultState="yandexMetrikaSettings.active"
        :item="{ state: yandexMetrikaSettings.active }"
        index=""
      />
    </div>

    <div class="YMetrika-settings__block" v-if="yandexMetrikaSettings.active">
      <div class="tit-h6">
        Url адрес, который нужно указать в настройках счетчика
      </div>
      <input
        type="text"
        class="default-input _small mb-3"
        value="tgapp-ipl6puj01s.qoob.store"
        disabled
      />

      <div>
        <div class="tit-h6">Скрипт счетчика *</div>
        <textarea
          placeholder=""
          name=""
          id=""
          class="default-textarea "
          :class="{invalid: !$store.state.telegram.steps.allStepsSettings.yandexMetrika.script}"
          v-model="$store.state.telegram.steps.allStepsSettings.yandexMetrika.script"
        ></textarea>
      </div>

      <div class="tit-h6">Доступные цели</div>
      <div class="metriks-table">
        <div class="metriks-table__row">
          <div class="metriks-table__col">Название</div>
          <div class="metriks-table__col">Описание</div>
          <div class="metriks-table__col">
            Идентификатор YM (без пробелов и знака "+")
          </div>
          <div class="metriks-table__col">Вкл/выкл</div>
        </div>
        <div
          v-for="(metrika, index) in yandexMetrikaSettings.metriks"
          :key="metrika.idYM"
          class="metriks-table__row"
        >
          <div class="metriks-table__col">{{ metrika.name }}</div>
          <div class="metriks-table__col">{{ metrika.description }}</div>
          <div class="metriks-table__col">{{ metrika.idYM }}</div>
          <div class="metriks-table__col">
            <custom-checkbox
              :defaultState="metrika.active"
              :item="{ state: metrika.active }"
              :index="index"
              @toggleCheckbox="toggleMetrikaItem"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import customCheckbox from "../../components/Dashboard/Billing/custom-checkbox.vue";

const store = useStore();

const yandexMetrikaSettings = computed(
  () => store.state.telegram.steps.allStepsSettings.yandexMetrika
);

function toggleMetrikaBlock(event) {
  store.state.telegram.steps.allStepsSettings.yandexMetrika.active =
    event.state;
}
function toggleMetrikaItem(event) {
  store.state.telegram.steps.allStepsSettings.yandexMetrika.metriks[event.index].active = event.state
}
</script>

<style lang="scss" scoped>
.YMetrika-settings {
  &__block {
    padding-left: 10px;
    min-height: 150px;

    display: flex;
    flex-direction: column;

    .default-textarea {
      min-height: 150px;
    }
  }
}
.metriks-table {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px rgba(0, 0, 0, 0.3);
    &:nth-child(1) {
      border-top: solid 1px rgba(0, 0, 0, 0.3);
    }
  }
  &__col {
    border-left: solid 1px rgba(0, 0, 0, 0.3);
    font-size: 12px;
    padding: 5px;
    &:nth-child(1) {
      flex-basis: 20%;
    }
    &:nth-child(2) {
      flex-basis: 50%;
    }
    &:nth-child(3) {
      flex-basis: 20%;
    }
    &:nth-child(4) {
      flex-basis: 10%;
      border-right: solid 1px rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
