<template>
  <transition name="fade">
    <div class="v-popup" v-if="visible">
      <div class="v-popup__bg" @click="this.$emit('close')"></div>
      <div
        class="v-popup__content v-scroll"
        :style="`max-width: ${maxWidth}; overflow: ${overflow}`"
      >
        <close-button
          :closeType="1"
          class="confirm-popup__close"
          @click="this.$emit('close')"
          v-if="closeBtn"
        />
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import closeButton from "../Dashboard/Billing/close-button.vue";
export default {
  props: {
    visible: Boolean,
    closeBtn: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      default: "500px",
      type: String,
    },
    overflow: {
      type: String,
      default: "none",
    },
  },
  components: {
    closeButton,
  },
  mounted() {
    window.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        this.$emit("close");
      }
    });
  },
};
</script>

<style scoped>
.v-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  user-select: text;
}

.v-popup__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}

.v-popup__content {
  position: relative;
  z-index: 8;
  width: 95%;
  max-height: 90vh;
  padding: 30px;
  font-size: 18px;
  border-radius: 12px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 25%;

  background: #fff;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.431));
}

@media (max-width: 567px) {
  .v-popup__content {
    padding: 10px;
  }
}
.v-popup__close {
  padding: 10px;
  position: absolute;
  top: 0;
  right: 5px;
}
</style>