<template>
  <div class="wrapInput">
    <div class="d-flex justify-content-between inputHeader">
      <div>
        <label class="main-label" v-if="label">{{ label }}</label>
      </div>
      <img v-if="required" src="@/assets/info-circle.svg" alt="!" />
    </div>
    <div class="tel-input__wrapper" :class="{ 'codes-open': codeListOpen }">
      <input
        :id="id"
        @input="updateInput"
        type="text"
        v-maska="maska"
        :placeholder="placeholder"
        v-model="tel"
        :ref="`telInput${id}`"
        class="tel-input"
      />
    </div>
  </div>
</template>

<script>
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
export default {
  name: "tel-input",
  data() {
    return {
      telCode: "+7",
      tel: "",
      codes: [],
      iti: {},
      codeListOpen: false,
      placeholder: "",
      maska: "",
    };
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    id: {
      required: true,
      type: String,
    },
    modelValue: {
      type: String,
      default: "",
    },
    country: {
      type: String,
      default: "RU",
    },
    fill: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: Array,
      required: false,
    },
  },
  methods: {
    hideCodes() {
      setTimeout(() => {
        this.selectCodes();
      }, 200);
    },
    updateInput() {
      this.getTelPlaceholder();
      this.getTelLenght();

      this.$emit("tel", [
        `${this.telCode} ${this.tel}`,
        this.telCode,
        this.tel,
      ]);
      if (this.fill) {
        this.$emit(
          "update:modelValue",
          `${this.telCode} ${this.tel}`.replace(/\s/g, "").slice(1)
        );
      } else {
        this.$emit("update:modelValue", `${this.tel}`);
      }
    },
    setValue(valueArr) {
      this.iti.setCountry(valueArr[0]);
      this.tel = valueArr[1];

      this.updateInput();
    },
    getTelLenght() {
      const length = 11 - (this.telCode.length - 1);

      // 11 - 1 = 10
      // 11 - 2 = 9
      // 11 - 3 = 8
      if (length === 10) {
        this.maska = "### ### ## ##";
      } else if (length === 9) {
        this.maska = "### ### #####";
      } else if (length === 8) {
        this.maska = "### ### ####";
      }
    },
    getTelPlaceholder() {
      const length = 11 - (this.telCode.length - 1);

      // 11 - 1 = 10
      // 11 - 2 = 9
      // 11 - 3 = 8
      if (length === 10) {
        this.placeholder = "... ... .. ..";
      } else if (length === 9) {
        this.placeholder = "... ... ....";
      }
      if (length === 8) {
        this.placeholder = "... ... ....";
      }
    },
    focus() {
      this.$refs[`telInput${this.id}`].focus();
    },
  },
  mounted() {

    this.getTelPlaceholder();
    this.getTelLenght();

    this.tel = this.modelValue;
    const input = document.querySelector(`#${this.id}`);
    const iti = intlTelInput(input, {
      separateDialCode: true,
    });
    iti.setCountry(this.country);
    input.addEventListener("countrychange", () => {
      this.telCode = `+${iti.selectedCountryData.dialCode}`;
      this.$emit("countryChange", iti.selectedCountryData.iso2);
      this.updateInput();
    });

    this.iti = iti;
  },
};
</script>

<style scoped>
/* tel codes style */

.tel-input__wrapper {
  font-family: "Graphik LCG";
  flex-direction: row;
  background: #f6f9fc;
  border-radius: 0.75rem;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  color: #000000;
  position: relative;
}
.tel-input__wrapper {
  display: flex;
}
.tel-input__wrapper.codes-open {
  border-bottom-left-radius: 0px;
}
.tel-input__list {
  line-height: 1;
  position: absolute;
  left: 0;
  top: 100%;
  /* display: flex; */
  flex-direction: column;
  min-width: 30%;
  max-height: 350px;
  overflow: auto;
  background: #f6f9fc;
  z-index: 9999;
  display: none;
}
.tel-input__list.open {
  display: flex;
}
.tel-input__item {
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  text-align: left;
  cursor: pointer;
}
.tel-input__item:hover {
  background: #dbdddf;
}
.code {
  font-size: 14px;
  font-weight: 500;
}
label {
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #6d6d6d;
  margin-bottom: 0.125rem;
}
input {
  padding: 0.75rem 1.25rem 0.75rem;
  font-size: 1rem;
  flex-grow: 1;
  background: transparent;
  border: none;
}
input:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}
input::placeholder,
input::-webkit-input-placeholder {
  color: #000;
}

.invalid .tel-input__wrapper {
  border: solid 1px #dd6068 !important;
  color: #dd6068 !important;
}
.invalid label,
.invalid .tel-input__value {
  color: #dd6068 !important;
}
.invalid input::placeholder {
  color: #dd6068 !important;
  font-weight: 500;
}
.invalid input {
  color: #dd6068 !important;
}

.main-label {
  margin-bottom: 5px;
  font-size: 14px;
}

.tel-input {
  height: 48px;
  padding: 12px 20px;

  border-radius: 0 12px 12px 0;

  font-size: 16px;
}
</style>
