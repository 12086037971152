<template>
  <div :class="{ '_loader-corner': loadingWhatsappStatus }">
    <cm-card height="100%" class="itemSecond whatsappCard" @click="$router.push('/dashboard/whatsapp')" :specialClass="'status-card'">
      <div class="d-flex justify-content-between">
        <div
          :style="{
            'background-image':
              'url(' + require('@/assets/whatsappLogo.png') + ')',
          }"
          class="servicesWhatsappLogo"
        ></div>
        <div></div>
      </div>
      <div class="servicesMiniText" v-if="crmConnect && licenseActive">
        <div v-if="!noToken">
          <div class="d-flex" v-if="whatsAppActive !== null">
            <p class="w-50" >Статус</p>
            {{ whatsAppActive ? "Активен" : "Не активен" }}
            <!-- {{ whatsappStatus.acc_id ? whatsappStatus.acc_id : "-" }} -->
          </div>
          <div class="d-flex" v-if="whatsAppActive !== null">
            <p class="w-50">Оплачен до</p>
            <p>
              {{ license.date }}
            </p>
          </div>
        </div>
        <div v-else>
          Токен не найден, обратитесь в <a href="https://t.me/qoob_store_support_bot" target="_blank" class="link">поддержку qoob.store</a>  
        </div>
      </div>
      <div class="servicesMiniText" v-else>
        <div>
          <p>Не активирован</p>
        </div>
      </div>

      <status-icon
        :loader="whatsAppActive === null"
        v-if="!loadingWhatsappStatus"
        class="card-icon-status"
        :type="typeIcon"
      ></status-icon>
    </cm-card>

    
  </div>
</template>

<script>
import CmCard from "@/components/UI/CmCard";
import statusIcon from "@/components/UI/status-icon";
// import toggleComponent from "@/components/Dashboard/Segments/popup-components/toggle-component";
import { mapState } from "vuex";

export default {
  name: "ServicesWhatsApp",
  components: { CmCard, statusIcon },
  data: function () {
    return {
      active: false,
      popupState: false,
    };
  },
  computed: {
    ...mapState({
      content: (state) => state.texts.dashboard.services.whatsApp,
      whatsappStatus: (state) => state.whatsapp.whatsappStatus,

      whatsAppAvailable: (state) => state.whatsapp.whatsappStatus.isAvailable,
      crmConnect: (state) => state.crm.crmConnect,
      licenseActive: (state) => state.tarif.license.isActive,
      license: (state) => state.tarif.license,
      licenseExisted: (state) => state.tarif.licenseExisted,
      loadingWhatsappStatus: (state) => state.whatsapp.loadingWhatsappStatus,

      whatsappWebSoket: (state) => state.whatsapp.whatsappWebSoket,
      whatsAppActive: (state) => state.whatsapp.whatsappWebSoket.auth,
      noToken: (state) => state.whatsapp.noToken,
    }),
    typeIcon() {
      if (!this.crmConnect || !this.licenseActive) {
        return 0;
      } else {
        if (this.whatsAppActive) {
          return 2;
        } else {
          return 1;
        }
      }
    },
  },
  methods: {
    
  },
};
</script>
<style>
.card-icon-status {
  position: absolute;
  width: 40px;
  height: 40px;
}
@media (min-width: 1500px) {
  .card-icon-status {
    top: 19px;
    right: 18px;
  }
  .card-icon-status svg {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 1500px) and (min-width: 1024px) {
  .card-icon-status {
    top: 11px;
    right: 0px;
  }
  .card-icon-status svg {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 1023px) {
  .card-icon-status {
    top: 9px;
    right: 9px;
  }
  .card-icon-status svg {
    width: 40px !important;
    height: 40px !important;
  }
}
</style>
<style scoped>
.servicesWhatsappLogo {
  height: 28px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}
.whatsappCard {
  cursor: pointer;
}
.servicesMiniText {
  margin-top: 25px;

  font-size: 14px;
  line-height: 24px;
}
.itemSecond {
  height: 100% !important;
  padding: 25px;
}

@media (max-width: 1600px) and (min-width: 1024px) {
  .itemSecond {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .servicesMiniText {
    margin-top: 15px;

    font-size: 12px;
    line-height: 20px;
  }

  .servicesWhatsappLogo {
    height: 20px;
  }
}

@media (max-width: 1280px) and (min-width: 1024px) {
  .itemSecond {
    padding: 15px 20px;
  }

  .servicesMiniText {
    margin-top: 10px;

    font-size: 10px;
    line-height: 18px;
  }

  .servicesWhatsappLogo {
    height: 16px;
  }
}

@media (max-width: 1023px) {
}

@media (max-width: 768px) and (min-width: 551px) {
  .itemSecond {
    padding: 15px 25px;
  }

  .servicesMiniText {
    margin-top: 15px;

    font-size: 12px;
    line-height: 24px;
  }

  .servicesWhatsappLogo {
    height: 22px;
  }
}

@media (max-width: 550px) {
  .servicesMiniText {
    margin-top: 15px;

    font-size: 12px;
    line-height: 20px;
  }

  .servicesWhatsappLogo {
    height: 22px;
  }
}
</style>
