import {
  createStore
} from 'vuex'
import tarifModule from '@/store/tarifModule'
import crmModule from '@/store/crmModule'
import authModule from './authModule'
import invoiceModule from './invoiceModule'
import profileInfo from './profileModule'
import documentsModule from './documentsModule'
import whatsappModule from './whatsapp/whatsappModule'
import axios from 'axios'
import smsModule from './smsModule'
import rfmModule from './rfmModule'
import mobileSettingsModule from './mobileSettingsModule'
import notificationModule from './notificationModule'
import dashboardModule from './dashboardModule'
import telegramModule from './telegram/telegramModule'
import guideModule from './guideModule'
import reviewModule from './reviewModule'

export default createStore({
  state: {
    version: '3.30.1',
    windowWidth: 0,
    count: 0,
    isFrameVersion: true,
    allLoader: true,
    loaderStatus: {
      percent: 20,
      text: 'Загрузка',
      isLoading: true
    },
    texts: {
      dashboard: {
        services: {
          name: "Статус сервисов",
          fullName: "Статус сервисов qoob.store",
          welcome: {
            title: "Добро пожаловать в сервис qoob.store",
            text: `«кьюб стор» или «куб стор» - появился благодаря одной простой идее:–
            Постоянные клиенты покупают больше и чаще

            Сервис qoob.store сегментирует вашу клиентскую базу по технологии RFM,
            и персонализирует общение с вашими клиентами доставляя им уведомления по« адресу»

            В паре с qoob.store работает приложение qoob

            Начните переводить клиентов из поисковых сетей в безопасное приложение для онлайн записи.

            Удержание клиентов гораздо выгоднее привлечения новых.
            `,
            btnText: "Прочитано"
          },
          crm: {
            notSync: {
              img: "/static/crm.svg",
              text: "Для начала синхронизируйте личный кабинет сервиса qoob.store с вашей CRM системой.",
              btnText: "Синхронизировать"
            },
            sync: {
              crm: "CRM",
              accId: "Аккаунт",
              expiry: "Лицензия",
              status: "Статус"
            },
            syncNotActive: {
              accId: "Аккаунт",
              expiry: "Лицензия",
              status: "Статус"
            }
          },
          qoobStore: {
            img: "/static/qoob.store.svg",
            notActive: {
              text: "Активируйте сервис qoob.store выбрав подходящий вам тариф.",
              btnText: "Активировать"
            },
            active: {
              accId: "Аккаунт",
              expiry: "Лицензия",
              status: "Статус",
              expiryBtnText: "Подробнее"
            }
          },
          qoobApp: {
            img: "/static/qoob.app.svg",
            notConfigured: {
              text: `Сразу после активации лицензии сервиса qoob.store, клиенты филиала смогут записываться через мобильное приложение qoob.`,
              btnText: "Настроить"
            },
            configured: {
              userCount: "Установок"
            }
          },
          whatsApp: {
            img: "/static/whatsappLogo.png",
            title: "WhatsApp",
            accId: "Ваш аккаунт ID",
            expiry: "Оплачен по"
          },
          sms: {
            img: "/static/sms.png",
            title: "Агрегатор"
          },
          clientFlow: {
            title: "Поток клиентов",
            btnText: "Подробнее"
          },
          segmentStatistic: {
            title: "Статсистика сегментов",
          }
        },
        guide: {
          name: "Гид по настройке",
          fullName: "Добро пожаловать в сервис qoob.store",
        },
        sms: {
          fullName: 'Настройки sms-агрегатора'
        },
        telegram: {
          fullName: 'Настройки telegram'
        },
        whatsapp: {
          fullName: 'Настройки WhatsApp'
        },
        mobileApp: {
          name: "Приложение",
          fullName: "Настройки мобильного приложения",
        },
        notifications: {
          name: "Уведомления",

          fullName: "Сегменты RFM",

          segments: {
            name: "Сегменты"
          },
          types: {
            name: "Типы"
          },
          history: {
            name: "История"
          },
        },
        billing: {
          name: "Биллинг",
          fullName: "Биллинг",
          license: {
            name: "Лицензия"
          },
          payments: {
            name: "Оплаты"
          }
        },
        settings: {
          name: "Настройки",
          fullName: "Настройки",
          title: "Настройки подключаемых сервисов qoob.store",
          services: [{
              name: "crm",
              img: '/static/yclientsLogo.png',
              text: "Настройка, подключение и синхронизация Вашей CRM системы с сервисом qoob.store",
              btnText: "Настройки",
              component: "CrmSettings"
            },
            {
              name: "whatsapp",
              img: "/static/whatsappLogo.png",
              text: "Подключение канала мессенджера WhatsApp",
              btnText: "Настройки",
              component: "WhatsAppSettings"
            },
            {
              name: "sms",
              img: "/static/smsAgregatorLogo.png",
              text: "Подключение СМС Агрегатора к каскаду уведомлений",
              btnText: "Настройки",
              component: "SmsSettings"
            },
            // {
            //   name: "push",
            //   img: "/static/pushLogo.png",
            //   text: "Настройка Push уведомлений приложения qoob",
            //   btnText: "Настройки",
            //   component: "CrmSettings"
            // },
            // {
            //   name: "vk",
            //   img: "/static/vkLogo.png",
            //   text: "Подключение каскада уведомлений соцсети Vkontakte",
            //   btnText: "Настроить",
            //   component: "CrmSettings"
            // },
            // {
            //   name: "fb",
            //   img: "/static/facebookLogo.png",
            //   text: "Подключение каскада уведомлений соцсети Facebook",
            //   btnText: "Настроить",
            //   component: "CrmSettings"
            // },
            // {
            //   name: "insta",
            //   img: "/static/instagramLogo.png",
            //   text: "Подключение каскада уведомлений соцсети Instagram",
            //   btnText: "Настроить",
            //   component: "CrmSettings"
            // }
          ]
        },
        personalArea: {
          name: "Личный кабинет",
          fullName: "Личный кабинет пользователя",
          contact: {
            title: 'Контакт',
            phoneText: 'Номер телефона WhatsApp',
            positionText: "Должность",
            noticesTexts: {
              text1: "Отправлять предупреждения",
              text2: "Отправлять новости",
              text3: "Отправлять уведомления о лицензии"
            },
            btnContactAdd: "Добавить новый контакт",
            btnContactDel: "Удалить контакт"
          },
          profileTitle: "Профиль Владельца аккаунта",
          personalData: {
            title: "Личные данные",
            avatarDescriptionText: "Краткая информация (технические требования) о формате, размере и соотношении сторон.",
            btnAvatarLoadText: "Загрузить",
            btnAvatarDelText: "Удалить",
            secondNameText: "Фамилия",
            firstNameText: "Имя",
            patronimText: "Отчество",
            statusText: "Статус",
            whatsappNumberText: "Личный номер телефона для связи на котором есть WhatsApp",
            emailText: "Личные e-mail, для связи",
            preferConnectText: "Предпочтительный способ связи"
          },
          serviceNotification: {
            title: "Уведомления о работе сервиса",
            text: "Как вы хотите получать информацию о новых функциях сервиса qoob.store и обучении?",
            noticesTexts: {
              text1: "На личный номер",
              text2: "На личный email"
            },
            btnSubcribeTelegramText: "Подписаться на телеграм канал"
          },
          btnSaveChangesText: "Сохранить изменения",
          alertWhatsapp: {
            title: "Экстренные оповещения WhatsApp",
            text: "Укажите номер WhatsApp для экстренных уведомлений собственника бизнеса, управляющего и (или) старшего администратора."
          }
        },

      },
      components: {
        whatsAppSettings: {
          title: "Подключение канала WhatsApp",
          instruction: {
            title: "Инструкция",
            subtitle: "Инструкция по подключению канала WhatsApp к каскаду уведомлений.",
            description: "Являясь всего лишь частью общей картины, предприниматели в сети интернет лишь добавляют фракционных разногласий и указаны как претенденты на роль ключевых факторов."
          },
          status: {
            img: "/static/whatsappLogo.png",
            accId: "Ваш аккаунт ID",
            expiry: "Оплачен до",
            status: "Статус —",
            imgActive: "/static/wa-status-ok.svg",
            imgInactive: "/static/wa-status-inactive.svg",
          },
          licenseNotExists: {
            title: "Внимание!",
            text: "Мы не нашли у Вас ниодной активной лицензии на использование сервиса доставки уведомлений через WhatsApp.\nДля начала работы, необходимо приобрести лицензию сервиса qoob.store",
            btnText: "Выбрать лицензию",
          },
          licenseExists: {
            notAuth: {
              agreeText: "Внимание! Нажимая на кнопку привязать сущкствующий канал WhatsApp все уведомления из текущего филиала будут поступать именно на этот номер.",
              btnAgreeText: "Привязать существующий",
              instructionText: `
              <p>Для отправки и приема сообщений Вам необходимо авторизовать наш сервер как WhatsApp Web.</p>
              <li>Откройте WhatsApp на телефоне</li> 
              <li>Нажмите “Настройки” > WhatsApp WEB > Добавить</li>
              <li>Просканируйте код и подождите 1 минуту</li>
              <li>Держите телефон подключенным к интернету</li>`,
              qrText: "Код доступен для сканирования в течении 1 минуты после загрузки. Отправка сообщений будет доступна сразу после авторизации."
            },
            auth: {
              text: "Ваш аккаунт WhatsApp подключен.",
              img: "/static/whatsappOk.jpg",
              btnDisable: "Отключить",
              btnReconfig: "Перенастроить"
            }

          }
        },
        smsSettings: {
          title: "Подключение канала SMS",
          instruction: {
            title: "Инструкция",
            subtitle: "Инструкция по подключению канала WhatsApp к каскаду уведомлений.",
            description: "Являясь всего лишь частью общей картины, предприниматели в сети интернет лишь добавляют фракционных разногласий и указаны как претенденты на роль ключевых факторов."
          },
          status: {
            img: "/static/sms.svg",
            aggregator: "Аггрегатор",
            status: "Статус",
            imgActive: "/static/wa-status-ok.svg",
            imgInactive: "/static/wa-status-inactive.svg",
          },
        }
      }
    },
    error: {},
    announcement: false,
    supportPopupOpen: false,
    errPopup: {
      open: false,
      status: 0,
    },
    errYclientsPopupOpen: false,
    differentVersions: false,

    visibleApp: true,
    welcomePopupVisible: true,
  },

  getters: {
    setAnnouncement() {
      console.log(localStorage.getItem('announcement') === 'yes');
      return localStorage.getItem('announcement') === 'yes'
    },
    isLocalHost() {
      return location.href.includes('localhost')
    }
  },
  mutations: {
    setAnnouncement(state, value) {
      state.announcement = value;
    },

    setLoaderText(state, status) {
      state.loaderStatus = status
    },

    setError(state, errorText) {
      state.error = {
        ...errorText
      }
    },

    errYclientsPopupOpen(state, value) {
      state.errYclientsPopupOpen = value
    },

    toggleStartPopup(state, val) {
      state.startPagePopup = val;
    },

    updateWindowWidth(state, val) {
      state.windowWidth = val;
    },
    hideSupportPopup(state) {
      state.supportPopupOpen = false
    },
    visibleSupportPopup(state) {
      state.supportPopupOpen = true
    },
    setWelcomeText(state, texts) {
      state.texts.dashboard.services.welcome.title = texts.title;
      state.texts.dashboard.services.welcome.text = texts.content;
    },
    setErrPopup(state, value) {
      state.errPopup = value
    },
    setDifferentVersions(state, value) {
      state.differentVersions = value
    },
    setIsFrameVersion(state, value) {
      state.isFrameVersion = value
    },

    setAllLoader(state, value) {
      state.allLoader = value
    }
  },
  actions: {
    setAnnouncement(context, value) {
      if (value) {
        context.commit('setAnnouncement', true);
        localStorage.setItem('announcement', 'yes')
      } else {
        context.commit('setAnnouncement', false);
        localStorage.setItem('announcement', 'no')
      }
    },
    baseResponses(context) {
      context.commit('setLoaderText', {
        text: 'Получаем данные профиля',
        percent: 10,
        isLoading: true
      })
      context.commit('setAllLoader', true)

      context.commit('setLoadingCrmStatus', true)
      context.commit('setLoadingSmsStatus', true)
      context.commit('setLoadingWhatsappStatus', true)
      context.commit('telegram/setLoadingTelegram', true)
      context.commit('setInflowOutflowLoading', true)
      context.commit('setChartsCircleLoading', true)

      context.dispatch("getAllDocs");

      context.dispatch('getActualVersion').then(() => {
        context.commit('setLoaderText', {
          text: 'Проверяем версию',
          percent: 20,
          isLoading: true
        })
        if (context.state.version !== context.getters.getActualVersion) {
          context.commit('setDifferentVersions', true)
        }
      })

      if (localStorage.getItem('announcement')) {
        if (localStorage.getItem('announcement') === 'no') {
          context.dispatch('flatPages', {
            url: 'mission',
            mutation: 'setWelcomeText',
          })
        }
      } else {
        context.dispatch('flatPages', {
          url: 'mission',
          mutation: 'setWelcomeText',
        })
      }

      context.dispatch('getCrmStatus').then(() => {
        const crmConnct = context.state.crm.crmConnect;

        context.dispatch("getCrmSystems").then(() => {
          context.commit('setLoadingCrmStatus', false)
        })

        console.log(`crm: ${crmConnct ? 'connect' : 'no connect'}`);
        context.dispatch('baseCrmResponses')

      }).catch((err) => {
        context.commit('setLoadingCrmStatus', false)

        if (err.response.status === 503) {
          this.$store.commit('errYclientsPopupOpen', false)
        }
      })
    },
    async baseCrmResponses(context) {
      context.dispatch('getFilialsList').then(() => {
        context.state.texts.dashboard.services.qoobApp.notConfigured.text = `Сразу после активации лицензии сервиса qoob.store, клиенты филиала смогут записываться через мобильное приложение qoob.`
      });

      context.dispatch('getOwnerInfo')

      context.dispatch('baseLicenseResponse')
    },
    async baseLicenseResponse(context) {
      context.commit('setLoaderText', {
        text: 'Получаем данные о лицензии',
        percent: 50,
        isLoading: true
      })
      Promise.all([
        context.dispatch('getTarifLicense').then(() => {
          context.commit('setLoaderText', {
            text: 'Готово',
            percent: 100,
            isLoading: false
          })

          setTimeout(() => {
            context.commit('setAllLoader', false)
          }, 150)

          context.dispatch('getWhatsappStatus')
        }),
        context.dispatch('getSmsSatatus'),
        context.dispatch('telegram/getTelegramStatus', {root: true}),
        context.dispatch('getMobileSetting')
      ]).finally(() => {
        // context.commit('setAllLoader', false)
      })

    },
    async timeline(context, messege) {
      return await axios({
        method: 'post',
        url: `${window.apiPath}/timeline`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        },
        data: {
          comment: `qoob.store / ${messege}`
        }
      })
    },
    async flatPages(context, obj) {
      return await axios({
        method: 'get',
        url: `${window.apiPath}/flatpages/${obj.url}`,
        headers: {
          accept: 'application/json'
        }
      }).then((request) => {
        context.commit(obj.mutation, request.data.data)
      })
    },

    pageReload(context) {
      context.state.visibleApp = false
      setTimeout(() => {
        context.dispatch('baseResponses')

        context.state.visibleApp = true
      }, 100)

    }
  },

  modules: {
    tarif: tarifModule,
    crm: crmModule,
    auth: authModule,
    invoice: invoiceModule,
    profile: profileInfo,
    doc: documentsModule,
    whatsapp: whatsappModule,
    sms: smsModule,
    rfm: rfmModule,
    mobile: mobileSettingsModule,
    notification: notificationModule,
    dashboard: dashboardModule,
    telegram: telegramModule,
    guide: guideModule,
    review: reviewModule
  }
})