<template>
<transition name="fade" appear>
  <div
    class="side_popup__wrapper"
    v-if="visible"
    @click.self="this.$emit('close')"
  >
</div>
</transition>
<transition name="slide" appear>
  <div
    class="side_popup"
    v-if="visible"
  >

    <close-button class="slide_popup__close" :closeType="closeType" @click="this.$emit('close')" />

    <div class="side_popup__content">
      <div class="side_popup__content_wrap">
        <slot></slot>
      </div>
    </div>
  </div>
</transition>
</template>

<script>
import closeButton from "@/components/Dashboard/Billing/close-button";
import { computed } from "vue";
import {useStore} from "vuex";

export default {
  name: "SidePopup",
  components: {
    closeButton: closeButton
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  setup() {

    const store = useStore();

    const windowWidth = computed(() => {
      return store.state.windowWidth;
    })

    const closeType = computed(() => {
      if(windowWidth.value > 768) {
        return 2;
      }
      return 3;
    })

    return { closeType }
  }
  
}
</script>

<style scoped>

.side_popup__wrapper{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1050;

  display: flex;
  justify-content: right;

  height: 100%;

  background: rgba(0, 0, 0, 0.2);
}
.side_popup{
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1051;

  width: 63%;
  min-width: 720px;
  height: 100%;

  background: #fff;
}
.slide_popup__close{
  position: absolute;
  background: #F6F9FC;
  top: 71px;
  left: -48px;
  width: 48px;
  height: 48px;
  border-bottom-left-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
}
.slide_popup__close > div {
  height: 1.5rem;
  width: 1.5rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  /*margin: -3px 0 0 0;*/
}
.side_popup__content_wrap{
  margin: 5rem 0;
}
.side_popup__content{
  overflow-y: auto;
  overflow-x: auto;
  padding: 0 1.75rem;
  position: relative;
  box-shadow: 0px 24px 120px -30px rgba(0, 0, 0, 0.15);
  /*max-height: calc(100% - 3rem);*/
  max-height: 100%;
  min-height: 100%;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(100vw);
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.6s;
}

.slide_popup__close {
  box-shadow: -15px 0px 13px -16px rgba(114, 114, 114, 0.2) inset;
}

@media (max-width: 1024px) {
  .side_popup__content {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .side_popup {
    top: 0;
    left: 0;

    width: 100%;
    min-width: auto;

    transform: none;
  }

  .side_popup__content_wrap {
    margin-top: 0;
  }

  .slide_popup__close {
    top: 0;
    right: 0;
    left: auto;
    z-index: 5;

    flex-direction: column;

    width: 60px;
    height: 60px;

    border-radius: 0;

    background-color: #000;
  }

  .close-icon {
    display: none;
  }

  .close-line {
    width: 28px;
    height: 2px;

    border-radius: 2px;
    transform: rotateZ(45deg);

    background-color: #fff;
  }

  .close-line2 {
    transform: translateY(-2px) rotateZ(-45deg);
  }

  .slide-enter-from,
  .slide-leave-to {
    left: 100vw;
    opacity: 0;
  }
  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.6s ease;
  }
}
</style>
