import axios from "axios"

export default {
  state: () => ({
    loadingPutTexts: false,

    platformLinks: [

    ],
    newLink: {
      name: '',
      link: '',
      id: null,
    },
    badRatingLimit: 3,
    negativeComment: {
      mark: 0,
      text: '',
      name: '',
      phone: '',
      company_id: ''
    },
  }),
  getters: {

  },
  mutations: {
    setPlatformLinks(state, obj) {
      state.platformLinks = obj
    },
  },
  actions: {
    async getPlatformLinks(context) {
      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/review_platform_link`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
      }).then((request) => {
        context.commit('setPlatformLinks', request.data.data)
      })
    },
    async createPlatformLink(context) {
      const currentLink = context.state.newLink
      const createLink = {
        name: currentLink.name,
        link: currentLink.link
      }
      console.log(createLink);
      context.state.platformLinks.push(currentLink)

      context.state.newLink = {
        id: null,
        name: "",
        link: "",
      };
      return await axios({
        method: 'post',
        url: `${window.apiPath}/company/review_platform_link`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
        params: createLink
      }).then((request) => {
        currentLink.id = request.data.data.link.id
        context.commit('addDashboardNotification', {
          text: 'успешно',
          type: 'ok'
        }, {
          root: true
        })
      }).catch(() => {
        const delId = context.state.platformLinks.findIndex(i => i.id === currentLink.id)
        context.state.platformLinks.splice(delId, 1)
        context.commit('addDashboardNotification', {
          text: 'ошибка',
          type: 'err'
        }, {
          root: true
        })
      })
    },
    async updatePlatformLink(context, id) {
      let currentLink = context.state.platformLinks.find(i => i.id === id)
      const link_id = currentLink.id

      const updateLink = {
        name: currentLink.name,
        link: currentLink.link,
      }

      currentLink.id = null

      return await axios({
        method: 'put',
        url: `${window.apiPath}/company/review_platform_link/${link_id}/`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
        params: updateLink
      }).then((request) => {
        console.log(request);
        currentLink.id = request.data.data.link.id
        context.commit('addDashboardNotification', {
          text: 'успешно',
          type: 'ok'
        }, {
          root: true
        })
      }).catch(() => {
        context.commit('addDashboardNotification', {
          text: 'ошибка',
          type: 'err'
        }, {
          root: true
        })
      })
    },
    async deletePlatformLink(context, id) {
      let currentLink = context.state.platformLinks.find(i => i.id === id)
      console.log(currentLink)
      currentLink.id = null
      return await axios({
        method: 'delete',
        url: `${window.apiPath}/company/review_platform_link/${id}`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
      }).then(() => {
        const delId = context.state.platformLinks.findIndex(i => i.name === currentLink.name)
        context.state.platformLinks.splice(delId, 1)
        context.commit('addDashboardNotification', {
          text: 'успешно',
          type: 'ok'
        }, {
          root: true
        })

        if (context.state.platformLinks.length === 0) {
          context.dispatch('putFeedbackInApp', true)
        }
      }).catch(() => {
        context.commit('addDashboardNotification', {
          text: 'ошибка',
          type: 'err'
        }, {
          root: true
        })
        currentLink.id = id
      })
    },
    async getBadRatingLimit(context) {
      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/bad_rating_limit`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
      }).then((request) => {
        context.state.badRatingLimit = request.data.data.bad_rating_limit
      }).catch(() => {
        context.commit('addDashboardNotification', {
          text: 'get bad_rating_limit error',
          type: 'err'
        })
      })
    },
    async setBadRatingLimit(context, ) {
      return await axios({
        method: 'put',
        url: `${window.apiPath}/company/bad_rating_limit`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
        params: {
          limit: context.state.badRatingLimit
        }
      }).then(() => {
        context.commit('addDashboardNotification', {
          text: 'успех',
          type: 'ok'
        }, {
          root: true
        })
      }).catch(() => {
        context.commit('addDashboardNotification', {
          text: 'ошибка',
          type: 'err'
        }, {
          root: true
        })

        context.state.badRatingLimit = 3
      })
    },
    async putCommentsTexts(context) {
      context.state.loadingPutTexts = true
      return await axios({
        method: 'put',
        url: `${window.apiPath}/company/comments_title`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
        params: {
          comments_title: context.rootState.telegram.telegramStatus.comments_title,
          bad_comments_title: context.rootState.telegram.telegramStatus.bad_comments_title
        }
      }).then((request) => {
        context.state.loadingPutTexts = false
        console.log(request);
        context.rootState.telegram.lastTelegramStatus.comments_title = request.data.data.comments_title
        context.rootState.telegram.lastTelegramStatus.bad_comments_title = request.data.data.bad_comments_title
        context.commit('addDashboardNotification', {
          text: 'Текст изменён',
          type: 'ok'
        }, {
          root: true
        })
      }).catch((err) => {
        context.state.loadingPutTexts = false
        console.dir(err);
        context.commit('addDashboardNotification', {
          text: `При изменении текста, произошла ошибка ${err.response.status}`,
          type: 'err'
        }, {
          root: true
        })
      })
    },
    async getReviewsLinks(context, salon_id) {
      return await axios({
        method: 'get',
        url: `/api/v2/company/${salon_id}/review/info`
      })
    },
    async postNegativeReview(context) {
      return await axios({
        method: 'post',
        headers: {
          accept: 'application/json',
        },
        url: 'https://qoob.store/api/v1/booking/yclients/comments/negative/',
        data: context.state.negativeComment
      })
    },
    async getFilialInfo(context, salon_id) {
      return await axios({
        method: 'get',
        url: `https://qoob.store/api/v1/booking/yclients/company/${salon_id}/`
      })
    }
  },
  namespaced: true,
}