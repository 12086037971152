<template>
  <div class="star-wrap " :class="{'adaptive' : adaptive}">
    <svg
      width="25"
      height="25"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        :fill="color ? color : '#F0F3F8'"
        d="M11.0408 1.06637C11.2143 0.663391 11.7857 0.66339 11.9592 1.06637L14.7623 7.57547C14.8347 7.74356 14.9932 7.85867 15.1754 7.87557L22.2321 8.53006C22.669 8.57058 22.8456 9.11399 22.5159 9.40356L17.1916 14.0809C17.0541 14.2017 16.9936 14.3879 17.0339 14.5665L18.592 21.4801C18.6885 21.9081 18.2263 22.2439 17.849 22.0199L11.7553 18.4016C11.5979 18.3081 11.4021 18.3081 11.2447 18.4016L5.151 22.0199C4.77374 22.2439 4.31149 21.9081 4.40796 21.4801L5.96615 14.5665C6.00639 14.3879 5.94587 14.2017 5.80838 14.0809L0.484064 9.40357C0.154433 9.11399 0.330996 8.57058 0.767882 8.53006L7.82461 7.87557C8.00684 7.85867 8.16528 7.74356 8.23766 7.57547L11.0408 1.06637Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      default: "#F0F3F8",
      type: String,
    },
    adaptive: {
      type: Boolean,
      default: true
    }
  },
};
</script>

<style lang="scss" scoped>
.star-wrap {
  display: inline-block;
  position: relative;
  cursor: pointer;

}
.star-wrap:hover {
  opacity: 0.7;
  transition: 0.1s;
}

@media (max-width:1024px) {
  .adaptive svg {
    width: 15px;
  }
}
</style>