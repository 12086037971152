<template>
  <div v-if="!crmConnect">
    <crmCyncError></crmCyncError>
  </div>
  <div class="wa-warn" v-else-if="!qoobLicenseActive">
    <h3>Внимание!</h3>

    <!-- crm не подключенна или лицензии никогда не существовало -->
    <div class="wa-warn__text" v-if="!licenseExisted">
      <p>
        Мы не нашли у Вас ни одной активной лицензии на использование сервиса
        доставки уведомлений через WhatsApp.
      </p>
      <p class="mb-4">
        Для начала работы, неоходимо приобрести лицензию сервиса qoob.store.
      </p>
    </div>
    <!-- лицензия закончилась -->
    <div class="wa-warn__text" v-else>
      <p>Ваш аккаунт был остановлен {{ license.date }} из-за неуплаты.</p>
      <p>
        Прямо сейчас вы можете включить этот инстанс, просто продлив подписку.
      </p>
    </div>

    <button-orange
      class="button-main"
      cls="main"
      to-next
      @click="$router.push('/dashboard/iframe/billing')"
      >Выбрать лицензию</button-orange
    >
  </div>

  <!-- crm подключенна и лицензия активна -->
  <div v-if="crmConnect && qoobLicenseActive" class="mb-5">
    <div
      class="if-main-max-width"
      
    >
      <div class="tit-h5 d-flex align-items-center">
        {{
          $store.state.notification.loadingHistoryMesseges
            ? "Загрузка истории сообщений"
            : "История уведомлений (бета версия)"
        }}
      </div>
      <NotificationHistory
        
      ></NotificationHistory>
    </div>
  </div>


</template>

<script>
import { computed, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import NotificationHistory from "../views/Dashboard/Notifications/NotificationHistory.vue";
import crmCyncError from '../components/UI/crmCyncError.vue';

export default {
  components: {
    NotificationHistory,
    crmCyncError
  },
  setup() {
    const store = useStore();

    const crmConnect = computed(() => store.state.crm.crmConnect);
    const licenseExisted = computed(() => store.state.tarif.licenseExisted);
    const whatsappStatus = computed(() => store.state.whatsapp.whatsappStatus);
    const whatsAppActive = computed(
      () => store.state.whatsapp.whatsappWebSoket.auth
    );
    const whatsAppAvailable = computed(
      () => store.state.whatsapp.whatsappStatus.isAvailable
    );
    const loadingWhatsappStatus = computed(
      () => store.state.whatsapp.loadingWhatsappStatus
    );
    const qoobLicenseActive = computed(
      () => store.state.tarif.license.isActive
    );
    const license = computed(() => store.state.tarif.license);

    onMounted(() => {
      if (!store.state.notification.historyMessegesPages.page1) {
        store.dispatch("getHistoryMesseges");
      }
    });

    return {
      crmConnect,
      licenseExisted,
      whatsappStatus,
      whatsAppActive,
      whatsAppAvailable,
      loadingWhatsappStatus,
      qoobLicenseActive,
      license,
    };
  },
};
</script>

<style>
</style>