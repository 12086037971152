<template>
  <div :class="{ _loader: $store.state.telegram.steps.loader }">
    <div class="steps-settings if-main-width">
      <div class="tit-h5">Формат записи</div>

      <newDropList
        :list="['Меню', 'Пошаговый', 'Экспресс']"
        class="mb-5"
        v-model="$store.state.telegram.steps.format"
        :currentIndex="$store.state.telegram.steps.format"
      ></newDropList>

      <div class="tit-h5 gray-block mb-5" v-html="texts[format]"></div>

      <div class="step-order mb-5" :class="'format-' + format">
        <div class="step-order__header">
          <div class="tit-h5">Шаг</div>
          <div class="tit-h5">Название шага</div>
        </div>
        <div class="step-order__drag" v-if="steps">
          <draggable
            v-model="steps"
            group="people"
            @start="drag = true"
            @end="drag = false"
            item-key="id"
            :disabled="format === 2"
          >
            <template #item="{ element }">
              <div class="step-order__item">
                <div class="step-order__row">
                  <div>{{ element.name }}</div>
                  <input
                    class="default-input _small"
                    v-model="element.stepName"
                  />
                </div>
              </div>
            </template>
          </draggable>
        </div>
      </div>

      <div class="step-check-box">
        <div class="tit-h5">Отображать занятое время</div>

        <custom-checkbox
          :defaultState="$store.state.telegram.steps.showBusyTime"
          :item="{ state: $store.state.telegram.steps.showBusyTime }"
          index=""
          @click="
            $store.state.telegram.steps.showBusyTime =
              !$store.state.telegram.steps.showBusyTime
          "
        />
      </div>
      <div class="step-check-box">
        <div class="tit-h5">Отображать цену прошедших визитов</div>

        <custom-checkbox
          :defaultState="allStepsSettings.widgetSettings.visitsAmountVisible"
          :item="{ state: allStepsSettings.widgetSettings.visitsAmountVisible }"
          index=""
          @click="
            $store.state.telegram.steps.allStepsSettings.widgetSettings.visitsAmountVisible =
              !$store.state.telegram.steps.allStepsSettings.widgetSettings.visitsAmountVisible
          "
        />
      </div>
      <div class="step-check-box">
        <div class="tit-h5">Сделать Фамилию обязательным полем при записи</div>

        <custom-checkbox
          :defaultState="allStepsSettings.widgetSettings.isSurnameRequired"
          :item="{ state: allStepsSettings.widgetSettings.isSurnameRequired }"
          index=""
          @click="
            $store.state.telegram.steps.allStepsSettings.widgetSettings.isSurnameRequired =
              !$store.state.telegram.steps.allStepsSettings.widgetSettings
                .isSurnameRequired
          "
        />
      </div>
      <div class="step-check-box">
        <div class="tit-h5">Сделать Отчество обязательным полем при записи</div>

        <custom-checkbox
          :defaultState="allStepsSettings.widgetSettings.isMiddleNameRequired"
          :item="{ state: allStepsSettings.widgetSettings.isMiddleNameRequired }"
          index=""
          @click="
            $store.state.telegram.steps.allStepsSettings.widgetSettings.isMiddleNameRequired =
              !$store.state.telegram.steps.allStepsSettings.widgetSettings
                .isMiddleNameRequired
          "
        />
      </div>

      <mainScreenSettings></mainScreenSettings>

      <categoryGroupSettings></categoryGroupSettings>

      <button
        class="btn-orange _small w-100 mb-6"
        @click="$store.dispatch('telegram/steps/putSettings')"
      >
        Сохранить
      </button>

      <YMetrikaSettings class="mb-5" v-if="$store.state.telegram.steps.allStepsSettings?.yandexMetrika">
        
      </YMetrikaSettings>

      <div class="step-check-box">
        <div class="tit-h5">Запись на дополнительные услуги</div>

        <custom-checkbox
          :defaultState="$store.state.telegram.steps.allStepsSettings?.additionalServices?.show"
          :item="{ state: $store.state.telegram.steps.allStepsSettings.additionalServices.show }"
          index=""
          @click="
            $store.state.telegram.steps.allStepsSettings.additionalServices.show =
              !$store.state.telegram.steps.allStepsSettings.additionalServices.show
          "
        />
      </div>
      <categorySettings :class="{_loader: $store.state.telegram.steps.additionalServicesLoader}" class="mb-5" v-if="$store.state.telegram.steps.allStepsSettings.additionalServices.show"></categorySettings>

      <button
        class="btn-orange _small w-100"
        @click="$store.dispatch('telegram/steps/putSettings')"
      >
        Сохранить
      </button>

      
    </div>
  </div>
</template>

<script>
import newDropList from "@/iframe/UI/newDropList.vue";
import { computed, onMounted, ref, watch } from "vue";
import draggable from "vuedraggable";
import { useStore } from "vuex";
import customCheckbox from "../../components/Dashboard/Billing/custom-checkbox.vue";

import categorySettings from '@/iframe/telegram/categorySettings.vue'
import mainScreenSettings from '@/iframe/telegram/mainScreenSettings.vue'
import categoryGroupSettings from '@/iframe/telegram/categoryGroupSettings.vue'
import YMetrikaSettings from './YMetrikaSettings.vue';


export default {
  components: {
    newDropList,
    draggable,
    customCheckbox,
    categorySettings,
    mainScreenSettings,
    categoryGroupSettings,
    YMetrikaSettings
  },
  setup() {
    const store = useStore();

    const format = computed(() => store.state.telegram.steps.format);

    const allStepsSettings = computed(
      () => store.state.telegram.steps.allStepsSettings
    );

    let steps = computed({
      get() {
        return store.state.telegram.steps.stepsArr;
      },
      set(newValue) {
        store.commit("telegram/steps/setStepsArr", newValue);
      },
    });

    watch(
      () => store.state.telegram.steps.format,
      (format) => {
        if (format === 2) {
          store.commit("telegram/steps/setStepsArrFormat2");
        }
      },
      { deep: true }
    );

    let drag = ref(false);

    let texts = [
      `Выбор сотрудника, услуги или времени записи в любой удобной последовательности. Самый универсальный сценарий: позволяет клиентам записываться так, как им удобно прямо сейчас.`,
      `Клиент сможет заполнить форму онлайн-записи только в последовательности, установленной вами. Удобный вариант, если большинство клиентов новые.`,
      `На 15% эффективнее других сценариев. Клиент всегда выбирает сначала услугу, затем сотрудника и время.`,
    ];

    onMounted(() => {
      store.dispatch("telegram/steps/getSettings");
    });

    return {
      allStepsSettings,
      format,
      texts,
      steps,
      drag,
    };
  },
};
</script>

<style lang="scss">
.sortable-shosen {
  background: #000 !important;
}
.step-order {
  background: #f6f9fc;
  border-radius: 21px;
  padding: 25px;

  &.format-2 {
    .step-order__item:before {
      display: none;
    }

    .step-order__header {
      margin-left: 0;
      padding-left: 0;
    }

    .step-order__row {
      padding-left: 0;
    }

    .default-input {
      max-width: 393px !important;
    }

    .step-order__item {
      cursor: default;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-left: 24px;
    padding-left: 20px;
    border-bottom: 1px solid #a1a2a2;
    div {
      width: 47.5%;
    }
  }

  &__drag {
    margin-bottom: 26px;
  }
  &__item {
    font-size: 14px;
    font-weight: 500;
    position: relative;
    display: flex;
    align-items: center;
    transition: .1s ;

    cursor: pointer;
    &:before {
      content: "";
      display: inline-block;
      background: url("~@/assets/icon-menu.svg") center;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .step-order__row {
      flex-grow: 1;
      border-bottom: 1px solid #a1a2a2;
      padding: 14px 0 14px 20px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .default-input {
        max-width: 369px;
        height: 37px;
        font-weight: 500;
        color: #6d6d6d;
      }
    }
  }
}
.step-check-box {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}
</style>