<template>
  <div class="container">

    <div class="align-items-center justify-content-center loginForm">
      <div>
        <div class="text-center">
          <h2 class="login-title">Вход с паролем</h2>

          <cm-input :label="'Логин'" v-model="supportLogin.login"></cm-input>

          <cm-input :label="'Пароль'" v-model="supportLogin.password"></cm-input>

          <button-orange
            class="btnLogin"
            :disabled="!(supportLogin.login.length > 0 && supportLogin.password.length > 0)"
            @click="$store.dispatch('supportLogin')"
            :class="{'_loader-btn': supportLoginLoading}"
          >
            Войти
          </button-orange>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CmInput from "@/components/UI/inputs/CmInput";
import buttonOrange from "@/components/UI/buttons/ButtonOrange";
import { mapState } from 'vuex';

export default {
  name: "Login",
  components: { CmInput, buttonOrange },
  computed: {
    ...mapState({
      supportLogin: state => state.auth.supportLogin,
      supportLoginLoading: state => state.auth.supportLoginLoading,
    })
  },
  methods: {
    
  },
  mounted() {
  },
};
</script>

<style scoped>
.login-title {
  padding-bottom: 24px;
  border-bottom: solid 2px #ffc549;
  margin-bottom: 17px;

  font-size: 32px;
}
.loginPushFromRegMessage {
  color: #000;
  text-align: left;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.loginForm {
  margin: 150px auto;
  width: 385px;
}
h2 {
  font-family: Graphik LCG;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.625rem;
  color: #1e2022;
  margin-bottom: 1.5rem;
}
.btnLogin {
  width: 100%;
  height: 48px;
  margin-top: 2.5rem;
}

.btnLogin {
  width: 100%;
  height: 48px;
}
</style>

