<template>
  <div
      class="wrapper"
      :style="maxHeight"
  >
    <span
        class="graph__line yellow"
        :style="plusHeight"
    ></span>
    <span
        class="graph__line graph__line--grey"
        :style="minusHeight"
    ></span>

  </div>
</template>

<script>
import {useStore} from "vuex";
import {ref, watchEffect} from "vue";

export default {
  name: "graph-item",
  props: {
    index: {
      type: Number,
      required: true
    },
    itemParams: {
      type: Object,
      required: true
    }
  },
  setup(props) {

    const store = useStore();

    const windowWidth = ref(0);

    watchEffect(() => {
      windowWidth.value = store.state.windowWidth;
    })

    const maxHeight = ref('');
    const plusHeight = ref('');
    const minusHeight = ref('');

    setTimeout(() => {
      maxHeight.value = 'min-height: ' + Math.max(props.itemParams.plus, props.itemParams.minus) + 'px;';
      plusHeight.value = 'min-height: ' + props.itemParams.plus + 'px;';
      minusHeight.value = 'min-height: ' + props.itemParams.minus + 'px;';
    }, 200)

    return {
      maxHeight,
      plusHeight,
      minusHeight
    }

  }
}
</script>

<style scoped>

.wrapper {
  position: relative;
  width: 13px;
}

.graph__line {
  position: absolute;
  /*left: -2px;*/
  left: 0;
  bottom: 0;

  display: block;
  /*min-width: 8px;*/
  /*max-width: 8px;*/
  min-width: 4px;
  max-width: 4px;

  border-radius: 12px;
}

.graph__line--grey {
  /*left: -6px;*/
  left: auto;
  right: 0;
  z-index: 2;
  background-color: #CDCDCD;
}

.yellow {
  background-color: #FFC549;
}

.grey {
  background-color: #CDCDCD;
}
</style>