<template>
  <div
    class="rate-page"
    :class="[
      { _loader: loadingTariffs || loadingLicense },
      { 'non-crm': !this.crmConnect },
      { _iframe: isFrameVersion },
      { 'base-padding': !isFrameVersion },
    ]"
  >
    <div
      class="rate__info if-main-width"
      v-if="this.crmConnect && license.status"
    >
      <div class="rate-info" v-if="license.status === 1">
        <img src="../../../assets/tarif-icon.svg" alt="" />
        <span class="rate-info__item info__date"
          >Лицензия вашего аккаунта окончена {{ license.date }}</span
        >
        <span class="rate-info__item info__rate"
          >Тариф: <b>“{{ license.prevTariff }}”</b></span
        >
        <span class="rate-info__item info__days"
          >{{ license.days }} дней назад</span
        >
      </div>
      <div class="rate-info rate__info" v-if="license.status === 2">
        <span>
          <img src="../../../assets/tarif-icon.svg" alt="" />Филиал #{{$store.state.crm.crmLicense.acc_id}}
        </span>
        <span class="rate-info__item info__rate">Тариф: {{ license.tariffName }}</span>
        <span class="rate-info__item info__date"> До {{ license.date }}</span>

        <span class="rate-info__item info__days"
          >Осталось {{ license.days }} дней
        </span>
      </div>
    </div>

    <div v-if="this.crmConnect || loadingTariffs">
      <div class="test-drive-block if-main if-gap-2">
        <div
          class="flex-column test-drive-block__col w-100 if-main-width"
          v-if="licenseInfo.isActive"
        >
          <div class="test-drive-block__info">
            <p>
              🙌 Поздравляем! <br />
              Вы успешно подключили сервис qoob.store 🥳
            </p>
            <p>Текущий филилал - {{$store.state.crm.filialsList.currentFilial.name}}</p>
            <p class="_status">
              Продолжайте настройку сервиса
              <status-icon :type="1" :kit="1"></status-icon>
            </p>

            <p>
              Если потребуется помощь - мы готовы помочь в чате и по видеосвязи
            </p>

            <p>
              🎯 Поддержка клиентов qoob.store <br />
              <a
                href="https://t.me/qoob_store_support_bot"
                target="_blank"
                class="link"
                >https://t.me/qoob_store_support_bot</a
              >
            </p>

            <div></div>

            <button class="btn-orange" @click="openTariffs">
              Перейти к тарифам
            </button>
          </div>
        </div>
        <div
          class="flex-column test-drive-block__col w-100 if-main-width"
          v-else-if="licenseInfo.license_expiry"
        >
          <div class="test-drive-block__info">
            <p class="_status">
              Лицензия закончилась!
              <status-icon :type="0" :kit="1"></status-icon>
            </p>
            <p>
              🧐 Чтобы продолжить использование сервиса qoob.store, перейдите на
              вкладку тарифы
            </p>

            <p>
              Если потребуется помощь - мы готовы помочь в чате и по видеосвязи
            </p>

            <p>
              🎯 Поддержка клиентов qoob.store <br />
              <a
                href="https://t.me/qoob_store_support_bot"
                target="_blank"
                class="link"
                >https://t.me/qoob_store_support_bot</a
              >
            </p>

            <button class="btn-orange" @click="openTariffs">
              Перейти к тарифам
            </button>
          </div>
        </div>
      </div>

      <div>
        <billing-payments class="if-main-width"></billing-payments>
      </div>
    </div>

    <crmCyncError v-if="this.$store.state.crm.crmConnect === 0"></crmCyncError>
  </div>
</template>

<script>
import statusIcon from "../../../components/UI/status-icon.vue";

import { ref, reactive, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import $router from "@/router";
import crmCyncError from "../../../components/UI/crmCyncError.vue";
import BillingPayments from "../BillingPayments.vue";

export default {
  name: "App",
  components: {
    crmCyncError,
    BillingPayments,

    statusIcon,
  },
  emits: ["selectTariff"],
  setup(props, { emit }) {
    const $store = useStore();

    const license = computed(() => $store.state.tarif.license);
    const licenseInfo = computed(() => $store.state.tarif.licenseInfo);
    const isTestDrive = computed(() => $store.state.tarif.isTestDrive);

    const crmConnect = computed(() => $store.state.crm.crmConnect);
    const isPersonal = computed(() => $store.state.tarif.isPersonal);
    const licenseExisted = computed(() => $store.state.tarif.licenseExisted);
    const loadingTariffs = computed(() => $store.state.tarif.loadingTariffs);
    const isFrameVersion = computed(() => $store.state.isFrameVersion);
    const cardList = computed(() => $store.state.tarif.tariffs);

    const loadingLicense = computed(() => $store.state.tarif.loadingLicense);

    const tarifsVisible = ref(false);

    const tariffsLenght = computed(() => {
      if (isTestDrive.value && license.value.days > 10) {
        return 1;
      } else {
        return $store.state.tarif.tariffsLength;
      }
    });

    const sliderPosition = ref(30);

    let touchArr = reactive([]);

    function sliderStart() {
      touchArr = [];
    }

    function sliderMove(event) {
      touchArr.push(event.touches[0].clientX);
    }

    function sliderEnd() {
      let side = 0;

      if (touchArr[0] > touchArr[touchArr.length - 1]) {
        side = -1;
      } else {
        side = 1;
      }

      slierLimit(side);
    }

    const potSliderPos = ref(30);

    function slierLimit(side) {
      potSliderPos.value += slideWidth * side;
      // Вместо 2 указать store.state.tarif.tarifs.length, сейчас не работает, тк есть баг с карточками (то 2, то 3, а длина массива при этом всегда 2)
      if (
        -potSliderPos.value >= slideWidth * (tariffsLenght.value - 1) &&
        side < 0
      ) {
        potSliderPos.value += slideWidth * -side;
        return;
      } else if (sliderPosition.value >= 30 && side > 0) {
        potSliderPos.value += slideWidth * -side;
        return;
      } else {
        sliderPosition.value += slideWidth * side;
      }
    }

    const slideWidth = 320;

    const sliderStyle = computed(() => {
      return "left: " + sliderPosition.value + "px;";
    });

    const isTimer = ref(null);

    function checkTimer() {
      cardList.value.forEach((item, index) => {
        if (item.timer) {
          isTimer.value = index;
        }
      });
    }

    checkTimer();

    const orderList = reactive([1, 2, 3]);

    function order() {
      if (isTimer.value === 0) {
        return;
      } else if (isTimer.value === 1) {
        orderList[0] = 2;
        orderList[1] = 1;
      } else if (isTimer.value === 2) {
        orderList[0] = 2;
        orderList[1] = 3;
        orderList[2] = 1;
      }
    }

    order();

    function selectTariff(tarif) {
      emit("selectTariff", tarif);
    }

    watch(
      license,
      () => {
        if (cardList.value.length === 0) {
          $store.dispatch("getTariffs");
        }
      },
      { deep: true }
    );

    onMounted(() => {
      if (localStorage.getItem("testDriveActive") === "active") {
        $router.push("/dashboard/iframe/billing");
      }
      if (cardList.value.length === 0 && crmConnect) {
        $store.dispatch("getTariffs");
      }
    });

    function openTariffs() {
      console.log("opentarif", {
        postMessage: { action: "open_tab", payload: "tariffs" },
        window: window,
        parent: window.parent,
      });

      window.parent.postMessage({ action: "open_tab", payload: "tariffs" }, 'https://yclients.com/');
    }

    return {
      cardList,
      isTimer,
      orderList,
      selectTariff,
      sliderPosition,
      isTestDrive,
      sliderStart,
      sliderMove,
      sliderEnd,
      license,
      sliderStyle,
      licenseInfo,
      crmConnect,
      isPersonal,
      licenseExisted,
      loadingTariffs,
      isFrameVersion,
      loadingLicense,
      tarifsVisible,
      openTariffs,
    };
  },
};
</script>

<style lang="scss" scoped>
.tarifs-popup {
  padding-bottom: 50px;
  @media (max-width: 567px) {
    padding: 10px 54px 10px 50px;
  }

  &__arr {
    position: fixed;
    display: block;
    transform: rotate(150deg);
    height: 300px;
    top: -4px;
    left: 281px;
  }
}
.test-drive-block__info {
  margin-bottom: 44px;
}
.rate-page._iframe .rate__title {
  margin-bottom: 0;
}

.test-drive-block__col {
  min-height: 100%;
}

.test-drive-block {
  display: flex;
  gap: 32px;
  align-items: flex-start;

  flex-wrap: wrap;
}
.rate-page._iframe .rate__info {
  font-weight: 600;
}
.test-drive-block ._status {
  display: flex;
  align-items: center;
  background: #fff;
  align-self: start;
  padding: 11px;
  border-radius: 10px;
  gap: 22px;
  margin-left: -10px;
}
.test-drive-block .link {
  text-decoration: none;
}

.test-drive-block .card-component {
  max-width: 345px;
}

.test-drive-block__info {
  display: flex;
  flex-direction: column;
  background: #f7f9fc;
  padding: 30px;
  border-radius: 10px;
  line-height: 24px;

  row-gap: 20px;
}
.test-drive-block__info p {
  font-size: 14px;
  font-weight: 600;
}

.rate__info.crm-sync {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 49px;
  font-size: 16px;
  max-width: 948px;
}

.rate-info__item {
  margin-right: 10px;
}

.rate__sync {
  width: 210px;
  height: 48px;
  margin-left: 20px;
  margin-top: 5px;
}

.rate__sync > img {
  margin-left: 10px;
}

.slider-wrapper {
  position: relative;
  height: 650px;
}
.slider-wrapper.no-overflow {
  overflow: visible;
  height: auto;
}

.card-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;

  width: 100%;

  transition: 0.3s all ease;
}
.rate__recommendation button {
  text-transform: capitalize;
}
.rate__info {
  font-size: 16px;
}
.rate-info {
  background: rgba(254, 212, 122, 0.5);
  border-radius: 21px;
  padding: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    display: flex;
    gap: 13px;
    align-items: center;
  }
}
.rate__recommendation {
  font-size: 16px;
}

@media (max-width: 1600px) and (min-width: 769px) {
  .non-crm {
    max-width: 860px;
  }
}

@media (max-width: 1280px) and (min-width: 769px) {
  .rate-page {
    padding: 25px 0;
  }

  .test-drive-block .card-component {
    max-width: 310px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .rate-page {
    padding: 25px 0;
  }

  .card-list {
    grid-column-gap: 10px;
  }

  .test-drive-block .card-component {
    max-width: 260px;
  }
}

@media (max-width: 768px) {
  .rate-page {
    padding: 0;
  }

  .card-list {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: left;

    grid-column-gap: 0;
  }

  .rate__title {
    max-width: calc(100% - 45px);
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 26px;
  }

  .rate__info {
    margin-bottom: 30px !important;

    font-size: 13px !important;
  }

  .crm-sync {
    display: none !important;
  }

  .slider-wrapper {
    width: calc(100% + 60px);
    /* left: -30px; */
    overflow: hidden;
  }

  .rate__recommendation {
    font-size: 14px;
  }
}

@media (max-width: 550px) {
  .rate-page {
    padding: 0;
    padding-bottom: 60px;
  }
  .rate-info {
    font-size: 11px;
  }
}
</style>


<style>
.rate-page {
  max-width: 1210px;
  min-height: 650px;
  padding: 0 !important;
  user-select: text;
}
@media (max-width: 768px) {
  .test-drive-block {
    flex-direction: column-reverse;
    align-items: center !important;
  }
  .test-drive-block__info {
    min-width: none;
  }
}
</style>