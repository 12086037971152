<template>
  <div class="graph1" >
    <h4 class="graph__title">
      Приток / Отток - за последние {{ linesNumber }} дней 
    </h4>
    <p class="graph__subtitle" v-show="!this.$store.state.crm.crmConnect">
      Необходимо выполнить синхронизацию с вашей crm системой
    </p>
    <div
    @click="$store.commit('visibleSupportPopup')"
      class="graph__wrapper"
      :class="{ _loader: this.$store.state.dashboard.inflowOutflow.loading }"
    >
      <div class="graph-container" v-if="this.$store.state.crm.crmConnect">
        <div class="axis-wrapper">
          <p class="axis-top">180</p>
          <p class="axis-bottom">0</p>
        </div>

        <ul
          v-if="statList.length > 0"
          class="graph__main"
          @mouseenter="togglePopup(true)"
          @mouseleave="togglePopup(false)"
        >
          <li
            class="graph__item"
            v-for="index in linesNumber"
            :key="index"
            @mouseenter="takePopupData(index)"
          >
            <span
              :class="[
                'item__background',
                {
                  'line--active':
                    popupData.id === index || (!isSync && index === 1),
                },
              ]"
              :style="'top: ' + (i - 1) * 8 + 'px;'"
              v-for="i in backgroundNumber"
              :key="i"
            ></span>
            <graph-lines
              :isSync="isSync"
              :index="index"
              :itemParams="statList[index]"
            />
          </li>
        </ul>

        <div class="graph__bottom">
          <div class="graph__info">
            <div class="info__item">
              <span class="info__color item--yellow"></span>
              <p class="info__text">
                Приток
                <span class="info__number">{{ inflow }}</span>
              </p>
            </div>
            <div class="info__item">
              <span class="info__color item--grey"></span>
              <p class="info__text">
                Отток
                <span class="info__number">{{ outflow }}</span>
              </p>
            </div>
          </div>

          <router-link
            to="/dashboard/notifications/segments"
            class="graph__about"
            v-if="isSync && false"
            >Подробнее</router-link
          >
          <button class="graph__license" v-else-if="false">Подключить crm</button>
        </div>
      </div>
      <div class="graph-placeholder" :class="placeholderBg" v-else></div>
    </div>

    <transition name="graphPopupAm">
      <div class="graph__popup" :style="popupStyle" v-show="popupState">
        <p class="popup__month">День {{ popupData.id }}</p>
        <p class="popup__block">
          <span class="popup__color grey"></span>
          <span class="popup__text">Отток {{ popupData.plus }}</span>
        </p>
        <p class="popup__block">
          <span class="popup__color yellow"></span>
          <span class="popup__text">Приток {{ popupData.minus }}</span>
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable */
import { useStore } from "vuex";
import { ref, reactive, computed, watchEffect, nextTick, onMounted } from "vue";
import graphLines from "@/components/Dashboard/Services/graph-lines";

export default {
  name: "ServicesClientFlow",
  components: {
    graphLines,
  },
  setup() {
    const store = useStore();

    const statList = reactive([]);

    class ItemStat {
      constructor(plus, minus) {
        this.plus = plus;
        this.minus = minus;
      }
    }

    const linesNumber = computed(() => {
      if (store.state.dashboard.inflowOutflow.data.chart_data) {
        return store.state.dashboard.inflowOutflow.data.chart_data.length;
      } else {
        return null;
      }
    });

    let inflow = ref(0);
    let outflow = ref(0);

    const chartData = computed(() => []);

    const popupState = ref(false);

    function togglePopup(state) {
      popupState.value = state;
      window.addEventListener("mousemove", changePosition);

      if (!state) {
        window.removeEventListener("mousemove", changePosition);
        popupData.id = 0;
        popupData.plus = 0;
        popupData.minus = 0;
      }
    }

    const popupData = reactive({
      id: 0,
      plus: 0,
      minus: 0,
    });

    function takePopupData(index) {
      popupData.id = index;
      popupData.plus = chartData.value[index].plus;
      popupData.minus = chartData.value[index].minus;
    }

    const popupPosition = reactive({
      x: 0,
      y: 0,
    });

    function changePosition(e) {
      popupPosition.x = e.x;
      popupPosition.y = e.y;
    }

    const popupStyle = computed(() => {
      return (
        "top: " +
        (popupPosition.y - 100) +
        "px; left: " +
        (popupPosition.x + 10) +
        "px;"
      );
    });

    const isSync = ref(true);

    const backgroundNumber = ref(25);

    const windowWidth = ref(0);

    watchEffect(() => {
      windowWidth.value = store.state.windowWidth;

      if (store.state.dashboard.inflowOutflow.data.chart_data) {

        // этот объект должен быть в самом начале, он нигде не отображается, но без него отображается некорректно, это костыль, но пока так.
        chartData.value.push({
            id: -1,
            plus: -1,
            minus: -1,
          })
        // ----------------

        store.state.dashboard.inflowOutflow.data.chart_data.forEach((item) => {
          chartData.value.push({
            id: item[0],
            plus: item[2],
            minus: item[1],
          });

          inflow.value += item[1];
          outflow.value += item[2];
        });

        for (let i = 1; i <= linesNumber.value; i++) {
          if (chartData.value[i]) {
            statList.push(
              new ItemStat(chartData.value[i].minus, chartData.value[i].plus)
            );
          }
        }
      }
    });

    const placeholderBg = computed(() => {
      if (windowWidth.value > 550) {
        return "background1";
      }
      return "background2";
    });

    return {
      takePopupData,
      popupData,
      changePosition,
      popupPosition,
      popupStyle,
      isSync,
      linesNumber,
      backgroundNumber,
      placeholderBg,
      chartData,
      togglePopup,
      popupState,
      inflow,
      outflow,
      statList,
    };
  },
};
</script>

<style scoped>
.graph__popup {
  position: fixed;
  top: 0;
  left: 10px;
  z-index: 5;

  width: 123px;
  height: 92px;
  padding: 10px;

  border-radius: 10px;
  box-sizing: border-box;

  font-size: 15px;
  line-height: 26px;
  color: #fff;

  background-color: #575c62;
}

.popup__block {
  display: flex;
  align-items: center;
}

.popup__color {
  display: block;
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 50%;
}

.yellow {
  background-color: #ffc549;
}

.grey {
  background-color: #cdcdcd;
}

.graph-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;

  border-radius: 30px;
  box-shadow: 0px 17px 40px rgb(19 49 97 / 7%),
    0px 6.20528px 21.901px rgb(19 49 97 / 5%),
    0px 3.01255px 10.6325px rgb(19 49 97 / 4%),
    0px 1.47681px 5.21226px rgb(19 49 97 / 3%),
    0px 0.583932px 2.06094px rgb(19 49 97 / 2%);
}

.background1 {
  background: url("../../../assets/graph-placeholder.jpg") #d0d0d0 no-repeat
    center / 95%;
}

.background2 {
  background: url("../../../assets/graph-placeholder.jpg") #d0d0d0 no-repeat
    center / 95%;
}

.graph--offline {
  position: relative;
}

.graph--offline::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;

  width: 100%;
  height: 100%;
  border-radius: 30px;

  background: rgba(21, 21, 21, 0.2);
}

.graph__title {
  margin-bottom: 5px;

  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
}

.graph__subtitle {
  font-size: 14px;
  color: #6d6d6d;
}

.graph__wrapper {
  position: relative;

  width: 100%;
  height: 340px;
  padding: 55px 40px 40px 70px;
  margin-top: 25px;

  box-sizing: border-box;
  box-shadow: 0 17px 40px rgba(19, 49, 97, 0.07),
    0 6.20528px 21.901px rgba(19, 49, 97, 0.0482987),
    0 3.01255px 10.6325px rgba(19, 49, 97, 0.0389404),
    0 1.47681px 5.21226px rgba(19, 49, 97, 0.0310596),
    0px 0.583932px 2.06094px rgba(19, 49, 97, 0.0217013);
  border-radius: 30px;

  background-color: #fff;
}

.graph-container {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
}

.axis-wrapper {
  position: absolute;
  top: 0;
  left: -35px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 185px;

  font-size: 14px;
}

.graph__main {
  display: flex;
  justify-content: space-between;

  overflow: hidden;
}

.graph__item {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  height: 180px;
}

.item__background {
  position: absolute;
  left: 5px;

  display: block;
  width: 3px;
  height: 6px;

  border-radius: 50%;

  background-color: #f0f3f8;
}

.line--active {
  background-color: #6d6d6d;
}

.graph__bottom {
  display: flex;
  justify-content: space-between;
}

.graph__info {
  display: flex;
  align-items: flex-end;
}

.info__item {
  display: flex;
  margin-right: 40px;
}

.info__color {
  display: block;
  width: 8px;
  height: 15px;

  border-radius: 12px;
}

.item--yellow {
  background-color: #ffc549;
}

.item--grey {
  background-color: #cdcdcd;
}

.info__text {
  margin-left: 10px;

  font-size: 14px;
}

.graph__license {
  position: relative;
  z-index: 5;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 175px;
  height: 40px;

  border: 1px solid #ffc549;
  box-sizing: border-box;
  border-radius: 12px;

  font-size: 14px;

  background-color: #fff;
}

.graph__about {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 136px;
  height: 40px;

  border: 1.7px solid #ffc549;
  box-sizing: border-box;
  border-radius: 12px;

  font-size: 14px;

  background-color: #fff;
}

.need-sync {
  position: absolute;
  bottom: 40px;

  width: calc(100% - 110px);

  font-size: 20px;
  text-align: center;
  color: #6d6d6d;
}

@media (max-width: 1600px) and (min-width: 1024px) {
  .graph__wrapper {
    height: 290px;
    padding: 30px 40px 30px 50px;
    margin-top: 20px;
  }

  .graph__title {
    font-size: 18px;
  }

  .graph__subtitle {
    font-size: 13px;
  }

  .axis-wrapper {
    left: -25px;
  }
}

@media (max-width: 1440px) and (min-width: 1024px) {
  .graph__wrapper {
    height: 240px;
    padding: 30px 20px 30px 35px;
  }

  .graph__item {
    height: 120px;
  }

  .axis-wrapper {
    height: 125px;
    left: -25px;

    font-size: 10px;
  }

  .info__number {
    display: none;
  }
}

@media (max-width: 1280px) and (min-width: 1024px) {
  .graph__title {
    font-size: 16px;
  }

  .graph__subtitle {
    font-size: 12px;
  }

  .info__item {
    margin-right: 20px;
  }
}

@media (max-width: 1024px) {
  .graph__wrapper {
    height: 340px;
  }
}

@media (max-width: 768px) and (min-width: 551px) {
  .info__item {
    margin-right: 20px;
  }
}

@media (max-width: 550px) {
  .graph__wrapper {
    width: 100%;
    padding: 30px 25px;
    margin-top: 15px;
  }

  .graph-container {
    width: 100%;
    height: auto;
  }

  .graph__title {
    margin-left: 30px;
    margin-bottom: 10px;
    line-height: 24px;
  }

  .graph__subtitle {
    display: none;
  }

  .axis-wrapper {
    display: none;
  }

  .graph__bottom {
    margin-top: 15px;
    flex-direction: column;
  }

  .graph__info {
    align-items: flex-start;
    margin-bottom: 15px;
  }

  .graph__about {
    width: 100%;
  }
}
</style>


