<template>
  <div
    v-if="crmConnect"
    class="col contentWrap"
    :class="{ _loader: loadindMobileSettins, 'pt-0': isFrameVersion }"
  >
    <div class="app__wrapper if-main">
      <div class="app-block app-block1" v-if="mobileSettings">
        <change-picture
          title="Логотип компании"
          requirements-text="После изменения или удаления логотипа страница будует перезагружена"
          :picture="this.$store.state.crm.filialsList.currentFilial.icon"
          @updateImg="updateLogo(e)"
          @deleteLogo="this.$store.dispatch('deleteMobileLogo')"
          :class="{ _loader: loadingMobileLogo }"
          ref="logo"
        ></change-picture>

        <div :class="{ _loader: loadindMobileSettins }">
          <h4 class="app__title mb-4">Информация о компании</h4>
          <labelInput
            disabled
            :label="'Кол-во пользователей приложения'"
            v-model="licenseInfo.qoob_app.user_count"
            class="mb-5"
          ></labelInput>

          <CmInput
            @input="editData()"
            label="Название"
            v-model="mobileSettings.name"
          ></CmInput>
          <!-- <CmInput
          @input="editData()"
          label="Сфера бизнеса"
          v-model="mobileSettings.service_type_id" 
        ></CmInput> -->
          <drop-menu class="mb-4" :bound="true">
            <template v-slot:name> Сфера бизнеса </template>

            <template v-slot:text>
              {{
                servicesTypeList.find(
                  (i) => i.id === mobileSettings.service_type_id
                )
                  ? servicesTypeList.find(
                      (i) => i.id === mobileSettings.service_type_id
                    ).name
                  : "Выбрать"
              }}
            </template>
            <template v-slot:item>
              <div
                class="drop__item-wrapper"
                v-for="(item, index) in servicesTypeList"
                :key="index"
              >
                <button
                  class="drop__item"
                  v-html="item.name"
                  @click="
                    (mobileSettings.service_type_id = item.id), editData()
                  "
                ></button>
              </div>
            </template>
          </drop-menu>
          <CmInput
            @input="editData()"
            label="Страна"
            v-model="mobileSettings.country"
          ></CmInput>
          <CmInput
            @input="editData()"
            label="Город"
            v-model="mobileSettings.city"
          ></CmInput>
          <CmInput
            @input="editData()"
            label="Адрес"
            v-model="mobileSettings.address"
          ></CmInput>
        </div>
      </div>
      <div class="app-block" v-if="networks">
        <h4 class="contact__title">Контакты и кнопки соцсетей</h4>
        <mobile-app-contact
          v-for="(contact, index) in networks.social_links"
          @input="editData()"
          :key="contact.network_type_id"
          :index="index"
          :contact="contact"
          v-model="contact.value"
          @delete="$store.dispatch('deleteSocialLink', contact)"
          class="mb-4"
          :class="[
            { 'mb-5': index === networks.social_links.length - 1 },
            {
              _loader: socialLinkTypes.find(
                (i) => i.id === contact.network_type_id
              ).loading,
            },
          ]"
        ></mobile-app-contact>

        <button-transparent
          class="add-button"
          @click="createSocialLink()"
          v-if="accessibleSocialLinkTypes.length > 0 && !createLinkBlockState"
          >Добавить новый контакт</button-transparent
        >

        <div
          class="add-link"
          v-if="accessibleSocialLinkTypes.length > 0 && createLinkBlockState"
        >
          <h3 class="add-link__title mb-3">Добавить ссылку</h3>
          <drop-menu class="mb-4" :bound="true" :boundPx="170">
            <template v-slot:name> Тип ссылки </template>

            <template v-slot:text
              >{{
                selectedTypeLink.type
                  ? selectedTypeLink.type
                  : accessibleSocialLinkTypes[0].type
              }}
            </template>
            <template v-slot:item>
              <div
                class="drop__item-wrapper"
                v-for="(item, index) in accessibleSocialLinkTypes"
                :key="index"
              >
                <button
                  class="drop__item"
                  @click="(newSocialLink.type = item.id), selectTypeLink(item)"
                >
                  {{ item.type }}
                </button>
              </div>
            </template>
          </drop-menu>

          <CmInput
            :class="{ invalid: invalidCreateLinkinput }"
            label="Значение"
            class="mb-4"
            v-model="newSocialLink.value"
          ></CmInput>

          <div class="add-link__btns">
            <button-transparent
              class="add-button"
              @click="postSocialLink()"
              v-if="createLinkBlockState"
              >Добавить</button-transparent
            >
            <button-grey
              class="add-button"
              @click="cancelCreateSocialLink()"
              v-if="createLinkBlockState"
              >Отмена</button-grey
            >
          </div>
        </div>
      </div>

      <div class="banner">
      <img src="../../assets/mobile-app.jpg" alt="" />

      <a href="https://apps.apple.com/ru/app/qoob/id1438612849" target="_blank">
        <button-transparent class="w-100">App store</button-transparent>
      </a>

      <a
        href="https://play.google.com/store/apps/details?id=qoob.store&hl=ru&gl=US"
        target="_blank"
      >
        <button-transparent class="w-100">Google play</button-transparent>
      </a>
    </div>
    </div>

    <transition name="fade">
      <div
        class="bottomBox"
        :class="{ _loader: loadindPutMobileSettins }"
        v-show="editedData"
      >
        <div class="buttons-wrapper">
          <button-grey class="preview-button" @click="togglePreview"
            >Предпросмотр</button-grey
          >
          <button-orange
            class="save-button"
            @click="
              $store
                .dispatch('putMoileSettings')
                .then(() => (editedData = false))
            "
            >Сохранить</button-orange
          >
        </div>
      </div>
    </transition>

    <side-popup-v2 :visible="previewState" @close="togglePreview">
      <div class="preview__header">
        <h4 class="header__title">Предпросмотр</h4>
      </div>

      <div class="preview__body">
        <h4 class="preview__title">Макет вашего мобильного приложения</h4>

        <div class="switch-wrapper">
          <button
            class="switch switch1"
            :class="{ 'switch--active': currentSwitcher === 1 }"
            @click="toggleSwitcher(1)"
          >
            Светлая
          </button>
          <button
            class="switch switch2"
            :class="{ 'switch--active': currentSwitcher === 2 }"
            @click="toggleSwitcher(2)"
          >
            Темная
          </button>
        </div>
      </div>
    </side-popup-v2>

    
  </div>

    

  <crmCyncError v-else></crmCyncError>
</template>

<script>
import ChangePicture from "@/components/UI/ChangePicture";
import CmInput from "@/components/UI/inputs/CmInput";
import MobileAppContact from "@/components/Dashboard/MobileApp/MobileAppContact";
import buttonTransparent from "@/components/UI/buttons/ButtonTransparent";
import buttonOrange from "@/components/UI/buttons/ButtonOrange";
import buttonGrey from "@/components/UI/buttons/ButtonGrey";
import sidePopupV2 from "@/components/UI/SidePopupV2";
import dropMenu from "@/components/Dashboard/Billing/drop-menu";
import { mapState } from "vuex";
import labelInput from "../../components/UI/inputs/labelInput.vue";
import crmCyncError from '../../components/UI/crmCyncError.vue';



export default {
  name: "MobileApp",
  components: {
    dropMenu,
    MobileAppContact,
    buttonTransparent,
    crmCyncError,
    buttonOrange,
    buttonGrey,
    CmInput,
    ChangePicture,
    sidePopupV2,
    labelInput,
  },
  data: function () {
    return {
      previewState: false,
      currentSwitcher: 1,
      editedData: false,
      createLinkBlockState: false,
      invalidCreateLinkinput: false,
    };
  },
  methods: {
    deleteContact(contactId) {
      this.networks = this.networks.filter((el) => el.id !== contactId);
    },
    togglePreview() {
      this.previewState = !this.previewState;
    },
    toggleSwitcher(index) {
      this.currentSwitcher = index;
    },
    editData() {
      this.editedData = true;
    },
    updateLogo(e) {
      console.log(e);
      this.$store.dispatch("postMobileLogo", this.$refs.logo.getImg());
    },
    createSocialLink() {
      this.createLinkBlockState = true;
    },
    selectTypeLink(link) {
      this.$store.commit("selectTypeLink", link);
    },
    postSocialLink() {
      if (this.newSocialLink.value) {
        this.createLinkBlockState = false;
        this.$store.dispatch("createSocialLink");
        this.newSocialLink.value = "";
        this.invalidCreateLinkinput = false;
      } else {
        this.invalidCreateLinkinput = true;
      }
    },
    cancelCreateSocialLink() {
      this.createLinkBlockState = false;
    },
  },

  computed: {
    ...mapState({
      mobile: (state) => state.mobile.mobileSettings,

      mobileSettings: (state) => state.mobile.mobileSettings.info,
      loadindMobileSettins: (state) => state.mobile.loadindMobileSettins,
      loadingSocialSettings: (state) => state.mobile.loadingSocialSettings,
      loadindPutMobileSettins: (state) => state.mobile.loadindPutMobileSettins,
      loadingMobileLogo: (state) => state.mobile.loadingMobileLogo,
      networks: (state) => state.mobile.mobileSettings.social,
      accessibleSocialLinkTypes: (state) =>
        state.mobile.accessibleSocialLinkTypes,
      newSocialLink: (state) => state.mobile.newSocialLink,
      servicesTypeList: (state) => state.mobile.servicesTypeList,
      selectedTypeLink: (state) => state.mobile.selectedTypeLink,
      socialLinkTypes: (state) => state.mobile.socialLinkTypes,
      isFrameVersion: (state) => state.isFrameVersion,
      licenseInfo: (state) => state.tarif.licenseInfo,
      crmConnect: state => state.crm.crmConnect
    }),
  },
  mounted() {
    if (!this.mobileSettings) {
      this.$store.dispatch("getMobileSetting");
    }
  },
};
</script>

<style scoped>
.add-link {
  background: #eaf0f7;
  padding: 15px;
  border-radius: 0.75rem;
}
.add-link__title {
  font-weight: 500;
  font-size: 18px;
}
.add-link__btns {
  display: flex;
  gap: 20px;
}
.contentWrap {
  position: relative;

  min-height: calc(100vh - 130px);
  padding: 25px 0;
}
.mobilePreviewWrap {
  position: fixed;
  top: 0;
  right: 0;

  height: 100%;
  width: calc(37% - 200px);

  background-color: #f7f9fc;
}

.mobile-content {
  margin-top: 135px;
}

.phonePicture {
  height: 570px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.selectColorBtn {
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  color: #000000;
}
.selectColorBtn_white {
  border: 0.125rem solid #ffc549;
  box-sizing: border-box;
  border-radius: 0.75rem 0px 0px 0.75rem;
  padding: 0.625rem 1.625rem;
  background: #ffffff;
}
.selectColorBtn_dark {
  background: #f7f9fc;
  opacity: 0.6;
  border: 0.125rem solid #a1a2a2;
  border-left: 0;
  box-sizing: border-box;
  border-radius: 0px 0.75rem 0.75rem 0px;
  color: #1e2022;
  padding: 0.625rem 1.625rem;
}
.me-5 {
  margin-right: 3.1rem !important;
}

.buttons-wrapper {
  display: flex;
  justify-content: right;
}

.preview-button {
  display: none;
}

.save-button {
  height: 48px;
  width: 220px;
}

.add-button {
  width: 100%;
  height: 48px;
}

.contact__title {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 35px;
}

.app__title {
  font-size: 20px;
  line-height: 26px;
}

.preview__caption {
  display: block;

  font-size: 20px;
  line-height: 26px;
  text-align: center;
}

.app__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
}

.app-block {
  width: 100%;
  margin-right: 0 !important;
}

.switch-wrapper {
  display: flex;
  width: 240px;
  margin: 0 auto;
}

.switch {
  width: 50%;
  height: 48px;

  border: 1px solid #a1a2a2;
  transition: 0.3s all ease;

  font-size: 16px;
  color: #a1a2a2;
}

.switch1 {
  border-right: none;
  border-radius: 12px 0 0 12px;
}

.switch2 {
  border-left: none;
  border-radius: 0 12px 12px 0;
}

.switch--active {
  border: 1px solid #ffc549;
  color: #1e2022;
}

@media (max-width: 1440px) {
  .app__wrapper {
    grid-column-gap: 30px;
  }

  .mobilePreviewWrap {
    display: none;
  }

  .preview-button {
    display: block;

    width: 50%;
    height: 48px;
    margin-right: 15px;
  }

  .save-button {
    width: 50%;
  }

  .preview__header {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 30px;

    border-bottom: 1px solid #eff0f0;
  }

  .header__title {
    font-size: 24px;
  }

  .preview__title {
    font-size: 20px;
    line-height: 26px;
    text-align: center;
  }

  .preview__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    max-width: 370px;
    height: 740px;
    padding: 30px 0;
    margin: 0 auto;

    background-size: cover;
    background-position: center;
    background-image: url("../../assets/previewMobile.svg");
  }
}

@media (max-width: 1280px) {
  .contentWrap {
    max-width: 600px;
    padding: 25px 0;
  }

  .app__wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 60px;
  }

  .bottomBox {
    left: 30px;
    width: calc(100% - 60px);
  }
}

@media (max-width: 1024px) {
  .contentWrap {
    padding: 25px 0;
  }

  .bottomBox {
    left: 0;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .app__wrapper {
    grid-row-gap: 50px;
  }

  .contentWrap {
    position: relative;
    max-width: 450px;
  }

  .app-block {
    width: 100% !important;
    margin: 0 !important;
  }

  .app__title {
    margin-bottom: 25px;

    font-size: 20px;
    line-height: 26px;
  }

  .contact__title {
    margin-bottom: 25px;

    font-size: 20px;
    line-height: 26px;
  }

  .bottomBox {
    position: absolute;
    bottom: 0;
    left: 7px;

    width: calc(100% - 14px);
    height: auto;

    padding: 10px;
    padding-bottom: 20px;
    margin-top: 32px;

    border-radius: 16px 16px 0 0;
    transform: none;
  }

  .buttons-wrapper {
    justify-content: space-between;
  }

  .save-button {
    height: 48px;
  }

  .header__title {
    font-size: 16px;
  }
}

@media (max-width: 550px) {
  .contentWrap {
    padding: 0;
    padding-top: 40px;
  }
  .add-link__btns {
    flex-direction: column;
  }
}
</style>

<style>
.bottomBox {
  position: fixed;
  bottom: 0;
  left: 10%;

  width: calc(100% - 120px);
  max-width: 1000px;
  height: auto;

  padding: 25px;
  padding-bottom: 40px;
  margin-top: 32px;

  border-radius: 16px 16px 0 0;
  transform: none;
  background: #ffffff;
  box-shadow: 0px 17px 40px rgba(19, 49, 97, 0.07),
    0px 6.20528px 21.901px rgba(19, 49, 97, 0.0482987),
    0px 3.01255px 10.6325px rgba(19, 49, 97, 0.0389404),
    0px 1.47681px 5.21226px rgba(19, 49, 97, 0.0310596),
    0px 0.583932px 2.06094px rgba(19, 49, 97, 0.0217013);
}

@media (max-width: 1280px) {
  .bottomBox {
    left: 30px;
    width: calc(100% - 60px);
  }
}

@media (max-width: 1024px) {
  .bottomBox {
    left: 0;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .bottomBox {
    position: absolute;
    bottom: 0;
    left: 7px;

    width: calc(100% - 14px);
    height: auto;

    padding: 10px;
    padding-bottom: 20px;
    margin-top: 32px;

    border-radius: 16px 16px 0 0;
    transform: none;
  }
}
</style>


