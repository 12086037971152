import defaultInput from './inputs/defaultInput.vue';
import buttonGray from './buttons/ButtonGrey.vue'
import buttonOrange from './buttons/ButtonOrange.vue'
import buttonTransparent from './buttons/ButtonTransparent.vue'

export default [
  defaultInput,
  buttonGray,
  buttonOrange,
  buttonTransparent,
] 