<template>
  <div class="history__wrapper">
    <div class="tit-h6 mb-2">Поиск сообщений по номеру, дате или тексту</div>
      <form @submit.prevent class="search-block" >
        <button class="search__reload" title="Обновить историю" @click="updateHistory()"  :class="{'_loader-btn': $store.state.notification.loadingHistoryMesseges}">
          <img src="../../../assets/img/reload-icon.svg" alt="">
        </button>
        <div class="search__wrapper">
          <input
            type="text"
            class="search"
            placeholder="Поиск"
            @input="$store.dispatch('searchHistory', $event.target.value)"
            v-model="inputSearchValue"
          />
          <img src="../../../assets/search-normal.svg" alt="" />
        </div>
      </form>
    

    <div class="mb-5">
      <button
        class="btn-orange"
        v-if="historyMode === 'search'"
        :class="{'_loader-btn': $store.state.notification.loadingHistoryMesseges}"
        @click="$store.dispatch('getHistoryMesseges')"
      >
        Найти
      </button>
    </div>
    <div
      :class="{
        '_loader-corner': $store.state.notification.loadingHistoryMesseges,
      }"
    >

    <div class="tit-h2">
    </div>
      <div v-if="historyList.length > 0">
        <ul class="history__header">
          <li class="header__item">Клиент</li>
          <li class="header__item">Уведомление</li>
          <li class="header__item">Время отправки</li>
          <li class="header__item">Статус</li>
          <li class="header__item">Канал</li>
        </ul>

        <ul class="history__list">
          <li
            v-for="(item, index) in historyList"
            :key="index"
            @click="item.open = !item.open"
          >
            <div class="history__item" v-if="windowWidth >= 768">
              <p class="history__number">{{ item.phone }}</p>
              <div class="history__notification" :class="{ _open: item.open }">
                {{ item.text }}
              </div>
              <p class="history__text">{{ item.sent_at }}</p>
              <p class="history__text">{{ item.status }}</p>
              <p class="history__text">{{ item.channel }}</p>
              <button class="item__more">
                <img
                  :class="['more__image', { 'item__more--active': item.open }]"
                  src="../../../assets/arrow-long.svg"
                  alt=""
                />
              </button>
            </div>
            <div class="history__item--mobile" v-else>
              <div class="item__header">
                <div class="item__section">
                  <p class="item__bold">{{ item.phone }}</p>
                  <p class="item__text">{{ item.sent_at }}</p>
                </div>
                <div class="item__section">
                  <p class="item__bold">{{ item.status }}</p>
                  <p class="item__text">{{ item.channel }}</p>
                </div>
              </div>
              <div class="history__notification" :class="{ _open: item.open }">
                <p class="notification__text">{{ item.text }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div v-else>
        <h2 v-if="!historySearch.result?.type" class="tit-h3">
          Сообщений не найдено
        </h2>
        <h2 v-else-if="historySearch.result?.type === 'phone'" class="tit-h3">
          По номеру
          <span class="fw-700">{{ historySearch.result.value }}</span>
          ничего не найдено
        </h2>
        <h2 v-else-if="historySearch.result?.type === 'date'" class="tit-h3">
          По дате
          <span class="fw-700">{{ historySearch.result.value }}</span>
          ничего не найдено
        </h2>
        <h2 v-else-if="historySearch.result?.type === 'text'" class="tit-h3">
          По запросу
          <span class="fw-700">{{ historySearch.result.value }}</span>
          ничего не найдено
        </h2>
      </div>

      <clients-pagination
      v-show="historyList.length > 0"
        :numberOfClients="$store.state.notification.historySize"
        :max="$store.state.notification.historyPageSize"
        @changePage="changePage($event)"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import clientsPagination from "@/components/Dashboard/Segments/popup-components/ClientsPagination";

export default {
  name: "NotificationHistory",
  components: {
    clientsPagination,
  },
  setup() {
    const store = useStore();

    const windowWidth = computed(() => {
      return store.state.windowWidth;
    });

    const currentHistoryPage = computed(
      () => store.state.notification.currentHistoryPage
    );

    const historySearch = computed(
      () => store.state.notification.historySearch
    );

    const inputSearchValue = ref('')

    const historyList = computed(() =>
      store.state.notification.historyMessegesPages[
        "page" + currentHistoryPage.value
      ]
        ? store.state.notification.historyMessegesPages[
            "page" + currentHistoryPage.value
          ]
        : []
    );

    const historyMode = computed(() => store.state.notification.historyMode);

    function changePage(data) {
      store.state.notification.currentHistoryPage = data;
      console.log(data);
      if (!store.state.notification.historyMessegesPages[`page` + data]) {
        store.dispatch("getHistoryMesseges");
      }
    }

    function updateHistory() {
        store.dispatch("getHistoryMesseges");
    }

    return {
      historyList,
      changePage,
      windowWidth,
      currentHistoryPage,
      historySearch,
      historyMode,
      updateHistory,
      inputSearchValue
    };
  },
};
</script>

<style scoped>
.history__wrapper {
  max-width: 1600px;
  padding-top: 20px !important;
}

.search-block {
  display: flex;
  align-items: flex-start;
}

.search__reload img {
  width: 60%;
  height: auto;
  opacity: .3;
}
.search__reload {
  background-color: #f6f9fc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  height: 48px;
  width: 48px;
  border-radius: 12px;
}

.search__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 0 25px;
  margin-bottom: 10px;
  border-radius: 12px;

  background-color: #f6f9fc;
}

.search {
  width: 100%;
  height: 48px;

  font-size: 16px;
}

.history__header {
  display: grid;
  grid-template-columns: 1.2fr 4.1fr 1.4fr 1.3fr 0.6fr 0.2fr;
  grid-column-gap: 50px;

  padding: 0 25px;
  margin-bottom: 10px;
}

.header__item {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.history__item {
  display: grid;
  grid-template-columns: 1.2fr 4.1fr 1.4fr 1.3fr 0.6fr 0.2fr;
  grid-column-gap: 50px;

  padding: 0 25px;
  margin-bottom: 15px;
  border-radius: 12px;

  align-items: center;

  background-color: #f6f9fc;

  transition: 0.2s;

  cursor: pointer;
}

.history__number {
  padding: 30px 0;

  font-size: 16px;
  line-height: 22px;
}

.history__text {
  padding: 30px 0;

  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.history__notification {
  padding: 8px 20px;
  margin: 10px 0;
  border-radius: 12px;

  background-color: #fff;
  position: relative;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  overflow: hidden;
  height: 55px;
  transition: 0.2s;
  /* box-shadow: none, none, inset 0px 0px 10px rgba(0,0,0,0.5), none; */
}
@media (max-width: 768px) {
  .history__notification {
    padding: 0;
  }
}

.history__notification._open {
  overflow: auto;
  height: auto;
}

.item__more {
  display: flex;
  align-items: flex-start;

  padding: 30px 0;
  margin-top: 5px;
}

.more__image {
  transition: 0.3s all ease;
}

.item__more--active {
  transform: rotateZ(180deg);
}

@media (max-width: 1600px) {
  .history__header {
    grid-template-columns: 1.4fr 3.7fr 1.6fr 1.3fr 0.5fr 0.3fr;
  }

  .header__item {
    font-size: 13px;
  }

  .history__item {
    grid-template-columns: 1.4fr 3.7fr 1.6fr 1.3fr 0.5fr 0.3fr;
  }

  .history__number {
    font-size: 14px;
  }

  .notification__text {
    font-size: 14px;
  }

  .history__text {
    font-size: 14px;
  }
}

@media (max-width: 1440px) {
  .history__header {
    grid-template-columns: 1.4fr 3.7fr 1.6fr 1.3fr 0.5fr 0.3fr;
    grid-column-gap: 30px;
  }

  .header__item {
    font-size: 11px;
  }

  .history__item {
    grid-template-columns: 1.4fr 3.7fr 1.6fr 1.3fr 0.5fr 0.3fr;
    grid-column-gap: 30px;
  }

  .history__number {
    font-size: 12px;
  }

  .notification__text {
    font-size: 12px;
  }

  .history__text {
    font-size: 12px;
  }

  .more__image {
    width: 16px;
  }
}

@media (max-width: 1280px) {
  .history__wrapper {
    padding-top: 20px !important;
  }

  .history__header {
    grid-template-columns: 1.4fr 3.7fr 1.6fr 1.3fr 0.5fr 0.3fr;
    grid-column-gap: 20px;

    padding: 0 15px;
  }

  .header__item {
    font-size: 10px;
  }

  .history__item {
    grid-template-columns: 1.4fr 3.7fr 1.6fr 1.3fr 0.5fr 0.3fr;
    grid-column-gap: 20px;

    padding: 0 15px;
  }

  .history__number {
    font-size: 11px;
  }

  .notification__text {
    font-size: 11px;
  }

  .history__text {
    font-size: 11px;
  }

  .more__image {
    width: 14px;
  }
}

@media (max-width: 1024px) {
  .history__wrapper {
    padding: 0 !important;
    padding-top: 20px !important;
  }

  .history__header {
    grid-template-columns: 1.4fr 3.8fr 1.6fr 1.3fr 0.5fr 0.2fr;
    grid-column-gap: 10px;
  }

  .history__item {
    grid-template-columns: 1.4fr 3.8fr 1.6fr 1.3fr 0.5fr 0.2fr;
    grid-column-gap: 10px;

    padding: 0 15px;
  }

  .more__image {
    width: 12px;
  }
}
</style>

<style scoped>
@media (max-width: 768px) {
  .history__header {
    display: none;
  }

  .history__item--mobile {
    padding: 10px 20px;
    border-top: 1px solid #a1a2a2;
  }

  .item__header {
    display: flex;
    justify-content: space-between;

    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #f6f9fc;
  }

  .item__bold {
    margin-bottom: 10px;

    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  .item__text {
    font-size: 16px;
    line-height: 22px;
  }

  .notification__text {
    font-size: 16px;
  }

  .notification__text--full {
    margin-bottom: 10px;
  }
}
</style>