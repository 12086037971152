<template>
  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.75 3.99992L3.58 6.82992L9.25 1.16992" stroke="#FFC549" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "tick-item-icon"
}
</script>

<style scoped>

</style>