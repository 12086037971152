<template>
<!-- устаревший компонент, лучше использоватеть  -->
  <div class="wrapInput" :class="{'password-input' : password}">
    <div class="d-flex justify-content-between inputHeader">
      <div><label class="cm-input__label" v-if="label">{{ label }}</label></div>
      <img v-if="required" src="@/assets/info-circle.svg" alt="!">
    </div>
    <button class="password-input__toggle" v-if="this.password" @click="inputValueHide = inputValueHide ? false : true">
      <img v-show="!inputValueHide" src="@/assets/password-visible.svg" alt="">
      <img v-show="inputValueHide" src="@/assets/password-hide.svg" alt="">
      </button>
    <input :disabled="disabled" :maxlength="maxlength" :ref="id ? `cmInput${id}`: ''" class="cm-input" :type="(password && !inputValueHide) ? 'password' : type" :placeholder='placeholder' @input="updateInput" :value="modelValue">
  </div>

</template>

<script>
export default {
  name: "CmInput",
  props: {
    label: {
      type: String,
      default: "",
      required: false
    },
    placeholder: {
      type: String,
      default: "",
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: false,
    },
    password: {
      type: Boolean,
      required: false,
      default: false
    },
    maxlength: {
      type: [String, Number],
      required: false,
    },
    disabled: {
      type: Boolean,
    },
    type: {
      default: 'text',
      type: String
    }
  },
  data () {
    return {
      input: '',
      inputValueHide: false,
    }
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
    focus() {
      this.$refs[`cmInput${this.id}`].focus()
    }
  }
}
</script>

<style scoped>
.inputHeader {
  align-items: center;
  position: relative;
}

.password-input {
  position: relative;
}
.password-input__toggle {
  position: absolute;
  vertical-align: sub;
  height: 48px;
  bottom: 0;
  right: 10px;

}
.password-input__toggle img {
  width: 20px;
  height: 50%;
  object-fit: contain;
  opacity: .5;
}

.invalid input {
  border: solid 1px #DD6068 !important;
  color: #DD6068 !important;
}
.invalid label {
  color: #DD6068 !important;
}
.invalid input::placeholder {
  color: #DD6068 !important;
  font-weight: 500;
}

input {
  font-family: 'Graphik LCG';
  flex-direction: row;
  padding: 0.75rem 1.25rem;
  background: #F6F9FC;
  border-radius: 0.75rem;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  color: #000000;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none; 
}
input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
}
.wrapInput {
  margin-bottom: 1rem;
}
input:active, :hover, :focus {
  outline: 0;
  outline-offset: 0;
}
input::placeholder,
input::-webkit-input-placeholder {
  color: #A1A2A2;
}
label {
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5rem;
  color: #6D6D6D;
  margin-bottom: 0.125rem;
}

.cm-input {
  height: 48px;
  font-size: 14px;
}

img {
  position: absolute;
  top: 0.125rem;
  right: 0.1875rem;
}

button img {
  position: static;
}

.cm-input {
  height: 48px;
  margin-top: 5px;
  border-radius: 12px;

  font-size: 14px;
}

.cm-input__label {
  font-size: 14px;
  line-height: 24px;
}
</style>

