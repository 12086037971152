<template>
  <h2 class="tit-h4 mb-3">
    Отключить выбор кол-ва мест при записи на групповые собятия
  </h2>

  <div v-if="groupEventsCategories.length > 0" class="tit-h6 mb-4">
    Виберите категории событий, в которых пользователю будет не доступна
    возможность указывать кол-во мест
  </div>
  <div
    v-for="category in groupEventsCategories"
    :key="category.id"
    class="staff-card mb-4"
  >
    <div class="staff-card__info">
      <div class="staff-card__name">{{ category.title }}</div>
    </div>

    <CmSwitch
      v-model="category.disabledCapacity"
      class="staff-card__switch"
      @click="toggleDisabledCapacity(category.id)"
    ></CmSwitch>
  </div>

  <div v-if="groupEventsCategories.length === 0" class="tit-h6 mb-4">
    В вашем филиале групповые события не найдены
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import CmSwitch from "@/components/UI/inputs/СmSwitch.vue";

const store = useStore();

const groupEventsCategories = computed(
  () => store.state.telegram.steps.categoriesSettigs.groupEventsCategories
);

function toggleDisabledCapacity(categoryId) {
  let idIndex = store.state.telegram.steps.allStepsSettings.widgetSettings.groupEvents.forbiddenCapacityCategories.findIndex(id => id === categoryId)
  if (idIndex === -1
  ) {
    store.state.telegram.steps.allStepsSettings.widgetSettings.groupEvents.forbiddenCapacityCategories.push(categoryId)
  } else {
    store.state.telegram.steps.allStepsSettings.widgetSettings.groupEvents.forbiddenCapacityCategories.splice(idIndex, 1)
  }
}
</script>

<style lang="scss" scoped>
.staff-card {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  padding-bottom: 7px;
  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &__info {
    flex-grow: 1;
  }

  &__name {
    font-size: 14px;
    font-weight: 500;
  }

  &__spec {
    font-size: 11px;
  }

  &__switch {
  }
}

@media (max-width: 800px) {
  .staff-card {
    width: 50%;
  }
}
@media (max-width: 567px) {
  .staff-card {
    width: 80%;
  }
}
</style>