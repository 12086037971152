<template>

  <div class="modal-header" v-show="windowWidth <= 550">
    <h3 class="header__title">
      <slot></slot>
    </h3>

    <close-button class="close-button" :closeType="3" @click="emitClose" />
  </div>

</template>

<script>
import closeButton from "@/components/Dashboard/Billing/close-button";
import {computed} from "vue";

export default {
  name: "mobileModalHeader",
  components: {closeButton},
  setup(props, {emit}) {

    function emitClose() {
      emit('closeModal')
    }

    const windowWidth = computed(() => {
      return document.documentElement.clientWidth;
    })

    return { emitClose, windowWidth }

  }
}
</script>

<style scoped>
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 60px;
    padding: 0;

    border-bottom: 1px solid #EFF0F0;
  }

  .header__title {
    margin-left: 15px;

    font-size: 16px;
    color: #1E2022;
  }

  @media (max-width: 768px) {
    .close-button {
      left: auto;
      right: 0;
    }
  }
</style>