<template>
  <div class="default-input-wrap">
    <span class="tit-h6" v-if="label">{{ label }}</span>
    <input
      ref="input"
      class="default-input"
      :class="inputClass"
      :type="type"
      :placeholder="placeholder"
      @input="this.$emit('update:modelValue', $event.target.value)"
      :value="modelValue"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  name: 'default-input',
  props: {
    modelValue: {
      type: String
    },
    label: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inputClass: [String]
  },

  setup() {
    const input = ref(null);

    function focus() {
      input.value.focus();
    }

    function blur() {
      input.value.blur();
    }

    return {
      input,
      focus,
      blur,
    };
  },
};
</script>

<style scoped>
.default-input-wrap {
  width: 100%;
}
</style>