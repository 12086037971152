<template>
  <DoughnutChart
  v-if="this.$store.state.dashboard.chartsCircle.data.length > 0"
    :chartData="testData"
    :options="chartOptions"
    :width="260"
    :height="260"
  />

  <graph-circle />
</template>

<script>
import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import graphCircle from "@/components/Dashboard/Services/graph-circle";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

Chart.register(...registerables);

export default {
  name: "App",
  components: {
    DoughnutChart,
    graphCircle,
  },
  setup() {
    const store = useStore();

    const testData = computed(() => {
      return {
        datasets: [
          {
            data: store.state.dashboard.chartsCircle.data,
            backgroundColor: ["#FFC549", "#4ADDBC", "#CDCDCD", "#575C62" ],
            // '#328FE4'
          },
        ],
      };
    });

    const chartOptions = {
      borderWidth: 1,
      cutout: "90%",
    };

    return { testData, chartOptions };
  },
};
</script>