<template>
  <div class="wrapper">
    <div class="row startPageWrap">

      <div class="startPageContent">
        <div>
          <h1 class="startPageMainTitle">Добро пожаловать <br>
            в сервис qoob.store</h1>
          <p class="startPageDescription">
            “кьюб” или “куб стор” — продвинутый агрегатор уведомлений, и приложение для онлайн записи которое понравится вам и вашим клиентам.
          </p>

          <button-orange class="to-registration" @click="$router.push('/login')" >
            Вход
            <img src="../assets/arrow-right-black.svg" alt="">
          </button-orange>

          
        </div>

        <div class="video-wrapper">
          <div class="video">
            <button class="video__play">
              <img src="../assets/video-play.svg" alt="">
            </button>
          </div>

          <p class="video__title">В двух словах о сервисе qoob.store</p>
          <p class="video__subtitle">Видео обзор на 5 минут</p>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import buttonOrange from "@/components/UI/buttons/ButtonOrange";

export default {
  name: 'StartPage',
  components: {buttonOrange},
  data() {
    return {

    }
  },
  mounted() {
    this.$store.dispatch('getLicenseAgreement')
  }
  
}
</script>

<style scoped>

.wrapper {
  padding: 10% 0;
}

.video {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 600px;
  height: 300px;

  margin-bottom: 25px;
  border-radius: 10px;

  background-color: #F6F9FC;
}

.video__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.video__subtitle {
  font-size: 16px;
  line-height: 24px;
}

.start_page_img img{
  height: 100vh;
}
.start_page_img {
  /*position: relative;*/
}
h1 {
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  color: #1E2022;
  width: 535px;
}
.startPageMainTitle {

}
.startPageWrap{
  max-width: 1340px;
  padding: 0 60px;
  margin: 0 auto!important;
}

.startPageContent {
  display: flex;
  justify-content: space-between;

  padding: 0;
}

.startPageDescription {
  max-width: 515px;

  text-align: left;
  margin-top: 24px;
  margin-bottom: 32px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.main {
  margin-right: auto;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  width: 50%;
}

.to-registration > img {
  margin-left: 10px;
}

@media (max-width: 1280px) {
  .startPageWrap {
    padding: 0 30px;
  }

  .startPageContent {
    flex-direction: column;
    align-items: center;
  }

  .video-wrapper {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .video {
    width: 350px;
    height: 175px;
  }

  .video__play img {
    width: 34px;
    height: 38px;
  }

  .startPageMainTitle {
    width: auto;
    font-size: 26px!important;
    line-height: 38px;
  }

  .startPageDescription {
    font-size: 14px!important;
  }
}
</style>


