<template>
  <!-- <info-modal
    :infoIndex="infoIndex"
    :closeType="2"
    @closeInfo="selectModal(0)"
  /> -->
  <transition name="modalAm">
    <info-modal
      v-show="infoIndex != 2"
      :infoIndex="infoIndex"
      :closeType="2"
      @closeInfo="selectModal(0)"
    />
  </transition>

  <v-popup
      :visible="!CrmLicenseExists"
      @close="CrmLicenseExists = false"
    >
      <h2 class="mb-3">
          Нет лицензии {{selectedCrmName}}
      </h2>
      <p class="mb-3">
        Мы не нашли для филиала #{{this.$store.getters.getSalonId}}
      </p> </v-popup
    >

  <confirm-popup
    :popupVisible="confirmPopupVisible"
    @confirming="CRMAsuncStep3"
    @cancelConfirm="confirmPopupVisible = false"
    @popupClose="confirmPopupVisible = false"
    confirmString="отключить"
  >
    При отключении синхронизации будет остановлен модуль RFM анализа, отправка
    уведомлений по всем каналам, запись через мобильное приложение qoob будет
    ограничена.
  </confirm-popup>
  <div
    class="crm-settings__wrapper row m-3"
    :class="{ _loader: step2Loading || loadingCrmStatus }"
  >
    <div class="col colPadding">
      <!-- 2 шаг -->
      <div class="crm-synh _step1" v-if="false">
        <h4 class="crm-settings__title mb-4">
          Доступные филиалы для синхронизации
        </h4>

        <p class="crm-settings__subtitle">
          Сделайте выбор из списка доступных филиалов которые хотите подключить
          к сервису qoob.store
        </p>
        <p class="crm-settings__subtitle">
          Вы в любое время сможете отключить или подключить филиал.
        </p>

        <div class="crmSettingsHeader mb-4">
          <h3>Подключить филиал</h3>
          <button
            class="add-all"
            @click.stop="this.$refs.addAllCrm.justToggle()"
          >
            <span> Добавить все </span>

            <toggle-component
              style="pointer-events: none"
              :defaultState="false"
              @justToggle="addAllCrm"
              ref="addAllCrm"
            />
          </button>
        </div>

        <ul class="history__list mb-4">
          <li
            :class="[
              'crmSettings__item',
              { 'crmSettings__item--first': index === 0 },
            ]"
            v-for="(item, index) in this.$store.state.crm.companyList"
            :key="item.title"
          >
            <p
              :class="[
                'crmSettings__text',
                { 'crmSettings__text--first': index === 0 },
              ]"
              v-for="(text, i) in item.table"
              :key="text"
              v-show="text != item.action"
            >
              <span v-if="item.table[i] === 'main'">
                <toggle-component
                  :banned="item.info.status"
                  :defaultState="item.info.default"
                  @justToggle="selectMainCrm(value, item, index)"
                  ref="crmMainToggle"
                />
              </span>
              <span v-else-if="item.table[i] === 'active'">
                <toggle-component
                  :banned="item.info.status"
                  :defaultState="item.info.default"
                  @justToggle="selectActiveCrm(value, item, index)"
                  ref="crmActiveToggle"
                />
              </span>
              <span v-else>
                {{ text }}
              </span>
            </p>
          </li>
        </ul>

        <div class="not-license">
          <toggle-component :banned="true" />
          <div class="not-license__text">
            Нет активной лицензии
            <strong>
              <span v-if="crms[activeDropItem]">{{
                crms[activeDropItem]
              }}</span>
              <span v-else>crm</span></strong
            >
            - невозможно подключить данный филиал
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button-orange
            @click="CRMSyncStep2()"
            :class="{ '_loader-btn': step2Loading }"
          >
            Сохранить изменения</button-orange
          >
        </div>
      </div>

      <!-- 1 шаг -->
      <div class="crm-synh _step0" v-if="crmSynhStep === 0">
        <h4 class="crm-settings__title mb-4">Подключение к CRM</h4>

        <!--  -->

        <div
          v-if="!crmConnect && crmNoSyncData.was_synchronized_earlier && false"
          class="crm-notation"
        >
          После синхронизации, вам будет доступен бесплантый пробный тариф на 30
          дней
        </div>

        <div
          class="crm-settings__block"
          :class="{
            invalid: v$.activeDropItem.$invalid && v$.activeDropItem.$dirty,
          }"
        >
          <drop-menu :itemSelected="itemSelected" :newClass="true">
            <template v-slot:name> CRM </template>

            <template v-slot:text>
              <span class="placeholder-color" v-if="activeDropItem === ''"
                >Выберите CRM из списка</span
              >
              <span v-else>{{ crms[activeDropItem] }}</span>
            </template>

            <template v-slot:item>
              <li
                class="drop__item-wrapper"
                v-for="item of crms"
                :key="item"
                @click="selectItem(item)"
              >
                <button class="drop__item">
                  {{ item }}
                </button>
              </li>
            </template>
          </drop-menu>
        </div>

        <div
          class="crm-settings__block"
          :class="{
            invalid:
              (v$.selectCrmObj.login.$invalid &&
                v$.selectCrmObj.login.$dirty) ||
              invalidForm,
          }"
        >
          <cm-input
            v-model="selectCrmObj.login"
            :label="
              invalidForm
                ? 'Логин / Введён не верный логин или пароль'
                : 'Логин'
            "
          ></cm-input>
        </div>
        <div
          class="crm-settings__block"
          :class="{
            invalid:
              (v$.selectCrmObj.password.$invalid &&
                v$.selectCrmObj.password.$dirty) ||
              invalidForm,
          }"
        >
          <cm-input
            :password="true"
            v-model="selectCrmObj.password"
            :label="'Пароль'"
          ></cm-input>
        </div>

        <div class="checkbox-wrapper">
          <custom-checkbox
            :item="{ state: confirm }"
            @toggleCheckbox="(e) => (confirm = e.state)"
            index=""
          />
          <button class="checkbox__button" @click="selectModal(2)">
            Я даю согласие на подключение к "{{ selectedCrm }}"
          </button>
        </div>

        <div class="button-wrapper mb-5 mt-3">
          <button-orange
            class="crm-settings__button"
            :class="{ '_loader-btn': crmSyncLoading }"
            :disabled="!confirm"
            @click="CRMSyncStep1()"
          >
            Подключить
          </button-orange>
        </div>
      </div>

      <!-- 3 шаг -->
      <div class="crm-synh _step2 mb-5" v-if="crmSynhStep === 2">
        <h4 class="crm-settings__title mb-4">Настройки синхронизации</h4>
        <p class="crm-text mb-4" v-if="!crmConnect">
          Сразу после синхронизации с вашей CRM системой будет сгенерирован ключ
          авторизации. Что бы узнать подробнее читайте — помощь по разделу.
        </p>
        <p v-else class="crm-text mb-4">
          Вы в любой момент можете прервать сронизацию, нажав на кнопку
          отключить.<br />
          При отключении синхронизации будет остановлен модуль RFM анализа,
          отправка уведомлений по всем каналам, запись через мобильное
          приложение qoob будет ограничена.
        </p>
        <buttonGrey
          :class="{ '_loader-btn': crmAsyncLoading }"
          @click="confirmPopupVisible = true"
        >
          Отключить
        </buttonGrey>
      </div>

      <!-- ключ авторизации -->
      <transition name="fade">
        <div v-if="crmSynhStep !== 1 && crmConnect">
          <h4 class="key-title">Ключ авторизации</h4>
          <p class="key-subtitle fs-16 mt-4">
            Перед вами ключ авторизации, скопируйте его и вставьте в
            соответсвующее поле в вашей crm. Что бы узнать подробнее читайте —
            помощь по разделу.
          </p>
          <div class="inputWithBtn mt-5" v-if="crmSynhStep === 2">
            <inputBtn
              :label="'Ключ авторизации для yclients'"
              :disabled="true"
              v-model="crmLicense.token"
              :type="'copy'"
            >
              <template v-slot:copy1>Копировать</template>
              <template v-slot:copy2>Скопированно</template>
            </inputBtn>
          </div>
        </div>
      </transition>
    </div>
    <div class="col-status px-3 ps-4" v-if="crmSynhStep !== 1">
      <cm-card-base>
        <div>
          <div class="d-flex align-items-center">
            <div
              class="crmLogo"
              :style="{
                'background-image': 'url(' + crmLogo + ')',
              }"
            ></div>

            <div class="d-flex align-items-end">
              <div
                class="crmStatusPic"
                :style="{
                  'background-image': 'url(' + circleType + ')',
                }"
              ></div>
            </div>
          </div>

          <hr />
          <div class="d-flex justify-content-between crmStatuses">
            <div class="d-flex align-items-end w-100">
              <div class="statuses-wrapper">
                <div class="crmStatuses__item">
                  <p class="crmStatuses-item__text">CRM</p>
                  <span v-if="!crmConnect">-</span>
                  <span v-else>{{ crmLicense.name }}</span>
                </div>
                <div class="crmStatuses__item">
                  <p class="crmStatuses-item__text">Ваш аккаунт</p>
                  <span v-if="!crmConnect">-</span>
                  <span v-else>ID {{ crmLicense.acc_id }}</span>
                </div>
                <div class="crmStatuses__item">
                  <p class="crmStatuses-item__text">Лицензия</p>
                  <span v-if="!crmConnect">-</span>
                  <span v-else>до {{ crmLicense.license_expiry }}</span>
                </div>
                <div class="crmStatuses__item">
                  <p class="crmStatuses-item__text">Статус</p>
                  <span v-if="!crmConnect">-</span>
                  <span v-else>{{ "активен" }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </cm-card-base>
      <help-card
        class="mt-4"
        :title="crmHelp.title"
        :description="crmHelp.content"
      ></help-card>
      <!-- </cm-card-base> -->
    </div>
  </div>
</template>


<script>
import CmInput from "@/components/UI/inputs/CmInput";
import HelpCard from "@/components/Dashboard/HelpCard";
import CmCardBase from "@/components/UI/CmCardBase";
import customCheckbox from "@/components/Dashboard/Billing/custom-checkbox";
import infoModal from "@/components/Dashboard/Billing/info-modal";
import dropMenu from "@/components/Dashboard/Billing/drop-menu";
import toggleComponent from "@/components/Dashboard/Segments/popup-components/toggle-component";
import inputBtn from "@/components/UI/inputs/InputBtn";
import buttonOrange from "@/components/UI/buttons/ButtonOrange";
import buttonGrey from "@/components/UI/buttons/ButtonGrey";
import confirmPopup from "@/components/confirmPopup.vue";

import useValidate from "@vuelidate/core";

import { required } from "@vuelidate/validators";
import { mapState, mapMutations, mapActions } from "vuex";
import vPopup from '../../UI/v-popup.vue';

export default {
  name: "CrmSettings",
  components: {
    CmCardBase,
    HelpCard,
    CmInput,
    customCheckbox,
    infoModal,
    dropMenu,
    toggleComponent,
    inputBtn,
    buttonOrange,
    buttonGrey,
    confirmPopup,
    vPopup
  },
  data: function () {
    return {
      v$: useValidate(),
      selectCrmObj: {
        login: "",
        password: "",
      },
      crmAsyncLoading: false,
      step2Loading: false,
      selectedCrmName: "",
      selectedCrmId: "",
      crms: [],
      confirm: false,
      infoIndex: 0,
      itemSelected: false,
      activeDropItem: "",
      selectedCrm: "название crm",

      CrmLicenseExists: true,

      MainFilialIndex: 0,

      allActives: true,

      crmKey: "",

      // Данные о наличии и действительности лицензии
      status: {
        license: false,
        active: false,
      },
      isAgree: false,

      invalidForm: false,
      crmSyncLoading: false,

      confirmPopupVisible: false,
    };
  },
  methods: {
    selectCrm(crm) {
      this.selectedCrmName = crm;
      this.selectedCrm = crm;
      this.selectedCrmId = crm;
    },
    selectItem(crmName) {
      this.itemSelected = true;
      this.activeDropItem = crmName;
      this.selectedCrm = crmName;
      setTimeout(() => {
        this.itemSelected = false;
      }, 500);
    },
    selectModal(index) {
      this.infoIndex = index;
    },
    agreeToggle() {
      this.isAgree = !this.isAgree;
    },
    CRMSyncStep1() {
      this.v$.selectCrmObj.$touch();
      this.v$.activeDropItem.$touch();

      if (!this.v$.$invalid) {
        this.invalidForm = false;
        let crmSyncObj = {
          credentials: {
            login: this.selectCrmObj.login,
            password: this.selectCrmObj.password,
            crm_name: this.activeDropItem,
          },
          companies: [
            {
              id: localStorage.getItem("salon_id"),
            },
          ],
        };

        this.crmSyncLoading = true;
        // this.getCrmCompanies(crmSyncObj)
        //   .then(() => {
        //     this.invalidForm = false;
        //     this.crmSyncLoading = false;
        //   })
        this.crmSync(crmSyncObj)
          .then((request) => {
            if (request.data.status_code === 404) {
              this.crmSyncLoading = false;
              this.invalidForm = true;
            } else if (request.data.status_code === 424) {
              this.CrmLicenseExists = false;
            } else {
              this.$store.commit("setCrmStep", 2);
              this.$store.dispatch(
                "timeline",
                "клиент успешно синхронизировал CRM"
              );
              location.reload();
            }
          })
          .catch((err) => {
            if (err.response.status === 500) {
              this.$store.commit("setErr500popupOpen", true);
            }
            this.crmSyncLoading = false;
          });
      } else {
        console.log("invalid");
      }
    },
    CRMSyncStep2() {
      this.step2Loading = true;
      this.crmSync().then(() => {
        location.reload();
        localStorage.setItem("crmSynced", "success");
      });
    },
    CRMAsuncStep3() {
      this.crmAsyncLoading = true;
      this.crmAsync()
        .then(() => {
          // localStorage.setItem("crmSynced", "success");
          location.reload();
        })
        .catch((err) => {
          if (err.response.status === 500) {
            this.$store.commit("setErr500popupOpen", true);
          }
          this.crmAsyncLoading = true;
        });
    },
    selectMainCrm(value, crm, crmIndex) {
      if (!value && this.allActives) {
        this.$refs.crmActiveToggle[this.MainFilialIndex].off();
        this.setSyncCompany({ id: this.MainFilialIndex, value: false });
      }
      this.$refs.crmMainToggle.forEach((item, index) => {
        if (crmIndex - 1 !== index) {
          item.off();
          this.setMainCompany({ id: index, value: false });
        } else {
          item.on();
          this.setMainCompany({ id: crmIndex - 1, value: true });
          this.$refs.crmActiveToggle[crmIndex - 1].on();
          this.setSyncCompany({ id: crmIndex - 1, value: true });
          this.MainFilialIndex = crmIndex - 1;
        }
      });
    },
    selectActiveCrm(value, crm, crmIndex) {
      console.log(crm, crmIndex);

      let item = this.$refs.crmActiveToggle[crmIndex - 1];

      if (crmIndex - 1 === this.MainFilialIndex || !this.allActives) {
        item.on();
        this.setSyncCompany({ id: crmIndex - 1, value: true });
        console.log("yes");
      } else {
        console.log("on");
        this.setSyncCompany({ id: crmIndex - 1, value: item.toggle });
      }
    },
    addAllCrm() {
      this.$refs.crmActiveToggle.forEach((item, index) => {
        if (!item.banned) {
          if (!this.allActives) {
            item.off();
            this.setSyncCompany({ id: index, value: false });
            if (index === this.MainFilialIndex) {
              item.on();
              this.setSyncCompany({ id: index, value: true });
            }
          } else {
            item.on();
            this.setSyncCompany({ id: index, value: true });
          }
        }
      });
      this.allActives = !this.allActives;
    },

    ...mapMutations({
      addCrmStep: "setCrmStep",
      setMainCompany: "setMainCompany",
      setSyncCompany: "setSyncCompany",
    }),
    ...mapActions({
      crmSync: "crmSync",
      getCrmCompanies: "getCrmCompanies",
      crmAsync: "crmAsync",
    }),
  },
  computed: {
    // В зависимости от статуса лицензии какую иконку выводить
    circleType() {
      if (this.crmConnect) {
        if (this.crmLicense.isActive) {
          return require("../../../assets/tick-circle.svg");
        } else {
          return require("../../../assets/info-circle.svg");
        }
      }
      return require("../../../assets/close-circle.svg");
    },

    ...mapState({
      crmConnect: (state) => state.crm.crmConnect,
      crmSynhStep: (state) => state.crm.crmSynhStep,
      filials: (state) => state.crm.companyList,
      filialsInfo: (state) => state.crm.companyListInfo,
      crmLicense: (state) => state.crm.crmLicense,
      crmLogo: (state) => state.crm.crmLogo,
      loadingCrmStatus: (state) => state.crm.loadingCrmStatus,
      crmNoSyncData: (state) => state.crm.crmNoSyncData,
      crmHelp: (state) => state.crm.crmHelp,
    }),
  },
  validations() {
    return {
      selectCrmObj: {
        login: { required },
        password: { required },
      },
      activeDropItem: { required },
    };
  },
  mounted() {
    this.$store.dispatch("getCrmHelp");
    this.crms = this.$store.state.crm.crmList;
  },
};
</script>

<style scoped>
.crm-notation {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding: 20px;
  border-radius: 20px;
  background: #f6d29c61;
  margin: 25px 0;
}

.not-license {
  display: flex;
  align-items: center;
}

.crm-text {
  font-size: 16px;
  line-height: 30px;
}

.not-license__text {
  margin-left: 15px;
}

.crmStatuses__item {
  display: flex;
  margin-bottom: 10px;

  font-size: 16px;
  line-height: 30px;
}

.crmStatuses-item__text {
  width: 125px;
  margin-right: 15px;
}

.sync-button {
  width: 150px;
  height: 48px;
}

.crmSettingsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-all {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #f7f9fc;
  border-radius: 12px;
  padding: 10px 24px;
  transition: 0.2s;
}
.add-all:hover {
  background: #eaebee;
  cursor: pointer;
}
.add-all span {
  margin-top: 1px;
}

.crm-synс._step1 {
  max-width: 731px;
}

.crmSettings__item--first {
  color: #000;
  font-weight: 500;
}

.crmSettings__text {
  padding: 10px 0;
}

.crmSettings__text:first-child {
  color: #000;
  font-weight: 500;
}

.crmSettings__item {
  display: grid;
  grid-template-columns: 1.3fr 4fr 2fr 2fr;
  align-items: center;
  border-bottom: 1px solid #eff0f0;
}

.drop__item-wrapper:hover {
  background-color: #f6f9fc;
}

.drop__item-wrapper:active {
  background-color: #f0f3f8;
}

.drop__item {
  display: flex;
  align-items: center;

  height: 45px;
  padding-left: 20px;

  font-size: 15px;
}

.modalAm-enter-active,
.modalAm-leave-active {
  transition: 0.3s all ease;
}

.modalAm-enter-from,
.modalAm-leave-to {
  right: -700px;
  opacity: 0;
}

.checkbox-wrapper {
  display: flex;
  margin-bottom: 20px;
}
.checkbox__button {
  margin-left: 20px;

  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #328fe4;
}

.colPadding {
  padding-left: 1rem;
  padding-right: 2.2rem;
}
.crmLogo {
  height: 41px;

  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}
.crmStatusPic {
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.crmStatuses {
  font-size: 0.875rem;
  line-height: 1.875rem;
}
hr {
  color: #c1c1c1;
}
.col-status {
  flex: 0 0 auto;
  width: 48%;
}

.crm-settings__title {
  padding-bottom: 20px;
  margin-bottom: 25px !important;

  border-bottom: 1px solid #eff0f0;

  font-size: 20px;
}

.key-title {
  font-size: 20px;
}

.srm-setting__input {
  border-radius: 0.75rem;
}

.crm-settings__block {
  margin-bottom: 20px;
}

.key-title {
  font-size: 20px;
}

.key-subtitle {
  font-size: 16px;
  line-height: 30px;
}

.text-wrapper {
  font-size: 16px;
  line-height: 30px;
}

.crm-sync__text {
  font-size: 16px;
  line-height: 30px;
}

.crm-settings__subtitle {
  margin-bottom: 20px;

  font-size: 16px;
  line-height: 30px;
}

.statuses-wrapper {
  width: 75%;
}

@media (max-width: 1600px) {
  .statuses-wrapper {
    width: 75% !important;
  }

  .crmStatuses__item {
    font-size: 12px;
    line-height: 18px;
  }
}

@media (max-width: 1280px) {
  .crm-sync__text {
    font-size: 14px;
    line-height: 24px;
  }

  .text-wrapper {
    font-size: 14px;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .crm-settings__wrapper {
    margin: 0 !important;
  }

  .crm-settings__title {
    display: flex;
    align-items: center;

    height: 61px;
    padding: 0 !important;

    border-bottom: 1px solid #eff0f0;

    font-size: 16px;
  }

  .crm-settings__subtitle {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 24px;
  }

  .col-status {
    display: none;
  }

  .colPadding {
    padding-left: 15px;
    padding-right: 15px;
  }

  .button-wrapper {
    margin-top: 30px !important;
  }

  .crm-settings__button {
    width: 100%;
    height: 48px;

    font-size: 16px;
  }

  .key-title {
    font-size: 16px;
  }

  .key-subtitle {
    max-width: 520px;
    margin-top: 24px !important;

    font-size: 14px;
    line-height: 24px;
  }

  .key-label {
    font-size: 14px;
  }

  .inputWithBtn {
    margin-top: 35px !important;
  }

  .srm-setting__input-wrapper {
    height: 48px;
  }

  .key-input__block {
    margin-top: 10px;
  }

  .srm-setting__input {
    height: 48px;
    border-radius: 12px 0 0 12px;

    font-size: 14px;
  }

  .inputBtn {
    font-size: 14px;
  }
}

@media (max-width: 550px) {
  .button-off {
    width: 100%;
  }
}
</style>




