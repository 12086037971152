<template>
  <div class="label-input if-gap-1">
    <label :for="id" class="tit-h4" :style="`min-width: ${labelWidth}%;`">
      {{label}}
    </label>
    <input
    class="default-input mb-0"
      :type="type"
      :id="id"
      :disabled="disabled"
      :value="modelValue"
      @input="this.$emit('update:modelValue', lazy ? $event.target.value.split(' ').join('') : $event.target.value)"
      v-maska="mask ? mask : ''"
      :placeholder="placeholder ? placeholder : ''"
      :class="{invalid:invalid}"
    />
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: String,
    label: String,
    labelWidth: {
      default: 20,
      type: Number
    },
    invalid: {
      default: false,
      type: Boolean
    },
    mask: String,
    placeholder: String,
    lazy: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style>
.label-input {
  display: flex;
  align-items: center;
}
.label-input input {
  height: 48px;
}
</style>
<style>
@media (max-width: 768px) {
  .label-input {
    flex-direction: column;
    row-gap: 5px;
    align-items: start;
  }
  .label-input label {
    color: #6D6D6D;
    font-weight: 500;
  }
}
</style>