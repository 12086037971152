<template>
  <div class="types__wrapper">
    типы уведомлений

    <div class="segment-item" v-if="$store.state.notification.types.rules">
      <button class="types__toggle" @click="toggleSegment(index)">
        <span class="toggle__main">
          <span class="image__wrapper">
            <!-- <img
              :src="require('../../../assets/img/smile' + index + '.png')"
              alt=""
            /> -->
          </span>
          <span class="toggle__title">Типы уведолмений</span>
        </span>
        <img
          :class="['arrow-icon', { 'arrow--active': index === activeSegment }]"
          src="../../../assets/arrow-long.svg"
          alt=""
        />
      </button>

      <transition name="segmentAm">
        <div class="item__content">
          <div class="types__header">
            <p class="header__item">Название уведомления</p>
            <p class="header__item">Тип уведомления</p>
            <p class="header__item">Время отправки</p>
            <p class="header__item">Очередь отправки</p>
            <p class="header__item">Действие</p>
          </div>

          <ul class="types__list">
            <li
              class="types__item"
              v-for="rule in $store.state.notification.types.rules"
              :key="rule.uid"
            >
              <div class="item__wrapper item__wrapper1">
                <p class="type__text">{{ rule.name }}</p>
              </div>
              <p class="item__text--mobile3">Время отправки</p>
              <div class="item__wrapper item__wrapper2">
                <p class="type__text">
                  {{ "Системный" }}
                </p>
              </div>
              <p class="item__text item__text--mobile2">Время отправки</p>
              <div class="item__wrapper item__wrapper3">
                <div class="type__text">{{ getDuration(rule.config) }}</div>
              </div>

              <p class="item__text item__text--mobile1">Очередь отправки</p>
              <div class="item__wrapper item__wrapper4">
                <p class="chanel--mobile">Очередь отправки</p>
                <drop-menu
                  class="item__drop"
                  :itemSelected="'deee'"
                  v-if="types.routes"
                >
                  <template v-slot:name>
                    <p class="drop__label"></p>
                  </template>

                  <template v-slot:text>{{
                    types.routes.find((i) => i.uid === rule.route)
                      ? types.routes.find((i) => i.uid === rule.route).name
                      : "Не уставлено"
                  }}</template>
                  <template v-slot:item>
                    <div
                      class="drop__item-wrapper"
                      v-for="(item, index) in types.routes"
                      :key="index"
                    >
                      <button
                        class="drop__item"
                        @click="
                          $store.dispatch('udpadeRuleField', {
                            rule: rule,
                            value: types.routes[index].uid,
                            field: 'route',
                          })
                        "
                      >
                        {{ item.name }}
                      </button>
                    </div>
                  </template>
                </drop-menu>
              </div>
              <div class="item__wrapper item__wrapper7">
                <div class="action-wrapper">
                  <button
                    class="types__action edit__action"
                    @click="openTypePopup(rule)"
                  >
                    <img src="../../../assets/edit.svg" alt="" />
                  </button>
                  <button
                    :class="[
                      'types__action',
                      'toggle__action',
                      { 'add-toggle--active': rule.is_active },
                    ]"
                    @click="
                      $store.dispatch('udpadeRuleField', {
                        rule: rule,
                        value: !rule.is_active,
                        field: 'is_active',
                      })
                    "
                  >
                    <span
                      :class="[
                        'toggle-wrapper',
                        { 'wrapper--active': rule.is_active },
                      ]"
                    >
                      <span class="action__line action__line1"></span>
                      <span class="action__line action__line2"></span>
                    </span>
                  </button>
                </div>
              </div>
            </li>
          </ul>

          <div class="add-wrapper" v-if="false">
            <div class="add-left">
              <img
                class="add-image"
                src="../../../assets/add-square.svg"
                alt=""
              />
              <p class="add-title">Новый тип уведомления для клиента</p>
            </div>

            <button-grey class="add-button" @click="openNewTypePopup"
              >Создать</button-grey
            >
          </div>
        </div>
      </transition>
    </div>

    <templates-editor
      :editorType="''"
      :notification="currentRedacting"
      @toggleEditor="closeEditor"
      v-if="currentRedacting.uid"
    />

    <confirm-popup
      :popupVisible="confirmPopupState"
      @popupClose="confirmPopupState = !confirmPopupState"
      @cancelConfirm="
        (confirmPopupState = !confirmPopupState),
          (this.$refs.disabledAll.toggle = messegesEnabled)
      "
      @confirming="
        this.$store
          .dispatch('toggleMessges')
          .then(() => (this.$refs.disabledAll.toggle = messegesEnabled))
      "
      :confirmString="messegesEnabled ? 'включить' : 'отключить'"
      :emitValue="messegesEnabled"
      >{{
        messegesEnabled
          ? "При включении всех уведомлений, включится отправка всех уведомлений"
          : "При отключении всех уведомлений, отключится отправка всех уведомлений"
      }}
    </confirm-popup>
  </div>
</template>

<script>
/* eslint-disable */
import buttonGrey from "@/components/UI/buttons/ButtonGrey";
import TemplatesEditor from "@/components/TemplatesEditor";
import dropMenu from "@/components/Dashboard/Billing/drop-menu";
import getDuration from "@/functions/getDuration.js";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import confirmPopup from "../../../components/confirmPopup.vue";
export default {
  name: "NewNotificationTypes",
  components: {
    buttonGrey,
    TemplatesEditor,
    dropMenu,
    confirmPopup,
  },
  setup() {
    const store = useStore();

    let confirmPopupState = ref(false);

    const types = computed(() => store.state.notification.types);

    const notificationLoading = computed(
      () => store.state.notification.notificationLoading
    );

    const messegesEnabled = computed(
      () => store.state.notification.messegesEnabled
    );

    function addType(notification) {
      console.log(notification);
      notification.state = !notification.state;

      store.dispatch("toggleNotification", notification);
    }

    const itemSelected = ref(false);

    function selectDrop(notification, index) {
      notification.channels.action_time_choice_id = index;
      itemSelected.value = true;
      setTimeout(() => {
        itemSelected.value = false;
      }, 300);
    }

    function toggleEditor(notification) {
      notification.modalState = !notification.modalState;
    }

    onMounted(() => {
      store.dispatch("getAllTypes");
    });

    function closeEditor() {
      currentRedacting.value = {};
    }

    let currentRedacting = ref({});

    function openNewTypePopup() {
      openTypePopup(store.getters.getEmptyType)
    }

    function openTypePopup(rule) {
      console.log(rule);
      
      let currentRule = structuredClone(rule)

      currentRule.rule_templates.forEach(rule_template => rule_template.visible = types.value.availableChannels.includes(rule_template.channel))

      console.log(rule, currentRule);
      currentRedacting.value = currentRule;
    }

    return {
      addType,
      selectDrop,
      toggleEditor,
      itemSelected,
      notificationLoading,
      messegesEnabled,
      confirmPopupState,
      types,
      getDuration,
      currentRedacting,
      closeEditor,
      openNewTypePopup,
      openTypePopup,
    };
  },
};
</script>

<style scoped>
.item__text--mobile1 {
  display: none;
}

.item__text--mobile2 {
  display: none;
}

.item__text--mobile3 {
  display: none;
}

.chanel--mobile {
  display: none;
}

.drop__item-wrapper:hover {
  background-color: #f6f9fc;
}

.drop__item-wrapper:active {
  background-color: #f0f3f8;
}

.drop__item {
  display: flex;
  align-items: center;

  height: 45px;
  padding-left: 20px;

  font-size: 15px;
}

.segment-item {
  padding-bottom: 15px;
}

.types__wrapper {
  position: relative;

  max-width: 1600px;
  padding-bottom: 50px !important;
}

.disable-all {
  position: absolute;
  top: -70px;
  right: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 180px;
  height: 40px;
  border-radius: 12px;
  padding: 0 10px;

  font-size: 14px;

  background-color: #f7f9fc;
}

.disable-all__toggle {
  margin-left: 10px;
}

.types__toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 80px;
  padding: 0 25px;
  border-radius: 12px;

  background-color: #f6f9fc;
}

.toggle__main {
  display: flex;
  align-items: center;
}

.image__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;
  margin-right: 30px;

  box-shadow: 0 1px 2px rgba(21, 21, 21, 0.2);
  border-radius: 12px;

  background: #ffffff;
}

.image__wrapper img {
  width: 26px;
  height: 26px;
}

.toggle__title {
  font-size: 16px;
  font-weight: 500;
  color: #1e2022;
}

.arrow-icon {
  width: 21px;
  height: 9px;

  transition: 0.3s all ease;
}

.arrow--active {
  transform: rotateZ(180deg);
}

.types__header {
  display: grid;
  grid-template-columns: 4.1fr 1.2fr 2.5fr 2fr 0.7fr;
  grid-column-gap: 30px;

  padding: 0 20px;
  margin-top: 10px;
  margin-bottom: 5px;

  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.types__item {
  display: grid;
  grid-template-columns: 4.1fr 1.2fr 2.5fr 2fr 0.7fr;
  grid-column-gap: 30px;

  padding: 0 20px;

  border-radius: 12px;
}

.item__wrapper {
  display: flex;
  align-items: center;

  height: 80px;
}

.type__text {
  max-width: 500px;

  font-size: 16px;
  line-height: 24px;
}

.drop-toggle {
  width: 100%;
  max-width: 253px;
  height: 40px;
  border-radius: 12px;

  background-color: #f0f3f8;
}

.action-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.types__action {
  width: 40px;
  height: 40px;

  border-radius: 12px;
  border: 1px solid #328fe4;

  transition: 0.3s all ease;
}

.toggle__action {
  position: relative;
  z-index: 2;
}

.edit__action {
  margin-right: 5px;
  border: 1px solid #ffc549;
}

.edit__action img {
  width: 20px;
  height: 20px;
}

.toggle-wrapper {
  position: relative;

  display: block;
  width: 100%;
  height: 100%;

  transform: rotateZ(0deg);
  transition: 0.3s all ease;
}

.action__line {
  position: absolute;
  top: 18px;
  left: 10px;

  display: block;
  width: 18px;
  height: 1px;
  border-radius: 2px;

  background-color: #000;
}

.action__line2 {
  transform: rotateZ(90deg);
}

.add-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 80px;
  padding-left: 50px;
  padding-right: 20px;

  border-radius: 30px;
  border: 1px dashed #a1a2a2;
}

.add-left {
  display: flex;
}

.add-image {
  width: 24px;
  height: 24px;
}

.add-title {
  margin-left: 45px;

  font-size: 16px;
  line-height: 24px;
  color: #a1a2a2;
}

.add-button {
  width: 180px;
}

.types--disabled {
  position: relative;
  overflow: hidden;
}

.types--disabled::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(228, 247, 255, 0.35);
}

.add-toggle--active {
  border: 1px solid #ffc549;
}

.wrapper--active {
  transform: rotateZ(45deg);
}

.non-radius--full {
  border-radius: 12px;
}

.non-radius--top {
  border-radius: 0 0 12px 12px;
}

.non-radius--bottom {
  border-radius: 12px 12px 0 0;
}

.non-radius--null {
  border-radius: 0;
}

.segmentAm-enter-active {
  animation: 0.5s segmentKeys ease;
}

.segmentAm-leave-active {
  animation: 0.5s segmentKeys ease reverse;
}

@keyframes segmentKeys {
  0% {
    height: 0;
    overflow: hidden;
  }
  100% {
    height: 1450px;
    overflow: hidden;
  }
}
</style>

<style scoped>
@media (max-width: 1600px) {
  .disable-all {
    font-size: 13px;
  }

  .types__header {
    grid-template-columns: 3.2fr 1.2fr 2.3fr 3fr 0.9fr;

    font-size: 13px;
  }

  .types__item {
    grid-template-columns: 3.2fr 1.2fr 2.3fr 3fr 0.9fr;
  }

  .type__text {
    font-size: 15px;
  }
}

@media (max-width: 1440px) {
  .types__toggle {
    height: 50px;
  }

  .image__wrapper {
    width: 32px;
    height: 32px;
    border-radius: 10px;
  }

  .image__wrapper img {
    width: 18px;
    height: 18px;
  }

  .toggle__title {
    font-size: 14px;
  }

  .arrow-icon {
    width: 15px;
    height: 7px;
  }

  .types__header {
    font-size: 12px;
  }

  .type__text {
    font-size: 14px;
  }
}

@media (max-width: 1280px) {
  .disable-all {
    width: 150px;
    height: 30px;

    font-size: 11px;
  }

  .types__header {
    grid-template-columns: 3fr 1.4fr 2.3fr 3fr 0.7fr;
    grid-column-gap: 20px;

    font-size: 10px;
  }

  .types__item {
    grid-template-columns: 3fr 1.4fr 2.3fr 3fr 0.7fr;
    grid-column-gap: 20px;
  }

  .type__text {
    font-size: 14px;
    line-height: 20px;
  }

  .types__action {
    width: 25px;
    height: 25px;
    border-radius: 6px;
  }

  .edit__action img {
    width: 14px;
    height: 14px;
  }

  .action__line {
    top: 11px;
    left: 6px;
    width: 11px;
  }
}

@media (max-width: 1024px) {
  .disable-all {
    display: none;
  }

  .types__header {
    display: none;
  }

  .item__wrapper3 {
    display: none;
  }

  .types__item {
    grid-template-columns: 1fr 0.25fr 0.25fr 0.25fr;
    grid-column-gap: 35px;
    grid-template-rows: auto auto auto;
    grid-row-gap: 20px;

    padding: 15px;
    border-bottom: 1px solid #eff0f0;
  }

  .item__wrapper {
    height: auto;
    align-items: flex-start;
    font-size: 12px;
  }

  .item__wrapper1 {
    grid-column: 1/5;
    grid-row: 1/2;
  }

  .action-wrapper {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .edit__action {
    margin-right: 0;
    margin-top: 15px;
  }

  .item__wrapper7 {
    grid-column: 5/6;
    grid-row: 1/2;
  }

  .item__text--mobile1 {
    display: block;
    grid-column: 1/3;
    grid-row: 2/3;

    font-size: 15px;
  }

  .item__text--mobile2 {
    display: block;
    font-size: 15px;
  }

  .chanel--mobile {
    display: block;
    margin-bottom: 15px;

    font-size: 15px;
  }

  .item__wrapper4 {
    grid-column: 3/4;
    grid-row: 2/3;
    flex-direction: column;
  }

  .item__wrapper5 {
    grid-column: 4/5;
    grid-row: 2/3;
    flex-direction: column;
  }

  .item__wrapper6 {
    grid-column: 5/6;
    grid-row: 2/3;
    flex-direction: column;
  }

  .item__text--mobile3 {
    grid-column: 1/3;
    grid-row: 3/4;
  }

  .item__wrapper2 {
    grid-column: 3/6;
    grid-row: 3/4;

    justify-content: center;
    align-items: center;

    height: 30px;
    border-radius: 12px;
    background-color: #f0f3f8;
  }

  .type__text {
    font-size: 14px;
  }

  .types__action {
    width: 40px;
    height: 40px;
    border-radius: 12px;
  }

  .edit__action img {
    width: 20px;
    height: 20px;
  }

  .action__line {
    top: 18px;
    left: 11px;
    width: 17px;
  }

  .add-wrapper {
    display: none;
  }
}
</style>