<template>
  <div class="platform-link">
    <transition name="fade" mode="out-in">
      <div v-if="state" class="platform-link__flex">
        <input
          class="default-input _small weight-500"
          @click="openRedactor()"
          :class="[{ '_loader-input': loader }, 'platform-link__input']"
          v-model="linkVal"
        />
        <button class="btn-white _small mw-124px" @click="deleteLink">Удалить</button>
      </div>

      <div v-else-if="!state && type === 'create'" class="platform-link__red">
        <div class="tit-h4 mb-3">Добавление ссылки</div>

        <div class="platform-link__add">
          <div>
            <span class="text">Название</span>
            <input
              type="text"
              v-model="$store.state.review.newLink.name"
              class="default-input mb-3 _small"
            />
          </div>
          <div>
            <span class="text">Сылка</span>
            <input
              type="text"
              v-model="$store.state.review.newLink.link"
              class="default-input _small"
            />
          </div>
        </div>

        <div class="d-flex gap-3">
          <button class="btn-orange _small" @click="createLink">
            Добавить
          </button>
          <button class="btn-white _small" @click="hideRedactor">
            Отменить
          </button>
        </div>
      </div>

      <div v-else class="platform-link__red">
        <div class="tit-h4 mb-3">Редактирование ссылки</div>

        <span class="text">Название</span>
        <input
          type="text"
          v-model="
            $store.state.review.platformLinks.find((i) => i.id === link.id)
              .name
          "
          class="default-input mb-3 _small"
        />

        <span class="text">Сылка</span>
        <input
          type="text"
          v-model="
            $store.state.review.platformLinks.find((i) => i.id === link.id)
              .link
          "
          class="default-input _small mb-3"
        />

        <div class="d-flex gap-3 flex-wrap">
          <button class="btn-orange _small" @click="updateLink">
            Сохранить
          </button>
          <button class="btn-white _small" @click="deleteLink">Удалить</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { watchEffect } from "@vue/runtime-core";
export default {
  components: {},
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    label: String,
    modelValue: String,
    link: Object,
    type: String,
  },
  setup(props, { emit }) {
    const $store = useStore();

    let state = ref(true);

    let linkVal = ref("");

    watchEffect(() => {
      if (props.type !== "create") {
        linkVal.value = props.link.name;
      } else {
        state.value = false;
      }
    });

    function openRedactor() {
      state.value = false;
    }

    function hideRedactor() {
      state.value = true;

      emit("cancelRedactor");

      $store.state.telegram.newLink = {
        id: null,
        name: "",
        link: "",
      };
    }

    function updateLink() {
      state.value = true;
      emit("updateLink", props.link.id);
    }

    function createLink() {
      state.value = true;

      emit("createLink");
    }

    function deleteLink() {
      state.value = true;
      emit("deleteLink", props.link.id);
      console.log(props.link);
    }

    return {
      state,
      openRedactor,
      hideRedactor,
      linkVal,
      updateLink,
      createLink,
      deleteLink,
    };
  },
};
</script>

<style scoped lang="scss">
.platform-link__flex {
  .default-input {
    cursor: pointer;
  }
}
.platform-link__red {
  display: flex;
  flex-direction: column;
  background: rgba(236, 236, 236, .31);
  padding: 10px;
  border-radius: 5px;

}
.platform-link .input-area {
  cursor: pointer !important;
}
.platform-link__input input {
  font-weight: 600;
  color: #000 !important;
}
.platform-link__input {
  margin-top: 0 !important;
}
.platform-link__add {
  display: flex;
  justify-content: space-between;

  div {
    width: 49%;
  }
}
.platform-link__flex {
  display: flex;
  gap: 33px;

  @media (max-width:567px) {
    gap: 10px;
  }
}
</style>