<template>
  <div >
    <div :class="{'_loader-corner': loadingTelegram}" class="h-100">
      <cm-card
      height="100%"
      class="itemSecond smsCard"
      @click="$router.push('/dashboard/telegram')"
      :specialClass="'status-card'"
    >
      <div class="d-flex justify-content-between">
        <div
          class="servicesSmsLogo"
          :style="{
            'background-image': 'url(' + require('@/assets/telegram-logo.jpg') + ')',
          }"
        ></div>
        <!--      <img class="servicesSmsLogo" src="@/assets/sms.png" alt="">-->
        <div>
          <!-- <toggle-component :type="2" :defaultState="false"  /> -->
          <status-icon
            v-if="!loadingSmsStatus"
            class="card-icon-status"
            :type="getTypeIcon()"
          ></status-icon>
        </div>
      </div>
      <div class="servicesSmsContent fs-14">
        <div class="w-100">
          <div class="d-flex w-100">
            <p class="w-50">Телеграм</p>
            <p class="">{{telegramStatus ? 'активен' : 'не активен'}} </p>
          </div>
          <div class="d-flex w-100">
            <p class="w-50"></p>
            <p class="w-50"></p>
          </div>
        </div>
      </div>
    </cm-card>
    </div>
  </div>
</template>

<script>
import CmCard from "@/components/UI/CmCard";
import StatusIcon from "../../../components/UI/status-icon.vue";
import { mapState } from "vuex";

export default {
  name: "ServicesSms",
  components: {CmCard, StatusIcon },
  data: function () {
    return {
      active: true,
      SmsAgregatorOn: "",
      SmsAgregatorStatus: "",
      popupState: false,
    };
  },
  watch: {},
  computed: {
    ...mapState({
      crmConnect: (state) => state.crm.crmConnect,
      licenseActive: (state) => state.tarif.licenseInfo.isActive,
      license: (state) => state.tarif.license,
      licenseExisted: (state) => state.tarif.licenseExisted,
      loadingTelegram: state => state.telegram.loadingTelegram,
      telegramStatus: state => state.telegram.status
    }),
  },
  methods: {
    getTypeIcon() {
      if (!this.crmConnect || !this.licenseActive) {
        return 0;
      } else {
        if (this.licenseActive && this.telegramStatus) {
          return 2;
        } else {
          return;
        }
      }
    },
  },
};
</script>

<style scoped>
.smsCard {
  cursor: pointer;
}
.text-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.text-column {
  font-size: 14px;
  line-height: 24px;
}

.servicesSmsContent {
  display: flex;
  align-items: center;
  margin-top: 25px;

  font-size: 14px;
  line-height: 24px;
}
.servicesSmsLogo {
  height: 33px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}
.itemSecond {
  height: 100% !important;
  padding: 25px;
}

@media (max-width: 1600px) and (min-width: 1024px) {
  .itemSecond {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .servicesSmsContent {
    margin-top: 15px;

    font-size: 12px;
    line-height: 20px;
  }

  .text-column {
    font-size: 12px;
    line-height: 20px;
  }

  .servicesSmsLogo {
    height: 25px;
  }
}

@media (max-width: 1280px) and (min-width: 1024px) {
  .itemSecond {
    padding: 15px 20px;
  }

  .servicesSmsContent  {
    font-size: 10px;
    line-height: 18px;
  }

  .servicesSmsContent {
    margin-top: 10px;
  }

  .servicesSmsLogo {
    height: 20px;
  }
}

@media (max-width: 768px) and (min-width: 551px) {
  .itemSecond {
    padding: 15px 25px;
  }

  .servicesSmsLogo {
    height: 25px;
  }

  .servicesSmsContent {
    margin-top: 15px;
  }

  .servicesSmsContent  {
    font-size: 12px;
    line-height: 24px;
  }
}

@media (max-width: 550px) {
  .servicesSmsContent {
    margin-top: 15px;
  }

  .servicesSmsContent  {
    font-size: 12px;
    line-height: 20px;
  }

  .servicesSmsLogo {
    height: 24px;
  }
}
</style>