<template>
  <div
    class="drop-list"
    @mouseout="toggleDrop(false)"
    @mouseover="toggleDrop(true)"
    :class="{ _open: openList }"
  >
    <button class="drop-list__value">{{ currentValue }}</button>
    <ul class="drop-list__list" v-show="openList">
      <li class="drop-list__item" v-for="(item, index) in list" :key="index">
        <button @click="setCurrentValue(item, index)">{{ item }}</button>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, ref } from "vue";
export default {
  props: {
    list: {
      type: Array,
    },
    placeholder: String,
    modelValue: Number,
  },
  setup(props, { emit }) {
    let currentValue = computed(() => {
      return props.modelValue >= 0
        ? props.list[props.modelValue]
        : props.placeholder;
    });
    let openList = ref(false);

    function setCurrentValue(value, index) {
      openList.value = false;
      emit("update:modelValue", index);
    }

    function toggleDrop(value) {
      openList.value = value;
    }

    return {
      currentValue,
      openList,
      setCurrentValue,
      toggleDrop,
    };
  },
};
</script>

<style scoped lang='scss'>
.drop-list {
  display: flex;
  flex-direction: column;
  position: relative;

  &._open {
    .drop-list__value:before {
      transform: rotate(-180deg);
    }
    .drop-list__value {
      border-radius: 12px 12px 0 0;
    }
  }
  &__value {
    background: #f6f9fc;
    display: block;
    font-size: 16px;
    text-align: left;
    padding: 12px 0 12px 20px;
    border-radius: 12px;
    position: relative;
  }
  &__value:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 1.51px;
    height: 24px;
    background: rgba(0, 0, 0, 0.1);
    right: 51px;
  }
  &__value:before {
    content: "";
    transition: 0.1s;
    position: absolute;
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url("~@/assets/arrow-select.svg") no-repeat center;
    right: 17px;
  }

  &__list {
    transition: 0.2s;
    position: absolute;
    top: 93%;
    left: -0.5px;
    z-index: 5;
    width: calc(100% + 1.5px);
    box-sizing: border-box;
    border: 1px solid #f0f3f8;
    border-top: none;
    border-radius: 0 0 12px 12px;
    background-color: #fff;

    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #dfdfdd rgba(0, 0, 0, 0.3); /* scroll thumb and track */

    &::-webkit-scrollbar {
      width: 12px;
      display: block;
    }

    &::-webkit-scrollbar-track {
      background: #dfdfdd;
      border-radius: 0 0 10px 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 20px;
    }
  }

  &__item button {
    display: flex;
    align-items: center;
    height: 45px;
    padding-left: 20px;
    font-size: 15px;
    width: 100%;
    transition: 0.1s;

    &:hover {
      background-color: #f6f9fc;
    }
    
  }
  &__item:last-child button {
      border-radius: 0 0 10px 10px;
    }
}
</style>