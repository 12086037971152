<template>
  <div class="wrapInput">
    <div class="d-flex justify-content-between inputHeader">
      <div>
        <label class="contact__label" v-if="contact.name">{{ contact.name }}</label>
      </div>
    </div>
    <div class="main-wrapper d-flex">
      <div class="d-flex align-items-center iconWrap">
        <img :src="require('../../../assets/mobileApp_' + contact.network_type_id + '.svg')" alt="" class="icon">

      </div>
      <div class="w-100">
        <input class="app__input" type="text" @input="$emit('update:modelValue', $event.target.value)" :value="modelValue">
      </div>
      <button @click="$emit('delete', contact.id )" class="inputBtn">
        <span class="button-text">Удалить</span>
        <span class="button-icon">
          <img src="../../../assets/close-square.svg" alt="">
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileAppContact",
  props: {
    contact: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: String
    },
    index: {
      type: Number
    }
  }
}
</script>

<style scoped>
.inputHeader {
  align-items: center;
  position: relative;
}

.main-wrapper {
  padding-left: 25px;
  padding-right: 15px;
  border-radius: 12px;
  background: #F6F9FC;
}

input {
  flex-direction: row;
  padding: 0.75rem 0;
  background: #F6F9FC;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  color: #000000;
}
.wrapInput {
}
input:active, :hover, :focus {
  outline: 0;
  outline-offset: 0;
}
input::placeholder,
input::-webkit-input-placeholder {
  color: #A1A2A2;
}
label {
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #6D6D6D;
  margin-bottom: 1px;
}
.iconWrap{
  display: flex;
  align-items: center;
  background: #F6F9FC;
  border-radius: 0.75rem 0 0 0.75rem;
  padding: 0 1.1rem;
}
.icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.inputBtn{
  display: flex;
  align-items: center;
  background: #F6F9FC;
  border-radius: 0 0.75rem 0.75rem 0;
  color: #6D6D6D;
}
.inputBtn > div{
  background: #F6F9FC;
  padding: 0 1.5rem;
  border-left: solid 1px #e7e7e7;
  color: #6D6D6D;
}
.inputBtn:hover {
  cursor: pointer;
}
.button-icon {
  display: none;
}

.app__input {
  height: 48px;

  padding: 0;
  margin-left: 15px;

  font-size: 14px;
}

.contact__label {
  margin-bottom: 5px;

  font-size: 14px;
  line-height: 24px;
}

.iconWrap {
  padding: 0!important;
}

.icon {
  width: auto;
  height: auto;
}

.button-text {
  font-size: 16px;
}

@media (max-width: 1280px) {
  .button-text {
    display: none;
  }

  .button-icon {
    display: block;
  }
}
</style>

<style scoped>
@media (max-width: 768px) {

  .button-text {
    display: none;
  }

  .button-icon {
    display: block;
  }

  .inputBtn {
    padding-left: 15px;
    margin: 12px 0;

    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }


}
</style>
