<template>
  <div :class="{ '_loader-corner': crmLoading }">
    <services-card
      v-if="!isExists && !isActive"
      :img="img"
      :text="content.notSync.text"
      :btn-text="content.notSync.btnText"
      btn-to-next
      @btn-action="this.$store.dispatch('crmModalVisible')"
      :statusIcon="0"
    ></services-card>

    <services-card
      class="crm-active"
      @click="this.$store.dispatch('crmModalVisible')"
      v-else-if="isActive && isExists"
      :img="img"
      :statuses="crm.statuses"
      :statusIcon="2"
      :specialClass="'status-card'"
    ></services-card>

    <services-card
      class="crm-active"
      @click="this.$store.dispatch('crmModalVisible')"
      v-else-if="isActive && !isExists"
      :img="img"
      :statuses="crm.statuses"
      :statusIcon="1"
      :specialClass="'status-card'"
    ></services-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ServicesCard from "@/components/Dashboard/Services/ServicesCard";
export default {
  name: "ServicesCrm",
  components: { ServicesCard },
  data: function () {
    return {
      crm: {},
    };
  },
  computed: {
    ...mapState({
      content: (state) => state.texts.dashboard.services.crm,
      integrationActive: (state) => state.crm.crmLicense.marketplace_integration,
      isActive: (state) => state.crm.crmConnect,
      isExists: (state) => state.crm.crmLicense.isExists,
      licenseInfo: (state) => state.crm.crmLicense,
      crmLoading: (state) => state.crm.loadingCrmStatus,
      img: (state) => state.crm.crmLogo,
    }),
  },

  mounted() {
    this.crmStatus();
  },

  watch: {
    licenseInfo() {
      this.crmStatus();
    },
  },
  methods: {
    // getCrmLogo() {
    //   return this.isExists
    //     ? this.glb.getMediaUrl(this.crm.img)
    //     : this.glb.getMediaUrl(this.content.notSync.img);
    // },
    crmStatus() {
      if (this.isExists || this.isActive) {
        this.crm.statuses = [
          {
            name: this.content.sync.crm,
            value: this.licenseInfo.name,
          },
          {
            name: this.content.sync.accId,
            value: `ID ${this.licenseInfo.acc_id}`,
          },
          {
            name: this.content.sync.expiry,
            value: `до ${this.licenseInfo.license_expiry}`,
          },
          {
            name: 'Интеграция',
            value: this.integrationActive ? "активна" : "не активна",
          },
        ];
      } else {
        this.crm = {
          // img: require("../../../assets/crm.svg"),
        };
      }
    },
  },
};
</script>

<style scoped>
.crm-active {
  cursor: pointer;
}
</style>
