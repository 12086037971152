<template>
  <div class="icon-status" v-if="!loader && kit === 0">
    <svg
      v-if="type === 0"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 55C43.75 55 55 43.75 55 30C55 16.25 43.75 5 30 5C16.25 5 5 16.25 5 30C5 43.75 16.25 55 30 55Z"
        stroke="#DD6068"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.9248 37.0748L37.0748 22.9248"
        stroke="#DD6068"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.0748 37.0748L22.9248 22.9248"
        stroke="#DD6068"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      v-if="type === 1"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 55C43.75 55 55 43.75 55 30C55 16.25 43.75 5 30 5C16.25 5 5 16.25 5 30C5 43.75 16.25 55 30 55Z"
        stroke="#FFC549"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30 20V32.5"
        stroke="#FFC549"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.9863 40H30.0088"
        stroke="#FFC549"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      v-if="type === 2"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 55C43.75 55 55 43.75 55 30C55 16.25 43.75 5 30 5C16.25 5 5 16.25 5 30C5 43.75 16.25 55 30 55Z"
        stroke="#4ADDBC"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.375 29.9998L26.45 37.0748L40.625 22.9248"
        stroke="#4ADDBC"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>

  <div class="icon-status" v-else-if="!loader && kit === 1">
    <svg
      v-if="type === 0"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
        stroke="#EB5757"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 8V13"
        stroke="#EB5757"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9941 16H12.0031"
        stroke="#EB5757"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      v-if="type === 1"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
        stroke="#27AE60"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.75 11.9999L10.58 14.8299L16.25 9.16992"
        stroke="#27AE60"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      v-if="type === 2"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
        stroke="#FFC549"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 8V13"
        stroke="#FFC549"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9941 16H12.0031"
        stroke="#FFC549"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>

  <div class="icon-status" v-else>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="
        margin: auto;
        background: rgba(241, 242, 243, 0);
        display: block;
        shape-rendering: auto;
      "
      width="276px"
      height="276px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <g transform="rotate(0 50 50)">
        <rect
          x="48"
          y="19"
          rx="2"
          ry="2.72"
          width="4"
          height="16"
          fill="#1d0e0b"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.7142857142857142s"
            begin="-0.6547619047619048s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(30 50 50)">
        <rect
          x="48"
          y="19"
          rx="2"
          ry="2.72"
          width="4"
          height="16"
          fill="#1d0e0b"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.7142857142857142s"
            begin="-0.5952380952380952s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(60 50 50)">
        <rect
          x="48"
          y="19"
          rx="2"
          ry="2.72"
          width="4"
          height="16"
          fill="#1d0e0b"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.7142857142857142s"
            begin="-0.5357142857142857s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(90 50 50)">
        <rect
          x="48"
          y="19"
          rx="2"
          ry="2.72"
          width="4"
          height="16"
          fill="#1d0e0b"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.7142857142857142s"
            begin="-0.47619047619047616s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(120 50 50)">
        <rect
          x="48"
          y="19"
          rx="2"
          ry="2.72"
          width="4"
          height="16"
          fill="#1d0e0b"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.7142857142857142s"
            begin="-0.41666666666666663s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(150 50 50)">
        <rect
          x="48"
          y="19"
          rx="2"
          ry="2.72"
          width="4"
          height="16"
          fill="#1d0e0b"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.7142857142857142s"
            begin="-0.35714285714285715s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect
          x="48"
          y="19"
          rx="2"
          ry="2.72"
          width="4"
          height="16"
          fill="#1d0e0b"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.7142857142857142s"
            begin="-0.2976190476190476s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(210 50 50)">
        <rect
          x="48"
          y="19"
          rx="2"
          ry="2.72"
          width="4"
          height="16"
          fill="#1d0e0b"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.7142857142857142s"
            begin="-0.23809523809523808s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(240 50 50)">
        <rect
          x="48"
          y="19"
          rx="2"
          ry="2.72"
          width="4"
          height="16"
          fill="#1d0e0b"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.7142857142857142s"
            begin="-0.17857142857142858s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(270 50 50)">
        <rect
          x="48"
          y="19"
          rx="2"
          ry="2.72"
          width="4"
          height="16"
          fill="#1d0e0b"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.7142857142857142s"
            begin="-0.11904761904761904s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(300 50 50)">
        <rect
          x="48"
          y="19"
          rx="2"
          ry="2.72"
          width="4"
          height="16"
          fill="#1d0e0b"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.7142857142857142s"
            begin="-0.05952380952380952s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(330 50 50)">
        <rect
          x="48"
          y="19"
          rx="2"
          ry="2.72"
          width="4"
          height="16"
          fill="#1d0e0b"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.7142857142857142s"
            begin="0s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <!-- [ldio] generated by https://loading.io/ -->
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: Number,
      default: 0,
      required: true,
    },
    loader: {
      default: false,
      type: Boolean,
    },
    kit: {
      default: 0,
      type: Number,
    },
  },
};
</script>

<style scoped>
</style>