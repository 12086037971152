<template>
  <div class="wrapInput" >
    <div class="d-flex justify-content-between inputHeader">
      <div>
        <label class="input-label" v-if="label">{{ label }}</label>
      </div>
    </div>
    <div class="d-flex ">
      <div class="w-100 ">
        <input
            class="input-area"
          :disabled="disabled"
          :class="{ copy: !copyText }"
          @input="$emit('update:modelValue', $event.target.value)"
          :value="modelValue"
        />
      </div>
      <button @click="$emit('btnClick'), copy()" class="inputBtn input-button" :class="{ copy: !copyText }">
        <div v-if="type === 'copy'" >
            <span v-show="copyText">
              <slot name="copy1"></slot>
            </span>
            <span v-show="!copyText">
              <slot name="copy2"></slot>
            </span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputBtn",
  props: {
    label: {
      type: String,
    },
    modelValue: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      copyText: true,
    };
  },
  methods: {
    copy() {
      const str = this.modelValue;
      const el = document.createElement("textarea");
      el.value = str;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.copyText = false;

      setTimeout(() => {
        this.copyText = true;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.copy {
  background: #e8eaed !important;
  transition: .1s;
}
.inputHeader {
  align-items: center;
  position: relative;
}

.input-area {
  height: 48px;
  font-size: 14px;
}

.input-button {
  height: 48px;

  font-size: 16px;
}

.input-label {
  margin-bottom: 5px;

  font-size: 14px;
  line-height: 24px;
}

input {
  flex-direction: row;
  padding: 0.75rem 0 0.75em 25px;
  background: #f6f9fc;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  color: #000000;
}
input:disabled {
  color: #6d6d6d;
}
.wrapInput {
  margin-top: 1.5rem;
}
input:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}
input::placeholder,
input::-webkit-input-placeholder {
  color: #a1a2a2;
}
label {
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #6d6d6d;
  margin-bottom: 1px;
}
.iconWrap {
  display: flex;
  align-items: center;
  background: #f6f9fc;
  border-radius: 0.75rem 0 0 0.75rem;
  padding: 0 1.1rem;
}
.icon {
  width: 1.5rem;
  height: 1.5rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.inputBtn {
  display: flex;
  align-items: center;
  background: #f6f9fc;
  border-radius: 0 0.75rem 0.75rem 0;
  font-size: 16px;
  color: #6d6d6d;
}
.inputBtn > div {
  background: #f6f9fc;
  padding: 0 1.5rem;
  color: #6d6d6d;
  background: transparent ;
}
.inputBtn:hover {
  cursor: pointer;
}

@media (max-width: 550px) {
  .input-area {
    padding-left: 15px;
    font-size: 12px;
  }
}
</style>


