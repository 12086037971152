<template>
  <div :class="{ '_loader-corner': loadingLicense || loadingCrmStatus }">
    <services-card
      v-if="!crmConnect || !licenseExisted && !isActive"
      :img="require('../../../assets/qoob.store.svg')"
      :text="content.notActive.text"
      :btn-text="content.notActive.btnText"
      with-btn
      @btn-action="this.$router.push('/dashboard/billing/license')"
      btn-to-next
      :statusIcon="0"
    ></services-card>
    <services-card
      v-else-if="crmConnect && isActive"
      :img="require('../../../assets/qoob.store.svg')"
      :statuses="statusesActive"
      :is-exists="crmConnect"
      :statusIcon="2"
      ><div v-if="license.tariffName === 'ТЕСТ-ДРАЙВ' && activeTestDrive" class="tariff-status">
        🥳 Тест драйв активирован
      </div>
    </services-card>
    <services-card
      v-else-if="!isActive"
      :img="require('../../../assets/qoob.store.svg')"
      :statuses="statusesInactive"
      :is-exists="crmConnect"
      :btn-text="this.content.active.expiryBtnText"
      @btn-action="this.$router.push('/dashboard/billing/license')"
      :statusIcon="1"
    ></services-card>
    <!-- <side-popup-v2 :visible="popupState" @close="popupState = false">
      <div class="m-4">
        <billing-layout :inModal="true"></billing-layout>
      </div>
    </side-popup-v2> -->
    <side-popup-v2
      :visible="instructionPopupState"
      @close="instructionPopupState = false"
    >
      <div class="p-5">
        <h4 class="">Инструкция</h4>
        <p class="mt-4">Здесь скоро появится инструкция</p>
      </div>
    </side-popup-v2>
  </div>
</template>

<script>
import { mapState } from "vuex";
import sidePopupV2 from "@/components/UI/SidePopupV2";
import ServicesCard from "@/components/Dashboard/Services/ServicesCard";
// import billingLayout from "@/views/Dashboard/Billing/billing-layout";

export default {
  name: "ServicesQoobStore",
  components: { ServicesCard, sidePopupV2 },
  data() {
    return {
      // crmConnect: false,
      // isActive: false,
      statusesActive: [],
      statusesInactive: [],
      popupState: false,
      instructionPopupState: false,
      activeTestDrive: false
    };
  },
  mounted() {
    this.createInfo();

    if (localStorage.getItem('testDriveActive') === 'active') {
      this.activeTestDrive = true
      localStorage.removeItem('testDriveActive')
    }
  },
  computed: {
    ...mapState({
      loadingLicense: (state) => state.tarif.loadingLicense,
      loadingCrmStatus: (state) => state.crm.loadingCrmStatus,
      content: (state) => state.texts.dashboard.services.qoobStore,
      crmConnect: (state) => state.crm.crmConnect,
      isActive: (state) => state.tarif.licenseInfo.isActive,
      license: (state) => state.tarif.license,
      licenseExisted: (state) => state.tarif.licenseExisted,
    }),
  },
  watch: {
    license() {
      this.createInfo();
    },
  },
  methods: {
    openPopup() {
      this.popupState = true;
    },
    createInfo() {
      this.statusesActive = [
        { name: this.content.active.accId, value: `ID ${this.license.acc_id}` },
        { name: this.content.active.status, value: "Активен" },
        { name: this.content.active.expiry, value: `до ${this.license.date}` },
        { name: 'Осталось' , value: `${this.license.days} дней` },
        {
          name: this.license.tariffName === "ТЕСТ-ДРАЙВ" ? "Тест драйв" : "",
          value: this.license.tariffName === "ТЕСТ-ДРАЙВ" ? `активен` : "",
        },
      ];
      this.statusesInactive = [
        { name: this.content.active.accId, value: `ID ${this.license.acc_id}` },

        { name: this.content.active.status, value: "Не активен" },
        {
          name: this.content.active.expiry,
          value: `окончена ${this.license.date}`,
        },
      ];
    },
  },
};
</script>

<style scoped>
.billing-layout {
  max-width: 950px;
  padding: 0;
}
.tariff-status {
  color: #328fe4;
  font-size: 16px;
}
@media (max-width: 1500px) {
  .tariff-status {
    font-size: 14px;
  }
}
@media (max-width: 1300px) {
  .tariff-status {
    margin-top: 10px;
  }
}
@media (max-width: 567px) {
  .tariff-status {
    margin-top: 10px;
  }
}
</style>