<template>
  <div class="review" :class="loader ? '_loader-corner' : ''">
    <div class="review__wrapper">
      <div class="review__top">Оставить отзыв</div>
      <div class="review__inner">
        <div class="review-profile" v-if="companyInfo">
          <img class="review-profile__ava" :src="companyInfo.logo" alt="аватар компании" />
          <div class="review-profile__name">{{companyInfo.public_title}}</div>
          <div class="review-profile__desc">{{companyInfo.short_descr}}</div>
        </div>

        <div class="review__title mt-21px _first-title" v-if="stepRating < 2">
          {{ reviewInfo.title_for_comments }}
        </div>
        <div class="review-rating" v-if="stepRating < 2">
          <big-star
            v-for="i in 5"
            :key="i"
            class="review-rating__star"
            :color="i <= rating ? '#FFC549' : '#F0F3F8'"
            @click="setRating(i)"
          ></big-star>
        </div>
        <div class="review-rating-block" v-else>
          <div class="review-rating _no-click mt-21px">
            <big-star
              v-for="i in 5"
              :key="i"
              :color="i <= rating ? '#FFC549' : '#F0F3F8'"
              class="review-rating__star"
            ></big-star>
          </div>
          <div class="review__title" v-if="rating > bad_rating_limit">
            ВЫБЕРИТЕ ПЛОЩАДКУ ДЛЯ ВАШЕГО ОТЗЫВА
          </div>
          <div class="review__title" v-else>
            {{ reviewInfo.title_for_bad_comments }}
          </div>
        </div>

        <div class="review__content" v-if="stepRating === 1">
          <button class="review__btn" @click="stepRating = 2">
            Продолжить
          </button>
        </div>

        <div class="review__content" v-if="stepRating === 2">
          <div class="review__links" v-if="rating > bad_rating_limit">
            <a
              class="review__link"
              v-for="link in reviewInfo.review_platform_links"
              :key="link.id"
              :href="link.link"
              >{{ link.name }}</a
            >

            <div class="review-profile__desc" v-if="reviewInfo.review_platform_links.length === 0">Увы, ни одной ссылки для отзыва не добавлено :(</div>
          </div>
          <form class="review-form" @submit.prevent v-else>
            <div
              class="review-form__label"
              :class="validators.name ? '_invalid' : ''"
            >
              <span class="review__title">ВАШЕ ИМЯ</span>
              <input
                type="text"
                class=""
                v-model="$store.state.review.negativeComment.name"
              />
            </div>
            <div
              class="review-form__label"
              :class="validators.comment ? '_invalid' : ''"
            >
              <span class="review__title">КОММЕНТАРИЙ</span>
              <textarea
                v-model="$store.state.review.negativeComment.text"
              ></textarea>
            </div>

            <button class="review-form__button" @click="submitreview">
              Отправить
            </button>
          </form>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div class="review-popup" v-if="reviewPopupVisible">
        <div class="review-popup__inner" v-if="errStatus === 200">
          <div class="review-popup__title">Благодарим за обратную связь!</div>
          <p class="review-popup__text">
            Ваш отзыв отправлен руководителю для рассмотрения!
          </p>
          <p class="review-popup__text">
            Мы свяжемся с вами в ближайшее время.
          </p>
        </div>
        <div class="review-popup__inner" v-else>
          <div class="review-popup__title">
            Ошибка {{ errStatus }} при отправке отзыва
          </div>
          <p class="review-popup__text">
            Наши специалисты уже работают над устранением неполадок
          </p>
          <p class="review-popup__text">Попробуйте повторить попытку позже</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import bigStar from "@/components/UI/icons/big-star.vue";
import { useStore } from "vuex";
import { computed, onBeforeMount, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
export default {
  components: {
    bigStar,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    let loader = ref(true);
    let rating = ref(null);
    let stepRating = ref(0);
    let errStatus = ref(200);
    const bad_rating_limit = 3;

    let companyInfo = ref(null);

    let reviewInfo = ref({});

    onBeforeMount(() => {
      store.state.welcomePopupVisible = false;
      store
        .dispatch("review/getFilialInfo", route.query.salon_id)
        .then((data) => {
          companyInfo.value = data.data.result;
          loader.value = false;
        });

      store
        .dispatch("review/getReviewsLinks", route.query.salon_id)
        .then((data) => {
          reviewInfo.value = data.data.data;
          loader.value = false;
        });

      console.log(router, route);
      store.state.review.negativeComment.company_id = route.query.salon_id;
      store.state.review.negativeComment.phone = route.query.phone;
    });

    function setRating(newRating) {
      store.state.review.negativeComment.mark = newRating;
      rating.value = newRating;
      stepRating.value = 1;
    }

    let validators = reactive({
      name: false,
      comment: false,
    });

    let negativeComment = computed(() => store.state.review.negativeComment);

    function submitreview() {
      store.state.review.name;
      store.state.review.salon_id;
      let isValid = validator();
      if (isValid) {
        store
          .dispatch("review/postNegativeReview")
          .then(() => {
            reviewPopupVisible.value = true;
          })
          .catch((err) => {
            errStatus.value = err.response.status;
            reviewPopupVisible.value = true;
          });
      }
    }

    function validator() {
      console.log("validator");
      if (negativeComment.value.name && negativeComment.value.text) {
        validators.name = !negativeComment.value.name;
        validators.text = !negativeComment.value.text;
        return true;
      } else {
        validators.name = !negativeComment.value.name;
        validators.comment = !negativeComment.value.text;

        return false;
      }
    }

    let reviewPopupVisible = ref(false);

    return {
      loader,
      rating,
      bad_rating_limit,
      reviewInfo,
      validators,
      validator,
      submitreview,
      reviewPopupVisible,
      setRating,
      errStatus,
      stepRating,
      companyInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.review {
  background: #f0f3f8;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  &__wrapper {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 450px;
  }

  &__top {
    display: flex;
    justify-content: center;
    align-items: center;

    background: #f6f9fc;

    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #000000;

    min-height: 46px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    background: #ffffff;

    padding: 0 22px 80px 22px;
    margin-top: 60px;

    border-radius: 30px 30px 0 0;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;

    color: #000000;
    margin-bottom: 2px;

    opacity: 0.6;

    align-self: flex-start;
  }

  &__content {
    width: 100%;
  }

  &__links {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 17px;
    margin-top: 17px;
  }

  &__link {
    background: #f6f9fc;
    border-radius: 6px;
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    padding: 17px;

    position: relative;

    &:after {
      content: "";

      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);

      width: 7px;
      height: 12px;

      background: url("~@/assets/arr-right.svg");
    }
  }

  &__btn {
    background: #ffc549;
    border-radius: 12px;
    padding: 15px 0;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #000000;
    margin-top: 30px;
  }
}
.mt-21px {
  margin-top: 21px;
}
.review-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: -44px;

  &__ava {
    display: inline-block;

    width: 80px;
    height: 80px;

    background: #f3f3f3;

    border: solid #ffc549 4px;
    border-radius: 50%;
  }

  &__name {
    margin-top: 27px;

    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
    color: #000000;
  }

  &__desc {
    margin-top: 2px;

    font-weight: 400;
    font-size: 12px;

    color: #000000;
  }
}
.review-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  min-height: 81px;
  width: 100%;

  border-top: solid 1px rgba(109, 109, 109, 0.2);
  border-bottom: solid 1px rgba(109, 109, 109, 0.2);
  &._no-click {
    pointer-events: none;
    border: none;
  }
  &__star:hover {
    @media (hover: hover) {
      cursor: pointer;
      filter: invert(15%);
      transition: 0.1s;
    }
  }
}
.review-rating-block {
  width: 100%;
  border-bottom: solid 1px rgba(109, 109, 109, 0.2);
}
.review-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding-top: 37px;

  &__label {
    display: flex;
    flex-direction: column;

    &._invalid {
      .review__title {
        color: #dd6068;
        opacity: 1;
      }

      input,
      textarea {
        border: solid 0.5px #dd6068ae;
      }
    }
  }

  input {
    background: #f6f9fc;
    border-radius: 6px;
    height: 50px;
    font-size: 14px;
    padding: 13px 16px;
  }

  textarea {
    background: #f6f9fc;
    border-radius: 6px;
    height: 170px;
    font-size: 14px;
    padding: 13px 16px;
    resize: none;
    border: none;
  }

  &__button {
    background: #ffc549;
    border-radius: 12px;
    height: 50px;

    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }
}
.review-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;

  &__inner {
    background: #ffffff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 17px;
    border-radius: 24px;
    max-width: 360px;
  }
  &__title {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    border-radius: 24px;
  }
  &__text {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
  }
}
</style>