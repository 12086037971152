<template>
  <div class="pagination">
    <p class="pagination__text">
      Показаны результаты <br> с 1 по {{ max }} из {{ numberOfClients }}
    </p>
    <div class="pagination__main">
      <button
        class="pagination__button button--prev"
        :disabled="currentPage <= 1"
        @click="changePage('prev', 1)"
      >
        Начало
      </button>
      <button class="pagination__button _bolet" @click="paginationPage > 0 ? paginationPage-- : 0">...</button>
      <button
        :class="[
          'pagination__button _bolet',
          { 'pagination__button--active': currentPage === i + paginationPage },
        ]"
        
        v-for="i in numberOfPages"
        :key="i"
        @click="changePage('number', i + paginationPage)"
      >
        {{ i + paginationPage }}
      </button>
      <button class="pagination__button _bolet" @click="paginationPage+(numberOfPages+1) <= pages ? paginationPage++ : paginationPage">...</button>
      <button
        class="pagination__button button--next"
        @click="changePage('next', +(numberOfClients/max).toFixed(0))"
        :disabled="currentPage >= pages"
      >
        Конец
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed, watchEffect } from "vue";

export default {
  name: "ClientsPagination",
  props: {
    numberOfClients: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const numberOfPages = computed(() => {
      if (props.numberOfClients / props.max < 1) {
        return 1;
      } else if (props.numberOfClients / props.max > 3) {
        return 3;
      }
      return (props.numberOfClients / props.max).toFixed(0);
    });

    const firstVisiblePage = computed(() => {
      return paginationPage.value + 1
    });

    let pages = ref(null);

    watchEffect(() => {
      pages.value = (props.numberOfClients / props.max).toFixed(0);
    });

    const currentPage = ref(1);
    const paginationPage = ref(0);

    function changePage(type, index) {
      if (type === "number") {
        currentPage.value = index;
        if (currentPage.value >= firstVisiblePage.value && firstVisiblePage.value+3 <= pages.value) {
          paginationPage.value++
        }
      } else if (type === "prev") {
        currentPage.value = index;
        if (currentPage.value < firstVisiblePage.value) {
          paginationPage.value = 0;
        }
      } else if (type === 'next') {
        currentPage.value = index;
        if (currentPage.value > firstVisiblePage.value) {
          paginationPage.value = index-3;
        }
      } else {
        currentPage.value += index;
      }
      console.log(currentPage.value);

      emit("changePage", currentPage.value);
    }

    return {
      currentPage,
      numberOfPages,
      changePage,
      paginationPage,
      pages,
      firstVisiblePage,
    };
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 70px;
  padding: 0 30px;

  background-color: #fff;
}

.pagination__text {
  font-size: 16px;
  font-weight: 500;
}

.pagination__main {
  display: flex;
}

.pagination__button {
  min-width: 34px;
  padding: 5px;
  height: 37px;
  margin-right: 5px;

  border: 1px solid #d8d8d8;
  border-radius: 4px;

  font-size: 16px;
}

.button--prev {
  width: 100px;
}

.button--next {
  width: 100px;
  margin-right: 0;
}

.pagination__button--active {
  border: 1px solid #fbcd00;
}

@media (max-width: 1600px) {
  .pagination__text {
    font-size: 15px;
  }

  .pagination__button {
    font-size: 15px;
  }
}

@media (max-width: 1440px) {
  .pagination__text {
    font-size: 14px;
  }

  .pagination__button {
    font-size: 14px;
  }
}

@media (max-width: 1280px) {
  .pagination__text {
    font-size: 13px;
  }

  .pagination__button {
    font-size: 13px;
  }
}

@media (max-width: 1024px) {
  .pagination__text {
    font-size: 12px;
  }

  .pagination__button {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .pagination {
    justify-content: center;
    padding: 0 15px;
  }

  .pagination__text {
    display: none;
  }
}

@media (max-width: 567px) {
  .pagination {
    justify-content: center;
    padding: 0;
  }

  .pagination__button {
    height: 30px;
  }
  .button--prev,
  .button--next {
    width: 66px;
  }

  .pagination__button {
    margin-right: 3px;
  }
}
</style>