<template>
  <div class="history-page">
    <div class="history__header">
      <h2 class="tit-h4 history__title">История платежей</h2>
    </div>
    <div class="history__wrapper" :class="{ _loader: invoiceListLoading }">
      <ul class="history__list">
        <li class="not-invoices text" v-if="historyList.length <= 1">
          У вас еще нет ни одного платежа
        </li>
        <li
          :class="['history__item', { 'history__item--first': index === 0 }]"
          v-for="(item, index) in historyList"
          :key="item.name"
        >
          <p
            :class="[
              'history__text',
              { 'history__text--first': index === 0 },
              {
                'history__text--blue':
                  text === item.state && item.state === 'Оплачен',
              },
              {
                'history__text--red':
                  text === item.state && item.state === 'Не оплачен',
              },
            ]"
            v-for="text in item"
            :key="text"
            v-show="text != item.action && text != item.date"
          >
            {{ text }}
          </p>

          <p class="history__text" :class="{ history__text1: index === 0 }">
            {{ item.date }}
          </p>

          <button
            :class="[
              'history__action',
              { history__action1: index === 0 },
              { download: item.action === 'Скачать' },
              { download: item.action === 'Скачать акт' },
            ]"
            @click="downloadInvioce(item.id)"
          >
            {{ item.action }}
          </button>
        </li>
        <li
          class="history__item--mobile"
          v-show="index != 0"
          v-for="(item, index) in historyList"
          :key="index"
        >
          <div class="item__header">
            <h4 class="header__title">{{ item.name }}</h4>
            <button
              :class="[
                'item__action',
                { download: item.action === 'Скачать' },
                { delete: item.action === 'Удалить' },
              ]"
            ></button>
          </div>

          <div class="item__section">
            <p class="item__param">Сумма</p>
            <p class="item__value">{{ item.sum }} ₽</p>
          </div>

          <div class="item__section">
            <p class="item__param">Дата</p>
            <p class="item__value">{{ item.date }}</p>
          </div>

          <div class="item__section">
            <p class="item__param">Способ оплаты</p>
            <p class="item__value">{{ item.method }}</p>
          </div>

          <div class="item__section item__section--status">
            <p class="item__param">Статус</p>
            <p
              :class="[
                'item__value',
                { 'history__text--blue': item.state === 'Оплачен' },
                { 'history__text--red': item.state === 'Не оплачен' },
              ]"
            >
              {{ item.state }}
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import { paymentList } from "@/data/paymentList.json";
import { computed, reactive, watch, ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "billing-history",
  setup() {
    const $store = useStore();

    const invoiceListLoading = ref(false);

    let invoiceList = [];

    function cutDate(item) {
      return item.split("").slice(0, 10).join("");
    }

    class Payment {
      constructor(id, name, sum, date, method, state, action) {
        this.id = id;
        this.name = name;
        this.sum = sum;
        this.date = date;
        this.method = method;
        this.state = state;
        this.action = action;
      }
    }

    const historyList = reactive([]);

    const windowWidth = computed(() => {
      return document.documentElement.clientWidth;
    });

    function updateHistory() {
      if (windowWidth.value > 550) {
        historyList.push(
          new Payment(
            "#",
            "Название платежа",
            "Сумма, ₽",
            "Дата выставления",
            "Способ оплаты",
            "Статус",
            "Действия"
          )
        );
      }

      // paymentList.forEach((item) => {
      //   historyList.push(new Payment(item.id, item.name, item.sum, item.date, item.method, item.state, item.action));
      // })
    }

    watch(
      () => historyList,
      () => {
        updateHistory();
        console.log("update");
      }
    );
    updateHistory();

    onMounted(() => {
      if ($store.state.invoice.invoiceList.length === 0) {
        invoiceListLoading.value = true;
        $store.dispatch("getInvioceList").then(() => {
          invoiceListLoading.value = false;
          invoiceList = $store.state.invoice.invoiceList;
          invoiceList.forEach((item) => {
            historyList.push(
              new Payment(
                item.id,
                item.topic,
                item.price,
                cutDate(item.date),
                item.type,
                item.status,
                item.status === "Оплачен" ? "Скачать акт" : "Скачать"
              )
            );
          });
        });
      } else {
        invoiceList = $store.state.invoice.invoiceList;

        invoiceList.forEach((item) => {
            historyList.push(
              new Payment(
                item.id,
                item.topic,
                item.price,
                cutDate(item.date),
                item.type,
                item.status,
                item.status === "Оплачен" ? "Скачать акт" : "Скачать"
              )
            );
          });
      }
    });

    return { historyList, windowWidth, invoiceList, invoiceListLoading };
  },
  methods: {
    downloadInvioce(id) {
      this.$store.dispatch("downloadInvoice", id);
      console.log("download", id);
    },
    deleteInvioce(id) {
      console.log("delete", id);
    },
  },
};
</script>

<style scoped>
.history__header {
  margin-top: 0 !important;
}
.history__title {
  margin-bottom: 10px;
}
.not-invoices {
  font-weight: 500;
  margin-bottom: 30px;
}
.history__item--mobile {
  display: none;
}

.download::before {
  content: "";
  position: absolute;
  top: 29px;
  left: 0;

  width: 24px;
  height: 24px;

  background-image: url("../../assets/img/download.png");
  background-size: cover;
}

.delete::before {
  content: "";
  position: absolute;
  top: 29px;
  left: 0;

  width: 24px;
  height: 24px;

  background-image: url("../../assets/img/delete.png");
  background-size: cover;
}

.history__action {
  position: relative;
  padding-left: 30px;

  font-size: 14px;
  text-align: left;
  color: #a1a2a2;
}

.history__action1 {
  padding-left: 0;
}

.delete {
  color: #000;
}

.download {
  color: #000;
}

.history__text {
  display: flex;
  align-items: center;

  height: 80px;

  font-size: 14px;
}

.history__text1 {
  height: auto;
  color: #a1a2a2;
}

.history__text--blue {
  color: #328fe4;
}

.history__text--red {
  color: #dd6068;
}

.history__text--first {
  display: block;
  height: auto;

  line-height: 24px;
  color: #a1a2a2;
}

.history__item {
  display: grid;
  grid-template-columns: 0.7fr 4fr 2fr 2fr 2fr 2fr 1.7fr;
  grid-column-gap: 10px;

  border-bottom: 1px solid #eff0f0;
}

.history__item--first {
  border-bottom: none;
}

.history__subtitle {
  font-size: 16px;
}


  .history__subtitle {
    font-size: 14px;
  }

  .history__item {
    grid-template-columns: 0.7fr 3fr 2fr 2fr 2fr 2.5fr 2.2fr;
  }

  .history__text {
    font-size: 12px;
  }

  .history__action {
    font-size: 12px;
  }

@media (max-width: 1024px) {
  .history-page {
    padding: 0 !important;
  }

  .history__item {
    grid-template-columns: 0.7fr 3.2fr 1.4fr 2.4fr 2fr 2.5fr 2.2fr;
  }
}
</style>

<style scoped>
@media (max-width: 768px) {
  .header__title {
    font-size: 15px;
  }

  .history__subtitle {
    display: none;
  }

  .history__item {
    display: none;
  }

  .item__action {
    position: relative;

    width: 24px;
    height: 24px;
  }

  .delete::before {
    top: 0;
  }

  .download::before {
    top: 0;
  }

  .history__item--mobile {
    display: block;
    padding-bottom: 25px;
    border-bottom: 1px solid #eff0f0;
  }

  .item__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 60px;
    padding: 0 15px;
    margin-bottom: 25px;

    background-color: #f6f9fc;
  }

  .item__section {
    display: flex;
    justify-content: space-between;

    padding: 0 15px;
    margin-bottom: 10px;

    font-size: 14px;
  }

  .item__section--status {
    margin-bottom: 0;
  }
}
</style>
