export default {
  state: () => ({
    texts: {
      sync: {
        title: '',
        col1: '',
        col2: ''
      }
    }
  }),
  getters: {
  
  },
  mutations: {
  
  },
  actions: {
  
  },
}