<template>
  <iframe :src="`${webappUrl}?indep=1&salon_id=${$route.query.s}`" frameborder="0"></iframe>
  <!-- https://tgapp-ipl6puj01s.qoob.store? -->
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore()

onMounted(() => {
  store.state.isFrameVersion = true
})

const webappUrl = computed(() => {
  if (store.getters.isLocalHost) {
    return 'http://localhost:8080'
  } else {
    return 'https://tgapp-ipl6puj01s.qoob.store'
  }
})
</script>

<style scoped lang="scss">
iframe {
  width:100vw;
  height:100vh;
  border: none;

}
</style>