<template>
  <div
    :class="{
      _loader: loadingWhatsappStatus,
      '': this.$store.state.isFrameVersion,
    }"
  >
    <div class="settings__top">
      <div class="mb-3 wa-name" v-if="crmConnect">
        <div class="wa-name__inner">
          <p class="tit-h6" v-if="statusQr === 3">
            Имя отправителя в WhatsApp
          </p>
          <input
            v-if="statusQr === 3"
            type="text"
            class="default-input w-100 mb-4"
            v-model="$store.state.whatsapp.whatsappSettings.sender"
          />
          <p class="tit-h6">
            Номер телефона для экстренных уведомлений о работе WhatsApp
          </p>
          <input
            type="text"
            v-model="owner.chatapi_alarm_recipient"
            @input="this.$store.commit('setEditProfileData', true)"
            class="default-input w-100 mb-3"
          />

          <transition name="fade">
            <div
              class="d-flex gap-3"
              v-if="
                $store.state.whatsapp.whatsappSettings.sender !==
                  $store.state.whatsapp.firstWhatsappSettings.sender ||
                $store.state.profile.owner.chatapi_alarm_recipient !==
                  $store.state.profile.lastOwnerData.chatapi_alarm_recipient
              "
            >
              <buttonOrange
                @click="
                  () => {
                    $store.dispatch('putWhatsappSettings');
                    $store.dispatch('postOwnerInfo');
                  }
                "
                :disabled="$store.state.whatsapp.loadingPutWhatsappSettings"
                :class="{
                  '_loader-btn':
                    $store.state.whatsapp.loadingPutWhatsappSettings,
                }"
                >Сохранить</buttonOrange
              >
              <buttonGrey
                :disabled="$store.state.whatsapp.loadingPutWhatsappSettings"
                @click="
                  () => {
                    $store.state.whatsapp.whatsappSettings.sender =
                      $store.state.whatsapp.firstWhatsappSettings.sender;
                    $store.state.profile.owner.chatapi_alarm_recipient =
                      $store.state.profile.lastOwnerData.chatapi_alarm_recipient;
                  }
                "
                :class="{
                  '_loader-btn':
                    $store.state.whatsapp.loadingPutWhatsappSettings,
                }"
                >Отмена</buttonGrey
              >
            </div>
          </transition>
        </div>
      </div>

      <v-popup
        :visible="$store.state.whatsapp.waErr.on && waErrVisible"
        @close="waErrVisible = false"
      >
        <div>
          <div class="tit-h2">{{ $store.state.whatsapp.waErr.text }}</div>

          <div class="tit-h3">
            Обратитесь в поддержку
            <a
              href="https://t.me/qoob_store_support_bot"
              class="link"
              target="_blank"
              >qoob.store</a
            >
            и мы решим все проблемы
          </div>
        </div>
      </v-popup>

      <!-- crm не подключенна или лицензия не активна -->
      <div v-if="!crmConnect">
        <crmCyncError></crmCyncError>
      </div>
      <div class="wa-warn" v-else-if="!qoobLicenseActive">
        <h3>Внимание!</h3>

        <!-- crm не подключенна или лицензии никогда не существовало -->
        <div class="wa-warn__text" v-if="!licenseExisted">
          <p>
            Мы не нашли у Вас ни одной активной лицензии на использование
            сервиса доставки уведомлений через WhatsApp.
          </p>
          <p class="mb-4">
            Для начала работы, неоходимо приобрести лицензию сервиса qoob.store.
          </p>
        </div>
        <!-- лицензия закончилась -->
        <div class="wa-warn__text" v-else>
          <p>Ваш аккаунт был остановлен {{ license.date }} из-за неуплаты.</p>
          <p>
            Прямо сейчас вы можете включить этот инстанс, просто продлив
            подписку.
          </p>
        </div>

        <button-orange
          class="button-main"
          cls="main"
          to-next
          @click="$router.push('/dashboard/iframe/billing')"
          >Выбрать лицензию</button-orange
        >
      </div>

      <!-- crm подключенна и лицензия активна -->
      <div v-if="crmConnect && qoobLicenseActive" class="mb-5">
        <transition name="fade" mode="out-in">
          <div>
            <div class="pre-wrap fs-5 mb-5 qr-code">
              <div class="qr-code__inner">
                <div class="wa-text qr-code__status">
                  <div
                    class="qr-code__title mb-md-4 mb-0"
                    v-if="!whatsappWebSoket.auth"
                  >Для отправки и приема сообщений Вам необходимо авторизовать
                    наш сервер как WhatsApp Web.
                  </div>

                  <div class="qr-code__title mb-4" v-else>
                    Канал WhatsApp подключен!
                  </div>

                  <div class="whatsapp-ul" v-if="!whatsappWebSoket.auth">
                    <ul>
                      <li>Откройте WhatsApp на своём телефоне</li>
                      <li>
                        Нажмите <span class="weight-500">Меню</span>
                        <span class="whatsapp-ul__icon">
                          <img src="../../../assets/dots-wa.svg" alt="" />
                        </span>
                        или <span class="weight-500">Настройки</span>

                        <span class="whatsapp-ul__icon">
                          <img src="../../../assets/settings-wa.svg" alt="" />
                        </span>
                        и выберите
                        <span class="weight-500">Связные устройства</span>
                      </li>
                      <li>
                        Нажмите
                        <span class="weight-500">Привязка устройства</span>
                      </li>
                      <li>
                        Наведите свой экран на этот экран, чтобы считать код
                      </li>
                    </ul>
                  </div>
                  <div class="whatsapp-ul" v-else>
                    <p class="mb-3">Чтобы сменить устройство / номер</p>
                    <ul>
                      <li>
                        Нажмите <span class="weight-500">Меню</span>
                        <span class="whatsapp-ul__icon">
                          <img src="../../../assets/dots-wa.svg" alt="" />
                        </span>
                        или <span class="weight-500">Настройки</span>

                        <span class="whatsapp-ul__icon">
                          <img src="../../../assets/settings-wa.svg" alt="" />
                        </span>
                        и выберите
                        <span class="weight-500">Связные устройства</span>
                      </li>
                      <li>
                        Выберите
                        <span class="weight-500">привязанное устройство</span> /
                        нажмите ВЫХОД
                      </li>
                      <li>после выхода перезагрузите страницу с QR кодом</li>
                      <li>Привяжите новое устройство</li>
                    </ul>
                  </div>
                </div>

                <div v-if="statusQr !== 3" class="qr-code__code">
                  <div
                    class="qr-code__loader"
                    :class="{
                      _loader:
                        statusQr === 1 || (!qrValue && statusQr === 2)
                    }"
                  ></div>

                  <div
                    v-if="statusQr === 2"
                    class="qr-code__qr"
                    :class="
                    { '_blured': $store.state.whatsapp.progressQr.qrProgress <= 0 || (!qrValue && statusQr === 2)}
                    "
                  >
                    <progress
                      :value="$store.state.whatsapp.progressQr.qrProgress"
                      max="100"
                      class="qr-code__progress"
                      v-if="whatsappProvider !== 'greenapi'"
                    ></progress>
                    <QrcodeVue :value="qrValue" :size="sizeQRCode" v-if="whatsappProvider !== 'greenapi'"></QrcodeVue>
                    <img :src="`data:image/png;base64,${qrValue}`" alt="" v-else>
                  </div>

                  <p
                    class="whatsapp-progress"
                    v-else-if="
                      whatsappWebSoket.loading && statusQr !== 0
                    "
                  >
                    <span v-if="whatsappWebSoket.progress"
                      >Проверяем подключение</span
                    >
                    <span v-else-if="whatsappWebSoket.rebort"
                      >Обновите страницу</span
                    >
                    <span v-else>
                      <span v-if="$store.state.whatsapp.newCode"
                        >Генерируем ваш QR код, это может занять до двух
                        минут</span
                      >
                      <span v-else
                        >Пожалуйста подождите</span
                      >
                    </span>
                  </p>

                  <div
                    v-else-if="statusQr === 0"
                    class="qr-code__code _off"
                  >
                    <div class="qr-code__qr">
                      <QrcodeVue
                        class=""
                        :value="'https://t.me/qoobstore'"
                        :size="sizeQRCode"
                      ></QrcodeVue>

                      <div class="qr-code__control">
                        <button
                          class="btn-green _big"
                          @click="getQr"
                        >
                          Получить QR код
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="qr-code__code _sucsess" v-else-if="statusQr === 3">
                  <div class="qr-code__qr">
                    <img src="../../../assets/wa-status-ok.svg" alt="" />
                    <QrcodeVue
                      :value="'Как вы это отсканировали? :D'"
                      :size="sizeQRCode"
                    ></QrcodeVue>
                  </div>
                </div>

                <p class="wa-text w-100">Внимание - приложение WhatsApp сканирует QR код дважды за доли
                  секунды / не убирайте телефон от QR кода слишком быстро
                </p>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import CmInput from "@/components/UI/inputs/CmInput";
import customCheckbox from "@/components/Dashboard/Billing/custom-checkbox";
import buttonTransparent from "@/components/UI/buttons/ButtonTransparent";
/* eslint-disable */
import { useStore } from "vuex";
import buttonOrange from "@/components/UI/buttons/ButtonOrange";
import {
  computed,
  onBeforeMount,
  onUnmounted,
  ref,
} from "@vue/runtime-core";
import QrcodeVue from "qrcode.vue";
import labelInput from "../../UI/inputs/labelInput.vue";
import buttonGrey from "../../UI/buttons/ButtonGrey.vue";
import crmCyncError from "../../UI/crmCyncError.vue";
import NotificationHistory from "../../../views/Dashboard/Notifications/NotificationHistory.vue";
import vPopup from "../../UI/v-popup.vue";

// import CmButton from '../../UI/buttons/CmButton.vue';
export default {
  name: "WhatsAppSettings",
  components: {
    buttonOrange,
    QrcodeVue,
    labelInput,
    crmCyncError,
    buttonGrey,
    NotificationHistory,
    vPopup,
    CmInput,
    customCheckbox,
    buttonTransparent,
  },

  setup() {
    const store = useStore();

    const windowWidth = computed(() => store.state.windowWidth);
    const crmConnect = computed(() => store.state.crm.crmConnect);
    const content = computed(
      () => store.state.texts.components.whatsAppSettings
    );
    const whatsappProvider = computed(() => store.state.whatsapp.whatsappStatus.provider)
    const licenseExisted = computed(() => store.state.tarif.licenseExisted);
    const whatsappStatus = computed(() => store.state.whatsapp.whatsappStatus);
    const whatsAppActive = computed(
      () => store.state.whatsapp.whatsappWebSoket.auth
    );
    const whatsAppAvailable = computed(
      () => store.state.whatsapp.whatsappStatus.isAvailable
    );
    const loadingWhatsappStatus = computed(
      () => store.state.whatsapp.loadingWhatsappStatus
    );
    const qoobLicenseActive = computed(
      () => store.state.tarif.license.isActive
    );
    const reloadQrLoading = computed(
      () => store.state.whatsapp.reloadQrLoading
    );
    const whatsappSettings = computed(
      () => store.state.whatsapp.whatsappSettings
    );
    const license = computed(() => store.state.tarif.license);
    const loadingPutWhatsappSettings = computed(
      () => store.state.whatsapp.loadingPutWhatsappSettings
    );

    const whatsappWebSoket = computed(
      () => store.state.whatsapp.whatsappWebSoket
    );

    const qrValue = computed(
      () => {
        if (whatsappProvider.value === 'greenapi') {
          return store.state.whatsapp.greenApi.qrCode
        } else {
          return store.state.whatsapp.whatsappWebSoket.qrCode
        }
      }
    );

    const greenApi = computed(() => store.state.whatsapp.greenApi)

    const owner = computed(() => store.state.profile.owner);
    const lastOwnerData = computed(() => store.state.profile.lastOwnerData);
    const editProfileData = computed(() => store.state.profile.editProfileData);

    const isFrameVersion = computed(() => store.state.isFrameVersion);
    const loadingProfile = computed(() => store.state.loadingProfile);

    const instanceStatusCode = computed(
      () => store.state.whatsapp.instanceStatusCode
    );

    const statusQr = computed(() => {
      // 0 - получить qr, 1 - крутим лоадер, 2 - отображаем qr, 3 - qr отсканирован 
      if (whatsappProvider.value !== 'greenapi') {
        if (instanceStatusCode.value === 404) {
          return 0
        } else if (instanceStatusCode.value === 503) {
          return 1
        } else if (instanceStatusCode.value === 401) {
          return 2
        } else if (instanceStatusCode.value === 200){
          return 3
        }
      } else {
        if (!greenApi.value.isAuth && !qrValue.value && !greenApi.value.isGettingQr) {
          return 0
        } else if (greenApi.value.isGettingQr && !qrValue.value) {
          return 1
        } else if (!greenApi.value.isAuth && qrValue.value) {
          return 2
        } else if (greenApi.value.isAuth) {
          return 3
        }
      }
    })

    let progress = ref(false);

    const sizeQRCode = computed(() => {
      if (windowWidth.value > 1400) {
        return 320;
      } else if (windowWidth.value > 1200) {
        return 280;
      } else {
        return 250;
      }
    });
    let status = computed(() => {
      if (whatsAppActive.value && whatsAppAvailable.value) {
        return "Активен";
      } else if (whatsAppAvailable.value && qrValue.value) {
        return "Сканирование QR";
      } else {
        return "Не активен";
      }
    });

    const typeIcon = computed(() => {
      if (
        !crmConnect.value ||
        !qoobLicenseActive.value ||
        !whatsAppAvailable.value
      ) {
        return 0;
      } else {
        if (qoobLicenseActive.value && whatsAppActive.value) {
          return 2;
        } else {
          return 1;
        }
      }
    });

    let waErrVisible = ref(true);

    let waInterval;

    onBeforeMount(() => {
      store.dispatch("getWhatsappSettings");

      if (store.state.whatsapp.whatsappStatus.provider !== "greenapi") {
        store.dispatch("startWhatsAppGetQr");

        waInterval = setInterval(() => {
          store.dispatch("startWhatsAppGetQr");
        }, 5000);
      } else {
        waInterval = setInterval(() => {
          if (!greenApi.value.isGettingQr)
          store.dispatch("greenApi/isAuthed");
        }, 5000);
      }
    });

    onUnmounted(() => {
      if (whatsappProvider.value !== 'greenapi') {
        clearInterval(waInterval);
      } else {
        clearInterval(waInterval);
        store.dispatch('greenApi/stopGettingQrStatus')
      }

      
    });

    function getQr() {
      store.dispatch('greenApi/getQrCode')
    }

    return {
      crmConnect,
      content,
      licenseExisted,
      whatsappStatus,
      whatsAppActive,
      loadingWhatsappStatus,
      qoobLicenseActive,
      whatsAppAvailable,
      reloadQrLoading,
      whatsappSettings,
      whatsappWebSoket,
      isFrameVersion,
      license,
      loadingPutWhatsappSettings,
      typeIcon,
      windowWidth,
      progress,
      sizeQRCode,
      qrValue,
      status,
      waErrVisible,
      instanceStatusCode,
      owner,
      lastOwnerData,
      editProfileData,
      loadingProfile,
      getQr,
      whatsappProvider,
      statusQr,
      greenApi
    };
  },
};
</script>

<style lang="scss" scoped>
.qr-code {
  &__code._off {
    .qr-code__qr {
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .qr-code__qr canvas {
      filter: blur(4px);
      display: flex;
    }
  }

  ._blured {
    opacity: 0.6;
    filter: blur(0.5rem);
  }

  &__control {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100%;

    background: rgba(217, 217, 217, 0.9);
    padding: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.wa-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wa-name__inner {
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
}
.wa-name__img {
  border-radius: 10px;
  height: 100%;
}

.wa-name input {
}

.settings__top {
  padding: 0;
}

@media (max-width: 768px) {
  .whatsapp-instruction {
    padding: 0 20px;
  }

  .modal__subtitle {
    font-size: 14px;
  }

  .wa-text {
    font-size: 14px;
  }
}

.modal__subtitle {
  font-size: 20px;
}

.whatsapp-ul__item {
  display: flex;
}

.whatsapp-ul__num {
  display: block;
  font-weight: 600;
  margin-right: 10px;
}
.whatsapp-ul__icon img {
  opacity: 0.3;
  width: 16px;
  height: 16px;
}
.whatsapp-ul__icon {
  width: 20px;
  height: 20px;

  background: #f2f2f2;
  padding: 3px;
  border-radius: 5px;
  margin: 5px;
}

.whatsapp-ul__link a {
  width: 100%;
  height: 100%;
}

.whatsapp-ul ul li {
  list-style-type: numeric;
}
.whatsapp-ul ul {
  padding-left: 20px;
}

.modal__title {
  padding-bottom: 20px;
  margin-bottom: 25px;
  /* border-bottom: 1px solid #eff0f0; */

  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}
.qr-code {
  padding: 38px;
  background: rgb(223, 223, 221);
  background: linear-gradient(
    0deg,
    rgba(223, 223, 221, 0.9) 80%,
    rgba(74, 162, 131, 0.9) 80%
  );
  border-radius: 10px;

  max-width: 1200px;
}

.qr-code__code {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 30px;
  min-height: 410px;
  width: 50%;
  position: relative;
}
.qr-code__code._sucsess {
  position: relative;
}
.qr-code__code._sucsess .qr-code__qr {
  position: relative;
}
.qr-code__code._sucsess canvas {
  opacity: 0.1;
}
.qr-code__code._sucsess img {
  /* filter: invert(3%); */
  border-radius: 30px;
  position: absolute;
  width: 150px;
  height: 150px;

  z-index: 50;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.qr-code__loader {
  position: absolute;
  width: 100%;
  height: 100%;
}

.QR-placeholder {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 19px;
  color: #a1a2a2;
  text-align: center;
}

.qr-code__inner {
  background: #fff;
  filter: drop-shadow(6px 8px 12px rgba(0, 0, 0, 0.25));
  border-radius: 4px;
  padding: 40px;
  display: flex;
  flex-wrap: wrap;

  row-gap: 30px;
}

.qr-code__status {
  width: 50%;

  font-size: 20px;

  display: flex;
  flex-direction: column;
  row-gap: 30px;

  column-gap: 10px;
}

.qr-code__title {
  font-size: 20px;
}

.whatsapp-progress {
  position: absolute;
  bottom: 10%;
  width: 80%;
  font-size: 18px;
  text-align: center;
  z-index: 40;
}

.wa-text {
  font-size: 18px;
}

.wa-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wa-form {
  display: flex;
  flex-direction: column;
  /* align-items: end; */
  margin-bottom: 55px;
  color: #545454;
  font-size: 20px;
}
.wa-input {
  width: 100%;
}
.wa-reload {
  display: flex;
  flex-direction: column;
  gap: 6px;
  color: #a1a2a2;
}
.wa-reload:first-child {
  margin-bottom: 71px;
}
.wa-reload p {
  font-size: 14px;
}
.wa-reload h6 {
  font-size: 16px;
  margin-bottom: 0;
}
.main-text {
  margin-bottom: 30px;

  font-size: 16px;
  line-height: 30px;
}

.whatsappLogo {
  width: 150px;
  height: 36px;
}

.waLogo {
  height: 36px;
}
.waStatusIcon {
  height: 50px;
}
hr {
  color: #d4d4d4;
}

.settings__button {
  width: 100%;
  max-width: 330px;
  height: 48px;
}

.settings__button1 {
  width: 50%;
  max-width: 160px;
  height: 48px;
}

.settings__button2 {
  width: 100%;
  max-width: 330px;
  height: 48px;
  margin-top: 30px;
}

.settings__text {
  font-size: 16px;
  line-height: 30px;
}

.settings__title {
  font-size: 20px;
  font-weight: 600;
}
.wa-card-text {
  font-size: 16px;
}
@media (max-width: 1200px) {
  .qr-code {
    padding: 29px;
  }
  .qr-code__inner {
    padding: 25px;
    align-items: start;
  }

  .qr-code__code {
    min-height: 300px;
  }

  .qr-code__title[data-v-1f86020b] {
    font-size: calc(9px + 15 * (100vw / 1280));
  }
  .whatsapp-ul ul {
    font-size: calc(9px + 10 * (100vw / 1280));
  }

  .wa-text {
    font-size: calc(5px + 15 * (100vw / 1280));
  }
  .whatsapp-progress {
    font-size: calc(5px + 15 * (100vw / 1280));
  }
}
@media (max-width: 768px) {
  .qr-code {
    padding: 20px;
  }
  .qr-code__inner {
    padding: 15px;
    align-items: start;
  }
}
@media (max-width: 567px) {
  .qr-code__inner {
    flex-direction: column;
  }
  .qr-code__code {
    order: -1;
    width: 100%;
  }
  .qr-code__status {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>

<style>
progress {
  display: block;
  width: 250px;
  height: 10px;
  margin-bottom: 10px;
  border: none;
}

progress::-webkit-progress-bar {
  border-radius: 10px;
  background-color: rgba(223, 223, 221, 0.7);
  overflow: hidden;
}

progress::-webkit-progress-value {
  background-color: rgba(74, 162, 131, 0.9);
}
.wa-warn {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
  background: #f6f9fc;
  border-radius: 30px;
  padding: 50px;
}
.wa-warn h3 {
  font-weight: 700;
  font-size: 20px;
}

.wa-warn__text {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  align-items: start;
  margin-bottom: 29px;
}
</style>

<style>
.whatsapp-ul ul {
  display: flex;
  flex-direction: column;
  gap: 10px;

  font-size: 18px;
}

@media (max-width: 567px) {
  .whatsapp-ul ul {
    gap: 10px;

    font-size: 13px;
  }
}
</style>