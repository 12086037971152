<template>
  <div>
    <div class="tit-h5 mb-3">
      Запрос отзыва по рейтингу, направит обращение клиента либо руководителю -
      либо на внешние ресурсы
    </div>
    <hr />
    <div class="mobile-reviews">
      <div class="mobile-reviews__col _first">
        <reviewRating
          class="mobile-reviews__stars w-100"
          :limit="$store.state.review.badRatingLimit"
          @updateLimit="updateBadRatingLimit"
        ></reviewRating>

        <div
          class="indicator"
          :style="`height: calc(60% + ${platformLinks.length * 20}px + ${
            addLinkFormVisible ? 39 : 0
          }px)`"
        >
          <div
            class="indicator__smiles"
            :style="`height: calc(${86 + platformLinks.length * 1.8}% + ${
              addLinkFormVisible ? 3 : 0
            }%);`"
          >
            <img :src="require('@/assets/smile.svg')" alt="" />
            <img :src="require('@/assets/sad-smile.svg')" alt="" />
          </div>
        </div>
      </div>
      <div class="mobile-reviews__col">
        <div class="mb-4">
          <div class="tit-h5 mb-3">Установите рейтинг</div>
          <div class="mobile-reviews__input _end _mobile mb-3">
            <default-input
              title="кол-во клиентов запустивших бот"
              :label="'Текст который увидит клиент перед тем как поставит оценку'"
              :inputClass="'weight-500 _small'"
              v-model="telegramStatus.comments_title"
            ></default-input>

            <button
              class="btn-orange _small fw-500"
              :class="{ '_loader-btn': loadingPutTexts }"
              @click="$store.dispatch('review/putCommentsTexts')"
              :disabled="
                lastTelegramStatus.comments_title ===
                telegramStatus.comments_title
              "
            >
              Сохранить
            </button>
          </div>

          <div class="tit-h5 mb-1">Положительная оценка</div>
          <hr class="m-0 mb-3" />

          <span class="tit-h6"
            >Отзыв по умолчанию - будет опубликован в системе yclients
            <span v-if="!this.$store.state.telegram.feedbackInApp"
              >(Отключено)</span
            >
          </span>
          <div class="mobile-reviews__input">
            <button
              class="default-input _small weight-500 mb-15px"
              :class="{ _disabled: !this.$store.state.telegram.feedbackInApp }"
              @click="
                this.$store.dispatch(
                  'telegram/putFeedbackInApp',
                  !this.$store.state.telegram.feedbackInApp
                )
              "
            >
              В приложении
            </button>

            <button
              class="btn-white _small"
              @click="
                this.$store.dispatch(
                  'telegram/putFeedbackInApp',
                  !this.$store.state.telegram.feedbackInApp
                )
              "
            >
              {{
                this.$store.state.telegram.feedbackInApp ? "Скрыть" : "Показать"
              }}
            </button>
          </div>

          <telegramPlatformLink
            class="mb-4"
            v-for="(link, index) in platformLinks"
            :key="index"
            :link="platformLinks[index]"
            :label="platformLinks[index].name"
            :loader="platformLinks[index].id === null"
            @updateLink="(id) => this.$store.dispatch('review/updatePlatformLink', id)"
            @deleteLink="(id) => this.$store.dispatch('review/deletePlatformLink', id)"
            @toggleLink="(state) => (toggleLink = state)"
          ></telegramPlatformLink>

          <transition name="fade" mode="out-in">
            <button
              class="mb-2 w-100 fw-500 btn-orange _small _big-text"
              v-if="!addLinkFormVisible"
              @click="addLinkFormVisible = true"
            >
              Добавить ресурс для публикации отзыва
            </button>

            <telegramPlatformLink
              :type="'create'"
              class="mb-4"
              v-else
              @cancelRedactor="addLinkFormVisible = false"
              @createLink="
                () => {
                  addLinkFormVisible = false;

                  this.$store.dispatch('review/createPlatformLink');
                }
              "
            ></telegramPlatformLink>
          </transition>
        </div>
        <div>
          <div class="tit-h5 mb-1">Отрицательная оценка</div>
          <hr class="m-0 mb-3" />

          <div class="mobile-reviews__input _end mb-3 _mobile">
            <default-input
              title="Текст который увидит клиент при отрицательной оценке"
              :label="'Текст который увидит клиент при отрицательной оценке'"
              :inputClass="'weight-500 _small'"
              v-model="telegramStatus.bad_comments_title"
            ></default-input>

            <button
              class="btn-orange _small fw-500"
              :class="{ '_loader-btn': loadingPutTelegramTexts }"
              @click="$store.dispatch('review/putCommentsTexts')"
              :disabled="
                lastTelegramStatus.bad_comments_title ===
                telegramStatus.bad_comments_title
              "
            >
              Сохранить
            </button>
          </div>
          <div class="mobile-reviews__input _end">
            <default-input
              title="На этот номер будет отправлен отрицательный отзыв"
              :label="'На этот номер будет отправлен отрицательный отзыв'"
              :inputClass="'weight-500 _small'"
              v-model="$store.state.profile.owner.chatapi_alarm_recipient"
            ></default-input>
            <button
              class="btn-orange _small"
              :class="[
                { '_loader-btn': $store.state.profile.loadingProfile },
                'fw-500',
              ]"
              @click="$store.dispatch('postOwnerInfo')"
              :disabled="
                $store.state.profile.lastOwnerData.chatapi_alarm_recipient ===
                $store.state.profile.owner.chatapi_alarm_recipient
              "
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
/* eslint-disable */
import telegramPlatformLink from "@/components/UI/telegramPlatformLink.vue";
import reviewRating from "@/iframe/iframeComponents/reviewRating.vue";

import { ref } from "@vue/reactivity";
import defaultInput from "../../components/UI/inputs/defaultInput.vue";
import { useStore } from "vuex";
import { computed, onBeforeMount } from "@vue/runtime-core";

export default {
  props: {
    type: [String],
  },
  components: {
    telegramPlatformLink,
    defaultInput,
    reviewRating,
  },
  setup() {
    const store = useStore();

    const telegramStatus = computed(() => store.state.telegram.telegramStatus);
    const lastTelegramStatus = computed(
      () => store.state.telegram.lastTelegramStatus
    );

    const loadingPutTexts = computed(
      () => store.state.telegram.loadingPutTexts
    );

    const platformLinks = computed(() => store.state.review.platformLinks);
    let state = ref(0);

    let addLinkFormVisible = ref(false);

    function updateBadRatingLimit(limit) {
      store.state.review.badRatingLimit = limit;
      store.dispatch("review/setBadRatingLimit");
    }

    onBeforeMount(() => {
      store.dispatch("review/getBadRatingLimit");
      store.dispatch("review/getPlatformLinks");
    });

    return {
      state,
      addLinkFormVisible,
      platformLinks,
      telegramStatus,
      lastTelegramStatus,
      loadingPutTexts,
      updateBadRatingLimit
    };
  },
};
</script>

<style lang="scss" scoped>
button._small {
  min-width: 124px;

  @media (max-width: 350px) {
    min-width: 0;
  }
}
.tit-h5 {
  font-weight: 600;
}
.indicator {
  position: relative;
  width: 52%;
  border-left: solid 1px #eb5757;
  border-bottom: solid 1px #eb5757;
  border-right: solid 1px #ffc549;

  &__smiles {
    position: absolute;
    bottom: -18px;
    right: -18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 13px 0 0 13px;
    background: #fff;

    @media (max-width: 320px) {
      right: -10px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.mobile-reviews {
  display: flex;

  &__input {
    display: flex;
    gap: 33px;
    @media (max-width: 567px) {
      gap: 10px;
    }
  }

  &__input._end {
    align-items: end;
  }

  &__input._mobile {
    @media (max-width: 567px) {
      flex-direction: column;
      align-items: start;
    }
  }

  &__col._first {
    min-width: 155px;

    @media (max-width: 1024px) {
      min-width: 80px;
    }
    @media (max-width: 320px) {
      min-width: 55px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
  }

  &__stars {
    margin-bottom: 10px;
  }
}
</style>