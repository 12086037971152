<template>
  <transition name="fade">
    <div class="qoob-loader-wrap" v-if="visible">
      <div class="qoob-loader-wrap__indicator" :style="`width: ${$store.state.loaderStatus.percent}%`"></div>
      <div class="qoob-loader">
        <svg
          width="28"
          height="31"
          viewBox="0 0 28 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="qoob-loader1 qoob-loader__item"
        >
          <path
            d="M27.9427 13.9951C27.9767 12.1615 27.6346 10.3403 26.9375 8.64394C26.2405 6.9476 25.2032 5.41208 23.8897 4.13213C22.6009 2.82555 21.0599 1.7946 19.3604 1.10191C17.6608 0.409206 15.8382 0.0692144 14.0033 0.10258C12.1747 0.0837251 10.3608 0.430364 8.6681 1.12215C6.97537 1.81393 5.43789 2.83692 4.14594 4.13105C2.85399 5.42518 1.8336 6.96438 1.14468 8.65828C0.455752 10.3522 0.112173 12.1666 0.134114 13.9951C0.108893 15.8234 0.450402 17.6383 1.13846 19.3323C1.82652 21.0264 2.84717 22.5654 4.1401 23.8583C5.43302 25.1512 6.972 26.1719 8.66607 26.8599C10.3601 27.548 12.175 27.8895 14.0033 27.8643C15.5966 27.8859 17.1817 27.6323 18.6888 27.1146C18.9641 27.0087 19.261 26.971 19.554 27.0048C19.8471 27.0385 20.1276 27.1428 20.3716 27.3085C20.6156 27.4743 20.8159 27.6966 20.9553 27.9566C21.0947 28.2166 21.1691 28.5064 21.1721 28.8014C21.1721 29.282 21.3614 29.7433 21.699 30.0853C22.0367 30.4273 22.4955 30.6226 22.976 30.6287H26.1153C26.6 30.6287 27.0648 30.4362 27.4075 30.0935C27.7502 29.7508 27.9427 29.286 27.9427 28.8014V13.9951ZM19.1105 19.1258C18.4418 19.7956 17.6448 20.3235 16.7672 20.6777C15.8896 21.032 14.9495 21.2053 14.0033 21.1874C13.0632 21.2114 12.1284 21.0406 11.2575 20.6858C10.3866 20.331 9.59858 19.7999 8.94289 19.1258C8.26571 18.4442 7.73546 17.6311 7.38489 16.7365C7.03433 15.842 6.8709 14.8851 6.90469 13.9249C6.8709 12.9647 7.03433 12.0078 7.38489 11.1132C7.73546 10.2187 8.26571 9.4055 8.94289 8.72394C9.59508 8.04524 10.3825 7.51103 11.2542 7.15589C12.1259 6.80075 13.0625 6.6326 14.0033 6.66231C14.9508 6.63469 15.8937 6.80362 16.7727 7.15844C17.6517 7.51326 18.4477 8.04629 19.1105 8.72394C19.7877 9.4055 20.3179 10.2187 20.6685 11.1132C21.019 12.0078 21.1825 12.9647 21.1487 13.9249C21.1825 14.8851 21.019 15.842 20.6685 16.7365C20.3179 17.6311 19.7877 18.4442 19.1105 19.1258Z"
            fill="#FFC549"
          />
        </svg>
        <svg
          width="29"
          height="28"
          viewBox="0 0 29 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="qoob-loader2 qoob-loader__item"
        >
          <path
            d="M24.5273 23.8112C21.8966 26.408 18.349 27.864 14.6526 27.864C10.9561 27.864 7.40849 26.408 4.77782 23.8112C3.47192 22.5268 2.44119 20.9899 1.74842 19.2943C1.05566 17.5986 0.715365 15.7796 0.748272 13.9482C0.735847 12.1285 1.08472 10.3244 1.77466 8.64046C2.4646 6.95654 3.4819 5.4263 4.76759 4.13843C6.05328 2.85057 7.58179 1.83068 9.26453 1.13789C10.9473 0.445091 12.7508 0.0931679 14.5706 0.102511C16.4121 0.0680672 18.242 0.402661 19.9522 1.08657C21.6624 1.77048 23.2184 2.78985 24.5284 4.08461C25.8385 5.37936 26.876 6.92329 27.58 8.62536C28.2839 10.3274 28.6399 12.1532 28.6271 13.9951C28.6486 15.8243 28.2961 17.6386 27.591 19.3266C26.886 21.0146 25.8434 22.5407 24.5273 23.8112ZM9.55705 19.1257C10.2229 19.7927 11.0161 20.3189 11.8895 20.673C12.7628 21.0272 13.6985 21.2021 14.6408 21.1874C15.5839 21.1879 16.5173 20.9986 17.3857 20.6309C18.254 20.2631 19.0395 19.7243 19.6953 19.0467C20.3511 18.369 20.8638 17.5663 21.2029 16.6864C21.542 15.8065 21.7006 14.8673 21.6691 13.9248C21.7029 12.9646 21.5395 12.0077 21.1889 11.1131C20.8384 10.2186 20.3081 9.40543 19.6309 8.72387C18.962 8.07245 18.17 7.56085 17.3011 7.21894C16.4323 6.87703 15.504 6.71167 14.5706 6.73252C13.6266 6.70532 12.6873 6.87452 11.8121 7.22942C10.937 7.58431 10.1451 8.11715 9.48676 8.79415C8.82642 9.48356 8.31345 10.3003 7.97914 11.1945C7.64482 12.0886 7.49618 13.0416 7.54227 13.9951C7.51504 14.9419 7.67947 15.8845 8.0257 16.7662C8.37193 17.6478 8.89281 18.4504 9.55705 19.1257Z"
            fill="#FFC549"
          />
        </svg>

        <svg
          width="29"
          height="28"
          viewBox="0 0 29 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="qoob-loader3 qoob-loader__item"
        >
          <path
            d="M24.5273 23.8112C21.8966 26.408 18.349 27.864 14.6526 27.864C10.9561 27.864 7.40849 26.408 4.77782 23.8112C3.47192 22.5268 2.44119 20.9899 1.74842 19.2943C1.05566 17.5986 0.715365 15.7796 0.748272 13.9482C0.735847 12.1285 1.08472 10.3244 1.77466 8.64046C2.4646 6.95654 3.4819 5.4263 4.76759 4.13843C6.05328 2.85057 7.58179 1.83068 9.26453 1.13789C10.9473 0.445091 12.7508 0.0931679 14.5706 0.102511C16.4121 0.0680672 18.242 0.402661 19.9522 1.08657C21.6624 1.77048 23.2184 2.78985 24.5284 4.08461C25.8385 5.37936 26.876 6.92329 27.58 8.62536C28.2839 10.3274 28.6399 12.1532 28.6271 13.9951C28.6486 15.8243 28.2961 17.6386 27.591 19.3266C26.886 21.0146 25.8434 22.5407 24.5273 23.8112ZM9.55705 19.1257C10.2229 19.7927 11.0161 20.3189 11.8895 20.673C12.7628 21.0272 13.6985 21.2021 14.6408 21.1874C15.5839 21.1879 16.5173 20.9986 17.3857 20.6309C18.254 20.2631 19.0395 19.7243 19.6953 19.0467C20.3511 18.369 20.8638 17.5663 21.2029 16.6864C21.542 15.8065 21.7006 14.8673 21.6691 13.9248C21.7029 12.9646 21.5395 12.0077 21.1889 11.1131C20.8384 10.2186 20.3081 9.40543 19.6309 8.72387C18.962 8.07245 18.17 7.56085 17.3011 7.21894C16.4323 6.87703 15.504 6.71167 14.5706 6.73252C13.6266 6.70532 12.6873 6.87452 11.8121 7.22942C10.937 7.58431 10.1451 8.11715 9.48676 8.79415C8.82642 9.48356 8.31345 10.3003 7.97914 11.1945C7.64482 12.0886 7.49618 13.0416 7.54227 13.9951C7.51504 14.9419 7.67947 15.8845 8.0257 16.7662C8.37193 17.6478 8.89281 18.4504 9.55705 19.1257Z"
            fill="#FFC549"
          />
        </svg>

        <svg
          width="28"
          height="31"
          viewBox="0 0 28 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="qoob-loader4 qoob-loader__item"
        >
          <path
            d="M27.9427 13.9951C27.9767 12.1615 27.6346 10.3403 26.9375 8.64394C26.2405 6.9476 25.2032 5.41208 23.8897 4.13213C22.6009 2.82555 21.0599 1.7946 19.3604 1.10191C17.6608 0.409206 15.8382 0.0692144 14.0033 0.10258C12.1747 0.0837251 10.3608 0.430364 8.6681 1.12215C6.97537 1.81393 5.43789 2.83692 4.14594 4.13105C2.85399 5.42518 1.8336 6.96438 1.14468 8.65828C0.455752 10.3522 0.112173 12.1666 0.134114 13.9951C0.108893 15.8234 0.450402 17.6383 1.13846 19.3323C1.82652 21.0264 2.84717 22.5654 4.1401 23.8583C5.43302 25.1512 6.972 26.1719 8.66607 26.8599C10.3601 27.548 12.175 27.8895 14.0033 27.8643C15.5966 27.8859 17.1817 27.6323 18.6888 27.1146C18.9641 27.0087 19.261 26.971 19.554 27.0048C19.8471 27.0385 20.1276 27.1428 20.3716 27.3085C20.6156 27.4743 20.8159 27.6966 20.9553 27.9566C21.0947 28.2166 21.1691 28.5064 21.1721 28.8014C21.1721 29.282 21.3614 29.7433 21.699 30.0853C22.0367 30.4273 22.4955 30.6226 22.976 30.6287H26.1153C26.6 30.6287 27.0648 30.4362 27.4075 30.0935C27.7502 29.7508 27.9427 29.286 27.9427 28.8014V13.9951ZM19.1105 19.1258C18.4418 19.7956 17.6448 20.3235 16.7672 20.6777C15.8896 21.032 14.9495 21.2053 14.0033 21.1874C13.0632 21.2114 12.1284 21.0406 11.2575 20.6858C10.3866 20.331 9.59858 19.7999 8.94289 19.1258C8.26571 18.4442 7.73546 17.6311 7.38489 16.7365C7.03433 15.842 6.8709 14.8851 6.90469 13.9249C6.8709 12.9647 7.03433 12.0078 7.38489 11.1132C7.73546 10.2187 8.26571 9.4055 8.94289 8.72394C9.59508 8.04524 10.3825 7.51103 11.2542 7.15589C12.1259 6.80075 13.0625 6.6326 14.0033 6.66231C14.9508 6.63469 15.8937 6.80362 16.7727 7.15844C17.6517 7.51326 18.4477 8.04629 19.1105 8.72394C19.7877 9.4055 20.3179 10.2187 20.6685 11.1132C21.019 12.0078 21.1825 12.9647 21.1487 13.9249C21.1825 14.8851 21.019 15.842 20.6685 16.7365C20.3179 17.6311 19.7877 18.4442 19.1105 19.1258Z"
            fill="#FFC549"
          />
        </svg>
      </div>

      <div class="qoob-loader__text">
        {{$store.state.loaderStatus.text}}
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.qoob-loader-wrap {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9000;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  background: #fff;
}

.qoob-loader-wrap__indicator {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  background: #8ECD88;
  box-shadow: 0 0 10px #8ECD88;

  transition: .2s;
}
.qoob-loader {
  position: relative;
  width: 50px;
  height: 50px;

  transform: scale(0.3);

  margin-right: 10px;
}

.qoob-loader1 {
  top: 0;
  left: 0;

  animation: qoob-loader1 2s infinite;
}

.qoob-loader2 {
  top: 0;
  left: 50px;
  animation: qoob-loader2 2s infinite;
}

.qoob-loader3 {
  top: 50px;
  left: 0;

  animation: qoob-loader3 2s infinite;
}

.qoob-loader4 {
  top: 50px;
  left: 50px;

  animation: qoob-loader4 2s infinite;

  transform: rotate(180deg);
}

.qoob-loader__item {
  position: absolute;
  width: 45px;
  height: 45px;
  transition: 0.2s;
}

.qoob-loader__text {
  line-height: 66px;
  font-size: 30px;
  font-weight: 500;
}

/* 0 | 15 | 30 | 45 | ** | 100 */
@keyframes qoob-loader1 {
  0% {
    transform: rotate(0deg);
    left: 0;
    top: 0;
  }

  25% {
    transform: rotate(-90deg);
    top: 50px;
    left: 0;
  }

  50% {
    top: 50px;
    left: 50px;
    transform: rotate(-180deg);
  }

  75% {
    top: 0;
    left: 50px;
    transform: rotate(-270deg);
  }

  100% {
    transform: rotate(-360deg);

    top: 0;
    left: 0;
  }
}

@keyframes qoob-loader2 {
  0% {
    transform: rotate(0deg);
    left: 50px;
    top: 0;
  }

  25% {
    transform: rotate(-90deg);
    top: 0;
    left: 0;
  }

  50% {
    top: 50px;
    left: 0;
    transform: rotate(-180deg);
  }

  75% {
    top: 50px;
    left: 50px;
    transform: rotate(-360deg);
  }

  100% {
    transform: rotate(-360deg);

    top: 0;
    left: 50px;
  }
}

@keyframes qoob-loader3 {
  0% {
    transform: rotate(0deg);
    left: 0;
    top: 50px;
  }

  25% {
    transform: rotate(-90deg);
    top: 50px;
    left: 50px;
  }

  50% {
    top: 0;
    left: 50px;
    transform: rotate(-180deg);
  }

  75% {
    top: 0;
    left: 0;
    transform: rotate(-270deg);
  }

  100% {
    transform: rotate(-360deg);

    top: 50px;
    left: 0;
  }
}

@keyframes qoob-loader4 {
  0% {
    transform: rotate(180deg);

    left: 50px;
    top: 50px;
  }

  25% {
    transform: rotate(90deg);

    top: 0;
    left: 50px;
  }

  50% {
    transform: rotate(0deg);

    top: 0;
    left: 0;
  }

  75% {
    transform: rotate(-90deg);

    top: 50px;
    left: 0;
  }

  100% {
    transform: rotate(-180deg);

    top: 50px;
    left: 50px;
  }
}
</style>