<template>
  <billing-layout @selectTariff="selectTariff($event)" v-if="step === 0" />
  <div class="rate-page" v-else-if="step === 1">
    <select-plan
      :currentTariff="currentTariff"
      :companyList="$store.state.crm.filialsList"

      @finish="finishPay(event)"
    />
  </div>

  <div class="blank" v-if="!this.$store.state.isFrameVersion"></div>
</template>

<script>
import billingLayout from "@/views/Dashboard/Billing/billing-layout";
import selectPlan from "@/components/Dashboard/Billing/select-plan";
import { ref } from "vue";

export default {
  name: "App",
  components: {
    billingLayout,
    selectPlan,
  },
  setup() {
    const modalState = ref(false);

    let step = ref(0);

    function toggleModal(tarif) {
      modalState.value = tarif;
      step.value++;
    }

    let currentTariff = ref(null);

    function selectTariff(item) {
      currentTariff.value = item;
      toggleModal(true);
    }

    function finishPay() {
      location.reload()
    }

    return { modalState, toggleModal, selectTariff, currentTariff, step, finishPay };
  },
};
</script>

<style src="../../assets/css/billing.css"></style> 

<style scoped>
.blank {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: calc(37% - 200px);
  padding-right: 60px;
  background-color: #f7f9fc;
}

@media (max-width: 1440px) {
  .blank {
    display: none;
  }
}
</style>