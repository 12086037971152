<template>
  <cm-card-base
    :width="width"
    padding="1.7rem 2.5rem"
    height="max-content"
    class="helpCardPadding"
  >
    <div class="d-flex justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <img src="@/assets/instruction.svg" class="me-3">
        <span class="helpTitle">
          {{ title }}
        </span>
      </div>
    </div>
    <div
      class="text-secondary"
      v-html="description"
    >
    </div>
    <div
      class="text-secondary"
    >
    <slot></slot>
    </div>
  </cm-card-base>
</template>

<script>
import CmCardBase from "@/components/UI/CmCardBase";
export default {
name: "HelpCard",
  components: {CmCardBase},
  props:{
    title:{
      type: String,
      required: true
    },
    subTitle:{
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    width: {
      type: String,
    }
  },
}
</script>

<style scoped>

.helpCardPadding > div {
  padding: 1rem 2rem;
  background-color: #3A7734;
}

.helpTitle {
  font-size: 16px;
  font-weight: 500;
}

.text-secondary {
  font-size: 16px;
  line-height: 30px;
  color: #6D6D6D;
}

@media (max-width: 1280px) {
  .text-secondary {
    font-size: 14px;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .helpCardPadding {
    padding: 30px;
  }
}
</style>

