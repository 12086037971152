<template>
  <!--<div>Гид по настройке</div>-->
  <div class="guide-wrapper col mt-5 mb-5">
    <h4 class="pageSubTitle">{{ pageSubTitle }}</h4>
    <p class="pageDescription mt-4">{{ pageDescription }}</p>
    <div class="guideBox" v-for="guidebox in guideboxes" :key="guidebox.id">
      <div class="d-flex titleWrap align-items-center">
        <span class="mySpan d-flex justify-content-center align-items-center">{{
          guidebox.id
        }}</span>
        <h3 class="guideBoxTitle">{{ guidebox.title }}</h3>
      </div>
      <div class="d-flex">
        <div class="guideBoxBody">{{ guidebox.body1 }}</div>
        <div class="guideBoxBody">{{ guidebox.body2 }}</div>
      </div>

      <button-transparent class="guide__link" @click="guidebox.func">
        {{ guidebox.caption }}
        <img
          class="link-arrow"
          src="../../assets/arrow-right-black.svg"
          alt=""
        />
      </button-transparent>
    </div>

    <div
      class="guide--mobile"
      v-for="(guide, index) in guideboxes"
      :key="guide.id"
      
    >
      <div class="guide__header" @click="openText(index)">
        <div class="header__wrapper">
          <span class="guide__id">{{ guide.id }}</span>
          <p class="guide__title">{{ guide.title }}</p>
        </div>
        <img class="arrow-icon" src="../../assets/arrow-down.svg" alt="" />
      </div>

      <transition name="guideAm">
        <div class="guide__body" v-show="currentText === index">
          <p class="guide__text">{{ guide.body1 }}</p>

          <button-transparent class="guide__link" @click="guide.func">
            Перейти к материалам
            <img
              class="link-arrow"
              src="../../assets/arrow-right-black.svg"
              alt=""
            />
          </button-transparent>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
import buttonTransparent from "@/components/UI/buttons/ButtonTransparent";

export default {
  name: "Guide",
  components: { buttonTransparent },
  data() {
    return {
      guideboxes: [
        {
          id: 1,
          caption: "Перейти к настройке",
          title: "Синхронизация с CRM системой yclients",
          body1:
            "Равным образом начало повседневной работы по формированию позиции позволяет выполнять важные задания по разработке позиций, занимаемых участниками в отношении поставленных задач. Идейные соображения высшего порядка, а также реализация намеченных плановых заданий представляет собой интересный эксперимент проверки форм развития.",
          body2:
            "Идейные соображения высшего порядка, а также постоянный количественный рост и сфера нашей активности влечет за собой процесс внедрения и модернизации соответствующий условий активизации. Равным образом реализация намеченных плановых заданий в значительной степени обуславливает создание существенных финансовых и административных условий.",
          func: () => this.$store.dispatch("crmModalVisible"),
        },
        {
          id: 2,
          caption: "Выбрать тариф",
          title: "Выбор тарифа оплаты qoob.store",
          body1:
            "2Равным образом начало повседневной работы по формированию позиции позволяет выполнять важные задания по разработке позиций, занимаемых участниками в отношении поставленных задач. Идейные соображения высшего порядка, а также реализация намеченных плановых заданий представляет собой интересный эксперимент проверки форм развития.",
          body2:
            "2Идейные соображения высшего порядка, а также постоянный количественный рост и сфера нашей активности влечет за собой процесс внедрения и модернизации соответствующий условий активизации. Равным образом реализация намеченных плановых заданий в значительной степени обуславливает создание существенных финансовых и административных условий.",
          func: () => this.$router.push("/dashboard/billing/license"),
        },
        {
          id: 3,
          caption: "Перейти к настройке",
          title: "Настройка уведомлений",
          body1:
            "2Равным образом начало повседневной работы по формированию позиции позволяет выполнять важные задания по разработке позиций, занимаемых участниками в отношении поставленных задач. Идейные соображения высшего порядка, а также реализация намеченных плановых заданий представляет собой интересный эксперимент проверки форм развития.",
          body2:
            "2Идейные соображения высшего порядка, а также постоянный количественный рост и сфера нашей активности влечет за собой процесс внедрения и модернизации соответствующий условий активизации. Равным образом реализация намеченных плановых заданий в значительной степени обуславливает создание существенных финансовых и административных условий.",
          func: () => this.$router.push("/dashboard/notifications/types"),
        },
        {
          id: 4,
          caption: "Перейти к настройке",
          title: "Настройка приложения для клиентов ",
          body1:
            "2Равным образом начало повседневной работы по формированию позиции позволяет выполнять важные задания по разработке позиций, занимаемых участниками в отношении поставленных задач. Идейные соображения высшего порядка, а также реализация намеченных плановых заданий представляет собой интересный эксперимент проверки форм развития.",
          body2:
            "2Идейные соображения высшего порядка, а также постоянный количественный рост и сфера нашей активности влечет за собой процесс внедрения и модернизации соответствующий условий активизации. Равным образом реализация намеченных плановых заданий в значительной степени обуславливает создание существенных финансовых и административных условий.",
          func: () => this.$router.push("/dashboard/app"),
        },
        {
          id: 5,
          caption: "Перейти к материалам",
          title: "Внедрение, обучение и дополнительные материалы",
          body1:
            "2Равным образом начало повседневной работы по формированию позиции позволяет выполнять важные задания по разработке позиций, занимаемых участниками в отношении поставленных задач. Идейные соображения высшего порядка, а также реализация намеченных плановых заданий представляет собой интересный эксперимент проверки форм развития.",
          body2:
            "2Идейные соображения высшего порядка, а также постоянный количественный рост и сфера нашей активности влечет за собой процесс внедрения и модернизации соответствующий условий активизации. Равным образом реализация намеченных плановых заданий в значительной степени обуславливает создание существенных финансовых и административных условий.",
        },
      ],
      pageSubTitle: "С чего начать?",
      pageDescription:
        "Для корректной работы приложения необходимо произвести настройку систем. Мы поможем вам с настройками.",
      currentText: 4,
    };
  },
  methods: {
    openText(index) {
      if (index === this.currentText) {
        this.currentText = -1;
      } else {
        this.currentText = index;
      }
    },
  },
};
</script>

<style scoped>
.sectionWrap {
  margin-top: 1.875rem;
  margin-left: 3.75rem;
}
.guideBox {
  background: #ffffff;
  box-shadow: 0px 17px 40px rgba(19, 49, 97, 0.07),
    0px 6.20528px 21.901px rgba(19, 49, 97, 0.0482987),
    0px 3.01255px 10.6325px rgba(19, 49, 97, 0.0389404),
    0px 1.47681px 5.21226px rgba(19, 49, 97, 0.0310596);
  border-radius: 1.875rem;
  padding: 60px;
  margin-bottom: 20px;
}
.mySpan {
  font-size: 25px;
  font-weight: 500;
  background-color: #ffc549;
  border-radius: 12px;
  width: 38px;
  height: 38px;
  margin-right: 1rem;
}
.pageSubTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  color: #1e2022;
}
.pageDescription {
  font-style: normal;
  font-size: 22px;
  line-height: 34px;
  color: #000000;
  max-width: 50%;
  margin-bottom: 3.75rem;
}
.guideBoxTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  color: #1e2022;
  margin-bottom: 0;
}
.guideBoxBody {
  font-family: "Graphik LCG";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin-right: 2.375rem;
  margin-bottom: 2.5rem;
}
.titleWrap {
  margin-bottom: 2rem;
}

.guide--mobile {
  display: none;
}

.guide-wrapper {
  max-width: 1600px;
  box-sizing: content-box;
}

.link-arrow {
  margin-left: 10px;
}

@media (max-width: 1600px) {
  .guideBox {
    padding: 50px;
  }
}

@media (max-width: 1440px) {
  .pageSubTitle {
    font-size: 26px;
    line-height: 30px;
  }

  .pageDescription {
    font-size: 16px;
    line-height: 26px;
  }

  .guideBoxTitle {
    font-size: 20px;
  }

  .guideBoxBody {
    font-size: 14px;
    line-height: 24px;
  }

  .mySpan {
    width: 32px;
    height: 32px;

    font-size: 20px;
  }

  .guide__link {
    height: 40px !important;
  }
}
</style>

<style scoped>
@media (max-width: 1280px) {
  .guide-wrapper {
    padding: 0 30px !important;
  }

  .pageDescription {
    max-width: 70%;
  }

  .guideBox {
    padding: 40px;
  }
}

@media (max-width: 1024px) {
  .guide-wrapper {
    padding: 0 !important;
  }

  .pageSubTitle {
    font-size: 20px;
  }

  .pageDescription {
    max-width: none;
    margin-top: 0 !important;

    font-size: 14px;
    line-height: 24px;
  }

  .guideBox {
    display: none;
  }

  .guide--mobile {
    display: block;
    padding-top: 30px;

    border-top: 1px solid #eff0f0;
  }

  .guide__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 30px;
  }

  .header__wrapper {
    display: flex;
    align-items: center;
  }

  .arrow-icon {
    width: 22px;
    height: 24px;
  }

  .guide__id {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;

    border-radius: 12px;

    font-size: 16px;
    font-weight: 500;

    background-color: #ffc549;
  }

  .guide__title {
    margin: 0 15px;
    margin-left: 60px;

    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  .guide__text {
    margin-bottom: 30px;

    font-size: 14px;
    line-height: 24px;
  }

  .guide__link {
    margin-bottom: 30px;
  }

  .guideAm-enter-active {
    animation: 0.5s guideKeys ease reverse;
    overflow: hidden;
  }

  .guideAm-leave-active {
    animation: 0.5s guideKeys ease;
    overflow: hidden;
  }

  @keyframes guideKeys {
    0% {
      height: 175px;
    }
    100% {
      height: 0;
    }
  }
}

@media (max-width: 768px) {
  .guide__title {
    margin-left: 30px;
  }
}

@media (max-width: 550px) {
  .pageSubTitle {
    padding-left: 15px;
  }

  .pageDescription {
    padding-left: 15px;
  }

  .guide--mobile {
    padding: 0 15px;
    padding-top: 30px;
  }

  .guide__title {
    margin-left: 15px;
  }
}
</style>

