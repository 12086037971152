import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {global} from "./globalObjects"
import mitt from 'mitt'
import Maska from 'maska'

import UiComponents from '@/components/UI/ui.js'
 
import '@/assets/scss/index.scss'

const emitter = mitt();

window.apiPath = '/api/v2'
const app = createApp(App);

UiComponents.forEach(component => {
  app.component(component.name, component)
})

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.glb = global;
app.use(store);
app.use(router);


app.use(Maska);


app.mount('#app')
