<template>
  <div class="graph2">
    <h4 class="graph__title">Жизненный цикл клиентов</h4>
    <p class="graph__subtitle" v-show="!this.$store.state.crm.crmConnect">
      Необходимо выполнить синхронизацию с вашей crm системой 
    </p>
    <div @click="$store.commit('visibleSupportPopup')" class="graph__wrapper" :class="{_loader: this.$store.state.dashboard.chartsCircle.loading}">
      <graph-circle v-if="this.$store.state.crm.crmConnect && !this.$store.state.dashboard.chartsCircle.loading" />
      <div v-else class="graph__placeholder" :class="placeholderBg"></div>
    </div>
  </div>
</template>

<script>
import graphCircle from "@/components/Dashboard/Services/graph-circle";
import { ref, computed, watchEffect } from "vue";
import {useStore} from "vuex";

export default {
  name: "ServicesSegmentStatistic",
  components: {
    graphCircle,
  },
  setup() {

    const store = useStore();

    const isSync = ref(true);

    const windowWidth = ref(0);

    watchEffect(() => {
      windowWidth.value = store.state.windowWidth;
    })

    const placeholderBg = computed(() => {
      if(windowWidth.value > 550) {
        return 'background1';
      }
      return 'background2';
    })

    return { isSync, placeholderBg };
  },
};
</script>

<style scoped>
.graph__placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;

  box-shadow: 0px 17px 40px rgb(19 49 97 / 7%), 0px 6.20528px 21.901px rgb(19 49 97 / 5%), 0px 3.01255px 10.6325px rgb(19 49 97 / 4%), 0px 1.47681px 5.21226px rgb(19 49 97 / 3%), 0px 0.583932px 2.06094px rgb(19 49 97 / 2%);
}

.background1 {
  background: url('~@/assets/segments-placeholder.jpg') #D0D0D0 no-repeat center / 95%;
}

.background2 {
  background: url('~@/assets/segments-placeholder2.jpg') #D0D0D0 no-repeat center / 95%;
}

.graph__title {
  margin-bottom: 5px;

  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
}

.graph__subtitle {
  font-size: 14px;
  color: #6d6d6d;
}

.graph__wrapper {
  position: relative;

  width: 100%;
  height: 340px;
  padding: 35px;
  padding-left: 40px;
  margin-top: 25px;

  box-sizing: border-box;
  box-shadow: 0 17px 40px rgba(19, 49, 97, 0.07),
  0 6.20528px 21.901px rgba(19, 49, 97, 0.0482987),
  0 3.01255px 10.6325px rgba(19, 49, 97, 0.0389404),
  0 1.47681px 5.21226px rgba(19, 49, 97, 0.0310596),
  0px 0.583932px 2.06094px rgba(19, 49, 97, 0.0217013);
  border-radius: 30px;

  background-color: #fff;
}

@media (max-width: 1600px) and (min-width: 1024px) {
  .graph__wrapper {
    height: 290px;
    padding: 20px;
    padding-left: 30px;
    margin-top: 20px;
  }

  .graph__title {
    font-size: 18px;
  }

  .graph__subtitle {
    font-size: 13px;
  }
}

@media (max-width: 1440px) and (min-width: 1024px) {
  .graph__wrapper {
    height: 240px;
    padding: 15px;
    padding-left: 20px;
  }
}

@media (max-width: 1280px) and (min-width: 1024px) {

  .graph__title {
    font-size: 16px;
  }

  .graph__subtitle {
    font-size: 12px;
  }
}

@media (max-width: 1024px) {
  .graph__wrapper {
    height: 320px;
  }
}

@media (max-width: 768px) {
  .graph__wrapper {
    height: 300px;
    padding: 25px;
  }
}

@media (max-width: 550px) {
  .graph__wrapper {
    width: 100%;
    height: 515px;
    margin-top: 15px;
  }

  .graph2 {
    width: 100%;
    margin: 0;
  }

  .graph__title {
    margin-left: 30px;
    margin-bottom: 10px;
    line-height: 20px;
  }

  .graph__subtitle {
    display: none;
  }

  .graph__placeholder {
    height: 578px;
    background-size: contain;
  }
}
</style>

