<template>
  <div class="contact__wrapper mt-4">
    <h6 class="contactTitle fw-500">Контакт
      <span class="ms-1" v-if="isSelectedPosition">"{{ selectedPosition }}"</span>
    </h6>
    <div class="contact__input">
      <CmInput
          v-model="contactPhone"
          :label="text.phoneText"
      ></CmInput>
    </div>

    <drop-menu :itemSelected="itemSelected">
      <template v-slot:name>
        <p class="drop__label">Предпочтительный способ связи</p>
      </template>

      <template v-slot:text>{{ positions[currentPosition].name }}</template>
      <template v-slot:item>
        <li class="drop__item-wrapper" v-for="(position, index) in positions" :key="position.id">
          <button class="drop__item" @click="selectDrop(index)">{{ position.name }}</button>
        </li>

      </template>
    </drop-menu>

    <div class="checkbox-area">
      <div class="checkbox-wrapper">
        <custom-checkbox
            item=""
            index=""
            :defaultState="true"
            @toggleCheckbox="checkboxToggle('warnings')"
        />
        <p class="checkbox__label">{{ text.noticesTexts.text1 }}</p>
      </div>

      <div class="checkbox-wrapper">
        <custom-checkbox
            item=""
            index=""
            :defaultState="true"
            @toggleCheckbox="checkboxToggle('news')"
        />
        <p class="checkbox__label">{{ text.noticesTexts.text2 }}</p>
      </div>

      <div class="checkbox-wrapper">
        <custom-checkbox
            item=""
            index=""
            :defaultState="true"
            @toggleCheckbox="checkboxToggle('license')"
        />
        <p class="checkbox__label">{{ text.noticesTexts.text3 }}</p>
      </div>
    </div>

    <div class="buttons-wrapper d-flex mt-4">

      <button-grey class="delete-button me-4" @click="deleteContact" v-if="last">Удалить контакт</button-grey>

      <button-transparent class="add-button" v-if="last">Добавить новый контакт</button-transparent>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import CmInput from "../../../components/UI/inputs/CmInput";
import dropMenu from "@/components/Dashboard/Billing/drop-menu";
import customCheckbox from "@/components/Dashboard/Billing/custom-checkbox";
import buttonGrey from "@/components/UI/buttons/ButtonGrey";
import buttonTransparent from "@/components/UI/buttons/ButtonTransparent";

export default {
  name: "PersonalAreaContact",
  props: {
    contactProp: {
      type: Object,
    },
    last: {
      type: Boolean,
      required: false
    }
  },
  components: {CmInput, dropMenu, customCheckbox, buttonGrey, buttonTransparent},
  data() {
    return {
      contact: {
          position: {name:'Не выбран'},
          phone: '+7 ... ... .. ..',
          warnings: false,
          news: false,
          license: false,
      },
      isSelectedPosition: false,
      contactPhone: '',
      contactWarnings: false,
      contactNews: false,
      contactLicense: false,
      selectedPosition: '',
      positions: [{name: 'Администратор', value: 1}, {name: 'Уборщица', value: 2}],
      itemSelected: false,
      currentPosition: 0
    }
  },
  mounted() {
    if (this.contactProp){
      this.contact = this.contactProp;
      this.selectedPosition = this.contact.position.name;
      this.isSelectedPosition = true;
      this.contactPhone = this.contact.phone;
      this.contactWarnings = this.contact.warnings;
      this.contactNews = this.contact.news;
      this.contactLicense = this.contact.license;
    }
  },
  computed: {
    ...mapState({
      text: state => state.texts.dashboard.personalArea.contact,
    }),
  },
  methods: {
    updatePosition(value){
      this.selectedPosition = value.name;
      this.isSelectedPosition = true;
    },
    deleteContact(){
      this.$emit('delete');
    },
    selectDrop(index) {
      this.currentPosition = index;
      this.itemSelected = true;

      setTimeout(() => {
        this.itemSelected = false;
      }, 300)
    },
    checkboxToggle(item) {
      this.contact[item] = !this.contact[item];
    }
  }

}
</script>

<style scoped>
hr {
  color: #d4d4d4;
}
.contactTitle{
  margin-top: 2.3rem;
}
.drop__label {
  margin-bottom: 10px;

  font-size: 14px;
  color: #6D6D6D;
}

.drop__item-wrapper:hover {
  background-color: #F6F9FC;
}

.drop__item-wrapper:active {
  background-color: #F0F3F8;
}

.drop__item {
  display: flex;
  align-items: center;

  height: 45px;
  padding-left: 20px;

  font-size: 15px;
}

.checkbox-wrapper {
  display: flex;
  margin-bottom: 20px;
}
.checkbox__label {
  margin-left: 20px;

  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

.checkbox-area {
  margin-top: 35px;
}

.add-button {
  width: 55%;
}

.delete-button {
  margin-right: 20px;
}

.contactTitle {
  margin-top: 35px;

  font-size: 16px;
  line-height: 22px;
}

@media (max-width: 1600px) {

  .buttons-wrapper {
    flex-direction: column;
  }

  .add-button {
    width: 100%;

    font-size: 14px!important;
  }

  .delete-button {
    width: 100%;
    margin-bottom: 25px;

    font-size: 14px!important;
  }
}

@media (max-width: 1440px) {
  .buttons-wrapper {
    flex-direction: row;
  }

  .add-button {
    width: 55%;
  }

  .delete-button {
    width: 45%;
  }
}

@media (max-width: 1280px) {
  .buttons-wrapper {
    flex-direction: column;
  }

  .delete-button {
    width: 100%;
    margin-bottom: 15px;
  }

  .add-button {
    width: 100%;
  }
}

@media (max-width: 768px) {

  .delete-button {
    font-size: 16px!important;
  }

  .add-button {
    font-size: 16px!important;
  }
}
</style>

<style scoped>
@media (max-width: 550px) {

  .contact__wrapper {
    margin-top: 35px!important;
  }

  .contact__input {
    margin-bottom: 25px;
  }

  .delete-button {
    width: 100%;
  }

  .buttons-wrapper {
    display: block!important;

    padding-bottom: 32px;
    border-bottom: 1px solid #EFF0F0;
  }

  .add-button {
    width: 100%;
    margin-top: 15px;
  }


}
</style>