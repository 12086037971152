<template>
  <div :class="['cardItem', 'position-relative', specialClass]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CmCard",
  props: {
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      // default: "340px"
    },
    ratio: {
      type: Number,
    },
    specialClass: String
  }
}
</script>

<style scoped>
.cardItem {
  padding: 35px 40px;
  border-radius: 1.85rem;
  height: 100%!important;
  box-shadow: 0px 17px 40px rgba(19, 49, 97, 0.07), 0px 6.20528px 21.901px rgba(19, 49, 97, 0.0482987), 0px 3.01255px 10.6325px rgba(19, 49, 97, 0.0389404), 0px 1.47681px 5.21226px rgba(19, 49, 97, 0.0310596), 0px 0.583932px 2.06094px rgba(19, 49, 97, 0.0217013);
  background-color: #ffffff;
}
.cardContent{
  height: 100%;
  padding: 1.875rem;
}
.cardContentWrap{
  border-radius: 1.85rem;
  background-color: #FFFFFF;
}

@media (max-width: 1600px) and (min-width: 1025px) {
  .cardItem {
    padding: 30px 35px;
  }
}

@media (max-width: 1440px) and (min-width: 1025px) {
  .cardItem {
    padding: 25px;
  }
}

@media (max-width: 1280px) and (min-width: 1025px) {
  .cardItem {
    padding: 20px;
  }
}

@media (max-width: 768px) and (min-width: 551px) {
  .cardItem {
    padding: 20px 25px;
  }
}

</style>


