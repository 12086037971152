import axios from "axios"

export default {
  state: () => ({
    dashboardNotificationsList: [],
    inflowOutflow: {
      loading: false,
      data: {}
    },
    chartsCircle: {
      loading: false,
      data: [],
      texts: []
    },
    actualVersion: null,
  }),
  getters: {
    getActualVersion(state) {
      return state.actualVersion
    }
  },
  mutations: {
    addDashboardNotification(state, text) {
      state.dashboardNotificationsList.push(text)
      
      let time = text.type === 'err' ? 3500 : 2000;

      setTimeout(() => {
        state.dashboardNotificationsList.shift()
      }, time)
    },
    setInflowOutflowLoading(state, value) {
      state.inflowOutflow.loading = value
    },
    setInflowOutflowInfo(state, info) {
      state.inflowOutflow.data = info
    },
    setChartsCircleLoading(state, value) {
      state.chartsCircle.loading = value
    },
    setChartsCircleInfo(state, data) {
      // const resultObj = [10, 20, 30, 40]
      const info = data.data.chart_data
      const resultObj = [
        (info.newcomer).toFixed(2),
        (info.growing + info.champions + info.doubting).toFixed(2),
        (info.loyal + info.need_attention + info.sleeping + info.on_the_brink + info['at risk']).toFixed(2),
        (info.outflow).toFixed(2),

        // info.without_orders
      ]

      state.chartsCircle.data = resultObj
    },
    setActualVersion(state, value) {
      state.actualVersion = value
    },
  },
  actions: {
    async getInflowOutflow(context) {
      context.commit('setInflowOutflowLoading', true)
      return await axios({
        method: 'get',
        url: `${window.apiPath}/dashboard/charts/inflow-outflow`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          Accept: `application/json`
        },
      }).then((request) => {
        context.commit('setInflowOutflowLoading', false)

        context.commit('setInflowOutflowInfo', request.data.data)
      }).catch(() => {
        context.commit('setInflowOutflowLoading', false)
      })
    },
    async getChartsCircle(context) {
      context.commit('setChartsCircleLoading', true)
      return await axios({
        method: 'get',
        url: `${window.apiPath}/dashboard/charts/circle`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          Accept: `application/json`
        },
      }).then((request) => {
        context.commit('setChartsCircleLoading', false)

        context.commit('setChartsCircleInfo', request.data)
      }).catch(() => {
        context.commit('setChartsCircleLoading', false)
      })
    },
    async getActualVersion(context) {
      return await axios({
        method: 'get',
        url: 'https://qoob.store/api/v1/qoobversion/',
      }).then((request) => {
        console.log('актуальная версия - ',request.data.result.qoob_version);
        console.log('версия сайта - ', context.rootState.version);
        context.commit('setActualVersion', request.data.result.qoob_version)
      })
    }
  },
}