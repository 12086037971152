<template>
  <div class="rating-wrapper">
    <star
      class="rating-wrapper__star"
      v-for="i in 5"
      :key="i + 1"
      :color="limit < i ? '#FFC549' : '#EB5F56'"
      @click="this.$emit('updateLimit', i -1 ? i - 1 : 1)"
    >
    </star>
  </div>
</template>

<script>
import star from "@/components/Dashboard/Billing/icons/star.vue";
export default {
  components: { star },
  props: {
    type: {
      type: String,
      default: "max",
    },
    limit: Number,
  },
};
</script>

<style lang="scss" scoped>
.rating-wrapper {

		&__star {
      margin-right: 5px;

      @media (max-width:1024px) {
        margin-right: 1px;
      }
		}
}

</style>