<template>
  <div class="row">
    <!--  :style="{'margin': calcPadding}"-->
    <div class="personalHeader col">
      <div class="area_header">
        <h2 class="header__title">{{ currentSection.fullName }}</h2>
        <div class="sectionsDiv">
          <router-link :to="'/dashboard'" class="breadCrumbs"
            >Главная</router-link
          >
          <div
            class="arrowPosition"
            :style="{
              'background-image':
                'url(' + require('@/assets/arrow-right.svg') + ')',
            }"
          ></div>
          <span class="breadCrumbs" v-if="middleName">{{ middleName }}</span>
          <div
            v-if="middleName"
            class="arrowPosition"
            :style="{
              'background-image':
                'url(' + require('@/assets/arrow-right.svg') + ')',
            }"
          ></div>

          <router-link :to="currentRoutePath" class="breadCrumbs">{{
            currentRouteName
          }}</router-link>
        </div>
      </div>
    </div>
    <div v-if="showBlank" class="col-4 col-4-custom blank"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PersonalAreaHeader",
  data() {
    return {
      currentRouteName: "",
      currentRoutePath: "",
      middleName: "",
    };
  },
  computed: {
    ...mapState({
      dashboard: (state) => state.texts.dashboard,
    }),
    currentSection() {
      let currentPathName = this.$route.name;
      console.log(currentPathName)
      let section = this.dashboard[currentPathName];
      if (section) {
        return section;
      } else if (
        [
          "notificationSegments",
          "notificationTypes"
        ].includes(currentPathName)
      ) {
        return this.dashboard["notifications"];
      } else if(currentPathName === 'notificationHistory') {
        return { name: "", fullName: "История" }
      } else if (
        ["billingLicense", "billingPayments"].includes(currentPathName)
      ) {
        return this.dashboard["billing"];
      } else {
        return { name: "", fullName: "" };
      }
    },
    showBlank() {
      let sectionsWithBlank = ["personalArea", "settings", "mobileApp"];
      return sectionsWithBlank.includes(this.$route.name);
    },
    mobilePreview() {
      let pageWithMobilePreview = ["mobileApp"];
      return pageWithMobilePreview.includes(this.$route.name);
    },
  },
  mounted() {
    this.currentRouteName = this.$route.meta.name;
    if (this.$route.meta.parent) {
      this.middleName = this.$route.meta.parent;
    } else {
      this.middleName = '';
    }
    this.currentRoutePath = this.$route.fullPath;
  },
  watch: {
    $route() {
      this.currentRouteName = this.$route.meta.name;
      if (this.$route.meta.parent) {
        this.middleName = this.$route.meta.parent;
      } else {
        this.middleName = '';
      }

      this.currentRoutePath = this.$route.fullPath;
    },
  },
};
</script>

<style scoped>
.breadCrumbs {
  font-size: 14px;
  line-height: 24px;
  color: #a1a2a2;
}

.personalHeader {
  padding: 25px 60px;
  padding-bottom: 0;
}

.area_header {
  border-bottom: 1px solid #eff0f0;
  padding-bottom: 15px;
}

.header__title {
  font-size: 32px;
  line-height: 42px;
}

.blank {
  background-color: #f7f9fc;
}
.sectionsDiv {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.arrowPosition {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  height: 8px;
  width: 8px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}

.blank {
  display: none !important;
}

@media (max-width: 1280px) {
  .personalHeader {
    padding: 25px 30px;
    padding-bottom: 0;
  }

  .header__title {
    font-size: 24px;
    line-height: 30px;
  }

  .sectionsDiv {
    margin-top: 5px;
  }
}

@media (max-width: 1024px) {
  .personalHeader {
    padding: 14px 0;
    padding-bottom: 0;
  }

  .sectionsDiv {
    display: none;
  }
}

@media (max-width: 768px) {
  .sectionsDiv {
    display: none;
  }

  .personalHeader {
    display: block;
    padding: 0;
  }

  .area_header {
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 60px;
    padding-bottom: 0;
  }

  .header__title {
    font-size: 20px;
    line-height: 24px !important;
  }
}

@media (max-width: 550px) {
  .area_header {
    max-width: calc(100% - 60px);
    padding: 15px;
  }

  .header__title {
    font-size: 16px;
  }
}

</style>

