<template>
  <div class="changePictureWrap">
    <h4 class="changePictureTitle">{{ title }}</h4>
    <div class="upperLabel d-flex mt-4">
      <div>
        <div
          class="labelWrap"
          :style="`background: url(${picture}) #F6F9FC no-repeat center /100%`"
        >
          <label v-show="!picture" for="picture" class="labelPicture"></label>
          <input
            v-show="!picture"
            @input="$emit('updateImg', $event)"
            ref="fileinput"
            type="file"
            id="picture"
            accept="image/*"
            class="d-none"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="position-relative changeDescription">
        <p class="picture__text">{{ requirementsText }}</p>
        <div class="changeBtnGroup">
          <button-transparent
            class="changeButton"
            @click="$refs.fileinput.click()"
            >Загрузить</button-transparent
          >
          <button-grey
            class="changeButton changeButton1"
            @click="this.$emit('deleteLogo')"
            >Удалить</button-grey
          >
        </div>
      </div>
    </div>
    <!--  <h4 class="fw-bold">{{ title }}</h4>-->
    <!--  <div class="mt-4">-->
    <!--    <div class="float-start me-3">-->
    <!--      <label for="picture" :style="{ backgroundImage: 'url('+ getPicture() +')'}" class="labelPicture"></label>-->
    <!--      <input @update="$emit('update:modelValue', $event.target.value)" ref="fileinput" type="file" id="picture" accept="image/*" class="d-none" autocomplete="off">-->
    <!--    </div>-->
    <!--    <div class="position-relative changeDescription">-->
    <!--      <p>{{ requirementsText }}</p>-->
    <!--      <div class="changeBtnGroup">-->
    <!--        <CmButton @click="$refs.fileinput.click()" class="me-3 ">Загрузить</CmButton>-->
    <!--        <CmButton :cls="'secondary'" class="">Удалить</CmButton>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->
  </div>
</template>

<script>
import buttonTransparent from "@/components/UI/buttons/ButtonTransparent";
import buttonGrey from "@/components/UI/buttons/ButtonGrey";

export default {
  name: "ChangePicture",
  components: { buttonTransparent, buttonGrey },
  props: {
    title: {
      type: String,
    },
    requirementsText: {
      type: String,
    },
    picture: {
      type: String,
    },
  },
  methods: {
    getPicture() {
      console.log(
        this.picture ? this.picture : require("@/assets/add-picture.svg")
      );
      return this.picture
        ? this.glb.getMediaUrl(this.picture)
        : require("@/assets/add-picture.svg");
    },
    getImg() {
      return this.$refs.fileinput.files[0];
    },
  },
};
</script>

<style scoped>
.changePictureWrap {
  margin-bottom: 3.8rem;
}

.upperLabel {
  min-width: 450px;
  margin-top: 25px !important;
}

.changePictureTitle {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
}
.labelWrap {
  position: relative;

  min-width: 146px;
  padding-top: 100%;

  border-radius: 12px;

  background-color: #f6f9fc;
}
.labelPicture {
  position: absolute;
  top: 50%;
  left: 50%;

  height: 32px;
  width: 32px;

  cursor: pointer;
  transform: translateX(-50%) translateY(-50%);

  border-radius: 0.75rem;
  opacity: 0.3;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/gallery-add.svg");
}
.changeBtnGroup {
  display: flex;
}
.changeDescription {
  max-width: 28.125rem;
  margin-left: 1.55rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.changeBtnGroup {
  margin-top: 23px;
}

.changeButton {
  width: 50%;
  height: 48px;
}

.changeButton1 {
  margin-left: 15px;
}

.picture__text {
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 1200px) {
  .changeDescription {
    max-width: 100%;
  }
}
</style>

