import axios from "axios"
/* eslint-disable */
export default {
  state: () => ({
    loadindMobileSettins: false,
    loadingSocialSettings: false,
    loadindPutMobileSettins: false,
    loadingMobileLogo: false,
    mobileSettings: {
      info: null,
      social: null,
      logo: null
    },
    servicesTypeList: [],
    socialLinkTypes: [{
        id: 1,
        type: 'Телефон',
        value: '',
        loading: false
      },
      {
        id: 2,
        type: 'Instagramm',
        value: '',
        loading: false
      },
      {
        id: 3,
        type: 'Telegram',
        value: '',
        loading: false
      },
      {
        id: 4,
        type: 'WhatsApp',
        value: '',
        loading: false
      },
      {
        id: 6,
        type: 'Share',
        value: '',
        loading: false
      },
      {
        id: 7,
        type: 'Website',
        value: '',
        loading: false
      },
      {
        id: 8,
        type: 'VK',
        value: '',
        loading: false
      },
    ],
    accessibleSocialLinkTypes: [{}],
    newSocialLink: {
      type: null,
      value: null,
    },
    selectedTypeLink: {
      type: ''
    },
    mobileLogoUrl: `https://qoob.store/api/v2/mobile/app/settings/logo`
  }),
  mutations: {
    setLoadindMobileSettins(state, value) {
      state.loadindMobileSettins = value;
    },
    setLoadindPutMobileSettins(state, value) {
      state.loadindPutMobileSettins = value;
    },
    setLoadingLoadingSocialSettings(state, value) {
      state.loadingSocialSettings = value;
    },
    setLoadindMobileLogo(state, value) {
      state.loadingMobileLogo = value
    },
    setMobileSettings(state, value) {
      state.mobileSettings.info = {
        ...value
      };
    },
    setSocialSettings(state, value) {
      value.social_links.forEach(link => {
        if (link.network_type_id === 1) {
          link.name = 'Телефон'
        } else if (link.network_type_id === 2) {
          link.name = 'Instagram'
        } else if (link.network_type_id === 3) {
          link.name = 'Telegram'
        } else if (link.network_type_id === 4) {
          link.name = 'WhatsApp'
        } else if (link.network_type_id === 6) {
          link.name = 'Share'
        } else if (link.network_type_id === 7) {
          link.name = 'Website'
        } else if (link.network_type_id === 8) {
          link.name = 'VK'
        } else {
          link.type = {
            name: 'Link'
          }
        }
        const typeIndex = state.accessibleSocialLinkTypes.findIndex((i) => i.id === link.network_type_id)
        if (typeIndex >= 0) {

          state.accessibleSocialLinkTypes.splice(typeIndex, 1)
        }
      });
      state.mobileSettings.social = {
        ...value
      };

    },
    setServicesTypeList(state, list) {
      state.servicesTypeList = list
    },
    selectTypeLink(state, link) {
      state.selectedTypeLink = {
        ...link
      }
    },
    setMobileLogo(state, url) {
      if (!url) {
        state.mobileSettings.logo = "/static/beauty-logo.jpg"
      }
      state.mobileSettings.logo = url
    },
    setLoadingSocialLink(state, obj) {
      state.socialLinkTypes.find(i => i.id === obj.typeId).loading = obj.value
    },
    removeSocialLink(state, link) {
      const linkIndex = state.mobileSettings.social.social_links.findIndex(i => i.id === link.id)

      state.mobileSettings.social.social_links.splice(linkIndex, 1)

      const type = state.socialLinkTypes.find((i) => i.id === link.network_type_id)
      state.accessibleSocialLinkTypes.push(type)
    },
    addSocialLinkId(state, obj) {
      state.mobileSettings.social.social_links.find(i => i.network_type_id === obj.typeId).id = obj.value
    },
    setNewSocialLink(state, link) {
      state.newSocialLink.type = link.id
    }
  },
  actions: {
    async getMobileSetting(context) {
      context.state.accessibleSocialLinkTypes = [
        ...context.state.socialLinkTypes
      ]
      context.state.selectedTypeLink = {
        type: ''
      }
      context.state.newSocialLink = {
        type: null,
        value: null
      }
      context.commit('setLoadindMobileSettins', true);
      return await axios({
        method: 'get',
        url: `${window.apiPath}/mobile/app/settings`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        }
      }).then((request) => {
        context.commit('setMobileSettings', request.data.data)
        if (request.data.data.show_app_settings) {
          context.dispatch('getServicesTypeList').then(() => {
            context.dispatch('getMobileSocialSetting')
          })

        } else {
          context.commit('setLoadindMobileSettins', false)
        }


      }).catch(() => {
        context.commit('setLoadindMobileSettins', false)
      })
    },
    async getMobileSocialSetting(context) {
      context.commit('setLoadindMobileSettins', true);
      return await axios({
        method: 'get',
        url: `${window.apiPath}/mobile/app/settings/social/list`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        }
      }).then((request) => {
        context.commit('setSocialSettings', request.data.data)
        context.commit('setLoadindMobileSettins', false)
      }).catch(() => {
        context.commit('setLoadindMobileSettins', false)
      })
    },
    async putMoileSettings(context) {
      context.commit('setLoadindPutMobileSettins', true)

      return await axios({
        method: 'put',
        url: `${window.apiPath}/mobile/app/settings`,
        params: {
          ...context.state.mobileSettings.info
        },
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        }
      }).then((request) => {
        context.dispatch('updateSocialLinks')
        context.dispatch('getMobileSetting').then(() => {
          context.commit('setLoadindPutMobileSettins', false)
        })

      })
    },
    updateSocialLinks(context) {
      context.state.mobileSettings.social.social_links.forEach(link => {
        const postObj = {
          company_id: link.company_id,
          social_id: link.id,
          network_type_id: link.network_type_id,
          value: link.value
        }
        context.dispatch('updateSocialLink', postObj)
      });

    },
    updateSocialLink(context, socialLink) {
      return axios({
        method: 'put',
        url: `${window.apiPath}/mobile/app/settings/social/${socialLink.social_id}`,
        params: {
          ...socialLink
        },
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        }
      })
    },
    async getServicesTypeList(context) {
      return await axios({
        method: 'get',
        url: `${window.apiPath}/mobile/app/settings/service_type/list`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        }
      }).then(request => {
        context.commit('setServicesTypeList', request.data.data)
      }).catch(err => {
        console.dir(err)
      })
    },
    async createSocialLink(context) {
      const linkTypeId = context.state.newSocialLink.type ? context.state.newSocialLink.type : context.state.accessibleSocialLinkTypes[0].id

      context.commit('setLoadingSocialLink', {
        typeId: linkTypeId,
        value: true
      })

      context.dispatch('addSocialLink', {
        company_id: context.getters.getFilialId,
        network_type_id: linkTypeId,
        value: context.state.newSocialLink.value
      })
      return await axios({
        method: 'post',
        url: `${window.apiPath}/mobile/app/settings/social`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
        params: {
          company_id: context.getters.getFilialId,
          network_type_id: linkTypeId,
          value: context.state.newSocialLink.value
        }
      }).then((request) => {
        console.log(request.data.data.social.id);

        context.commit('addSocialLinkId', {
          typeId: linkTypeId,
          value: request.data.data.social.id
        })
        context.commit('setSocialSettings', context.state.mobileSettings.social)
        context.commit('setLoadingSocialLink', {
          typeId: linkTypeId,
          value: false
        })
        // context.dispatch('getMobileSetting')
      }).catch((err) => {
        console.dir(err);
      })
    },
    async deleteSocialLink(context, link) {
      context.commit('setLoadingSocialLink', {
        typeId: link.network_type_id,
        value: true
      })
      return await axios({
        method: 'delete',
        url: `${window.apiPath}/mobile/app/settings/social/${link.id}`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
      }).then(() => {
        context.commit('removeSocialLink', link)

        context.commit('setSocialSettings', context.state.mobileSettings.social)
        context.commit('setLoadingSocialLink', {
          typeId: link.network_type_id,
          value: false
        })

      })
    },
    async getMobileLogo(context) {
      let logoUrl = context.state.mobileLogoUrl + '?r='
      const randomNum = Math.floor(Math.random() * (10 - 3)) + 3
      for (let i = 0; i < randomNum; i++) {
        logoUrl += Math.floor(Math.random() * (10 - 2)) + 2
      }
      return await axios({
        method: 'get',
        url: logoUrl,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
      }).then(() => {
        context.commit('setMobileLogo', logoUrl)
      }).catch(() => {
        context.commit('setMobileLogo', null)
      })
    },
    async postMobileLogo(context, logo) {
      context.commit('setLoadindMobileLogo', true)
      let formData = new FormData()

      formData.append('logo_file', logo)

      return await axios({
        method: 'post',
        url: `${window.apiPath}/mobile/app/settings/logo`,
        headers: {
          Accept: 'application/json',
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          'Content-Type': 'multipart/form-data'
        },
        data: formData,
      }).then((res) => {
        setTimeout(() => {
          context.dispatch('pageReload')
          context.commit('setLoadindMobileLogo', false)
        }, 1500)
        console.log(res);
      }).catch((err) => {
        // context.dispatch('getMobileLogo')
        console.dir(err);
        console.log(logo);
      })

    },
    async deleteMobileLogo(context) {
      context.commit('setLoadindMobileLogo', true)
      return await axios({
        method: 'DELETE',
        url: `${window.apiPath}/mobile/app/settings/logo`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
      }).then(() => {
        setTimeout(() => {
          context.dispatch('pageReload')
          context.commit('setLoadindMobileLogo', false)
        }, 200)
      }).catch(() => {
        context.commit('setLoadindMobileLogo', false)
        context.dispatch('pageReload')
      })
    },
    addSocialLink(context, obj) {
      context.state.mobileSettings.social.social_links.push({
        company_id: obj.company_id,
        network_type_id: obj.network_type_id,
        value: obj.value,
        name: context.state.socialLinkTypes.find(i => i.id === obj.network_type_id).type
      })
      const typeIndex = context.state.accessibleSocialLinkTypes.findIndex((i) => i.id === obj.network_type_id)
      console.log(typeIndex);


      if (typeIndex >= 0) {
        context.state.accessibleSocialLinkTypes.splice(typeIndex, 1)
      }
      if (context.state.accessibleSocialLinkTypes.length > 0) {
        context.commit('selectTypeLink', context.state.accessibleSocialLinkTypes[0])
        context.commit('setNewSocialLink', context.state.accessibleSocialLinkTypes[0])
      }


      // context.state.accessibleSocialLinkTypes.splice(obj.network_type_id, 1)
    },
  },
}