<template>
  <div>
    <div
      class="wa-warn"
      v-if="
        !this.$store.state.crm.crmLicense.marketplace_integration &&
        !this.$store.state.tarif.licenseInfo.promised_payment_is_active
      "
    >
      <h3>Лицензия окончена</h3>

      <div class="wa-warn__text">
        <p>Оплатите лицензию, чтобы продожлить пользоваться сервисом</p>
        <div>
          <ButtonTransparent
            @click="openTariffs"
            >Прейти к тарифам</ButtonTransparent
          >
        </div>
      </div>
    </div>

    <div class="wa-warn" v-else>
      <h3>Ошибка интеграции</h3>

      <div class="wa-warn__text">
        <p>
          Без паники! Обратитесь в
          <a
            href="https://t.me/qoob_store_support_bot"
            class="link"
            target="_blank"
            >поддержку qoob.store</a
          >
          и мы вам поможем решить все проблемы!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonTransparent from "../../components/UI/buttons/ButtonTransparent.vue";
export default {
  components: {
    ButtonTransparent,
  },
  setup() {
    function openTariffs() {
      console.log("opentarif", {
        postMessage: { action: "open_tab", payload: "tariffs" },
        window: window,
        parent: window.parent,
      });

      window.parent.postMessage(
        { action: "open_tab", payload: "tariffs" },
        "https://yclients.com/"
      );
    }

    return {
      openTariffs,
    };
  },
};
</script>

<style>
</style>