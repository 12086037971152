<template>
  <div :class="['settings__item', itemClass, { bound: bound }]">
    <p class="drop__name placeholder-color">
      <slot name="name"></slot>
    </p>

    <div
      :class="[
        'settings__block',
        blockClass,
        'drop__wrapper',
        { 'drop--active': dropState },
      ]"
      @mouseout="toggleDrop(false)"
      @mouseover="toggleDrop(true)"
    >
      <p class="drop__text">
        <slot name="text"></slot>
      </p>

      <div class="settings__right">
        <span class="settings__line"></span>
        <button class="settings__change">
          <arrow-down-icon
            :class="[
              'arrow-icon',
              'svg-placeholder-color',
              { 'icon--active': dropState },
            ]"
          />
        </button>
      </div>

      <ul
        :style="bound ? 'max-height:' + boundPx + 'px;' : ''"
        class="drop__list"
        :class="{ drop__list1: newClass }"
        v-show="dropState"
        @click="toggleDrop()"
      >
        <slot name="item"></slot>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import arrowDownIcon from "@/components/Dashboard/Billing/icons/arrow-down-icon";

export default {
  name: "drop-menu",
  components: {
    arrowDownIcon: arrowDownIcon,
  },
  props: {
    blockClass: {
      type: String,
      required: false,
    },
    itemSelected: {
      type: Boolean,
      required: false,
    },
    itemClass: {
      type: String, // mini
      required: false,
      default: "",
    },
    newClass: {
      type: Boolean,
    },
    bound: {
      type: Boolean,
      default: false,
    },
    boundPx: {
      type: Number,
      default: 300,
    },
  },
  setup(props) {
    const dropState = ref(false);

    function toggleDrop(state) {
      dropState.value = state;
    }

    watchEffect(() => {
      if (props.itemSelected) {
        dropState.value = false;
      }
    });

    return { toggleDrop, dropState };
  },
};
</script>


<style scoped>
.bound .drop__list {
  overflow: auto;
}
.settings__item {
  width: 100%;
}

.drop__wrapper {
  position: relative;
}

.settings__item1 {
  width: auto;
}

.settings__right {
  display: flex;
}

.settings__name {
  margin-bottom: 5px;

  font-size: 14px;
  color: #6d6d6d;
}

.settings__block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 48px;
  padding: 0 20px;

  border-radius: 12px;
  box-sizing: border-box;
  cursor: pointer;

  background-color: #f6f9fc;

  transition: 0.2s;

  overflow: visible;
}

.settings__block.drop--active {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}
.drop--active .drop__list {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.drop--active {
  border-radius: 12px 12px 0 0;
}

.settings__text {
  font-size: 16px;
}

.settings__text1 {
  margin-right: 85px;
}

.settings__line {
  display: block;
  width: 1px;
  height: 24px;
  margin-right: 15px;

  background-color: rgba(0, 0, 0, 0.1);
}

.drop__list {
  transition: 0.2s;

  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;

  width: 100%;
  padding: 20px 0;

  box-sizing: border-box;
  border: 1px solid #f0f3f8;
  border-top: none;
  border-radius: 0 0 12px 12px;

  background-color: #fff;

  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #dfdfdd rgba(0, 0, 0, 0.3); /* scroll thumb and track */
}

.mini .drop__list {
  border-radius: 0 0 6px 6px;
  padding: 10px 0;
}
.drop__list::-webkit-scrollbar {
  width: 12px;
  display: block;
}

.drop__list::-webkit-scrollbar-track {
  background: #dfdfdd;
  border-radius: 0 0 10px 0;
}

.drop__list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.arrow-icon {
  transition: 0.1s all ease;
}

.icon--active {
  transform: rotateZ(180deg);
}

.mark-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 95px;
  height: 48px;
  padding: 0 15px;
  border-radius: 12px;
  box-sizing: border-box;

  background-color: #f6f9fc;
}

.drop__name {
  font-size: 14px;
}

.drop__text {
  font-size: 16px;
}

@media (max-height: 800px) {
  .modal-drop {
    height: 38px;
  }
}

.mini .settings__block {
    border-radius: 6px;
    background: #f0f3f8;
  }

@media (min-width: 800px) {
  .mini .settings__block {
    border-radius: 6px;
    background: #f0f3f8;
    height: 48px;
  }
}

@media (max-width: 768px) {
  .modal-drop {
    height: 48px !important;
  }
}
</style>
