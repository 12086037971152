<template>
  <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6004 1.45834L9.16706 6.89167C8.52539 7.53334 7.47539 7.53334 6.83372 6.89167L1.40039 1.45834" stroke="#6D6D6D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "arrow-down-icon"
}
</script>

<style scoped>

</style>