import axios from 'axios'

export default {
  state: () => ({
    crmSynhStep: 0,
    crmConnect: null,
    loadingCrmStatus: false,
    loadingFilials: false,
    crmList: null,
    crmOpenModal: false,
    successMessege: null,
    currentCompany: null,
    companyList: [],
    companyListInfo: [],
    filialsList: {
      currentFilial: {},
      otherFilials: [],
      allFilials: [],
    },
    crmLicense: {},
    crmNoSyncData: {},
    crmLogo: null,

    crmHelp: {
    }
  }),
  getters: {
    getCrmOpenModal(state) {
      return state.crmOpenModal
    },
    getCurrentFilial(state) {
      return state.filialsList.currentFilial
    },
    getFilialById: (state) => (id) => {
      return state.filialsList.allFilials.find(i => i.id === id)
    },
    getWasSynchronizedEarlier(state) {
      return state.crmLicense.was_synchronized_earlier
    }
  },
  mutations: {
    setCrmStatus(state, status) {
      state.crmConnect = status
    },
    setCrmList(state, crmList) {
      state.crmList = {
        ...crmList
      }
    },
    setFilialsList(state, filialsList) {
      console.log('доступные компании -',filialsList);
      const resultObj = {}
      resultObj.allFilials = [...filialsList.available_companies];
      resultObj.currentFilial = {
        ...filialsList.current_company
      }


      const otherFilials = [...filialsList.available_companies]
      const currentFilialIndex = filialsList.available_companies.findIndex(i => i.id == filialsList.current_company.id)

      if (currentFilialIndex >= 0) {
        otherFilials.splice(currentFilialIndex, 1)
      }

      resultObj.otherFilials = [...otherFilials]

      state.filialsList = resultObj;
    },
    setCrmOpenModal(state, value) {
      state.crmOpen = value

    },
    setSuccessMessege(state, messege) {
      state.successMessege = messege
    },
    setLoadingCrmStatus(state, status) {
      state.loadingCrmStatus = status
    },
    setCompanyList(state, companyList) {
      state.companyListInfo = JSON.parse(JSON.stringify(companyList));
      state.companyListInfo[0].sync_with_crm = true;
      state.companyListInfo[0].main = true;

      const defaultActiveIndex = companyList.indexOf((i) => {
        !!i.license_is_active === true
      }) + 1
      const filialsList = []


      state.companyListInfo.forEach((item, index) => {
        const filial = {
          table: {},
          info: {}
        }
        filial.table = {
          id: item.id,
          title: item.title,
          mainCell: 'main',
          activeCell: 'active'
        }
        filial.info = {
          status: !item.license_is_active,
          default: index === defaultActiveIndex
        }

        if (index === defaultActiveIndex) {
          item.sync_with_crm = true;
          item.main = true;
        } else {
          item.sync_with_crm = false;
          item.main = false;
        }

        filialsList.push(filial)
      })

      // companyList.forEach(company => {
      //   company.mainCell = "banned"
      //   company.activeCell = "banned"
      //   // title
      //   // sync
      // });

      state.companyList = [{
          table: {
            id: 'ID',
            title: "Назввание филиала",
            mainCell: 'Главный',
            activeCell: 'Подключить'
          }
        },
        ...filialsList,

      ];

    },
    setMainCompany(state, settings) {
      state.companyListInfo[settings.id].main = settings.value
    },
    setSyncCompany(state, settings) {
      state.companyListInfo[settings.id].sync_with_crm = settings.value
    },
    setCrmStep(state, step) {
      state.crmSynhStep = step;
    },
    setCrmLicense(state, info) {
      if (info.isActive) {
        info.isExists = true
      } else {
        info.isExists = false
      }
      state.crmLicense = info
    },
    updateCrmLogo(state) {
      if (state.crmConnect) {
        state.crmLogo = require("../assets/yclients-logo.svg")
      } else {
        state.crmLogo = require('../assets/crm.svg')
      }
    },
    setFilialsLoading(state, value) {
      state.loadingFilials = value;
    },
    setCrmNoSyncData(state, value) {
      state.crmNoSyncData = value
    },
    setCrmHelp(state, value) {
      state.crmHelp = value
    }
  },
  actions: {
    async getCrmStatus(context) {
      return await axios({
        method: 'get',
        url: `${window.apiPath}/crm/status`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
          'accept': 'application/json'
        },
      }).then(request => {
        if (request.data.error.crm && (request.data.error.crm === "not connected")) {
          context.commit('setCrmStatus', 0)
          context.commit('setCrmLicense', {
            acc_id: null,
            isActive: null,
            license_expiry: null,
            name: null,
          })
          context.commit('setCrmNoSyncData', request.data.error)
          context.commit('updateCrmLogo')
        } else {
          context.commit('setCrmStatus', 1)
          context.commit('setCrmLicense', request.data.data)
          
          context.commit('setCrmStep', 2)
          context.commit('updateCrmLogo')
        }
      })
    },
    async getCrmCompanies(context, loginCrmObj) {
      return await axios({
        method: 'post',
        url: `${window.apiPath}/crm/companies`,
        params: {
          crm_name: loginCrmObj.crmName
        },
        data: `login=${loginCrmObj.login}&password=${loginCrmObj.password}`,
        // data: {
        //   login: loginCrmObj.login,
        //   password: loginCrmObj.password
        // },
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
          'accept': 'application/json'
        }
      }).then((request) => {
        context.commit('setCompanyList', request.data.data.companies);
        localStorage.setItem('crmPassword', loginCrmObj.password);
        localStorage.setItem('crmLogin', loginCrmObj.login);
        localStorage.setItem('crm_name', loginCrmObj.crmName)
        context.commit('setCrmStep', 1);

        context.dispatch('timeline', 'клиент приступил к синхронизации CRM')
      })
    },
    async crmSync(context, obj) {
      // const loginCrmObj = {
      //   login: localStorage.getItem('crmLogin'),
      //   password: localStorage.getItem('crmPassword'),
      //   crm_name: localStorage.getItem('crm_name')
      // }
      // const resultObject = {
      //   credentials: {
      //     ...loginCrmObj
      //   },
      //   companies: [...context.state.companyListInfo]
      // }
      localStorage.removeItem('crmPassword');
      localStorage.removeItem('crmLogin');
      localStorage.removeItem('crm_name');
      return await axios({
        method: 'post',
        url: `${window.apiPath}/crm/sync`,
        data: {
          ...obj
        },

        // data: {
        //   login: loginCrmObj.login,
        //   password: loginCrmObj.password
        // },

        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
          'accept': 'application/json'
        }
      })
    },
    async crmAsync(context, crm_name) {
      localStorage.removeItem('crmPassword');
      localStorage.removeItem('crmLogin');

      return await axios({
        method: 'post',
        params: {
          name: crm_name
        },
        url: `${window.apiPath}/crm/async`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
          'accept': 'application/json'
        }
      });

    },
    async getCrmSystems(context) {
      return await axios({
        type: 'get',
        url: `${window.apiPath}/crm`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
          'accept': 'application/json'
        }
      }).then(request => {
        context.commit('setCrmList', request.data.data.crm_systems)
      })
    },
    async setCurrentCompany(context, id) {
      const prevFilial = context.state.filialsList.currentFilial;
      const nextFilial = context.getters.getFilialById(id)
      context.commit('setFilialsLoading', true)
      return await axios({
        method: 'post',
        url: `${window.apiPath}/company/current/${id}`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
          'accept': 'application/json'
        }
      }).then(() => {
        context.commit('setFilialsLoading', true)
        context.dispatch('timeline', `клиент сменил текущий филиал с ${prevFilial.name} на ${nextFilial.name}`)
      })
    },
    async getFilialsList(context) {
      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/list`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          Accept: `application/json`
        }
      }).then((request) => {
        context.commit('setFilialsList', request.data.data)
      }).catch((err) => {
        context.commit('setError', err)
      })
    },
    crmModalVisible(context, obj) {
      console.log(obj);
      if (obj && obj.success) {
        context.commit('setSuccessMessege', true)
      }
      context.state.crmOpenModal = true
    },
    crmModalHide(context) {
      context.state.crmOpenModal = false

      if (context.state.successMessege) {
        context.commit('setSuccessMessege', false)
      }
    },
    getCrmHelp(context) {
      context.dispatch('flatPages', {
        url: 'help_yclients',
        mutation: 'setCrmHelp'
      }).then(() => {
      }).catch(() => {
        context.commit('addDashboardNotification', {
          text: 'При загрузке инструкции, произошла ошибка',
          type: 'err'
        })
      })
    }
  },
}