<template>
  <div class="emoji-overlay v-scroll">
    <div class="overlay__list">
      <button class="overlay__item" v-for="emiji in emojiArray" :key="emiji" @click="addEmoji(emiji)">{{emiji}}</button>
    </div>
  </div>
</template>

<script>
import emojiArray from './emojis.js'

export default {
  name: "emoji-overlay",
  setup(props, { emit }) {
    function addEmoji(emiji) {
      emit("addEmoji", emiji);
    }
    return { addEmoji, emojiArray };
  },
};
</script>

<style scoped lang="scss">
.emoji-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: 10;

  width: 100%;
  padding: 13px 20px;

  box-sizing: border-box;
  border-radius: 12px 12px 0 0;

  background-color: #f6f9fc;
  overflow: auto;
}

.overlay__list {
  display: flex;
  flex-wrap: wrap;

  min-height: 193px;
}

.overlay__item {
  font-size: 25px; 
  width: 55px;
  height: 55px;
  transition: .2s;
  border-radius: 5px;

  &:hover {
    background: rgb(232, 232, 232);
  }
}
</style>