import axios from "axios"

export default {
  state: () => ({
    invoiceTypes: [
      null, // null - заглушка, т.к. выбор типа оплаты начинаем с 1 индекса
      'yookassa',
      'bank'
    ],
    invoiceLoading: false,
    payUrl: null,
    invoiceList: [],

    invoiceInfo: {
      tariff_code: '',
      invoice_type: '',
      company: '',
      company_adr: '',
      inn: '',
      email: '',
      phone: '',
    },

    currentInvoiceStatus: {
      data: {}
    },

    bankInfo: {},

    error: {
      status: '',
      method: '',
      text: ''
    }
  }),
  getters: {
    getInvoiceLading(state) {
      return state.invoiceLoading;
    }
  },
  mutations: {
    setInvoiceLoading(state, value) {
      state.invoiceLoading = value
    },
    setInvoiceList(state, list) {
      list.forEach(i => {
        let price = +i.price
        i.price = price.toLocaleString()
      });
      state.invoiceList = list
    },
    setCurrentInvoiceStatus(state, status) {
      state.currentInvoiceStatus = status;
    }
  },
  actions: {
    async getCompanyByInn(context) {
      return await axios({
        method: 'post',
        url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `Token 8504739a885b6b1adfec3136ada70a8caeebb382`,
        },
        data: {
          query: context.state.invoiceInfo.inn
        }
      }).then(request => {
        console.log(request);
        this.loadingCompany = false;
        if (request.data.suggestions.length === 0) {
          this.error = true;
          this.paymentData.name = "";
          this.paymentData.address = "";
        } else {
          this.error = false;
          context.state.invoiceInfo.company = request.data.suggestions[0].value;
          context.state.invoiceInfo.company_adr =
            request.data.suggestions[0].data.address.value;
        }
      })
    },
    async createInvoice(context, amount) {
      const owner = context.getters.getOwnerInfo;

      const invoiceInfo = context.state.invoiceInfo
      invoiceInfo.email = owner.email
      invoiceInfo.phone = owner.telephone

      console.log(invoiceInfo);

      context.commit('setInvoiceLoading', true)

      await axios({
        method: 'post',
        url: `${window.apiPath}/invoice`,
        params: {
          ...context.state.invoiceInfo
        },
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
          'Content-Type': "application/json",
        }
      }).then((request) => {
        context.dispatch('timeline', `клиент создал счёт'`)
        context.state.bankInfo = request.data.data;
        if (invoiceInfo.invoice_type === 'yookassa') {
          return context.dispatch('yookassaInvoice', {
            ...invoiceInfo,
            ...request.data,
            amount: amount
          })
        } else {
          context.commit('setInvoiceLoading', false)
        }

      }).catch((err) => {
        context.state.error.status = err.response.status ? err.response.status : '0';
        context.state.error.method = 'bank';
        context.state.error.text = invoiceInfo;
        if (err.response.status === 500) {
          console.log('err500');
          context.commit('setErr500popupOpen', true)
        }
      })
    },
    async yookassaInvoice(context, info) {
      const amount = +(info.amount.replace(/\s+/g, ''))
      const yookassaInvoiceObject = {
        tariff_code: info.tariff_code,
        amount: amount,
        bitrix_invoice_id: info.data.bitrix_invoice_id,
        personal_offer_id: info.personal_offer_id
      }
      return await axios({
        method: 'post',
        url: `${window.apiPath}/invoice/yookassa`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        },
        params: {
          ...yookassaInvoiceObject
        }
      }).then((request) => {
        context.dispatch('timeline', `клиент перенаправлен на страницу оплаты yookassa'`)
        context.commit('setInvoiceLoading', false)
        console.log(request);
        const url = request.data.data.confirmation_url;
        context.state.payUrl = url;
        setTimeout(() => {
          window.open(`${url}`, '_blank');
        }, 150);

        
      }).catch(err => {
        console.log(err);
        context.state.error.status = err.response.status ? err.response.status : '0';
        context.state.error.method = 'yookassa';

        context.state.error.text = {
          ...info
        }
      })
    },
    async getInvioceList(context) {
      return await axios({
        method: 'get',
        url: `${window.apiPath}/invoice/list`,

        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        }
      }).then((request) => {
        context.commit('setInvoiceList', request.data.data)
      })
    },
    async downloadInvoice(context, id) {
      return await axios({
        type: 'get',
        url: `${window.apiPath}/invoice/external_link/${id}`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        }
      }).then((request) => {
        console.log(request);
        const url = request.data.data.external_link
        window.open(`${url}`, '_blank');
      })
    },
    async getStatusInvoice(context) {
      let orderId = context.state.payUrl
        .split('?')[1]
        .split("&")
        .reduce(function (p, e) {
          let a = e.split("=");
          p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
          return p;
        }, {}).orderId;

      console.log(orderId);
      return await axios({
        type: 'get',
        url: `${window.apiPath}/invoice/yookassa/${orderId}`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        }
      }).then((request) => {
        console.log(request);
        context.commit('setCurrentInvoiceStatus', request.data)
      })
    }
  },
}