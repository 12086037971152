import axios from 'axios'
import greenApi from './greenApi';
const {
  Socket
} = require("phoenix-channels");

/* eslint-disable */

export default {
  modules: {
    greenApi: greenApi
  },
  state: () => ({
    whatsappStatus: {
      provider: '',
      provider_settings: {
        uid: null,
      },
      wa_instance_state: {
        code: 0,
      }
    },
    instanceStatusCode: 0,
    loadingWhatsappStatus: false,
    loadingWhatsappSettings: false,
    loadingPutWhatsappSettings: false,

    noToken: false,
    newCode: false,

    socket200: false,

    whatsappSettings: {},

    firstWhatsappSettings: {

    },

    whatsappWebSoket: {
      auth: null,
      loading: true,
      qrCode: '',
      qrHistory: [],
      sokets: [],
      progress: false,
      rebort: false,
    },

    progressQr: {
      qrTimer: null,
      qrTimeout: null,
      qrProgress: 20,
    },

    waErr: {
      on: false,
      text: '',
    }

  }),
  getters: {

  },
  mutations: {
    setWhatsappStatus(state, status) {
      if (status.provider === 'PUSHSMS' || status.provider === 'Chatapi' || status.provider === 'qoob' || status.provider === 'greenapi') {
        status.wa_instance_state.code = 404
      }
      if (!status.provider || !status.provider_settings) {
        state.instanceStatusCode = 404
      } else {
        const date = new Date(status.expiry)
        status.date = date.toLocaleDateString();
        state.whatsappStatus = status;
        state.instanceStatusCode = status.wa_instance_state.code
      }

    },
    setWhatsappSettings(state, settings) {
      state.whatsappSettings = {
        ...settings
      }
      state.firstWhatsappSettings = {
        ...settings
      }
    },
    setLoadingWhatsappStatus(state, staus) {
      state.loadingWhatsappStatus = staus;
    },
    setLoadingWhatsappSettings(state, value) {
      state.loadingWhatsappSettings = value
    },
    setLoadingPutWhatsappSettings(state, value) {
      state.loadingPutWhatsappSettings = value
    },

  },
  actions: {
    async getWhatsappStatus(context, loader = true) {
      context.commit('setLoadingWhatsappStatus', loader)
      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/whatsapp/status`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        }
      }).then((request) => {
        context.commit('setLoadingWhatsappStatus', false)
        context.commit('setWhatsappStatus', {...request.data.data, wa_instance_state: {code: 0}})

        if (request.data.data.provider === 'greenapi') {
          context.dispatch('greenApi/isAuthed')
        }
        
      }).catch((err) => {
        context.commit('setLoadingWhatsappStatus', false)
        console.log(err);

        context.commit("addDashboardNotification", {
          text: `Ошибка ${err.response.status} при загрузке статуса вацапа`,
          type: "err",
        });
      })
    },
    async getWhatsappSettings(context) {
      context.commit('setLoadingWhatsappSettings', true)
      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/whatsapp/settings`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        }
      }).then((request) => {
        console.log(request.data.data);
        context.commit('setWhatsappSettings', request.data.data)
        context.commit('setLoadingWhatsappSettings', false)

      }).catch(() => {
        context.commit('setLoadingWhatsappSettings', false)

      })
    },
    async putWhatsappSettings(context) {
      const formData = new FormData()

      formData.append('sender', context.state.whatsappSettings.sender)
      context.commit('setLoadingPutWhatsappSettings', true)
      return await axios({
        method: 'put',
        url: `${window.apiPath}/company/whatsapp/settings`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        },
        data: formData
      }).then(() => {
        context.dispatch('getWhatsappSettings').then(() => {
          context.commit('setLoadingPutWhatsappSettings', false)
        })
      }).catch(() => {

        context.commit('setLoadingPutWhatsappSettings', false)
      })
    },
    async whatsappWebSoket(context) {
      function parseJwt(token) {
        var base64Url = token.split(".")[1];
        var base64 = decodeURIComponent(
          atob(base64Url)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
        );

        return JSON.parse(base64);
      }

      class WhatsAppSocket {
        constructor() {}

        change_auth_status(status, qr_code) {
          if (status == false && qr_code == null) {
            return;
          }
          if (status == true) {
            context.state.whatsappWebSoket.auth = true
            console.log('whatsapp подключен');
          } else {
            context.state.whatsappWebSoket.auth = false
            console.log('whatsapp не подключен');
          }
        }

        show_qr_code(status, qr_code) {
          if (status == false && qr_code == null) {
            return;
          }
          if (status == true) {
            context.dispatch("getWhatsappStatus");
            return;
          }
          if (qr_code) {
            context.state.whatsappWebSoket.qrCode = qr_code
          }
        }

        show_in_progress(status) {
          if (status) {
            context.state.progress = true
          }
        }

        show_reboot_page(state) {
          if (state == false) {
            context.state.whatsappWebSoket.reboot = true
          }
        }

        init_connect(customer_id, api_key) {
          let socket = new Socket("wss://msg-farmer.pushsms.ru/socket", {
            params: {
              token: api_key
            },
            logger: (kind, msg, data) => {
              console.log(`${kind}: ${msg}`, data);
            },
          });

          socket.connect();
          // socket.onOpen((ev) => console.log("OPEN", ev));
          // socket.onError((ev) => console.log("ERROR", ev));
          // socket.onClose((e) => console.log("CLOSE", e));

          var chan = socket.channel(`room:v1:whats_app:auth:${customer_id}`, {});

          chan.on("auth_state", (msg) => {
            // console.log("get state");
            // console.log(msg);
            let auth = msg.auth;
            let qr_code = msg.qr_code;
            let in_progress = msg.in_progress;
            this.change_auth_status(auth, qr_code);
            this.show_qr_code(auth, qr_code);
            this.show_in_progress(in_progress);
          });

          chan
            .join()
            .receive("ignore", () => console.log("auth error"))
            .receive("ok", () => console.log("join ok"));

          chan.onError((e) => console.log("something went wrong", e));
          chan.onClose((e) => {
            this.show_reboot_page(true);
            console.log("channel closed", e);
          });
        }
      }

      window.WhatsApp = new WhatsAppSocket();

      if (context.state.whatsappStatus.pushsms.token && !(location.href.includes('localhost'))) {
        // если мы не на на локалхосте
        window.WhatsApp.init_connect(
          parseJwt(context.state.whatsappStatus.pushsms.token).customer_id,
          context.state.whatsappStatus.pushsms.token
        );
      } else {
        if (location.href.includes('localhost')) {
          // если мы разрабатываем на локалхосте
          context.commit("addDashboardNotification", {
            text: `Вебсокет статуса вацапа локально не подлкючается, только с хостинга`,
            type: "err",
          });
          context.state.whatsappWebSoket.auth = false
          throw `Вебсокет статуса вацапа локально не подлкючается, только с хостинга. @/store/whatsappModule.js, action whatsappWebSoket() `
        } else {
          context.state.noToken = true
          throw 'Токен не найден'
        }
      }
    },

    startWhatsAppGetQr(context) {
      context.dispatch('getQoobWhatsAppStatus').then((instance) => {
        context.dispatch('updateQr', instance).then(() => {
  
        })
      }).catch(err => {
        console.dir(err)
      })
    },

    updateQr(context, instance) {
      console.log(instance.state);
      if (instance.code && instance.code === 404 && !context.state.newCode) {
        context.state.instanceStatusCode = 404
        context.state.progressQr.qrProgress = 20
      } else {
        if (instance.state === 'online') {
          // qr / ready / change_state
          if (instance.wa_status === 'qr' && context.state.whatsappWebSoket.sokets.length === 0) {
            context.state.instanceStatusCode = 401
            context.state.whatsappWebSoket.auth = false;
            context.dispatch('getQoobWhatsAppQr')
          } else if (instance.wa_status === 'ready' || instance.wa_status === 'change_state') {
            context.state.instanceStatusCode = 200
            context.state.newCode = false;
            console.log('authenticated');
            context.state.whatsappWebSoket.auth = true;
          } else if (instance.wa_status === 'disconnected') {
            context.state.instanceStatusCode = 503
            context.state.whatsappWebSoket.auth = false;
          }
        } else if (instance.state === 'launching' || instance.state === 'deployed' || instance.state === 'stopping') {
          context.state.instanceStatusCode = 503
          context.state.whatsappWebSoket.sokets = []
          context.state.whatsappWebSoket.loading = true
          context.state.whatsappWebSoket.auth = false;
        } else if (instance.state === 'stopped') {
          context.state.instanceStatusCode = 404
          context.state.progressQr.qrProgress = 20
          context.state.whatsappWebSoket.qrCode = ''
          context.state.whatsappWebSoket.sokets = []
          context.state.newCode = false;
          context.state.whatsappWebSoket.auth = false;
        } else if (instance.state === 'stopping' || instance.state === 'stopped') {
          context.state.instanceStatusCode = 404
          context.state.progressQr.qrProgress = 20
          context.state.whatsappWebSoket.qrCode = ''
          context.state.whatsappWebSoket.sokets = []
          context.state.newCode = false;
          context.state.whatsappWebSoket.auth = false;
        }
      }
    },

    async getQoobWhatsAppStatus(context) {
      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/whatsapp/router/status/${context.rootState.crm.crmLicense.acc_id}`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        }
      }).then(request => {
        console.log(`/company/whatsapp/router/status/${context.rootState.crm.crmLicense.acc_id}, статус вацапа`, request?.data?.data);
        if (request.data.data.state) {
          return request.data.data.state[0]
        } else if (request.data.status_code === 424) {
          context.state.instanceStatusCode = 404
          context.state.progressQr.qrProgress = 20
          context.state.whatsappWebSoket.qrCode = ''
          context.state.whatsappWebSoket.sokets = []
          context.state.newCode = false;
          context.state.whatsappWebSoket.auth = false;
        } else {
          return request.data.data
        }
      }).catch(err => {
        context.dispatch("getWhatsappStatus", false);
      })
    },
    async getQoobWhatsAppQr(context) {
      let urlWss = context.state.whatsappStatus.whatsapp_wss_url;

      let progressQr = context.state.progressQr

      const sokets = context.state.whatsappWebSoket.sokets
      const whatsappStatus = context.state.whatsappStatus
      const webSoket = new WebSocket(`${urlWss}/` + whatsappStatus.provider_settings.uid)

      function setQrProgress() {
        if (progressQr.qrTimeout > 20) {
          progressQr.qrTimeout = 0
        }
        progressQr.qrProgress = progressQr.qrTimeout * (100 / 20)
        progressQr.qrTimeout--

        if (progressQr.qrTimeout < -60) {
          context.state.instanceStatusCode = 404
          context.state.whatsappWebSoket.qrCode = ''
          clearInterval(progressQr.qrTimer)
        }
      }

      sokets.push(webSoket)

      const qrHistory = context.state.whatsappWebSoket.qrHistory

      webSoket.onmessage = (event) => {
        clearInterval(progressQr.qrTimer)
        const message = JSON.parse(event.data)

        qrHistory.push(message.data)

        if (message.event === 'qr') {
          console.log('qr');
          context.state.newCode = false;
          context.state.whatsappWebSoket.qrCode = message.data

          if (progressQr.qrTimeout === null) {
            progressQr.qrTimeout = 20 - Math.floor((new Date().getTime() - message.timestamp) / 1000)
          } else {
            progressQr.qrTimeout = 20
          }

          setQrProgress()
          progressQr.qrTimer = setInterval(setQrProgress, 1000, message.timestamp);


        } else if (message.event === 'authenticated') {
          context.state.newCode = false;
          console.log('authenticated');
          progressQr.qrTimeout = 20
          context.dispatch('getWhatsappStatus')
          context.state.whatsappWebSoket.auth = true;
        } else if (message.event === 'disconnected') {
          progressQr.qrTimeout = 20
          context.state.newCode = false;
          context.state.whatsappWebSoket.auth = false;
          context.state.whatsappWebSoket.qrCode = ''
        }
      }

      webSoket.onclose = () => {
        context.state.whatsappWebSoket.sokets = []
      }
    },
    async createInstance(context) {
      context.commit('setLoadingWhatsappStatus', true)
      return await axios({
        method: 'post',
        url: `${window.apiPath}/api/v2/company/whatsapp/instance`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json'
        }
      }).then((data) => {
        console.log(data);
        context.commit('setLoadingWhatsappStatus', false)
      }).catch(err => {
        context.commit('setLoadingWhatsappStatus', false)
      })
    },
  },

}