<template>
  <transition name="fade">
    <div class="confirm-popup" v-if="popupVisible">
      <div
        class="confirm-popup__bg"
        @click="popupClose, this.$emit('cancelConfirm')"
      ></div>

      <div class="confirm-popup__wrapper">
        <close-button
          :closeType="1"
          class="confirm-popup__close"
          @click="popupClose, this.$emit('cancelConfirm')"
        />
        <h2 class="confirm-popup__title">
          Для подтверждения действия введите <br />
          <span class="confirm-text">{{ confirmString }}</span> в поле ниже
        </h2>

        <div class="confirm-popup__text">
          <slot></slot>
        </div>

        <cm-input
          class="confirm-popup__input"
          v-model.trim="confirmText"
        ></cm-input>

        <div class="confirm-popup__btns">
          <button class="btn-orange" @click="confirm" :disabled="disableBtn"
            >Подтвердить</button
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CmInput from "@/components/UI/inputs/CmInput";
import closeButton from "@/components/Dashboard/Billing/close-button";
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
export default {
  components: {
    CmInput,
    closeButton,
  },

  props: {
    popupVisible: {
      type: Boolean,
      default: false,
    },
    confirmString: {
      type: String,
      default: "подтвердить",
    },
  },

  setup(props, { emit }) {
    let confirmText = ref("");

    const disableBtn = computed(() => {
      if (
        confirmText.value.toLocaleLowerCase() ===
        props.confirmString.toLocaleLowerCase()
      ) {
        return false;
      } else {
        return true;
      }
    });

    function confirm() {
      if (confirmText.value === props.confirmString) {
        emit("confirming");
        emit("popupClose");
      }
    }

    function popupClose() {
      confirmText.value = "";
      emit("popupClose");
    }

    return {
      confirm,
      confirmText,
      popupClose,
      disableBtn,
    };
  },
};
</script>

<style csoped>
.confirm-popup {
  z-index: 9999;
  position: relative;
}
.confirm-popup__title {
  font-size: 29px;
  text-align: center;
  font-weight: 500;
}
.confirm-popup__input input {
  font-weight: 600;
  border: solid 0.5px rgba(0, 0, 0, 0.4);
}
.confirm-popup__btns {
  display: flex;
  justify-content: center;
  gap: 30px;
}
.confirm-popup__btns button {
  width: 100%;
}
.confirm-popup__wrapper {
  position: fixed;
  top: 40px;
  left: 50%;
  z-index: 20;
  box-shadow: 0px 24px 120px -30px rgb(0 0 0 / 15%);
  border-radius: 10px;
  transform: translateX(-50%);
  background-color: #ffffff;

  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.431));

  z-index: 10000;

  padding: 70px 50px;

  display: flex;
  flex-direction: column;
  row-gap: 20px;

}

@media (max-width: 1024px) {
  .confirm-popup__wrapper {
    width: 70%;
    padding: 50px 30px;
    max-width: 400px;
  }

  .confirm-popup__title {
    font-size: 20px;
    line-height: 1.5;
  }
}
@media (max-width: 568px) {
  .confirm-popup__wrapper {
    width: 95%;
    max-height: 100vh;
    overflow: auto;
  }
  .confirm-popup__close {
    top: 0px !important;
    right: 0px !important;
    border-radius: 10px 10px 10px 50% !important;
  }
}
.confirm-popup__close {
  position: absolute;
  top: -25px;
  right: -25px;
}
.confirm-popup__bg {
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, .9);
  position: fixed;
  top: 0;
  left: 0;
}
.confirm-text {
  font-weight: 700;
  position: relative;
}
.confirm-text:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: #000;
  position: absolute;
  bottom: 0;
  left: 0;
}

.confirm-popup__text {
  margin: 0 auto;
  text-align: center;
  max-width: 500px;

  font-size: 20px;
}
@media (max-width: 1200px) {
  .confirm-popup__text {
    font-size: 17px;
  }
  .confirm-popup__btns button {
    padding: 2rem 1.5rem;
    width: 100%;
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .confirm-popup__text {
    font-size: 15px;
  }
}
</style>
