import axios from "axios"

export default {
  state: () => ({
    allStepsSettings: {
      widgetSettings: {
        isSurnameRequired: false,
        isMiddleNameRequired: false,
        buttons: {
          toGroupOrdening: "Группа",
          toPersonalOrdening: "Персона"
        },
      },
      additionalServices: {
        show: false,
        additionalCategories: [],
        excludedServices: [],
        excludedStaff: []
      }
    },
    format: 0,
    showBusyTime: false,
    stepsArr: [{
        step: "staff",
        stepName: "Выбор сотрудника",
        name: "Выбор сотрудника",
        id: 0,
      },
      {
        step: "time",
        stepName: "Выбор даты и времени",
        name: "Выбор даты и времени",
        id: 1,
      },
      {
        step: "service",
        stepName: "Выбор услуги",
        name: "Выбор услуги",
        id: 2,
      },
    ],
    stepOrderFormat2: ['service', 'staff', 'time'],
    emptySettings: {
      format: 0,
      step_order: {
        0: '',
        1: '',
        2: ''
      },
      steps: {
        staff: {
          name: ""
        },
        time: {
          name: ""
        },
        service: {
          name: ""
        },
      },
    },
    categoriesSettigs: {
      list: [],
      staffs: [],
      groupEventsCategories: []
    },

    loader: false,
    additionalServicesLoader: false
  }),
  mutations: {
    updateSettings(state, settings) {
      state.settings = settings
    },
    setStepsArr(state, value) {
      state.stepsArr = value
    },
    setStepsSettings(state, value) {
      state.allStepsSettings = value
      state.format = value.format;
      state.showBusyTime = value.steps.time.showBusyTime;

      console.log(value);

      let stepsArr = []

      let id = 0
      for (let key in value.step_order) {
        let step = value.step_order[key]
        stepsArr.push({
          step: step,
          id: id,
          stepName: value.steps[step].stepName,
          name: value.steps[step].name
        })
        id++
      }

      state.stepsArr = stepsArr
    },
    setStepsArrFormat2(state) {
      const stepArr = []

      stepArr[0] = state.stepsArr.find(i => i.step === state.stepOrderFormat2[0])
      stepArr[1] = state.stepsArr.find(i => i.step === state.stepOrderFormat2[1])
      stepArr[2] = state.stepsArr.find(i => i.step === state.stepOrderFormat2[2])

      state.stepsArr = [...stepArr]
    },
  },
  actions: {
    async getSettings(context) {
      context.state.loader = true;
      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/record_settings`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
      }).then(data => {
        context.commit('setStepsSettings', data.data.data.recordSettings)
        context.dispatch('getCategories')
        context.state.loader = false;
      }).catch((err) => {
        context.state.loader = false;
        context.commit('addDashboardNotification', {
          text: `Ошибка ${err.response.status}`,
          type: 'err'
        }, {
          root: true
        })


      })
    },
    async putSettings(context) {
      context.state.loader = true;
      const settings = {
        ...context.state.emptySettings
      }

      let stepsSettings = [...context.state.stepsArr]

      settings.format = context.state.format;

      stepsSettings.forEach((item, index) => {
        settings.step_order[index] = item.step
        settings.steps[item.step].stepName = item.stepName
        settings.steps[item.step].name = item.name
      })

      settings.widgetSettings = context.state.allStepsSettings.widgetSettings

      settings.steps.time.showBusyTime = context.state.showBusyTime

      const additionalServices = {
        show: context.state.allStepsSettings.additionalServices.show,
        additionalCategories: [],
        excludedServices: [],
        excludedStaff: []
      }

      context.state.categoriesSettigs.list.forEach(item => {
        if (item.isAdditional) {
          additionalServices.additionalCategories.push(item.id)
        }
        item.services.forEach(service => {
          if (!service.active) {
            additionalServices.excludedServices.push(service.id)
          }
        })
      })

      context.state.categoriesSettigs.staffs.forEach(item => {
        if (item.additional) {
          additionalServices.excludedStaff.push(item.id)
        }
      })

      settings.additionalServices = additionalServices
      settings.yandexMetrika = context.state.allStepsSettings.yandexMetrika
      
      console.log(settings);
      return await axios({
        method: 'post',
        url: `${window.apiPath}/company/record_settings`,
        data: settings,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
      }).then(() => {
        context.state.loader = false;
        context.commit('addDashboardNotification', {
          text: `Настройки обновлены!`,
          type: 'ok'
        }, {
          root: true
        })
      }).catch((err) => {
        context.state.loader = false;
        context.commit('addDashboardNotification', {
          text: `Ошибка ${err.response.status}`,
          type: 'err'
        }, {
          root: true
        })
      })
    },

    async getCategories(context) {
      context.state.additionalServicesLoader = true
      let salon_id = context.rootState.crm.crmLicense.acc_id
      context.state.categoriesSettigs.list = []
      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/${salon_id}/service_categories`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
      }).then((res) => {
        console.log(res);
        context.state.categoriesSettigs.list = res.data.data.data
        context.state.categoriesSettigs.list.forEach(item => {
          item.services = []
          item.open = false

          if (context.state.allStepsSettings.additionalServices.additionalCategories.findIndex(i => i === item.id) > -1) {
            item.isAdditional = 1
          } else {
            item.isAdditional = 0
          }
        })

        context.dispatch('getServices').then((res) => {
          console.log('res', res.data.data.data);
          res.data.data.data.forEach(i => {
            i.active = context.state.allStepsSettings.additionalServices.excludedServices.findIndex(id => id === i.id) === -1
            let categoryCurrentEvent = context.state.categoriesSettigs.list.find(category => category.id === i.category_id)
            console.log(i, categoryCurrentEvent);
            categoryCurrentEvent.services.push(i)

            if (i.capacity > 0 && context.state.categoriesSettigs.groupEventsCategories.findIndex(category => category.id === categoryCurrentEvent.id) === -1) {
              let disabledCapacityCurentCategory = context.state.allStepsSettings.widgetSettings.groupEvents.forbiddenCapacityCategories.findIndex(categoryId => categoryId === categoryCurrentEvent.id) > -1
              context.state.categoriesSettigs.groupEventsCategories.push({
                ...categoryCurrentEvent,
                disabledCapacity: disabledCapacityCurentCategory
              })
            }
          })

          context.state.additionalServicesLoader = false
        })
        context.dispatch('getStaff')
      })
    },
    async getServices(context) {
      let salon_id = context.rootState.crm.crmLicense.acc_id
      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/${salon_id}/services`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
      })
    },

    async getStaff(context) {

      let salon_id = context.rootState.crm.crmLicense.acc_id
      context.state.categoriesSettigs.staffs = []
      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/${salon_id}/staff`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          accept: 'application/json',
        },
      }).then((res) => {
        res.data.data.data.forEach(item => {
          item.additional = context.state.allStepsSettings.additionalServices.excludedStaff.findIndex(i => i === item.id) > -1
        })
        context.state.categoriesSettigs.staffs = res.data.data.data
        context.state.additionalServicesLoader = false
      })
    },
  },
  namespaced: true
}