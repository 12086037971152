import axios from 'axios'
import $router from '@/router'

export default {

  state: () => ({
    tariffs: [],
    tariffsInfo: {},
    license: {},
    licenseInfo: {
      tariff_info: {}
    },
    isPersonal: false,
    tariffsLength: 3,
    loadingLicense: false,
    licenseExisted: false,
    licenseActive: false,
    loadingActiveTestDrive: false,
    loadingTariffs: true,
    isTestDrive: false,
    isSpecialTarif: false
  }),
  getters: {
    getFilialId(state) {
      return state.license.acc_id
    }
  },
  mutations: {
    setTariffs(state, tariffs) {

      tariffs.forEach(tariff => {
        let price = 0
        let services = []

        if (!tariff.personal) {
          tariff.services.forEach(service => {
            price += service.price;

            services.push(service.service.name)
          })
          tariff.price = price.toLocaleString();
          tariff.properties = services;
        }
      });
      state.tariffsLength = tariffs.length
      state.tariffs = tariffs;

    },
    setLicenseStatus(state, licenseInfo) {
      if (licenseInfo === 'not found') {
        state.licenseExisted = false;
        state.licenseActive = false;

        state.licenseInfo = licenseInfo
        state.license = licenseInfo

      } else {
        state.licenseInfo = {
          ...licenseInfo
        }

        state.licenseActive = licenseInfo.isActive
        const resultObj = {};

        resultObj.acc_id = licenseInfo.acc_id
        resultObj.isActive = licenseInfo.isActive


        if (!licenseInfo.isActive) {
          resultObj.status = 1;

          const expDate = new Date(licenseInfo.license_expiry);
          const currentDate = new Date();

          const diff = currentDate - expDate
          const milliseconds = diff;
          const seconds = milliseconds / 1000;
          const minutes = seconds / 60;
          const hours = minutes / 60;
          const days = hours / 24;

          resultObj.days = Math.trunc(days)
          resultObj.date = expDate.toLocaleDateString()
        } else if (licenseInfo.isActive) {
          resultObj.status = 2;

          const expDate = new Date(licenseInfo.license_expiry);
          const currentDate = new Date();

          const diff = expDate - currentDate
          const milliseconds = diff;
          const seconds = milliseconds / 1000;
          const minutes = seconds / 60;
          const hours = minutes / 60;
          const days = hours / 24;

          resultObj.days = Math.trunc(days)
          resultObj.date = expDate.toLocaleDateString()
        }

        resultObj.tariffName = licenseInfo.tariff_name ? licenseInfo.tariff_name.split(' ')[0] : null;

        if (licenseInfo.previous_license && licenseInfo.previous_license.tariff_name) {
          resultObj.prevTariff = licenseInfo.previous_license.tariff_name.split(' ')[0];
        } else {
          resultObj.prevTariff = null
        }

        state.licenseExisted = !!licenseInfo.previous_license
        if (resultObj.tariffName === 'ТЕСТ-ДРАЙВ' && resultObj.isActive) {
          state.isTestDrive = true
        }
        state.license = {
          ...resultObj
        }
      }
    },
    setPersonalStatus(state, value) {
      state.isPersonal = value
    },
    setLoadingLicense(state, value) {
      state.loadingLicense = value
    },
    setLoadingActiveTestDrive(state, value) {
      state.loadingActiveTestDrive = value
    },
    setLoadingTariffs(state, value) {
      state.loadingTariffs = value
    },
    setTariffsInfo(state, value) {
      state.tariffsInfo = value
    }
  },
  actions: {
    async getTariffs(context) {
      context.commit('setLoadingTariffs', true)
      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/tariffs`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          Accept: `application/json`
        }
      }).then((request) => {
        context.commit('setTariffsInfo', request.data.data)
        const resultObj = {}
        let tariffsArr = []

        let isSpecialTarif = request.data.data.has_special_tariff

        context.state.isSpecialTarif = isSpecialTarif


        if (request.data.data.personal_offers.length > 0) {
          request.data.data.personal_offers.forEach(i => {
            i.personal = true;
            i.name = 'Персональный';
            i.properties = [
              "Ваш персональный тариф от Романа Васильева",
              "Дополнительная скидка от тарифа",
              "Данное предложение ограниченно по времени"
            ];
            i.lastPrice = i.old_price.toLocaleString();
            i.blueText = i.label;
            i.lastSecond = i.seconds_to_expiry
            i.timer = true;
            i.price = i.price.toLocaleString()
          })
          request.data.data.tariffs.forEach(i => {
            i.personal = false;
            i.name = i.name.toLowerCase()
          })
          resultObj.personal = true;
          context.commit('setPersonalStatus', true)

          tariffsArr = [...request.data.data.personal_offers, ...request.data.data.tariffs]
        } else if (request.data.data.test_drive_tariff && !isSpecialTarif) {
          // was_synchronized_earliers
          request.data.data.test_drive_tariff.isTestDrive = true
          tariffsArr = [request.data.data.test_drive_tariff]
        } else {
          request.data.data.tariffs.forEach(i => {
            context.commit('setPersonalStatus', false)

            i.name = i.name.toLowerCase()
          })
          tariffsArr = [...request.data.data.tariffs]
        }

        context.commit('setTariffs', tariffsArr);

        context.commit('setLoadingTariffs', false)

      }).catch(err => {
        context.commit('setLoadingTariffs', false)
        console.dir(err);

        context.commit('addDashboardNotification', {
          text: `При загрузке тарифов произошла ошибка ${err.response.status}`,
          type: 'err'
        })
      })
    },
    async getTarifLicense(context) {
      context.commit('setLoadingLicense', true)
      return await axios({
        method: 'get',
        url: `${window.apiPath}/company/license/status`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          Accept: `application/json`
        }
      }).then(request => {
        context.commit('setLoadingLicense', false)

        if (request.data.error.license) {
          context.commit('setLicenseStatus', request.data.error.license);
        } else {
          context.commit('setLicenseStatus', request.data.data);
        }
      }).catch(() => {
        context.commit('setLoadingLicense', false)
        // context.commit('setLicenseStatus', request.data.data);
      })
    },
    async activeTestDrive(context) {
      context.commit('setLoadingActiveTestDrive', true)
      return await axios({
        method: 'post',
        url: `${window.apiPath}/invoice/test_drive`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          Accept: `application/json`
        }
      }).then(() => {
        context.commit('setLoadingActiveTestDrive', false)
        context.commit('addDashboardNotification', {
          type: 'ok',
          text: 'Тест-драйв активирован'
        })
        localStorage.setItem('testDriveActive', 'active');
        $router.push('/dashboard/iframe/billing')
        context.dispatch('pageReload')
      }).catch(() => {
        context.commit('setLoadingActiveTestDrive', false)
        context.commit('addDashboardNotification', {
          type: 'err',
          text: 'Ошибка'
        })
      })
    },
    async activePromisedPayment(context) {
      context.commit('setLoadingLicense', true)
      return await axios({
        method: 'post',
        url: `${window.apiPath}/invoice/promised/payment`,
        headers: {
          Authorization: `${localStorage.getItem('authTokeType')} ${localStorage.getItem('authToken')}`,
          Accept: `application/json`
        }
      }).then(() => {
        context.dispatch('pageReload')
      }).catch(() => {
        context.commit('setLoadingLicense', false)
        context.commit('addDashboardNotification', {
          type: 'err',
          text: 'Ошибка'
        })
      })
    }
  }
}